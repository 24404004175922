
const rotaaberta_local='http://localhost:8000/';
const rotafechada_local='http://localhost:8000/api/';

const rotaaberta_producao = 'https://backend.invictos.app.br:8000/';
const rotafechada_producao = 'https://backend.invictos.app.br:8000/api/';

function isDevelopment() {
    return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
}

function rotafechada(){
    //Ele vai retornar apenas a origin do site
    //exemplo
    //http://localhost:8000
    let rota
    if(isDevelopment()){
        rota = rotafechada_local
    }else{
        rota = rotafechada_producao
    }

    //separando sem a porta
    let temp='';
    for (let i=rota.length-1;i>=0;i--){
        if (rota[i]===':'){
            temp=rota.substring(0,i);
            break;
        }
    }
   
    if (temp!=='')
    temp=`${temp}:8000/api/`;
    else temp=rotafechada_local;

    //  console.log('rota fechada:',temp);
    return temp;
}

function rotaaberta(){
    //Ele vai retornar apenas a origin do site
    //exemplo
    //http://localhost:8000
    let rota
    if(isDevelopment()){
        rota = rotaaberta_local
    }else{
        rota = rotaaberta_producao
    }
    // console.log('rota:',rota);
    let temp='';
    for (let i=rota.length-1;i>=0;i--){
        if (rota[i]===':'){
            temp=rota.substring(0,i);
            break;
        }
    }

    // console.log(rotafechada);
    if (temp!=='')
    temp=`${temp}:8000/`;
    else temp=rotaaberta_local;

    // console.log('rota aberta:',temp);
    return temp
}


module.exports={
    rotaaberta,
    rotafechada,
}