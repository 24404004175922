import React, { Component, useState } from 'react';
import { render } from 'react-dom';
import { Stage, Layer, Rect, Text, Shape, Circle, Line, Image, Transformer } from 'react-konva';
import Konva from 'konva';
import { Box } from '@mui/system';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import useImage from 'use-image';

// custom component that will handle loading image from url
// you may add more logic here to handle "loading" state
// or if loading is failed
// VERY IMPORTANT NOTES:
// at first we will set image state to null
// and then we will set it to native image instance when it is loaded
class URLImage extends React.Component {
    state = {
        image: null,
    };
    componentDidMount() {
        this.loadImage();
    }
    componentDidUpdate(oldProps) {
        if (oldProps.src !== this.props.src) {
            this.loadImage();
        }
    }
    componentWillUnmount() {
        this.image.removeEventListener('load', this.handleLoad);
    }
    loadImage() {
        // save to "this" to remove "load" handler on unmount
        this.image = new window.Image();
        this.image.src = this.props.src;
        this.image.addEventListener('load', this.handleLoad);
    }
    handleLoad = () => {
        // after setState react-konva will update canvas and redraw the layer
        // because "image" property is changed
        this.setState({
            image: this.image,
        });
        // if you keep same image object during source updates
        // you will have to update layer manually:
        // this.imageNode.getLayer().batchDraw();
    };
    render() {
        return (
            <Image
                x={this.props.x}
                y={this.props.y}
                image={this.state.image}
                ref={(node) => {
                    this.imageNode = node;
                }}
                draggable
            />
        );
    }
}
const pulseShape = (shape) => {
    // use Konva methods to animate a shape
    shape.to({
        scaleX: 1.5,
        scaleY: 1.5,
        onFinish: () => {
            shape.to({
                scaleX: 1,
                scaleY: 1,
            });
        },
    });
};

export default function Exemplos() {

    const [image] = useImage('https://konvajs.org/assets/lion.png');
    const [color, setColor] = useState('green');
    const [textDrag, setTextDrag] = useState({
        isDragging: false,
        x: 100,
        y: 100
    })
    const circleRef = React.useRef(null);

    const handleStageClick = () => {
        // this event demonstrates how to access Konva node using ref
        const shape = circleRef.current;
        pulseShape(shape);
    };

    const handleCircleClick = (e) => {
        // another way to access Konva nodes is to just use event object
        const shape = e.target;
        pulseShape(shape);
        // prevent click on stage
        e.cancelBubble = true;
    };

    const handleClick = () => {
        setColor(Konva.Util.getRandomColor());
    }

    const Rectangle = ({ shapeProps, isSelected, onSelect, onChange }) => {
        const shapeRef = React.useRef();
        const trRef = React.useRef();

        React.useEffect(() => {
            if (isSelected) {
                debugger;
                trRef.current.nodes([shapeRef.current]);
                trRef.current.getLayer().batchDraw();
            }
        }, [isSelected]);

        return (
            <React.Fragment>
                <Rect
                    onClick={onSelect}
                    onTap={onSelect}
                    ref={shapeRef}
                    {...shapeProps}
                    draggable
                    onDragEnd={(e) => {
                        onChange({
                            ...shapeProps,
                            x: e.target.x(),
                            y: e.target.y(),
                        });
                    }}
                    onTransformEnd={(e) => {
                        // transformer is changing scale of the node
                        // and NOT its width or height
                        // but in the store we have only width and height
                        // to match the data better we will reset scale on transform end
                        const node = shapeRef.current;
                        const scaleX = node.scaleX();
                        const scaleY = node.scaleY();

                        // we will reset it back
                        node.scaleX(1);
                        node.scaleY(1);
                        onChange({
                            ...shapeProps,
                            x: node.x(),
                            y: node.y(),
                            // set minimal value
                            width: Math.max(5, node.width() * scaleX),
                            height: Math.max(node.height() * scaleY),
                        });
                    }}
                />
                {isSelected && (
                    <Transformer
                        ref={trRef}
                        boundBoxFunc={(oldBox, newBox) => {
                            // limit resize
                            if (newBox.width < 5 || newBox.height < 5) {
                                return oldBox;
                            }
                            return newBox;
                        }}
                    />
                )}
            </React.Fragment>
        );
    };


    const [initialRectangles,] =useState([
        {
            x: 10,
            y: 10,
            width: 100,
            height: 100,
            fill: 'red',
            id: 'rect1',
        },
        {
            x: 150,
            y: 150,
            width: 100,
            height: 100,
            fill: 'green',
            id: 'rect2',
        },
    ]);

    const [rectangles, setRectangles] = React.useState(initialRectangles);
    const [selectedId, selectShape] = React.useState(null);

    return (
        <>
            <Stage
                width={600}
                height={600}
                onClick={()=>selectShape(null)}
                onTap={()=>selectShape(null)}
            >
                <Layer>

                    <Text text="Simples text no drag " />
                    <Text
                        text="Draggable Text"
                        x={textDrag.x}
                        y={textDrag.y}
                        draggable
                        fill={textDrag.isDragging ? 'green' : 'black'}
                        onDragStart={() => {
                            setTextDrag({ ...textDrag, isDragging: true })
                        }}
                        onDragEnd={(e) => {
                            setTextDrag({
                                ...textDrag,
                                isDragging: false,
                                x: e.target.x(),
                                y: e.target.y(),

                            })
                        }}
                    />
                    <Rect
                        x={20}
                        y={20}
                        width={50}
                        height={50}
                        fill={color}
                        shadowBlur={20}
                        onClick={handleClick}
                        draggable
                        shadowColor="blue"
                    />
                    <Shape
                        draggable
                        sceneFunc={(context, shape) => {
                            context.beginPath();
                            context.moveTo(20, 50);
                            context.lineTo(220, 80);
                            context.quadraticCurveTo(150, 100, 260, 170);
                            context.closePath();
                            // (!) Konva specific method, it is very important
                            context.fillStrokeShape(shape);
                        }}
                        fill="#00D2FF"
                        stroke="black"
                        strokeWidth={4}
                    />

                    <Circle x={200} y={100} radius={50} fill="green" draggable />

                    <Line
                        draggable
                        x={20}
                        y={200}
                        points={[0, 0, 100, 0, 100, 100]}
                        tension={0.5}
                        closed
                        stroke="black"
                        fillLinearGradientStartPoint={{ x: -50, y: -50 }}
                        fillLinearGradientEndPoint={{ x: 50, y: 50 }}
                        fillLinearGradientColorStops={[0, 'red', 1, 'yellow']}
                    />


                    <URLImage src="https://konvajs.org/assets/yoda.jpg" x={150} />

                    <Circle
                        ref={circleRef}
                        // x={window.innerWidth / 2}
                        // y={window.innerHeight / 2}
                        radius={80}
                        fill="red"
                        onClick={handleCircleClick}
                        onTap={handleCircleClick}
                        draggable
                    />

                    {rectangles.map((rect, i) => {
                        return (
                            <Rectangle
                                key={i}
                                shapeProps={rect}
                                isSelected={rect.id === selectedId}
                                onSelect={() => {
                                    selectShape(rect.id);
                                }}
                                onChange={(newAttrs) => {
                                    const rects = rectangles.slice();
                                    rects[i] = newAttrs;
                                    setRectangles(rects);
                                }}
                            />
                        );
                    })}

                    <Image image={image} draggable />

                </Layer>
            </Stage>
        </>
    )

}