import React, { useContext, useEffect, useState } from 'react';
import MDBox from "components/MDBox";
import MessagesContext from 'hooks/MessagesContext';
import AuthContext from "hooks/AuthContext";
import { useForm, useWatch } from 'react-hook-form';
import MDButton from 'components/MDButton';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import Cabecalho from './cabecalho';
import TiposPessoas from './tipospessoas';
import PessoaContatos from './pessoacontatos';
import PessoaEnderecos from './pessoaenderecos';
import { Icon, Tooltip } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MDTypography from 'components/MDTypography';
import Cadastro from "./index";

function DialogoPessoa(props) {

  const { open, handleVoltar, setPessoaAtual, pessoaAtual } = props;



  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          handleVoltar()
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen
      >
        <DialogTitle id="alert-dialog-title" sx={{ padding: '5px' }}>
          <MDBox width="100%">
            <MDBox display="flex" justifyContent="space-between">
              <MDTypography fontWeight='bold' ml={5} mt={1} variant='h4'>{'Cadastro de Pessoa'}</MDTypography>

              <Tooltip title="Fechar (Esc)">
                <MDButton size="large" sx={{ padding: 0 }} onClick={() => {
                  handleVoltar()

                }}>
                  <Icon>close</Icon>
                </MDButton>

              </Tooltip>
            </MDBox>
          </MDBox>
        </DialogTitle>


        <DialogContent dividers sx={{ backgroundColor: "#f1f4f9", borderColor: '#7bb5e4' }}>
          <DialogContentText id="alert-dialog-description">
            <Cadastro
              setPessoaAtual={setPessoaAtual}
              pessoaAtual={pessoaAtual}
      

            />


          </DialogContentText>
        </DialogContent>

        <DialogActions sx={{ padding: '7px', margin: 0.5 }}> {/*BOTÕES*/}
          <MDBox width="100%">
            <MDBox width="100%" display="flex" justifyContent="space-between">
              <MDButton onClick={() => {
                handleVoltar()
              }} variant="gradient" type="button" color="secondary">
                voltar
              </MDButton>
              {/* <MDButton
                // onClick={() => {
                //   dadosVenda?.id > 0 ? handleClickConcluirVenda() : handleClickGravar()
                // }}
                variant="gradient"
                type="submit"
                //type="button" 
                color="info">
                SALVAR
              </MDButton> */}
            </MDBox>
          </MDBox>
        </DialogActions>
      </Dialog >
    </>
  )

}

export default DialogoPessoa;
