import axios from 'axios';
import { rotaaberta } from './urlconf';


function copiainicioefim(ptoken) {

    if (ptoken)
        return `${ptoken.substring(0, 5)}...${ptoken[ptoken.length - 3]}${ptoken[ptoken.length - 2]}${ptoken[ptoken.length - 1]}`;
    else return '';
}


let dadoslocalstorage = JSON.parse(localStorage.getItem('dadossessao'));

const api = axios.create({

});

const tokenheader = dadoslocalstorage?.token ? dadoslocalstorage?.token : '';

api.defaults.headers.common['authorization'] = `bearer ${tokenheader || ''}`;
api.defaults.withCredentials = true;

//console.log('Setando Header ', copiainicioefim(tokenheader));

let failedRequestQueue = [];
let isRefreshing = false;

//Usando um interceptador das requisicoes
api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // Se a requisição der erro, verifica se o erro é de autenticação
        if (error.response.status === 401) {
            // Se o erro for de autenticação, verifica se o erro foi de token expirado
            //console.log('erro interceptor:',error.response.data?.name)
            if (error.response.data?.name === 'TokenExpiredError') {

                //console.log('erro de token expirado');

                // Recupera toda a requisição que estava sendo feita e deu erro para ser refeita após o refresh token
                const originalConfig = error.config;

                // Verifica se já existe uma request de refreshToken acontecendo
                if (!isRefreshing) {
                    // Se não existir, inicia a requisição de refreshToken
                    isRefreshing = true;
                    //recarrega do storage
                    dadoslocalstorage = JSON.parse(localStorage.getItem('dadossessao'));

                    // Faz uma requisição de refreshToken
                    //console.log('requisitando um refresh. Token atual: ', copiainicioefim(dadoslocalstorage?.token || ''));

                    //aqui eu chamo com axios e nao com API, para nao entrar em loop infinito caso de algum erro na geracao do refreshtoken
                    axios.defaults.headers.common['authorization'] = `bearer ${dadoslocalstorage?.token || ''}`;
                    axios
                        .post(`${rotaaberta()}refreshtoken/`, { refreshToken: dadoslocalstorage?.refreshToken || '' })
                        .then((response) => {

                            const { token, refreshToken } = response.data;
                            //console.log('resposta do refresh ', copiainicioefim(token));
                            
                            //resgato o que estava no localstorage e altero só o token e refreshtoken
                            let dadoslocalstorage = JSON.parse(localStorage.getItem('dadossessao'));

                            if (!dadoslocalstorage)
                              dadoslocalstorage = {}

                            dadoslocalstorage.token=token;
                            dadoslocalstorage.refreshToken=refreshToken;
                      
                            localStorage.setItem('dadossessao', JSON.stringify(dadoslocalstorage));

                            //******************************************** */

                            //refaz os cabecalhos
                            api.defaults.headers.common['authorization'] = `bearer ${token}`;
                            api.defaults.withCredentials = true;

                            // Faz todas as requisições que estavam na fila e falharam
                            failedRequestQueue.forEach((request) =>
                                request.onSuccess(token)
                            );
                            // Limpa a fila de requisições que falharam
                            failedRequestQueue = [];
                        })
                        .catch((err) => {
                            // Retorna os erros que estão salvos na fila de requisições que falharam
                            failedRequestQueue.forEach((request) => request.onFailure(err));
                            // Limpa a fila de requisições que falharam
                            failedRequestQueue = [];
                            localStorage.removeItem('dadossessao');
                        })
                        .finally(() => {
                            // Indica que a requisição de refreshToken acabou
                            isRefreshing = false;
                        });
                }

                // Usando a Promise no lugar do async await, para que a requisição seja feita após o refresh token
                return new Promise((resolve, reject) => {
                    // Adiciona a requisição na fila de requisições que falharam com as informações necessárias para refazer a requisição novamente
                    failedRequestQueue.push({
                        // Se a requisição der sucesso, chama o onSuccess
                        onSuccess: (token) => {

                            // Adiciona o novo token gerado no refresh token no header de autorização
                            originalConfig.headers["authorization"] = `bearer ${token}`;

                            //console.log('refazendo a chamada ', originalConfig);
                            // Faz a requisição novamente passando as informações originais da requisição que falhou
                            resolve(api(originalConfig));
                        },
                        // Se a requisição der erro, chama o onFailure
                        onFailure: (err) => {
                            // Se não for possivel refazer a requisição, retorna o erro
                            reject(err);
                        },
                    });
                });
            } else {
                // Caso der erro desloga o usuário
                //localStorage.removeItem('dadossessao');
            }
        }

        // Se não cair em nenhum if retorna um error padrão
        return Promise.reject(error);
    }
);



export default api;