import React, { useContext, useEffect, useState } from 'react';
import MDBox from "components/MDBox";
import MessagesContext from 'hooks/MessagesContext';
import AuthContext from "hooks/AuthContext";
import { useForm, useWatch } from 'react-hook-form';
import MDButton from 'components/MDButton';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import Cabecalho from './cabecalho';
import AssinaturaModulos from './assinaturamodulos';
import { formataFloat } from "commons/utils";
//INDEX QUE COMANDA O CABECALHO E O MODULO ASSINATURAS

function Cadastro(props) {

  const { usuarioAtual, setUsuarioAtual, empresaAtual, camposEmpresa } = props;
  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;
  const authContext = useContext(AuthContext);
  const { getSessao } = authContext;
  const sessaolocal = getSessao();
  const { handleSubmit, control, setValue, getValues, setFocus } = useForm({});

  const [opcoesAtivo, setOpcoesAtivo] = useState([
    { value: -1, label: '<Selecione uma opção>' },
    { value: 'S', label: 'Sim' },
    { value: 'N', label: 'Não' },
  ]);

  const [periodicidade, setPeriodicidade] = useState([
    { value: -1, label: '<Selecione uma opção>' },
    { value: 'S', label: 'Semestral' },
    { value: 'M', label: 'Mensal' },
    { value: 'T', label: 'Trimestral' },
    { value: 'A', label: 'Anual' },
  ])

  const [opcoesModulosSistema, setOpcoesModulosSistema] = useState([]);

  const [recarregaComponenteOpcoes, setRecarregaComponenteOpcoes] = useState(false);

  const [dadosAssinaturas, setDadosAssinaturas] = useState({});
  const [assinaturaAtual, setAssinaturaAtual] = useState();

  const onSubmit = (data) => {
    handleClickGravar(data);
  }

  const validaCampos = (data) => {

    let camposObrigatorios = ``;

    if (!data.valor_base) {
      camposObrigatorios = `${camposObrigatorios}/valor_base`
    }

    if (!data.ativo) {
      camposObrigatorios = `${camposObrigatorios}/Ativo`
    }

    if (!data.periodicidade) {
      camposObrigatorios = `${camposObrigatorios}/Periodicidade`
    }

    if (camposObrigatorios !== '') {
      throw new Error(`Campos obrigatórios: ${camposObrigatorios}`)
    }
  }

  const handleClickGravar = async (data) => {

    //tratando os campos autocomplete
    data.ativo = data.ativo?.value ? data.ativo.value : '';
    data.periodicidade = data.periodicidade?.value ? data.periodicidade.value : '';

    try {
      validaCampos(data);
    }
    catch (error) {
      MensagemErro(`${error.message}`);
      return;
    }



    try {
      const dados = { empresa_id: sessaolocal.empresa_id || null };

      //pegando do OBJ do RHF apenas os campos que sao do empresa

      dados.empresa_id = empresaAtual
      dados.ativo = data.ativo;
      dados.valor_base = data.valor_base;
      dados.valor_total = data.valor_total;
      dados.data = data.data;
      dados.dia_vencimento = data.dia_vencimento;
      dados.periodicidade = data.periodicidade;
      dados.data_cancelamento = data.data_cancelamento;
      dados.data_limite_uso = data.data_limite_uso;
      dados.observacao = data.observacao;

      let retorno;

      if (!dadosAssinaturas.length > 0) { //rota post inserir

        let url = `${rotafechada()}assinaturas`
        retorno = await api.post(url, dados);

        //codigoAssinatura = retorno.data.data.id;

        setRecarregaComponenteOpcoes(!recarregaComponenteOpcoes);

        MensagemAviso(`Gravado com sucesso!`);

        await abrirAssinatura();
      }
      else { //rota put editar

        let url = `${rotafechada()}assinaturas/${dadosAssinaturas[0].id}`
        MostraTelaEspera('Gravando usuário'); //Abre tela de espera
        retorno = await api.put(url, dados);
        FechaTelaEspera(); //Fecho tela de espera

        setRecarregaComponenteOpcoes(!recarregaComponenteOpcoes);

        MensagemAviso(`Alterado com sucesso!`);

        await abrirAssinatura();
      }

    }
    catch (error) {
      FechaTelaEspera(); //Fecho tela de espera
      console.log(error);
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const abrirAssinatura = async () => {

    let url = '';
    let retorno;
    let opcoesModulosSistemaTemp = [];

    try {
      url = `${rotafechada()}modulos_sistema?limit=-1&adicional=S`;
      retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;
      opcoesModulosSistemaTemp = [{ id: -1, value: -1, label: '<Selecione um módulo>' }];

      data.map((item) => {
        opcoesModulosSistemaTemp.push({ id: item.id, value: item.id, label: item.caption })
      })

      setOpcoesModulosSistema(opcoesModulosSistemaTemp);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }

    try {
      let url = `${rotafechada()}assinaturas?empresa_id=${empresaAtual}`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;

      setDadosAssinaturas(data);
      setAssinaturaAtual(data[0].id);

      if (!data.length > 0) {
        MensagemErro(`Empresa ${empresaAtual} não possui assinatura`);
        return;
      }

      setValue('id', data[0].id);
      setValue('valor_base', formataFloat(data[0].valor_base));
      setValue('valor_total', formataFloat(data[0].valor_total));
      setValue('data', data[0].data);
      setValue('dia_vencimento', data[0].dia_vencimento);
      setValue('data_cancelamento', data[0].data_cancelamento);
      setValue('data_limite_uso', data[0].data_limite_uso);
      setValue('observacao', data[0].observacao);

      let temp_periodicidade = data[0].periodicidade
      setValue(`periodicidade`,
        {
          value: temp_periodicidade === 'S' ? 'S' : (temp_periodicidade === 'M' ? 'M' : (temp_periodicidade === 'T' ? 'T' : 'A')),
          label: temp_periodicidade === 'S' ? 'Semanal' : (temp_periodicidade === 'M' ? 'Mensal' : (temp_periodicidade === 'T' ? 'Trimestral' : 'Anual'))
        });

      setValue(`ativo`, { value: data[0].ativo === 'S' ? 'S' : 'N', label: data[0].ativo === 'S' ? 'Sim' : 'Não' });

      setRecarregaComponenteOpcoes(!recarregaComponenteOpcoes);

    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
      MensagemErro(`Empresa ${empresaAtual} não possui assinatura`);
    }
  }

  useEffect(async () => {
    await abrirAssinatura();
  }, [])

  return (
    <MDBox p={2}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Cabecalho
          control={control}
          opcoesAtivo={opcoesAtivo}
          periodicidade={periodicidade}
          getValues={getValues}
        />
        <MDBox width="100%" display="flex" justifyContent="flex-end">
          <MDButton variant="gradient" color="info" type="submit">
            Gravar
          </MDButton>
        </MDBox>

        {
          parseInt(assinaturaAtual, 10) > 0 ?
            <AssinaturaModulos
              control={control}
              getValues={getValues}
              setValue={setValue}
              assinaturaAtual={assinaturaAtual}
              dadosAssinaturas={dadosAssinaturas}
              setDadosAssinaturas={setDadosAssinaturas}
              handleSubmit={handleSubmit}
              recarregaComponenteOpcoes={recarregaComponenteOpcoes}
              opcoesModulosSistema={opcoesModulosSistema}
              opcoesAtivo={opcoesAtivo}
            //parametrosPessoa={parametrosPessoa}
            />
            : false
        }

      </form>
    </MDBox>
  )
}

export default Cadastro;
