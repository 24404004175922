import React, { useContext, useState, useEffect } from "react";
import SearchIcon from '@mui/icons-material/Search';
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MessagesContext from "hooks/MessagesContext";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import DialogoConfirma from 'componentes/DialogoConfirma';
import FormField from "componentes/FormField";
import { Checkbox, Divider, FormControlLabel, FormGroup, Typography } from "@mui/material";
import PesquisaEmpresa from "../../componentes/pesquisaempresa";
import MDButton from "components/MDButton";


function ImportaEmpresaAdmin(props) {

  const { empresaAtual, nomeEmpresaAtual } = props;

  const messageContext = useContext(MessagesContext);
  const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;
  const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });
  const [recarregaTabela, setRecarregaTabela] = useState(false);
  const [empresaModeloId, setEmpresaModeloId] = useState('');
  const [nomeEmpresaModeloId, setNomeEmpresaModeloId] = useState('');
  const [confPesquisaDinamica, setConfPesquisaDinamica] = useState({});
  const [memoLog, setMemoLog] = useState('');
  const [apagaProdutos, setApagaProdutos] = useState(false);
  const [apagaProjetos, setApagaProjetos]= useState(false);
  const [apagaVendas, setApagaVendas] = useState(false);
  const [apagaPessoas, setApagaPessoas] = useState(false);
  const [apagaParametros, setApagaParametros] = useState(false);
  const [importaProjetoeProduto,setImportaProjetoeProduto]= useState(false);
  const [importaPessoas,setImportaPessoas]= useState(false);
  const [importaFinanceiro,setImportaFinanceiro]= useState(false);

  const baseUrl = `${rotafechada()}empresa_usuarios`;


  const importaDados = async () => {

    setDialogoOpen({ ...dialogoOpen, visible: false });
    
    if (!((importaProjetoeProduto)||(importaPessoas)||(importaFinanceiro))){
      MensagemErro('Selecione a opção que deseja importar');
      return;
    }

    if (!(empresaModeloId)){
      MensagemErro('Selecione a empresa modelo !');
      return;
    }

    try {
      const id = dialogoOpen.id
      MostraTelaEspera('Aguarde a importação');
      const retorno = await api.post(`${rotafechada()}admin/empresas/importar`, {
        empresa_id_origem: empresaModeloId,
        empresa_id_destino: empresaAtual,
        importaprojetoeproduto:importaProjetoeProduto,
        importapessoa:importaPessoas,
        importafinanceiro: importaFinanceiro
      });
      FechaTelaEspera();
      MensagemAviso('Importado com sucesso!');
      setMemoLog('');
    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      const erro = error.response?.data?.erro || msg;
      setMemoLog(erro)
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleClickPesquisaEmpresa = async () => {
    setConfPesquisaDinamica({ ...confPesquisaDinamica, open: true });
  }

  useEffect(async () => {

  }, [])

  const handleClickIniciarImportacao = async (id) => {
    setDialogoOpen({
      ...dialogoOpen,
      visible: true,
      id,
      title: 'Confirma Importação',
      message: `Certeza que importar os dados da empresa  ${empresaModeloId}-${nomeEmpresaModeloId}`,
      funcao: 'importaDados'
    });
  }



  const handleConfirmDialogo = () => {
    if (dialogoOpen.funcao === 'importaDados') {
      importaDados();
    } 
  }


  const handleFuncaoSelecaoPesquisaDinamica = (codigo, datasetPesquisa) => {
    /* Essa funcao é chamada pelo componente de pesquisa dinamica quando um registro é escolhido
    ele retorna os campos que foram configurados*/
    setConfPesquisaDinamica({ ...confPesquisaDinamica, open: false });
    setNomeEmpresaModeloId(datasetPesquisa.fantasia);
    setEmpresaModeloId(codigo);
  }

  const handleBlurEmpresaModelo = async () => {

    if (!(parseInt(empresaModeloId, 10) > 0)) {
      setNomeEmpresaModeloId('');
      return;
    }

    try {
      let url = `${rotafechada()}empresas?all=true&id=${empresaModeloId}`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;
      if ((data) && (data?.length > 0)) {
        setNomeEmpresaModeloId(data[0].fantasia || '');
      } else {
        setNomeEmpresaModeloId('');
        setEmpresaModeloId('');
      }
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  return (
    <>

      <DialogoConfirma
        open={dialogoOpen.visible}
        title={dialogoOpen.title}
        message={dialogoOpen.message}
        handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
        handleConfirm={handleConfirmDialogo}
      />

      <PesquisaEmpresa
        open={confPesquisaDinamica.open || false}
        handleClose={
          () => setConfPesquisaDinamica({ ...confPesquisaDinamica, open: false })
        }
        handleClick={handleFuncaoSelecaoPesquisaDinamica}
      />

      <MDBox>
        <MDBox mt={0} mb={4} textAlign="center">
          <MDBox mb={1}>
            <MDTypography variant="h4" fontWeight="bold">
              {`Importa Banco de Dados`}
            </MDTypography>
          </MDBox>
        </MDBox>

        <MDBox>
          <Grid container>
            <Grid item xs={12} sm={2}>{/*empresaModeloId*/}
              <FormField
                type='text'
                label='Empresa Modelo'
                name='empresaModeloId'
                rows={1}
                multiline={false}
                required={true}
                disabled={false}
                value={empresaModeloId}
                onChange={(e) => setEmpresaModeloId(e.target.value)}
                {...props}
                inputProps={{
                  onKeyUp: (e) => {
                    e.preventDefault();
                    if (e.key === 'F9') {
                      handleClickPesquisaEmpresa();
                    }

                  },
                  onBlur: (e) => {
                    e.preventDefault();
                    handleBlurEmpresaModelo();
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={1}>{/*botao da empresa_modelo_id*/}
              <MDBox width="100%" height='100%' display="flex" justifyContent='center' alignItems='center' >
                <SearchIcon sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    handleClickPesquisaEmpresa();
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={9}>{/*label do empresa_modelo_id*/}
              <MDBox width="100%" height='100%' display="flex" alignSelf='flex-start' alignItems='center' >
                <Typography variant='caption'>{nomeEmpresaModeloId}</Typography>
              </MDBox>
            </Grid>

            <Grid item xs={12} sm={12} mt={2}>
            <MDBox >
                <FormGroup>
                <FormControlLabel ml={2} control={<Checkbox checked={importaProjetoeProduto} onChange={(e) => setImportaProjetoeProduto(e.target.checked)} />} label="Importar Projetos e Produtos" />
                  <FormControlLabel ml={2} control={<Checkbox checked={importaPessoas} onChange={(e) => setImportaPessoas(e.target.checked)} />} label="Importar Pessoas" />
                  <FormControlLabel ml={2} control={<Checkbox checked={importaFinanceiro} onChange={(e) => setImportaFinanceiro(e.target.checked)} />} label="Importar Financeiro" />
              </FormGroup>
              <MDButton color='warning' onClick={handleClickIniciarImportacao} ml={2} >Importar da Empresa Escolhida</MDButton>
              </MDBox>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Divider fullWidth />
            </Grid>

            <Grid item xs={12} sm={12} mt={12}>
              <FormField
                type='text'
                label='Log'
                name='memoLog'
                rows={10}
                multiline={true}
                required={true}
                disabled={false}
                value={memoLog}
              />
            </Grid>

          </Grid>
        </MDBox>

      </MDBox>
    </>
  );
}

export default ImportaEmpresaAdmin;
