import api from 'commons/api';
import { rotafechada } from 'commons/urlconf';
import TabelaPadrao from 'componentes/TabelaPadrao';
import MessagesContext from 'hooks/MessagesContext';
import React, { useContext, useEffect, useState } from 'react';
import { formatDate } from 'commons/utils';
import { format } from 'date-fns';


const TabelaOpcoes = (props) => {

    const { dataInicial, dataFinal, recarregaTabela, filtroAtivo } = props;

    const messageContext = useContext(MessagesContext);
    const { MensagemErro, FechaTelaEspera, MostraTelaEspera } = messageContext;

    const [countAll, setCountAll] = React.useState(100);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [colunasTable, setColunasTable] = React.useState([]);
    const [linhas, setLinhas] = React.useState([]);

    const carregaTabela = async () => {
        //console.log("Carrega tabela");


        try {

            let complementoUrl = '';

            if (dataInicial && dataFinal) {
                let datainicialpesquisa = new Date(format(dataInicial, 'yyyy-MM-dd 00:00:00'));
                let datafinalpesquisa = new Date(format(dataFinal, 'yyyy-MM-dd 23:59:59'));
                complementoUrl = `${complementoUrl}data_ini=${datainicialpesquisa}`;
                complementoUrl = `${complementoUrl}&data_fin=${datafinalpesquisa}`;
            }

            if (filtroAtivo?.value) {
                complementoUrl = `${complementoUrl}&ativo=${filtroAtivo.value}`
            }

            MostraTelaEspera('Buscando estatísticas gerais de todas as empresas...');

            let url = `${rotafechada()}admin/empresas/estatisticasfull?${complementoUrl}`
            const retorno = await api.get(url);

            //console.log("URL da pesquisa da tabela", url)

            const { data, countAll: countAllretorno } = retorno.data;


            const dadostemp = [];

            const colunasTabletemp = [
                {
                    id: 'empresa_id',
                    numeric: false,
                    disablePadding: true,
                    label: 'ID'
                },
                {
                    id: 'codigo_erp',
                    numeric: false,
                    disablePadding: true,
                    label: 'Cod. ERP'
                },
                {
                    id: 'fantasia',
                    numeric: false,
                    disablePadding: true,
                    label: 'Fantasia'
                }, {
                    id: 'razao_social',
                    numeric: false,
                    disablePadding: true,
                    label: 'Razão Social'
                },
                {
                    id: 'vendas',
                    numeric: false,
                    disablePadding: true,
                    label: 'Vendas'
                },
                {
                    id: 'orcamentos',
                    numeric: false,
                    disablePadding: true,
                    label: 'Orçamentos'
                },
                {
                    id: 'tempera',
                    numeric: false,
                    disablePadding: true,
                    label: 'Ped.de Têmpera'
                },
                {
                    id: 'ultimo_acesso',
                    numeric: false,
                    disablePadding: true,
                    label: 'Último Acesso'
                },
                {
                    id: 'projetos',
                    numeric: false,
                    disablePadding: true,
                    label: 'Última Modificação Projetos'
                },
                {
                    id: 'projeto_produtos',
                    numeric: false,
                    disablePadding: true,
                    label: 'Última Modificação Projeto Produtos'
                },
                {
                    id: 'projeto_possibilidades',
                    numeric: false,
                    disablePadding: true,
                    label: 'Última Modificação Projeto Possibilidades'
                },
                {
                    id: 'projeto_componentes',
                    numeric: false,
                    disablePadding: true,
                    label: 'Última Modificação Projeto Componentes'
                },
                {
                    id: 'projeto_medidas',
                    numeric: false,
                    disablePadding: true,
                    label: 'Última Modificação Projeto Medidas'
                },
                {
                    id: 'produtos',
                    numeric: false,
                    disablePadding: true,
                    label: 'Última Modificação Produtos '
                },
            ]


            //mapeando os dados para a "rows"
            data.map((item, index) => {
                dadostemp.push({
                    empresa_id: item.empresa_id,
                    codigo_erp: item.codigo_erp,
                    fantasia: item.fantasia,
                    razao_social: item.razaosocial,
                    ultimo_acesso: item.ultimo_acesso,
                    vendas: item.quantidade_de_vendas,
                    orcamentos: item.quantidade_de_orcamentos,
                    tempera: item.quantidade_de_pedido_tempera,
                    projetos: item.modificacao_projetos,
                    projeto_produtos: item.modificacao_projeto_produtos,
                    projeto_possibilidades: item.modificacao_projeto_possibilidades,
                    projeto_componentes: item.modificacao_projeto_componentes,
                    projeto_medidas: item.modificacao_projeto_medidas,
                    produtos: item.ultima_modificacao_produtos,
                })
            })


            setColunasTable([...colunasTabletemp]);
            setLinhas([...dadostemp]);
            setCountAll(countAllretorno);
            FechaTelaEspera();
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const ordenaCampos = async () => {
        //console.log("Passou na Ordena campos");

        let dadosOrdenar = linhas
        //console.log("dadosOrdenar:::::",dadosOrdenar)
        dadosOrdenar.sort(compareNumbers);
        //dadosOrdenar.sort();
        //console.log("dadosOrdenar:::::",dadosOrdenar)
        setLinhas([...dadosOrdenar]);
    }

    function compareNumbers(a, b) {
        //console.log("Passou no BubbleSort","order",order,"orderBy",orderBy,"a[orderBy]" ,a[orderBy] ,"b[orderBy]" ,b[orderBy] );

        if (order == 'asc')
            return a[orderBy] > b[orderBy] ? 1 : -1
        if (order == 'desc')
            return a[orderBy] > b[orderBy] ? -1 : 1
        // if (order == 'asc'){
        //     if(a[orderBy] < b[orderBy])
        //         return -1
        //     if(a[orderBy] > b[orderBy])
        //         return 1
        //     return 0
        // }
        // if (order == 'desc'){
        //     if(a[orderBy] > b[orderBy])
        //         return -1
        //     if(a[orderBy] < b[orderBy])
        //         return 1
        //     return 0
        // }
    }

    useEffect(async () => {
        carregaTabela();

    }, [recarregaTabela])

    useEffect(async () => {
        ordenaCampos();

    }, [order, orderBy])

    return (

        <TabelaPadrao
            colunas={colunasTable}
            linhas={linhas}
            countAll={countAll}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
        />
    )
}


export default TabelaOpcoes;