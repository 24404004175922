import React, { useState, useEffect, useContext } from 'react';

// @mui material components
import Grid from "@mui/material/Grid";
import { Icon } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

//My Componentes
import FormField from "componentes/FormField";
//My componentes e functions
import MessagesContext from "hooks/MessagesContext";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import AuthContext from 'hooks/AuthContext';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';



function Cadastro(props) {

    const messageContext = useContext(MessagesContext);
    const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;

    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();

    const { setCodigoAbrir, codigoAbrir, setAba } = props;

    const [id, setId] = useState('');
    const [nome, setNome] = useState('');
    const [mascara, setMascara] = useState('');

    const [ativo, setAtivo] = useState({
        label: 'Sim',
        value: 'S'
    });
    const optionAtivo = [
        {
            label: 'Não',
            value: 'N'
        },
        {
            label: 'Sim',
            value: 'S'
        },
    ]

    const [usoInterno, setUsoInterno] = useState({
        label: 'Não',
        value: 'N'
    });
    const optionUsoInterno = [
        {
            label: 'Não',
            value: 'N'
        },
        {
            label: 'Sim',
            value: 'S'
        },
    ]

    const [tipo, setTipo] = useState({
        label: '',
        value: ''
    });
    const optionTipo = [
        {
            label: 'Despesa',
            value: 'D'
        },
        {
            label: 'Receita',
            value: 'R'
        },
        {
            label: "Todos",
            value: "T"
          },
    ]

    const [mascaraTipo, setMascaraTipo] = useState({
        label: '',
        value: ''
    });
    const optionMascaraTipo = [
        {
            label: 'Título',
            value: 'T'
        },
        {
            label: 'Analítico',
            value: 'A'
        },
        {
            label: 'Soma',
            value: 'S'
        },
    ]

    const buscaDados = async () => {

        if (!parseInt(props.codigoAbrir, 10) > 0)
            return;

        try {
            const retorno = await api.get(`${rotafechada()}plano_contas?id=${props.codigoAbrir}`);
            const { data, count } = retorno.data;

            if (!count > 0) {
                MensagemErro('Nenhuma informação foi selecionada');
                return;
            }

            if (data[0].empresa_id !== sessaolocal.empresa_id) {
                MensagemErro('Registro de outra empresa!');
                return;
            }

            setId(data[0].id);
            setNome(data[0].nome);
            setMascara(data[0].mascara);

            if (data[0].ativo === 'N') {
                setAtivo(optionAtivo[0])
            }
            else setAtivo(optionAtivo[1])

            setTipo(data[0].tipo === 'D' ? optionTipo[0] : optionTipo[1])
            setUsoInterno(data[0].uso_interno === 'N' ? optionUsoInterno[0] : optionUsoInterno[1])
            setMascaraTipo(data[0].mascara_tipo === 'T' ? optionMascaraTipo[0] : data[0].mascara_tipo === 'A' ? optionMascaraTipo[1] : optionMascaraTipo[2])

        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }

    }

    const handleClickGravar = async (e) => {
        e.preventDefault();

        try {
            MostraTelaEspera('Gravando o plano de contas...');
            let retorno;


            const dados = {
                empresa_id: sessaolocal.empresa_id || null,
                nome,
                tipo: tipo.value,
                mascara,
                mascara_tipo: mascaraTipo.value,
                uso_interno: usoInterno.value,
                ativo: ativo.value,
            }
            //console.log(dados);

            if (codigoAbrir && parseInt(codigoAbrir, 10) > 0)
                retorno = await api.put(`${rotafechada()}plano_contas/${codigoAbrir}`, dados);
            else {
                retorno = await api.post(`${rotafechada()}plano_contas`, dados);
                props.setCodigoAbrir(retorno.data.data.id || 0)
            }

            MensagemAviso('Gravado com sucesso!');
            FechaTelaEspera();
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const limpaImputs = async () => {

        setCodigoAbrir('');
        setNome('');
        setMascara('');
        setTipo('');
        setUsoInterno(optionUsoInterno[0]);
        setMascaraTipo('');
        setAtivo(optionAtivo[1]);
        setId('');

    }

    useEffect(() => {
        buscaDados();

    }, [codigoAbrir])


    return (
        <MDBox p={2}>
            <MDBox width="100%" display="flex" justifyContent="space-between">
                <MDButton
                    variant="gradient"
                    color="secondary"
                    type="button"
                    onClick={() => setAba(0)}
                >Voltar</MDButton>
                <MDButton
                    variant="gradient"
                    circular
                    color="info"
                    type="button"
                    onClick={limpaImputs}
                >
                    <Icon>add</Icon>
                </MDButton>
            </MDBox>

            <form onSubmit={handleClickGravar}>
                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12} sm={3}> {/* ID */}
                        <FormField
                            type="text"
                            label="ID"
                            value={id || ''}
                            disabled={true}
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}> {/* TIPO */}
                        <AutoCompleteStyled
                            //defaultValue={}
                            options={optionTipo}
                            getOptionLabel={(option) => option.label || ''}
                            renderInput={(params) => <FormField {...params} label="Tipo" required />}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            value={tipo}
                            onChange={(event, newValue) => {
                                setTipo(newValue);
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}> {/* USO_INTERNO */}
                        <AutoCompleteStyled
                            //defaultValue={optionAtivo ? optionAtivo[0] : { value: 'N' }}
                            options={optionUsoInterno}
                            getOptionLabel={(option) => option.label || ''}
                            renderInput={(params) => <FormField {...params} label="Uso interno" required />}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            value={usoInterno}
                            onChange={(event, newValue) => {
                                setUsoInterno(newValue);
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}> {/* ATIVO */}
                        <AutoCompleteStyled
                            //defaultValue={optionAtivo ? optionAtivo[0] : { value: 'N' }}
                            options={optionAtivo}
                            getOptionLabel={(option) => option.label || ''}
                            renderInput={(params) => <FormField {...params} label="Ativo" required />}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            value={ativo}
                            onChange={(event, newValue) => {
                                setAtivo(newValue);
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12}> {/* NOME */}
                        <FormField
                            required
                            type="text"
                            label="Nome"
                            value={nome}
                            onChange={(e) => setNome(e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12}> {/* MASCARA */}
                        <FormField
                            type="text"
                            label="Mascara"
                            value={mascara}
                            onChange={(e) => setMascara(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}> {/* MASCARA_TIPO */}
                        <AutoCompleteStyled
                            //defaultValue={}
                            options={optionMascaraTipo}
                            getOptionLabel={(option) => option.label || ''}
                            renderInput={(params) => <FormField {...params} label="Mascara Tipo" required />}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            value={mascaraTipo}
                            onChange={(event, newValue) => {
                                setMascaraTipo(newValue);
                            }}
                        />
                    </Grid>

                </Grid>

                <MDBox p={2}>{/*Botão gravar */}
                    <MDBox mt={3} width="100%" display="flex" justifyContent="flex-end">
                        <MDButton
                            variant="gradient"
                            color="info"
                            //onClick={handleClickGravar}
                            type="submit"
                        >
                            Gravar
                        </MDButton>
                    </MDBox>
                </MDBox>

            </form>

        </MDBox >
    )

}

export default Cadastro;