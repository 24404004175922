import { Card, Collapse, Grid, IconButton, Typography } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MDBox from "components/MDBox";
import { useContext, useState } from "react";
import MDButton from "components/MDButton";
import DialogoConfirma from "componentes/DialogoConfirma";
import MessagesContext from "hooks/MessagesContext";
import { rotafechada } from "commons/urlconf";
import api from "commons/api";


export default function AtualizacaoAdmin(props) {

    const { openCollapseAtualizacao, handleClickCollapseAtualizacao } = props;
    const messageContext = useContext(MessagesContext);
    const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;

    const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });

    const handleClickReprocessaPermissoes = async () => {

        setDialogoOpen({ ...dialogoOpen, visible: false });

        try {
            MostraTelaEspera('Aguarde o reprocessamento');
            await api.post(`${rotafechada()}permissoes/processapermissoes`, {
                empresa_id: '',
                permissao_id: ''
            });
            FechaTelaEspera();
            MensagemAviso('Permissões reprocessadas com Sucesso!');
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }


    const handleConfirmDialogo = () => {

        if (dialogoOpen.funcao === 'reprocessarPermissoes') {
            handleClickReprocessaPermissoes();
        }
    }

    const handleClickPermissoes = () => {
        setDialogoOpen({
            ...dialogoOpen,
            visible: true,
            id: null,
            title: 'Processamento de Permissões',
            message: `Certeza que deseja reprocessar as permissões para todas as Empresas?`,
            funcao: 'reprocessarPermissoes'
        });
    }

    return (
        <>
            <DialogoConfirma
                open={dialogoOpen.visible}
                title={dialogoOpen.title || ''}
                message={dialogoOpen.message || ''}
                handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
                handleConfirm={handleConfirmDialogo}
            />
            <Card id="cadastro-atualizacao" sx={{ overflow: "visible" }}>

                <MDBox p={2} bgColor='grey-100' mt={2}>
                    <IconButton aria-label="expand row" size="small" onClick={() => handleClickCollapseAtualizacao(!openCollapseAtualizacao)}>
                        {openCollapseAtualizacao ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        <Typography variant='h6' mb={2}>Atualização</Typography>
                    </IconButton>

                    <Collapse in={openCollapseAtualizacao} timeout="auto" unmountOnExit>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <MDButton color='info' onClick={handleClickPermissoes}>Reprocessar Permissões</MDButton>
                            </Grid>
                        </Grid>


                    </Collapse>
                </MDBox>
            </Card>
        </>
    )
}