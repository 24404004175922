import React, { useContext, useEffect, useState } from "react";

// @mui material components
import { Collapse, Grid } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

//My componentes e functions
import MessagesContext from "hooks/MessagesContext";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import FormField from "componentes/FormField";
import TabelaVendas from "./TabelaVendas";
import { Box } from "@mui/system";
import { formatDate } from "commons/utils";
import { formataValor } from "commons/utils";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { retornaSomenteNumeros } from "commons/utils";
import { AutoCompleteStyled } from "componentes/AutoCompleteStyled";
import { ptBR } from "date-fns/locale";
import { format } from "date-fns";


function Tabela(props) {

  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;
  const { handleClickExcluir, abrirRegistro, recarregaTabela, openFiltrosAvancados } = props;

  const [countAll, setCountAll] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState('desc');
  const [concluido, setConcluido] = React.useState({});
  const [orderBy, setOrderBy] = React.useState('id');
  const [colunasTable, setColunasTable] = React.useState([]);
  const [linhas, setLinhas] = React.useState([]);
  const [dataInicial, setDataInicial] = useState(null);
  const [dataFinal, setDataFinal] = useState(null);
  const opcaoAtivo = [
    {
      label: 'Vendas',
      value: '1'
    },
    {
      label: 'Orçamentos',
      value: '0'
    },
    {
      label: 'Todos',
      value: '-1'
    },
  ]


  // *****States relacionado do filtro de pesquisa ****
  const [campoPesquisa, setCampoPesquisa] = React.useState({ id: 'vendacliente', label: 'Nome Venda/Cliente', buscaParcial: true });
  const [valorPesquisa, setValorPesquisa] = React.useState('');
  const camposFiltro = [
    { id: 'nome', label: 'Nome Venda', buscaParcial: true },
    { id: 'fantasia_c', label: 'Nome Cliente', buscaParcial: true },
    { id: 'fantasia_v', label: 'Nome Vendedor', buscaParcial: true },
    { id: 'vendacliente', label: 'Nome Venda/Cliente', buscaParcial: true },
    { id: 'conta_id', label: 'N° da Conta' },
    { id: 'id', label: 'Id' },
  ];
  // ****************************

  const baseUrl = `${rotafechada()}vendas`;
  const baseCaption = 'Vendas';

  const buscaDados = async () => {
    if (props.validaRotaPermissao) {
      if (await props.validaRotaPermissao('listar') === false) {
        return;
      }
    }

    try {

      let complementoUrl = '';

      if (campoPesquisa) {
        if ((campoPesquisa.id) && (valorPesquisa)) {
          complementoUrl = `&${campoPesquisa.id}=${valorPesquisa}`;

          if ((campoPesquisa.id === 'id') || (campoPesquisa.id === 'conta_id')) {
            const temp = retornaSomenteNumeros(valorPesquisa);
            if ((`${temp}`.length !== `${valorPesquisa}`.length) ||
              (!(parseInt(`${valorPesquisa}`, 10) > 0))) {
              MensagemErro('A pesquisa por ID aceita só números, escolha outro campo para busca');
              return;
            }
          }

          if ((valorPesquisa) && (valorPesquisa.length > 0) && (valorPesquisa[0] !== '%') && (valorPesquisa[valorPesquisa.length - 1] !== '%')
            && (camposFiltro) && (camposFiltro.length > 0)) {

            //se o texto a ser buscado nao terminar ou começar com %
            //e tiver uma configuracao no camposFiltro
            //entao localizo que o campo usado nesse momento é um campo que esteja configurado
            //o buscaParcial, se sim acrescento automaticamente o % no inicio e fim


            let encontrouCampo = false;
            for (let i = 0; i < camposFiltro.length; i++) {

              if ((camposFiltro[i].buscaParcial === true) && (camposFiltro[i].id === campoPesquisa.id)) {
                encontrouCampo = true;
              }
            }

            if (encontrouCampo === true) {
              complementoUrl = `&${campoPesquisa.id}=%${valorPesquisa}%`;
            }
          }
        }
      }

      if (page > 0) {
        complementoUrl = `${complementoUrl}&offset=${page * rowsPerPage}`;
      }

      if (concluido) {
        complementoUrl = `${complementoUrl}&concluido=${concluido.value}`;//////////////////////
      }


      if (dataInicial) {
        const datainicial = new Date(format(dataInicial, 'yyyy-MM-dd 00:00:00'));
        complementoUrl = `${complementoUrl}&datainicial=${datainicial}`;//Data Inicial

      }
      if (dataFinal) {
        const datafinal = new Date(format(dataFinal, 'yyyy-MM-dd 23:59:59'));
        complementoUrl = `${complementoUrl}&datafinal=${datafinal}`;//Data Final

      }

      if (orderBy) {
        complementoUrl = `${complementoUrl}&order=${orderBy}`;
        if (order) {
          complementoUrl = `${complementoUrl}&direction=${order}`;
        }
      }

      //complementoUrl = encodeURI(complementoUrl)

      // if (parseInt(filtroGrupo?.id, 10) > 0) {
      //   complementoUrl = `${complementoUrl}&grupo_projeto_id=${filtroGrupo.id}`;
      // }

      MostraTelaEspera(`Buscando ${baseCaption}`);

      const url = `${baseUrl}?limit=${rowsPerPage}${complementoUrl}`;
      //console.log("Busca URL Vendas:", url)
      const retorno = await api.get(encodeURI(url));
      const { data, countAll: countAllretorno } = retorno.data;
      const dadostemp = [];

      const colunasTabletemp = [
        {
          id: 'id',
          numeric: false,
          disablePadding: true,
          label: 'Id'
        },
        {
          id: 'nome',
          numeric: false,
          disablePadding: true,
          label: 'Nome Venda'
        },
        {
          id: 'fantasia_c',
          numeric: false,
          disablePadding: true,
          label: 'Cliente',
          disableOrder: true
        },
        {
          id: 'data_orcamento',
          numeric: false,
          disablePadding: true,
          label: 'Data'
        },
        {
          id: 'valor_final',
          numeric: false,
          disablePadding: true,
          label: 'Valor Final'
        },
        {
          id: 'conta',
          numeric: false,
          disablePadding: true,
          label: 'Conta',
          disableOrder: true
        },
        {
          id: 'botoes',
          numeric: false,
          disablePadding: true,
          label: 'Ações',
          disableOrder: true
        },
      ]
      //console.log("data Venda:", data);
      data.map((item, index) => {
        item.fantasia_c = item.Cliente?.fantasia || item.Cliente?.razao_social || '';
        item.data_orcamento = item.data_orcamento ? formatDate(new Date(item.data_orcamento), 'DD/MM/YY') : '';
        //formata o valor aqui
        item.valor_final = formataValor(item.valor_final);
        item.conta = item?.conta_id ? item.conta_id : '';
        return item;
      })

      setColunasTable([...colunasTabletemp]);
      setLinhas(data);
      setCountAll(countAllretorno);
      FechaTelaEspera();
    }
    catch (error) {
      //FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  useEffect(() => {
    buscaDados();
  }, [recarregaTabela, page, rowsPerPage, order, orderBy])

  const handleClickPesquisar = () => {
    setPage(0);
    buscaDados();
  }

  const desenhaFiltro = () => {
    return (
      <Collapse in={openFiltrosAvancados} timeout="auto" unmountOnExit>
        <Box sx={{ margin: 1 }}>

          < Grid container spacing={2} justifyContent="flex-start" >

            <Grid item xs={12} sm={3} mb={2}>{/*Status Vendas */}
              <AutoCompleteStyled
                autoHighlight
                options={opcaoAtivo}
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderInput={(params) => <FormField {...params} label='Status'/>}
                value={concluido}
                onChange={(event, newValue) => {
                  setConcluido(newValue);
                }}
                defaultValue={{ id: -1, value: -1, label: '<Escolha um opção>' }}
              />
            </Grid>

            <Grid item xs={12} sm={3} mb={2}> {/*Periodo inicio*/}
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <DesktopDatePicker
                  label='Data Inicial'
                  inputFormat="dd/MM/yyyy"
                  value={dataInicial}
                  onChange={(e) => setDataInicial(e)}
                  renderInput={(params) => <FormField {...params} />}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={3} mb={2}> {/*Periodo fim*/}
              <LocalizationProvider dateAdapter={AdapterDateFns} >
                <DesktopDatePicker
                  label='Data Final'
                  inputFormat="dd/MM/yyyy"
                  value={dataFinal}
                  onChange={(e) => setDataFinal(e)}
                  renderInput={(params) => <FormField {...params} />}
                />
              </LocalizationProvider>

            </Grid>


          </Grid>

          < Grid container spacing={2} justifyContent="center" >
            <Grid item xs={12} sm={3} >{/*Campo*/}
              <AutoCompleteStyled
                autoHighlight
                options={camposFiltro}
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderInput={(params) => <FormField {...params} label='Campo'/>}
                value={campoPesquisa}
                onChange={(event, newValue) => {
                  setCampoPesquisa(newValue);
                }}
                defaultValue={null}
              />
            </Grid>
            <Grid item xs={12} sm={7}> {/*Valor a ser pesquisado*/}
              <FormField type="text" label="Valor a ser Pesquisado" value={valorPesquisa} onChange={(e) => setValorPesquisa(e.target.value)}

                inputProps={
                  {
                    onKeyPress: (e) => {

                      if (e.key === 'Enter') {
                        handleClickPesquisar();
                      }
                    },
                  }
                }
              />
            </Grid>
            <Grid item xs={12} sm={2} > {/*Pesquisar*/}
              <MDButton onClick={handleClickPesquisar} color='info'> Pesquisar</MDButton>
            </Grid>
          </Grid >
        </Box>
      </Collapse>
    )
  }

  useEffect(() => {
  }, [])


  return (
    <>

      <MDBox p={3} lineHeight={1}>
        <MDTypography variant="h5" fontWeight="medium">
          {desenhaFiltro()}
        </MDTypography>
      </MDBox>

      <TabelaVendas
        colunas={colunasTable}
        linhas={linhas}
        countAll={countAll}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        handleClickExcluir={handleClickExcluir}
        abrirRegistro={abrirRegistro}
      />
    </>


  );
}

export default Tabela;
