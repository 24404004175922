
import React, { useContext, useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Tabela from "./Tabela";
import Cadastro from "./Cadastro";
import MessagesContext from "hooks/MessagesContext";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import DialogoConfirma from 'componentes/DialogoConfirma';
import { useTrataRetorno } from "commons/authUtils";

function UsuariosAdmin(props) {

  const trataRetorno = useTrataRetorno();
  const { empresaAtual } = props;
  const [usuarioAtual, setUsuarioAtual] = useState('');
  const messageContext = useContext(MessagesContext);
  const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;
  const [dataset, setDataset] = useState([]);
  const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });
  const [recarregaTabela, setRecarregaTabela] = useState(false);
  const [enabledBotaoGravar, setEnabledBotaoGravar] = useState(true);
  const [openFiltrosAvancados, setOpenFiltrosAvancados] = useState(false);

  const baseUrl = `${rotafechada()}empresa_usuarios`;
  const baseCaption = 'Usuários';

  const validaRotaPermissao = async (operacao = '', silencioso = false) => {
    if ((operacao !== 'inserir') &&
      (operacao !== 'editar') &&
      (operacao !== 'excluir') &&
      (operacao !== 'listar')) {
      MensagemErro('Escolha a operação que deseja validar a permissão!');
      return false;
    }

    //validando a permissao
    try {
      await api.get(`${baseUrl}/permissao/${operacao}`);
      return true;
    }
    catch (error) {
      trataRetorno(error?.response?.data?.erro);
      const msg = error.response?.data?.msg || error;

      if (silencioso === false) {
        MensagemErro(`Erro: ${msg}`);
      }
      return false;
    }
  }

  const abrirRegistro = async (id) => {

    if (!parseInt(id, 10) > 0)
      return;

    try {
      setUsuarioAtual(id);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const enviarEmail = async () => {
    setDialogoOpen({ ...dialogoOpen, visible: false });

    try {
      const dados = {}
      dados.id = dialogoOpen.id
      let retorno;

      MostraTelaEspera('Enviando email'); //Abre tela de espera
      retorno = await api.post(`${rotafechada()}empresa_usuarios/email`, dados);

      FechaTelaEspera(); //Fecho tela de espera
      if (retorno.data.data == 'habilitado') {
        MensagemAviso('Usuário já habilitado');
      }
      else MensagemAviso('Email enviado com sucesso!');

      setUsuarioAtual('');
      setRecarregaTabela(!recarregaTabela);
    }
    catch (error) {
      FechaTelaEspera(); //Fecho tela de espera
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const excluirRegistro = async () => {
    setDialogoOpen({ ...dialogoOpen, visible: false });

    try {
      const id = dialogoOpen.id
      const retorno = await api.delete(`${rotafechada()}empresa_usuarios/${id}`);
      MensagemAviso('Excluído com sucesso!');
      setUsuarioAtual('');
      setRecarregaTabela(!recarregaTabela);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  useEffect(async () => {

    if (parseInt(usuarioAtual, 10) > 0) {
      if (await validaRotaPermissao('editar') === false) {
        //alem da mensagem, desativo o botao de gravar
        setEnabledBotaoGravar(false);
      }
    }
    else {
      if (await validaRotaPermissao('inserir') === false) {
        //alem da mensagem, desativo o botao de gravar
        setEnabledBotaoGravar(false);
      }
    }


  }, [])

  const handleClickEmail = async (id) => {

    setDialogoOpen({
      ...dialogoOpen,
      visible: true,
      id,
      title: 'Confirma Envio de Email',
      message: 'Certeza que deseja enviar email de ativação?',
      funcao: 'enviarEmail'
    });
  }

  const handleClickExcluir = async (id) => {
    setDialogoOpen({
      ...dialogoOpen,
      visible: true,
      id,
      title: 'Confirma Exclusão',
      color: 'error',
      message: `Certeza que excluir o usuário ID ${id}?`,
      funcao: 'excluirRegistro'
    });
  }

  const handleConfirmDialogo = () => {
    if (dialogoOpen.funcao === 'enviarEmail') {
      enviarEmail();
    }
    else {
      if (dialogoOpen.funcao === 'excluirRegistro') {
        excluirRegistro();
      }
    }
  }

  return (
    <>

      <DialogoConfirma
        open={dialogoOpen.visible}
        title={dialogoOpen.title}
        message={dialogoOpen.message}
        handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
        handleConfirm={handleConfirmDialogo}
      />

      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={12}>
            <MDBox mt={0} mb={4} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  {`${baseCaption}`}
                </MDTypography>
              </MDBox>
            </MDBox>

            <Card>
              <MDBox width="100%" display="flex" justifyContent="space-between">
                <Cadastro
                  setUsuarioAtual={setUsuarioAtual}
                  usuarioAtual={usuarioAtual}
                  recarregaTabela={recarregaTabela}
                  setRecarregaTabela={setRecarregaTabela}
                  empresaAtual={empresaAtual}
                />
              </MDBox>

              <Tabela
                openFiltrosAvancados={openFiltrosAvancados}
                setOpenFiltrosAvancados={setOpenFiltrosAvancados}
                abrirRegistro={abrirRegistro}
                excluirRegistro={handleClickExcluir}
                handleClickEmail={handleClickEmail}
                recarregaTabela={recarregaTabela}
                validaRotaPermissao={validaRotaPermissao}
                empresaAtual={empresaAtual}
              />
            </Card>

          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}

export default UsuariosAdmin;
