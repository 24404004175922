
import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, Button, Icon, IconButton, Tooltip, Typography, Checkbox, Divider, useMediaQuery, Switch } from "@mui/material";
import DialogoOpcoes from 'componentes/DialogoOpcoes';
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Tabela from "./Tabela";
//import Cadastro from "./Cadastro";
import MDButton from "components/MDButton";

import MessagesContext from "hooks/MessagesContext";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import AuthContext from "hooks/AuthContext";
import DialogoConfirma from 'componentes/DialogoConfirma';
import SettingsIcon from '@mui/icons-material/Settings';
import MenuConfiguracaoModulos from "componentes/MenuConfiguracaoModulos";
import { useNavigate, useParams } from "react-router-dom";
import packageJson from '../../../../package.json';
import DialogoValidaModulo from "componentes/ValidaModulo";
import { useTrataRetorno } from "commons/authUtils";
import FormField from "componentes/FormField";
import SearchIcon from '@mui/icons-material/Search';
import List from '@mui/material/List';
import CardHeader from '@mui/material/CardHeader';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import PesquisaVenda from './pesquisavenda';
import PesquisaProjetoVenda from './pesquisaprojetovenda';
import PesquisaProdutoVenda from './pesquisaprodutovenda';
import PesquisaPlanoCorte from './pesquisaplanocorte';
import { format } from "date-fns";
import DialogoFiltrosRelatorio from '../../relatoriosPersonalizados/Filtro';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.any,
  index: PropTypes.number,
  value: PropTypes.number,
};

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

function Otimizar() {

  const messageContext = useContext(MessagesContext);
  const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;
  const [openMensagemModuloAdicional, setOpenMensagemModuloAdicional] = useState(false);
  const authContext = useContext(AuthContext);
  const { getSessao } = authContext;
  const sessaolocal = getSessao();
  const isMobile = useMediaQuery('(max-width: 600px)');
  const trataRetorno = useTrataRetorno();
  const [openFiltrosAvancados, setOpenFiltrosAvancados] = useState(false);
  const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });
  const [dialogoOpenEstorno, setDialogoOpenEstorno] = useState({ visible: false, id: null });
  const baseUrl = `${rotafechada()}plano_corte_barras`;
  const baseCaption = 'Otimização de Barras de Alumínio';
  const navigate = useNavigate();
  const [openMenuConfiguracao, setOpenMenuConfiguracao] = useState(false);
  const [currentTargetBotaoMenu, setCurrentTargetBotaoMenu] = useState(null);
  const [parametrosVenda, setParametrosVenda] = useState({});

  {/* Box com os totais */ }
  const [dataPlanoCorte, setDataPlanoCorte] = useState(format(new Date(), 'dd/MM/yyyy'));
  const [concluidoPlanoCorte, setConcluidoPlanoCorte] = useState('N');
  const [observacaoPlanoCorte, setObservacaoPlanoCorte] = useState('');
  const [descartePlanoCorte, setDescartePlanoCorte] = useState(0);
  const [usaRetalho, setUsaRetalho] = useState(0);

  const [venda_value, setVenda_value] = useState('');
  const [confPesquisaDinamicaVenda, setConfPesquisaDinamicaVenda] = useState({});

  const [projetoVenda_value, setProjetoVenda_value] = useState('');
  const [confPesquisaDinamicaProjetoVenda, setConfPesquisaDinamicaProjetoVenda] = useState({});

  const [produtoVenda_value, setProdutoVenda_value] = useState('');
  const [confPesquisaDinamicaProdutoVenda, setConfPesquisaDinamicaProdutoVenda] = useState({});

  const [planoCorte_value, setPlanoCorte_value] = useState('');
  const [confPesquisaDinamicaPlanoCorte, setConfPesquisaDinamicaPlanoCorte] = useState({});
  const [checkedRetalho, setCheckedRetalho] = useState(false);
  const [checked, setChecked] = React.useState([]);
  const [dadosAluminios, setDadosAluminios] = React.useState([]);
  const [dadosOtimizar, setDadosOtimizar] = React.useState([]);

  //Relatórios
  const [opcoes, setOpcoes] = useState({
    open: false,
    items: [{}]
  });

  const [filtrosPersonalizados, setFiltrosPersonalizados] = useState({
    open: false,
    itens: [{}],
    dataset: [{}],
    relatorio_id: 0
  });

  const leftChecked = intersection(checked, dadosAluminios);
  const rightChecked = intersection(checked, dadosOtimizar);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setDadosOtimizar(dadosOtimizar.concat(leftChecked));
    setDadosAluminios(not(dadosAluminios, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    //setDadosAluminios(dadosAluminios.concat(rightChecked)); comentei para que os aluminios para otimizar não retorne para o lado dos aluminios da venda
    setDadosOtimizar(not(dadosOtimizar, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (title, items) => (
    <Card
      sx={{
        px: 2,
        py: 1,
        border: '1px solid gray',
        borderRadius: 4, // Optional for rounded corners
      }}
    >
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={
              numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              'aria-label': 'all items selected',
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selecionado`}
      />
      {/* <Divider /> */}
      <List
        sx={{
          width: isMobile ? 'auto' : '28vw',//largura
          height: isMobile ? 'auto' : 300, //altura
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value, index) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItemButton
              key={value}
              role="listitem"
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                // primary={`List ${value + 1}`} 
                primary={
                  <>
                    <Typography variant='subtitle2' ><span style={{ fontWeight: 'bold' }}>Produto:</span> {value.produto}</Typography>
                    <Typography variant='subtitle2' ><span style={{ fontWeight: 'bold' }}>Medida:</span> {value.medida}</Typography>
                    <Typography variant='subtitle2' ><span style={{ fontWeight: 'bold' }}>Venda:</span> {value.venda}   <span style={{ fontWeight: 'bold' }}>Projeto:</span> {value.projeto}</Typography>
                    {/* <Typography variant='subtitle2' ><span style={{ fontWeight: 'bold' }}>Projeto:</span> {value.projeto}</Typography> */}
                    {/* <Divider/> */}
                  </>
                }
              />
            </ListItemButton>
          );
        })}
      </List>
    </Card>
  );
  //Venda
  const handleClickPesquisaVenda = async () => {
    setConfPesquisaDinamicaVenda({ ...confPesquisaDinamicaVenda, open: true });
  }

  const handleBlurVenda = async () => {
    try {
      if (typeof venda_value === 'string' && (!venda_value || venda_value.trim() === '')) {
        setVenda_value('');
        return;
      }
      if (venda_value) {
        let url = `${rotafechada()}vendas?id=${venda_value}&empresa_id=${sessaolocal.empresa_id}`;
        const retorno = await api.get(encodeURI(url));
        const { data } = retorno.data;
        // console.log('data venda:', data)
        if ((data) && (data?.length > 0)) {
          setVenda_value(data[0].id);
        } else {
          setVenda_value('');
        }
      }
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleFuncaoSelecaoPesquisaDinamicaVenda = (codigo, datasetPesquisa) => {
    /* Essa funcao é chamada pelo componente de pesquisa dinamica quando um registro é escolhido
    ele retorna os campos que foram configurados*/
    // console.log('retorno da escolha do campo', datasetPesquisa);
    setConfPesquisaDinamicaVenda({ ...confPesquisaDinamicaVenda, open: false });
    setVenda_value(datasetPesquisa.id);
  }
  //Projeto
  const handleClickPesquisaProjetoVenda = async () => {
    setConfPesquisaDinamicaProjetoVenda({ ...confPesquisaDinamicaProjetoVenda, open: true });
  }

  const handleBlurProjetoVenda = async () => {
    try {
      if (typeof projetoVenda_value === 'string' && (!projetoVenda_value || projetoVenda_value.trim() === '')) {
        setProjetoVenda_value('');
        return;
      }
      if (projetoVenda_value) {
        let url = `${rotafechada()}venda_projetos?id=${projetoVenda_value}&empresa_id=${sessaolocal.empresa_id}`;
        const retorno = await api.get(encodeURI(url));
        const { data } = retorno.data;
        // console.log('data venda:', data)
        if ((data) && (data?.length > 0)) {
          setProjetoVenda_value(data[0].id);
        } else {
          setProjetoVenda_value('');
        }
      }
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro101010: ${msg}`, 300000);
    }
  }

  const handleFuncaoSelecaoPesquisaDinamicaProjetoVenda = (codigo, datasetPesquisa) => {
    /* Essa funcao é chamada pelo componente de pesquisa dinamica quando um registro é escolhido
    ele retorna os campos que foram configurados*/
    // console.log('retorno da escolha do campo', datasetPesquisa);
    setConfPesquisaDinamicaProjetoVenda({ ...confPesquisaDinamicaProjetoVenda, open: false });
    setProjetoVenda_value(datasetPesquisa.id);
  }
  //Produto projeto
  const handleClickPesquisaProdutoVenda = async () => {
    setConfPesquisaDinamicaProdutoVenda({ ...confPesquisaDinamicaProdutoVenda, open: true });
  }

  const handleBlurProdutoVenda = async () => {
    try {
      if (typeof produtoVenda_value === 'string' && (!produtoVenda_value || produtoVenda_value.trim() === '')) {
        setProdutoVenda_value('');
        return;
      }
      if (produtoVenda_value) {
        let url = `${rotafechada()}venda_produtos?id=${produtoVenda_value}&empresa_id=${sessaolocal.empresa_id}`;
        const retorno = await api.get(encodeURI(url));
        const { data } = retorno.data;
        // console.log('data venda:', data)
        if ((data) && (data?.length > 0)) {
          setProdutoVenda_value(data[0].id);
        } else {
          setProdutoVenda_value('');
        }
      }
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleFuncaoSelecaoPesquisaDinamicaProdutoVenda = (codigo, datasetPesquisa) => {
    /* Essa funcao é chamada pelo componente de pesquisa dinamica quando um registro é escolhido
    ele retorna os campos que foram configurados*/
    // console.log('retorno da escolha do campo', datasetPesquisa);
    setConfPesquisaDinamicaProdutoVenda({ ...confPesquisaDinamicaProdutoVenda, open: false });
    setProdutoVenda_value(datasetPesquisa.id);
  }
  //Carrega Aluminios
  const handleClickCarregaAluminios = async () => {
    try {

      let complementoUrl = '';

      if (venda_value > 0) {
        complementoUrl = `${complementoUrl}&venda_id=${venda_value}`;
      }

      if (projetoVenda_value > 0) {
        complementoUrl = `${complementoUrl}&venda_projeto_id=${projetoVenda_value}`;
      }

      //const retorno = await api.get(`${rotafechada()}venda_produtos?otimizar=S&limit=2000&empresa_id=${sessaolocal.empresa_id}${complementoUrl}`);
      const retorno = await api.get(`${rotafechada()}plano_corte_barras/otimizacoes?&limit=2000&empresa_id=${sessaolocal.empresa_id}${complementoUrl}`);
      //trocar por uma rota que não traga os aluminios que já foram otimizados
      const { data } = retorno.data;
      let temp = []
      console.log('data CarregaAluminios:');
      console.log(data);

      //pegando somente os produtos que não foram otimizados
      for (let i = 0; i < data.length; i++) {
        if (data[i]?.Registro_Plano_Corte_Barras[0]?.Plano_Corte_Barra?.rateio == 'N') {
        }
        else {
          temp.push(data[i]);
        }
      }

      let dadosAluminiosTemp = [];
      temp.map((item) => {
        dadosAluminiosTemp.push({
          registro_plano_corte_barra_id: null,
          venda_produto_id: item.id,
          produto_grade_id: item.produto_grade_id,
          produto: item.Produto_Grade.Produto.nome + ' - ' + item.Produto_Grade.Core.nome,
          medida: parseFloat(item.quantidade).toString().replace(/\.0+$/, '') + ' mm',
          quantidade: item.quantidade,
          venda: item.venda_id,
          projeto: item.venda_projeto_id
        })
      })

      console.log('dadosAluminiosTemp', dadosAluminiosTemp);

      setDadosAluminios(dadosAluminiosTemp);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  //Novo Plano de Corte
  const handleClickNovo = () => {
    setDadosAluminios([])
    setDadosOtimizar([])
    setPlanoCorte_value('');
    setVenda_value('');
    setProjetoVenda_value('');
    setProdutoVenda_value('');
    setConcluidoPlanoCorte('N');
    setDataPlanoCorte(format(new Date(), 'dd/MM/yyyy'));
    setObservacaoPlanoCorte()
  }

  //Abrir Plano de Corte
  const handleClickPesquisaPlanoCorte = async () => {
    setConfPesquisaDinamicaPlanoCorte({ ...confPesquisaDinamicaPlanoCorte, open: true });
  }

  const handleFuncaoSelecaoPesquisaDinamicaPlanoCorte = (codigo, datasetPesquisa) => {
    /* Essa funcao é chamada pelo componente de pesquisa dinamica quando um registro é escolhido
    ele retorna os campos que foram configurados*/
    // console.log('retorno da escolha do campo', datasetPesquisa);
    setConfPesquisaDinamicaPlanoCorte({ ...confPesquisaDinamicaPlanoCorte, open: false });
    setPlanoCorte_value(datasetPesquisa.id);
    setConcluidoPlanoCorte(datasetPesquisa.concluido);
    setDataPlanoCorte(datasetPesquisa.data);
    setObservacaoPlanoCorte(datasetPesquisa.observacao)

    setVenda_value('')
    setProjetoVenda_value('')
    setProdutoVenda_value('')

    let dadosAluminiosTemp = [];
    //      { id: 1, produto: 'Contra Marco - Natural Fosco', venda: 100, projeto: 10 },

    datasetPesquisa.RegistroPlanoCorte.map((item) => {
      dadosAluminiosTemp.push({
        registro_plano_corte_barra_id: item.id || null,
        produto_grade_id: item.Produto_Grade.id,
        produto: item.Produto_Grade.Produto.nome + ' - ' + item.Produto_Grade.Core.nome,
        medida: item.tamanho + ' mm',
        venda: item.Venda_Produto.venda_id,
        projeto: item.Venda_Produto.venda_projeto_id
      })
    })

    // console.log('dadosAluminiosTemp:',dadosAluminiosTemp);
    setDadosAluminios([])
    setDadosOtimizar(dadosAluminiosTemp)
  }

  function converterData(dataString) {
    // Divide a string em dia, mês e ano
    const partesData = dataString.split('/');
    const dia = parseInt(partesData[0]);
    const mes = parseInt(partesData[1]) - 1; // Meses em JavaScript começam em 0
    const ano = parseInt(partesData[2]);

    // Cria um novo objeto Date
    const novaData = new Date(ano, mes, dia);

    return novaData;
  }

  const handleClickOtimizaAluminios = async () => {
    try {
      MostraTelaEspera(`Otimizando`);
      let dados = {
        usaRetalho: checkedRetalho == true ? 'S' : 'N',
        plano_corte_id: planoCorte_value,
        concluido: concluidoPlanoCorte,
        observacao: observacaoPlanoCorte,
        data: converterData(dataPlanoCorte),
        produtos: dadosOtimizar,
        tamanho_padrao_descarte: descartePlanoCorte
      }

      // console.log('dados otimizarbarras:', dados);

      const retorno = await api.post(`${rotafechada()}plano_corte_barras/otimizarbarras`, dados);
      const { data } = retorno.data;
      // console.log('retorno:', data);


      setDadosAluminios([])
      setPlanoCorte_value(data.id);
      setVenda_value('');
      setProjetoVenda_value('');
      setProdutoVenda_value('');
      setConcluidoPlanoCorte(data.concluido);
      setDataPlanoCorte(format(new Date(data.data), 'dd/MM/yyyy'));
      setObservacaoPlanoCorte(data.observacao)
      FechaTelaEspera();
      MensagemAviso('Otimizado com sucesso!');

    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  //Excluir Plano de Corte
  const handleExcluir = async () => {
    setDialogoOpen({ ...dialogoOpen, visible: false });

    try {
      await api.delete(`${baseUrl}/full/${dialogoOpen.id || 0}`);
      MensagemAviso('Excluído com sucesso!');
      // setCodigoCarregado('');
      handleClickNovo()
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleClickExcluir = async (id) => {

    if (!(await validaRotaPermissao('excluir') === true)) {
      return;
    }

    setDialogoOpen({ ...dialogoOpen, visible: true, id });
  }

  //Estornar Plano de Corte
  const handleEstornar = async () => {
    setDialogoOpenEstorno({ ...dialogoOpenEstorno, visible: false });

    try {
      await api.delete(`${baseUrl}/estorno/${dialogoOpenEstorno.id || 0}`);
      MensagemAviso('Estornado com sucesso!');
      // setCodigoCarregado('');
      handleClickNovo()
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleClickEstornar = async (id) => {

    // if (!(await validaRotaPermissao('estornar') === true)) {
    //   return;
    // }

    setDialogoOpenEstorno({ ...dialogoOpenEstorno, visible: true, id });
  }
  // Valida Modulo
  const validaModulo = async () => {

    MostraTelaEspera('Validando acesso ao módulo');
    try {
      let url = `${rotafechada()}assinatura_modulos/validar?nome=${'OTIMIZABARRAS'}`;
      await api.get(url);
    }
    catch (error) {
      setOpenMensagemModuloAdicional(true)
    }
    FechaTelaEspera();
  }

  const buscaParametros = async () => {


    try {
      let url = `${rotafechada()}vendas/parametros`;
      const retorno = await api.get(encodeURI(url));
      const data = retorno.data;
      setDescartePlanoCorte(data.tamanho_padrao_descarte)
      setParametrosVenda(data);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const validaRotaPermissao = async (operacao = '', silencioso = false) => {
    if ((operacao !== 'inserir') &&
      (operacao !== 'editar') &&
      (operacao !== 'excluir') &&
      (operacao !== 'listar')) {
      MensagemErro('Escolha a operação que deseja validar a permissão!');
      return false;
    }

    //validando a permissao
    try {
      await api.get(`${baseUrl}/permissao/${operacao}`);
      return true;
    }
    catch (error) {
      trataRetorno(error?.response?.data?.erro);
      const msg = error.response?.data?.msg || error;

      if (silencioso === false) {
        MensagemErro(`Erro: ${msg}`);
      }
      return false;
    }
  }
  //Impressão Relatório
  const handleClickOpcoesImpressao = async () => {

    const url = `${rotafechada()}relatorios_personalizados?nome_modulo=Plano de Corte Barras&filtro_externo=N&ativo=S&empresa_id=1&empresa_id=${sessaolocal.empresa_id}`
    let retorno = await api.get(encodeURI(url))
    const { data } = retorno.data
    // console.log(' relatorios: ', data)

    const items = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].Modulos_Sistema.caption == 'Plano de Corte Barras - Otimização')
        items.push({ value: data[i].id, label: data[i].nome })
    }
    setOpcoes({ ...opcoes, open: true, tipo: 'opcoes', items });
  }

  const onCloseDialogoOpcoes = async (value) => {

    //fechando o dialogo
    setOpcoes({ ...opcoes, open: false, tipo: '' });
    await handleClickFiltroRelatorioPersonalizado(value)
  }

  const handleClickFiltroRelatorioPersonalizado = async (e) => {
    setFiltrosPersonalizados({ ...filtrosPersonalizados, configuracao: true, open: true, impressao: true, relatorio_id: e });
  }

  const onCloseDialogoOpcoesFiltro = (value) => {

    setFiltrosPersonalizados({ ...filtrosPersonalizados, open: false, })

  }

  useEffect(() => {
    validaModulo()
    buscaParametros();
  }, [])

  return (
    <>

      <DialogoValidaModulo
        open={openMensagemModuloAdicional}
        title={'Módulo adicional'}
        message={'Módulo de Otimização de barras de alumínio não contratado, para mais informações entre em contato com nossa equipe de suporte!'}
        handleCancel={() => {
          setOpenMensagemModuloAdicional(false);
          navigate('/desktop');
        }}
        handleConfirm={() => {
          setOpenMensagemModuloAdicional(false);
          navigate('/desktop');
          window.open(packageJson?.urlsuporte.replace('text', 'text=Tenho interesse em contratar o módulo de Otimização de barras de alumínio para o VidrosWeb📌 '))
        }
        }
      />

      <MenuConfiguracaoModulos
        open={openMenuConfiguracao}
        setOpen={setOpenMenuConfiguracao}
        nomeModulo='PLANO_CORTE'
        currentTarget={currentTargetBotaoMenu}
      />

      {/* dialogo filtro dos relatórios */}
      <DialogoFiltrosRelatorio
        open={filtrosPersonalizados.open || false}
        onClose={onCloseDialogoOpcoesFiltro}
        options={filtrosPersonalizados.itens || []}
        relatorio_id={filtrosPersonalizados.relatorio_id}
        configuracao={filtrosPersonalizados.configuracao}
        impressao={filtrosPersonalizados.impressao}
      />

      {/* dialogo de opções dos relatórios */}
      <DialogoOpcoes
        value={''}
        open={opcoes.open || false}
        onClose={onCloseDialogoOpcoes}
        options={opcoes.items || []}
      />

      <PesquisaVenda
        open={confPesquisaDinamicaVenda.open || false}
        handleClose={
          () => setConfPesquisaDinamicaVenda({ ...confPesquisaDinamicaVenda, open: false })
        }
        handleClick={handleFuncaoSelecaoPesquisaDinamicaVenda}
      />

      <PesquisaProjetoVenda
        open={confPesquisaDinamicaProjetoVenda.open || false}
        handleClose={
          () => setConfPesquisaDinamicaProjetoVenda({ ...confPesquisaDinamicaProjetoVenda, open: false })
        }
        venda_value={venda_value}
        handleClick={handleFuncaoSelecaoPesquisaDinamicaProjetoVenda}
      />

      <PesquisaProdutoVenda
        open={confPesquisaDinamicaProdutoVenda.open || false}
        handleClose={
          () => setConfPesquisaDinamicaProdutoVenda({ ...confPesquisaDinamicaProdutoVenda, open: false })
        }
        venda_value={venda_value}
        projetoVenda_value={projetoVenda_value}
        handleClick={handleFuncaoSelecaoPesquisaDinamicaProdutoVenda}
      />

      <DialogoConfirma
        open={dialogoOpen.visible}
        title={'Confirma Exclusão'}
        color={'error'}
        message={'Certeza que deseja excluir?'}
        handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
        handleConfirm={handleExcluir}
      />

      <DialogoConfirma
        open={dialogoOpenEstorno.visible}
        title={'Confirma Estorno'}
        color={'warning'}
        message={'Certeza que deseja estornar?'}
        handleCancel={() => setDialogoOpenEstorno({ ...dialogoOpenEstorno, visible: false })}
        handleConfirm={handleEstornar}
      />

      <PesquisaPlanoCorte
        open={confPesquisaDinamicaPlanoCorte.open || false}
        handleClose={
          () => setConfPesquisaDinamicaPlanoCorte({ ...confPesquisaDinamicaPlanoCorte, open: false })
        }
        venda_value={venda_value}
        projetoVenda_value={projetoVenda_value}
        handleClick={handleFuncaoSelecaoPesquisaDinamicaPlanoCorte}
      />

      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mt={5} mb={9}>
          <Grid container justifyContent="center">
            <Grid item xs={12} lg={12}>
              <MDBox mt={0} mb={0} textAlign="center">
                <MDBox mb={1}>
                  <MDTypography variant="h3" fontWeight="bold">
                    {`${baseCaption}`}
                  </MDTypography>
                </MDBox>
                {/* Botao de Configuracoes (Parametros e Permissoes) */}
                <MDBox width="100%" display="flex" justifyContent="flex-end">
                  <Tooltip title="Configurações e Permissões" placement="top">
                    <IconButton
                      onClick={(event) => {
                        setCurrentTargetBotaoMenu(event.currentTarget);
                        setOpenMenuConfiguracao(true);
                      }
                      }
                    >
                      <SettingsIcon />
                    </IconButton>
                  </Tooltip>
                </MDBox>
              </MDBox>

              <Card>
                <TabPanel>
                  <MDBox width="100%" display="flex" justifyContent="space-between">
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={9}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={3}> {/* Novo Plano de Corte */}
                            <MDButton
                              variant="gradient"
                              onClick={handleClickNovo}
                              color='info'
                            >
                              Novo Plano de Corte
                            </MDButton>
                          </Grid>
                          <Grid item xs={12} sm={3}>{/* Abrir Plano de Corte */}
                            <MDButton
                              variant="gradient"
                              onClick={handleClickPesquisaPlanoCorte}
                              color='info'
                            >
                              Abrir Plano de Corte
                            </MDButton>
                          </Grid>
                          <Grid item xs={12} sm={2}>{/* Imprimir */}
                            <MDButton
                              variant="gradient"
                              onClick={handleClickOpcoesImpressao}
                              color='info'
                            >
                              Imprimir
                            </MDButton>
                          </Grid>
                          <Grid item xs={12} sm={2}>{/* Estornar */}
                            <MDButton
                              disabled={concluidoPlanoCorte == 'S' ? false : true}
                              variant="gradient"
                              onClick={() => { handleClickEstornar(planoCorte_value) }}
                              color='warning'
                            >
                              Estornar
                            </MDButton>
                          </Grid>
                          <Grid item xs={12} sm={2}>{/* Excluir */}
                            <MDButton
                              disabled={planoCorte_value ? false : true}
                              variant="gradient"
                              onClick={() => { handleClickExcluir(planoCorte_value) }}
                              color='error'
                            >
                              Excluir
                            </MDButton>
                          </Grid>
                          <Grid item xs={12} sm={2.5}> {/* Orçamento/Venda */}
                            <FormField
                              type="text"
                              label="Orçamento/Venda"
                              value={venda_value}
                              onChange={(e) => setVenda_value(e.target.value)}
                              inputProps={{
                                onKeyUp: (e) => {
                                  e.preventDefault();
                                  if (e.key === 'F9') {
                                    handleClickPesquisaVenda();
                                  }
                                },
                                onBlur: (e) => {
                                  e.preventDefault();
                                  handleBlurVenda();
                                },
                              }}
                              InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                            />
                          </Grid>
                          <Grid item xs={12} sm={0.5}> {/*LUPA DO ORÇAMENTO VENDA */}
                            <MDBox width="100%" height='100%' display="flex" justifyContent='center' alignItems='center' >
                              <SearchIcon
                                sx={{ cursor: 'pointer' }}
                                onClick={() => {
                                  handleClickPesquisaVenda();
                                }}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} sm={2.5}> {/* Projeto da Orçamento/Venda  */}
                            <FormField
                              type="text"
                              label="Projeto da Orçamento/Venda"
                              value={projetoVenda_value}
                              onChange={(e) => setProjetoVenda_value(e.target.value)}
                              inputProps={{
                                onKeyUp: (e) => {
                                  e.preventDefault();
                                  if (e.key === 'F9') {
                                    handleClickPesquisaProjetoVenda();
                                  }
                                },
                                onBlur: (e) => {
                                  e.preventDefault();
                                  handleBlurProjetoVenda();
                                },
                              }}
                              InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                            />
                          </Grid>
                          <Grid item xs={12} sm={0.5}>{/*LUPA DO PROJETO VENDA */}
                            <MDBox width="100%" height='100%' display="flex" justifyContent='center' alignItems='center' >
                              <SearchIcon
                                sx={{ cursor: 'pointer' }}
                                onClick={() => {
                                  handleClickPesquisaProjetoVenda();
                                }}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} sm={2.5}> {/* Produto/Cor da Orçamento/Venda*/}
                            <FormField
                              type="text"
                              label="Produto/Cor da Orçamento/Venda"
                              value={produtoVenda_value}
                              onChange={(e) => setProdutoVenda_value(e.target.value)}
                              inputProps={{
                                onKeyUp: (e) => {
                                  e.preventDefault();
                                  if (e.key === 'F9') {
                                    handleClickPesquisaProdutoVenda();
                                  }
                                },
                                onBlur: (e) => {
                                  e.preventDefault();
                                  handleBlurProdutoVenda();
                                },
                              }}
                              InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                            />
                          </Grid>
                          <Grid item xs={12} sm={0.5}> {/*LUPA DO PRODUTO VENDA */}
                            <MDBox width="100%" height='100%' display="flex" justifyContent='center' alignItems='center' >
                              <SearchIcon
                                sx={{ cursor: 'pointer' }}
                                onClick={() => {
                                  handleClickPesquisaProdutoVenda();
                                }}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} sm={3}> {/* Carrega Alumínios */}
                            <MDButton
                              disabled={(venda_value || projetoVenda_value) && concluidoPlanoCorte == 'N' ? false : true}
                              variant="gradient"
                              onClick={handleClickCarregaAluminios}
                              color='info'
                            >
                              Carrega Alumínios
                            </MDButton>
                          </Grid>
                          <Grid item xs={12} sm={7}> {/* OBSERVACAO */}
                            <FormField
                              type="text"
                              label="Observação"
                              value={observacaoPlanoCorte || ''}
                              onChange={(e) => setObservacaoPlanoCorte(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={1.5}> {/* OBSERVACAO */}
                            <FormField
                              type="text"
                              label="Descarte (mm)"
                              value={descartePlanoCorte || ''}
                              onChange={(e) => setDescartePlanoCorte(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={1.4} > {/*RATEIO*/}
                            <MDBox width='100%' display='flex' alignItems='center' flexDirection='column'>
                              <MDTypography variant='caption'>
                                {'Usa retalho'}
                              </MDTypography>
                              <Switch
                                checked={checkedRetalho}
                                onChange={(event) => setCheckedRetalho(event.target.checked)}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} sm={2.1}> {/* Otimiza Alumínios */}
                            <MDButton
                              disabled={dadosOtimizar.length > 1 && concluidoPlanoCorte == 'N' ? false : true}
                              variant="gradient"
                              onClick={handleClickOtimizaAluminios}
                              color='success'
                            >
                              Otimizar
                            </MDButton>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} sm={3} mb={2}> {/* Box com os totais */}
                        <MDBox width="100%" variant="title" borderRadius="lg" coloredShadow="info">
                          <Box
                            sx={{
                              '& > :not(style)': { width: '100%' },
                              border: "solid",
                              borderWidth: '1px',
                              borderColor: 'info',
                              //color: "info",
                              borderRadius: '10px',

                            }}

                          >
                            <Grid container spacing={1} justifyContent="flex-start" mt={0.1}>
                              <Grid item xs={12} sm={6}>
                                <Typography ml={2} variant='subtitle2' fontWeight="bold" >Plano de Corte:</Typography>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <MDTypography
                                  //onClick={() => { validaModulo() }}
                                  color={'info'} sx={{ cursor: "pointer" }} variant='body2' >{planoCorte_value}</MDTypography>
                                {/* <MDTypography sx={{ cursor: "pointer" }} variant="body2" textTransform="capitalize">{`Transferência - ${item.transferencia}`}</MDTypography> */}
                              </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent="flex-start">
                              <Grid item xs={12} sm={6}>
                                <Typography ml={2} variant='subtitle2' fontWeight="bold">Data:</Typography>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Typography variant='subtitle2' >{dataPlanoCorte}</Typography>
                              </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent="flex-start">
                              <Grid item xs={12} sm={6}>
                                <Typography ml={2} variant='subtitle2' fontWeight="bold">Concluído:</Typography>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Typography variant='subtitle2' > {concluidoPlanoCorte == 'S' ? 'Sim' : 'Não'} </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        </MDBox>
                      </Grid>

                      <Grid item xs={12} sm={12}>{/*Transfer List*/}
                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                          <Grid item>{customList('Aumínios da Venda', dadosAluminios)}</Grid>
                          <Grid item>
                            <Grid container direction="column" alignItems="center">
                              {/* <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={handleCheckedRight}
                                disabled={leftChecked.length === 0}
                                aria-label="move selected right"
                              >
                                &gt;
                              </Button> */}
                              <Tooltip title="Incluir alumínio para otimizar" placement="top">
                                <MDButton
                                  sx={{ my: 0.5 }}
                                  variant="gradient"
                                  size="small"
                                  onClick={handleCheckedRight}
                                  disabled={leftChecked.length === 0}
                                  color='info'
                                  aria-label="move selected right"
                                >
                                  &gt;
                                </MDButton>
                              </Tooltip>
                              {/* <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={handleCheckedLeft}
                                disabled={rightChecked.length === 0}
                                aria-label="move selected left"
                              >
                                &lt;
                              </Button> */}
                              <Tooltip title="Remover alumínio para otimizar" placement="top">
                                <MDButton
                                  sx={{ my: 0.5 }}
                                  variant="gradient"
                                  size="small"
                                  onClick={handleCheckedLeft}
                                  disabled={rightChecked.length === 0}
                                  color='error'
                                  aria-label="move selected left"
                                >
                                  &lt;
                                </MDButton>
                              </Tooltip>
                            </Grid>
                          </Grid>
                          <Grid item>{customList('Alumínios para Otimizar', dadosOtimizar)}</Grid>
                        </Grid>

                      </Grid>

                    </Grid>


                  </MDBox>

                </TabPanel>

              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout >
    </>
  );
}

export default Otimizar;
