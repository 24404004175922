import { Card, Grid } from "@mui/material";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import FormField from "componentes/FormField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import AuthContext from "hooks/AuthContext";
import MessagesContext from "hooks/MessagesContext";
import { useEffect } from "react";
import { useContext, useState } from "react";



export default function ConfiguracaoImpressao() {

    const messageContext = useContext(MessagesContext);
    const { MensagemAviso, MensagemErro } = messageContext;
    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();
    const [dataset, setDataset] = useState({
        parametro_cabecalho_proposta_id: '',
        parametro_cabecalho_proposta_valor:'',
    })
    const [permitido, setPermitido] = useState(false);

    const validaRotaPermissao = async (operacao = '', silencioso = false) => {
        if ((operacao !== 'inserir') &&
            (operacao !== 'editar') &&
            (operacao !== 'excluir') &&
            (operacao !== 'listar')) {
            MensagemErro('Escolha a operação que deseja validar a permissão!');
            return false;
        }

        //validando a permissao
        try {
            await api.get(`${rotafechada()}parametro_empresas/permissao/${operacao}`);
            return true;
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;

            if (silencioso === false) {
                MensagemErro(`Erro: ${msg}`);
            }
            return false;
        }
    }

    const validaPermissao = async () => {
        const permitidotemp = await validaRotaPermissao('editar', true);
        setPermitido(permitidotemp);
        buscaDados();
    }

    const buscaDados = async () => {
        try {
            const retorno = await api.get(`${rotafechada()}parametros/validar?nome=CABECALHO PROPOSTAS`);
            const data = retorno.data;

            setDataset({
                parametro_cabecalho_proposta_id: data.parametro_id,
                parametro_cabecalho_proposta_valor: data.valor
            });
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const gravarConfiguracaoImpressao = async () => {
        try {

            //CABECALHO PEDIDO
            const dados = {
                empresa_id: sessaolocal.empresa_id,
                parametro_id: dataset.parametro_cabecalho_proposta_id,
                valor:dataset.parametro_cabecalho_proposta_valor
            };
            await api.post(`${rotafechada()}parametro_empresas/altera`, dados);

            MensagemAviso('Alterado com Sucesso!');
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    useEffect(() => {
        validaPermissao();

    }, [])


    return (
        <Card id="configuracao-impressao" sx={{ overflow: "visible" }}>
            <MDBox p={3}>
                <MDTypography variant="h5">Configurações de Impressão</MDTypography>
            </MDBox>

            <MDBox component="form" pb={3} px={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <FormField label="Cabeçalho Proposta"

                            disabled={!permitido}
                            laceholder=""
                            name='parametrocabecalho'
                            rows={4}
                            multiline={true}
                            value={dataset.parametro_cabecalho_proposta_valor}
                            onChange={(e) => {
                                setDataset({
                                    ...dataset,
                                    parametro_cabecalho_proposta_valor: e.target.value
                                })
                            }}
                        />
                    </Grid>


                    <Grid item xs={12} md={10}>
                    </Grid>

                    <Grid item xs={12} md={2} >
                        <MDButton color='info' disabled={!permitido} onClick={gravarConfiguracaoImpressao}>Gravar</MDButton>
                    </Grid>
                </Grid>
            </MDBox>
        </Card>

    )
}