import { copiaProfundaObjetos } from 'commons/utils';
import { createContext, useState } from 'react'

const DataPrinterContext = createContext()

export default DataPrinterContext;

export const DataPrinterProvider = ({ children }) => {

    const [titulo,setTituloLocal] = useState('Gere o Relatório novamente');
    const [subtitulo,setSubtituloLocal] = useState([]);
    const [colunas,setColunasLocal] = useState([{ id: 'id', label: 'Id' }, { id: 'nome', label: 'Nome' }, { id: 'valor', label: 'Valor' }]);
    const [linhas,setLinhasLocal]    =useState([{ id: 1, nome: '...', valor: 0 }, { id: 2, nome: '...', valor: 0 }])
    const [totalizador,setTotalizadorLocal]=useState('');
    
    const [estiloColunas,setEstiloColunasLocal]=useState({
        color:'black',
        fontWeight:'bold',
        fontSize:'14px'
    });
    
    const [estiloLinhas,setEstiloLinhasLocal]=useState({
        color:'black',
        fontWeight:'normal',
        fontSize:'12px'
    });

    const setTitulo = (texto = '') => {
        setTituloLocal(texto);
    }

    const setSubtitulo = (texto = []) => {
        setSubtituloLocal(texto);
    }

    const setColunas = (arrayColunas = [{
        id: '',
        label: ''
    }]) => {
        setColunasLocal(arrayColunas);
    }

    const setLinhas = (arrayLinhas = []) => {
        setLinhasLocal(arrayLinhas);
    }

    const setTotalizador = (campoTotalizador = '') => {
        setTotalizadorLocal(campoTotalizador);
    }

    const setEstiloColunas=(objEstilos={})=>{
        setEstiloColunasLocal({...estiloColunas,...objEstilos});
    }

    const setEstiloLinhas=(objEstilos={})=>{
        setEstiloLinhasLocal({...estiloLinhas,...objEstilos});
    }

    let contextDataPrinterData = {
        titulo,
        subtitulo,
        colunas,
        linhas,
        totalizador,
        estiloColunas,
        estiloLinhas,
        setTitulo,
        setSubtitulo,
        setColunas,
        setLinhas,
        setTotalizador,
        setEstiloColunas,
        setEstiloLinhas
    }

    return (
        <DataPrinterContext.Provider value={contextDataPrinterData} >
            {children}
        </DataPrinterContext.Provider>
    )
}
