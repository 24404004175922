/*
1º Etapa (preparapedidotempera)-> Mostra uma tela com os "Projetos sem Pedido de Têmpera" na parte de cima e os "Projetos com Pedido de Tempera na parte de baixo"
Ao selecionar um projeto em cima e mandar adicionar verificar se já existe algum pedido de têmpera para essa venda
Se Sim
    mostrar uma tela de escolha para ("Criar um novo projeto de tempera") ou "Escolher qual projeto de tempera desejo adicionar esse projeto"
        Se escolher um novo projeto (abrir a tela de novo projeto), caso contrario avança para a próxima etapa
Se Não
    Abir a tela de novo Projeto de têmpera (Solicitar fornecedor, data e obs)

2º Etapa-> Tem mais de um projeto de tempera para a Venda?

Se Sim -> Mostra uma tela para escolher qual projeto deseja imprimir neste momento depois que selecionar ir para a tela de desenho (3º etapa)
Se Não -> Ir direto para a tela de desenho (3º etapa)

3º Etapa-> Mostra todos os projetos com seus desenhos podendo personalizar algum, após personalizar clicar para imprimir

4º Etapa-> Grava as personalizações e gera todos os desenhos finais (base64), envia essa base64 para o backend, depois de enviar todas
solicita a geração do pedido de têmpera em pdf 


*/

import { Dialog, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import { Box } from "@mui/system";
import DialogoConfirma from "componentes/DialogoConfirma";
import MessagesContext from "hooks/MessagesContext";
import { useContext, useEffect, useState } from "react";
import Etapa1_PreparaPedidoTempera from "./etapa1_preparapedidotempera";
import Etapa2_EscolhePedidoTempera from "./etapa2_escolhepedidotempera";
import Etapa3_ConfiguraDesenhos from "./etapa3_configuradesenhos";
import Etapa4_MostraDesenhos from "./etapa4_mostradesenhos";
import CloseIcon from '@mui/icons-material/Close';
import MDButton from "components/MDButton";


export default function PedidoTempera(props) {

    const { open, handleClose, vendaAtual, etapaAtual, setEtapaAtual, parametrosVenda } = props;

    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;

    const [openConfirmacao, setOpenConfirmacao] = useState({ open: false });
    const [openDesenhos, setOpenDesenhos] = useState({ open: false, pedido_tempera_id: null });

    const [etapaAnterior, setEtapaAnterior] = useState(0);

    const handleConfirmaAbortar = () => {
        setOpenConfirmacao({
            open: true,
            message: 'Certeza que deseja abortar o processo?',
            handleCancel: () => setOpenConfirmacao({ open: false }),
            handleConfirm: () => {
                setOpenConfirmacao({ open: false });
                handleClose();
            }
        });
    }

    const abortaProcesso = () => {
        //aborta sem mostrar a mensagem de confirmação
        handleClose();
    }

    useEffect(() => {


    }, [open, vendaAtual])

    const handleClickPedidoTemperaSelecionado = (pedido_tempera_id) => {
        MensagemAviso('Pedido selecionado ' + pedido_tempera_id);
        setOpenDesenhos({ open: true, pedido_tempera_id });
        setEtapaAtual((i) => i + 1);
    }



    return (
        <>
            <DialogoConfirma
                open={openConfirmacao?.open || false}
                message={openConfirmacao?.message || ''}
                title={openConfirmacao?.title || 'Confirmação'}
                handleCancel={openConfirmacao.handleCancel}
                handleConfirm={openConfirmacao.handleConfirm}
            />
            <Dialog
                fullWidth={true}
                maxWidth={'xl'}
                open={open}
                onClose={handleConfirmaAbortar}
                disableEscapeKeyDown
            >
                <DialogTitle>
                    <Box display='flex' justifyContent='space-between'>
                        <Typography variant='h5'>Pedido de Têmpera</Typography>
                        <MDButton onClick={abortaProcesso}>
                            <CloseIcon fontSize="large" color='info'/>
                        </MDButton>
                    </Box>

                </DialogTitle>
                <DialogContent>
                    {
                        (parseInt(etapaAtual, 10) === 0) &&
                        <Etapa1_PreparaPedidoTempera
                            abortaProcesso={abortaProcesso}
                            vendaAtual={vendaAtual}
                            etapaAtual={etapaAtual}
                            setEtapaAtual={setEtapaAtual}
                            parametrosVenda={parametrosVenda}
                        />
                    }
                    {

                        (parseInt(etapaAtual, 10) === 1) &&
                        <Etapa2_EscolhePedidoTempera
                            abortaProcesso={abortaProcesso}
                            vendaAtual={vendaAtual}
                            etapaAtual={etapaAtual}
                            setEtapaAtual={setEtapaAtual}
                            handleClickPedidoTemperaSelecionado={handleClickPedidoTemperaSelecionado}
                            setEtapaAnterior={setEtapaAnterior}
                        />
                    }
                    {

                        ((parseInt(etapaAtual, 10) === 2) && (openDesenhos?.open)) &&
                        <Etapa3_ConfiguraDesenhos
                            abortaProcesso={abortaProcesso}
                            vendaAtual={vendaAtual}
                            etapaAtual={etapaAtual}
                            setEtapaAtual={setEtapaAtual}
                            pedido_tempera_id={openDesenhos.pedido_tempera_id}
                            etapaAnterior={etapaAnterior}
                        />

                    }
                    {

                        (parseInt(etapaAtual, 10) === 3) &&
                        <Etapa4_MostraDesenhos
                            abortaProcesso={abortaProcesso}
                            vendaAtual={vendaAtual}
                            etapaAtual={etapaAtual}
                            setEtapaAtual={setEtapaAtual}
                            pedido_tempera_id={openDesenhos.pedido_tempera_id}
                            etapaAnterior={etapaAnterior}
                        />

                    }



                </DialogContent>
            </Dialog>
        </>
    )
}