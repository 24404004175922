import { Autocomplete, Grid, IconButton, Typography } from '@mui/material';
import api from 'commons/api';
import { rotafechada } from 'commons/urlconf';
import { formataValor } from 'commons/utils';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';
import FormField from 'componentes/FormField';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDTypography from 'components/MDTypography';
import MessagesContext from 'hooks/MessagesContext';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { Controller, useWatch } from 'react-hook-form';

const CalculaPorM2 = (props) => {

    const { control, setValue, getValues, vendaAtual, handleSubmit,
        etapaAtual, setEtapaAtual, objFull, proximaEtapa, handleSolicitaAbortarProcesso, desenhaCabecalhoPadrao } = props;

    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;
    const opcaoDefault = [{ id: -1, value: -1, label: '<Selecione...>' }];
    const [opcoesCores, setOpcoesCores] = useState(opcaoDefault)

    function gravarTeste(data) {
        console.log('objfull-original:', objFull);
        console.log('obj-RHF:', data);
    }


    const validaProximaEtapa = () => {

        setValue('projetos.valor_total_m2',calcula_valor_m2());

        proximaEtapa();
    }

    const etapaAnterior = () => {
        setEtapaAtual(etapaAtual - 1);
    }

    useEffect(() => {
        //buscaOpcoesCores();
    }, [])

    const getValor_custo_m2 = useWatch({
        control,
        name: "projetos.valor_custo_m2",
    });

    const getPercentual_lucro_m2 = useWatch({
        control,
        name: "projetos.percentual_lucro_m2",
    });

    const calcula_valor_m2 = () => {

        let quantidade_m2 = parseFloat(`${getValues('projetos.quantidade_m2')}`.replace(',', '.'));
        let valor_custo_m2 = parseFloat(`${getValor_custo_m2}`.replace(',', '.'));
        let percentual_lucro_m2 = parseFloat(`${getPercentual_lucro_m2}`.replace(',', '.'))

        let valor_total_custo = (quantidade_m2 * valor_custo_m2);

        let valor_final_m2 = valor_total_custo + (valor_total_custo * percentual_lucro_m2) / 100;

        return valor_final_m2;

    }


    return (
        <>
            {desenhaCabecalhoPadrao('Cálculo Final por M² ')}
            <Typography variant='subtitle2' mb={2}>Digite os Valores Desejados</Typography>
            <Grid container spacing={1}>


                <Grid item xs={12} sm={12}>
                    <Grid container spacing={2}>

                        <Grid item xs={2} sm={2}> {/*quantidade_m2*/}
                            <Typography variant='subtitle' >{getValues('projetos.quantidade_m2')}m² X </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>{/*valor_custo_m2*/}
                            <Controller
                                name={`projetos.valor_custo_m2`}
                                control={control}
                                defaultValue=''
                                render={({ field }) =>
                                    <FormField
                                        {...field}
                                        key='projetos.valor_custo_m2'
                                        id='projetos.valor_custo_m2'
                                        autoComplete={false}
                                        type='text'
                                        label='Valor de Custo do M² (R$)'
                                        rows={1}
                                        multiline={false}
                                        disabled={false}
                                    />}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>{/*percentual_lucro_m2*/}
                            <Controller
                                name={`projetos.percentual_lucro_m2`}
                                control={control}
                                defaultValue=''
                                render={({ field }) =>
                                    <FormField
                                        {...field}
                                        key='projetos.percentual_lucro_m2'
                                        id='projetos.percentual_lucro_m2'
                                        autoComplete={false}
                                        type='text'
                                        label='Percentual de Lucro (%)'
                                        rows={1}
                                        multiline={false}
                                        disabled={false}
                                    />}
                            />
                        </Grid>

                        <Grid item xs={2} sm={2}> {/*quantidade_m2*/}
                            <Typography variant='subtitle' >
                                =
                                {
                                    formataValor(calcula_valor_m2())
                                }

                            </Typography>
                        </Grid>



                    </Grid>
                </Grid>





            </Grid>

            <MDBox width="100%" display="flex" justifyContent="space-between" mt={2}>
                <MDButton variant="gradient" color="info" onClick={etapaAnterior} sx={{ ml: 2 }}>Retornar</MDButton>
                {
                    (props.parametrosVenda.mostra_botao_obj || false) &&
                    <MDButton variant="gradient" color="warning" onClick={() => handleSubmit(gravarTeste)()} sx={{ ml: 2 }}>Mostra OBJ</MDButton>
                }
                <MDButton variant="gradient" color="info" onClick={validaProximaEtapa} sx={{ ml: 2 }}>Avançar</MDButton>

            </MDBox>
        </>
    )
}

export default CalculaPorM2;

