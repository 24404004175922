
import AuthContext from "hooks/AuthContext";
import { useContext } from "react";



export function useTrataRetorno() {
    const authContext = useContext(AuthContext);

    const trataRetorno = (props) => {
        const { logout } = authContext;
        const logoff = logout;

        if (props && props.includes) {
            if (props.includes('Usuário/Empresa')&& props.includes('desativado na empresa')) {
                logoff();
            }
            if (props.includes('{refreshToken} não pode ser em branco')) {
                logoff();
            }
        }
    }

    return trataRetorno;
}
