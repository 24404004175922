import React, { useContext, useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MessagesContext from "hooks/MessagesContext";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import DialogoConfirma from 'componentes/DialogoConfirma';
import FormField from "componentes/FormField";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import MDButton from "components/MDButton";


function ApagaBancodeDadosAdmin(props) {

  const { empresaAtual, nomeEmpresaAtual } = props;

  const messageContext = useContext(MessagesContext);
  const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;
  const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });
  const [memoLog, setMemoLog] = useState('');
  const [apagaProdutos, setApagaProdutos] = useState(false);
  const [apagaProjetos, setApagaProjetos] = useState(false);
  const [apagaVendas, setApagaVendas] = useState(false);
  const [apagaFinanceiro, setApagaFinanceiro] = useState(false);

  const [apagaPessoas, setApagaPessoas] = useState(false);
  const [apagaParametros, setApagaParametros] = useState(false);
  const [apagaEmpresa, setApagaEmpresa] = useState(false);


  const apagaDados = async () => {

    setDialogoOpen({ ...dialogoOpen, visible: false });

    if (!((apagaProdutos) || (apagaProjetos) || (apagaVendas) || (apagaPessoas) || (apagaParametros) || (apagaFinanceiro)||(apagaEmpresa))) {
      MensagemErro('Selecione a opção que deseja excluir');
      return;
    }

    try {
      const id = dialogoOpen.id
      MostraTelaEspera('Aguarde a exclusão');
      const retorno = await api.post(`${rotafechada()}admin/empresas/limparbase`, {
        empresa_id: empresaAtual,
        apagavenda: apagaVendas,
        apagaprojeto: apagaProjetos,
        apagaproduto: apagaProdutos,
        apagapessoa: apagaPessoas,
        apagaparametros: apagaParametros,
        apagafinanceiro: apagaFinanceiro,
        apagaempresa:apagaEmpresa

      });
      FechaTelaEspera();
      MensagemAviso('Apagado com sucesso!');
      setMemoLog('');
    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      const erro = error.response?.data?.erro || msg;
      setMemoLog(erro)
      MensagemErro(`Erro: ${msg}`);
    }
  }


  // //comentar essa funcao apos usar
  // const apagaDadosGIS = async () => {

  //   setDialogoOpen({ ...dialogoOpen, visible: false });

  //   if (!((apagaProdutos) || (apagaProjetos) || (apagaVendas) || (apagaPessoas) || (apagaParametros) || (apagaFinanceiro)||(apagaEmpresa))) {
  //     MensagemErro('Selecione a opção que deseja excluir');
  //     return;
  //   }

  //   try {
  //     const id = dialogoOpen.id
  //     MostraTelaEspera('Aguarde a exclusão');
  //     const retorno = await api.post(`${rotafechada()}admin/empresas/apagarempresasexportacaoGIS`, {
  //       empresa_id: empresaAtual,
  //       apagavenda: apagaVendas,
  //       apagaprojeto: apagaProjetos,
  //       apagaproduto: apagaProdutos,
  //       apagapessoa: apagaPessoas,
  //       apagaparametros: apagaParametros,
  //       apagafinanceiro: apagaFinanceiro,
  //       apagaempresa:apagaEmpresa

  //     });
  //     FechaTelaEspera();
  //     MensagemAviso('Apagado com sucesso!');
  //     setMemoLog('');
  //   }
  //   catch (error) {
  //     FechaTelaEspera();
  //     const msg = error.response?.data?.msg || error;
  //     const erro = error.response?.data?.erro || msg;
  //     setMemoLog(erro)
  //     MensagemErro(`Erro: ${msg}`);
  //   }
  // }

  const handleClickApagarDados = async (id) => {
    setDialogoOpen({
      ...dialogoOpen,
      visible: true,
      id,
      title: 'Apagar Dados',
      message: `Certeza que APAGAR os dados da empresa  ${empresaAtual}-${nomeEmpresaAtual}`,
      funcao: 'apagaDados'
    });
  }

  // const handleClickApagarDadosGIS = async (id) => {
  //   setDialogoOpen({
  //     ...dialogoOpen,
  //     visible: true,
  //     id,
  //     title: 'Apagar Dados',
  //     message: `Certeza que APAGAR todas as empresas ???????`,
  //     funcao: 'apagaDadosGIS'
  //   });
  // }

  const handleConfirmDialogo = () => {

    if (dialogoOpen.funcao === 'apagaDados') {
      apagaDados();
    }
    // else
    // if (dialogoOpen.funcao === 'apagaDadosGIS') {
    //   apagaDadosGIS();
    // }

  }


  return (
    <>

      <DialogoConfirma
        open={dialogoOpen.visible}
        title={dialogoOpen.title}
        message={dialogoOpen.message}
        handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
        handleConfirm={handleConfirmDialogo}
      />

      <MDBox>
        <MDBox mt={0} mb={4} textAlign="center">
          <MDBox mb={1}>
            <MDTypography variant="h4" fontWeight="bold">
              {`Exclui Banco de Dados`}
            </MDTypography>
          </MDBox>
        </MDBox>

        <MDBox>
          <Grid container>


            <Grid item xs={12} sm={12} mt={4}>
              <MDBox >
                <FormGroup>
                  <FormControlLabel ml={2} control={<Checkbox checked={apagaFinanceiro} onChange={(e) => setApagaFinanceiro(e.target.checked)} />} label="Apaga Financeiro" />
                  <FormControlLabel ml={2} control={<Checkbox checked={apagaVendas} onChange={(e) => setApagaVendas(e.target.checked)} />} label="Apaga Vendas" />
                  <FormControlLabel ml={2} control={<Checkbox checked={apagaProjetos} onChange={(e) => setApagaProjetos(e.target.checked)} />} label="Apaga Projetos" />
                  <FormControlLabel ml={2} control={<Checkbox checked={apagaProdutos} onChange={(e) => setApagaProdutos(e.target.checked)} />} label="Apaga Produtos" />
                  <FormControlLabel ml={2} control={<Checkbox checked={apagaPessoas} onChange={(e) => setApagaPessoas(e.target.checked)} />} label="Apaga Pessoas" />
                  <FormControlLabel ml={2} control={<Checkbox checked={apagaParametros} onChange={(e) => setApagaParametros(e.target.checked)} />} label="Apaga Parâmetros" />
                  <FormControlLabel ml={2} control={<Checkbox checked={apagaEmpresa} onChange={(e) => setApagaEmpresa(e.target.checked)} />} label="Apaga Empresa no Final" />
                </FormGroup>
                <MDButton color='error' onClick={handleClickApagarDados}>Apagar do Contrato {empresaAtual}</MDButton>


                {/* <MDButton color='error' onClick={handleClickApagarDadosGIS}>Apagar empresas e deixar GIS </MDButton> */}
              </MDBox>
            </Grid>

            <Grid item xs={12} sm={12} mt={12}>
              <FormField
                type='text'
                label='Log'
                name='memoLog'
                rows={10}
                multiline={true}
                required={true}
                disabled={false}
                value={memoLog}
              />
            </Grid>

          </Grid>
        </MDBox>

      </MDBox>
    </>
  );
}

export default ApagaBancodeDadosAdmin;
