
import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, Icon, IconButton, Tooltip, Typography } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Tabela from "./Tabela";
import Cadastro from "./Cadastro";
import MDButton from "components/MDButton";

import MessagesContext from "hooks/MessagesContext";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import AuthContext from "hooks/AuthContext";
import DialogoConfirma from 'componentes/DialogoConfirma';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SettingsIcon from '@mui/icons-material/Settings';
import MenuConfiguracaoModulos from "componentes/MenuConfiguracaoModulos";
import { useNavigate, useParams } from "react-router-dom";
import { useTrataRetorno } from "commons/authUtils";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.any,
    index: PropTypes.number,
    value: PropTypes.number,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function PlanoContas() {

    let valorinicial = '';
   const trataRetorno = useTrataRetorno();
    const params = useParams();
    const { id } = params;
    const navigate = useNavigate();
    if ((id) && (parseInt(id, 10) >= 0)) {
        valorinicial = id;
    }

    const [aba, setAba] = React.useState(0);
    const [codigoCarregado, setCodigoCarregado] = useState(valorinicial);
    const messageContext = useContext(MessagesContext);
    const { MensagemAviso, MensagemErro } = messageContext;
    const [dataset, setDataset] = useState([]);
    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();
    const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });
    const [recarregaTabela, setRecarregaTabela] = useState(false);
    const [enabledBotaoGravar, setEnabledBotaoGravar] = useState(true);
    const [openFiltrosAvancados, setOpenFiltrosAvancados] = useState(false);

    const [openMenuConfiguracao, setOpenMenuConfiguracao] = useState(false);
    const [currentTargetBotaoMenu, setCurrentTargetBotaoMenu] = useState(null);

    const baseUrl = `${rotafechada()}plano_contas`;
    const baseCaption = 'Plano de contas';

    const validaRotaPermissao = async (operacao = '', silencioso = false) => {
        if ((operacao !== 'inserir') &&
            (operacao !== 'editar') &&
            (operacao !== 'excluir') &&
            (operacao !== 'listar')) {
            MensagemErro('Escolha a operação que deseja validar a permissão!');
            return false;
        }

        //validando a permissao
        try {
            await api.get(`${baseUrl}/permissao/${operacao}`);
            return true;
        }
        catch (error) {
            trataRetorno(error?.response?.data?.erro);
            const msg = error.response?.data?.msg || error;

            if (silencioso === false) {
                MensagemErro(`Erro: ${msg}`);
            }
            return false;
        }
    }

    const handleClickNovo = async () => {
        if (await validaRotaPermissao('inserir') === false) {
            return;
        }
        setCodigoCarregado('');
        setAba(1)
    }

    const handleClickAbrir = (id) => {
        setCodigoCarregado(id);
        abrirRegistro(id);
    }

    const abrirRegistro = async (id) => {
        if (!parseInt(id, 10) > 0)
            return;

        try {
            setCodigoCarregado(id);
            setAba(1);
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const handleExcluir = async () => {
        setDialogoOpen({ ...dialogoOpen, visible: false });

        try {
            await api.delete(`${baseUrl}/${dialogoOpen.id || 0}`);
            MensagemAviso('Excluído com sucesso!');
            setCodigoCarregado('');
            setRecarregaTabela(!recarregaTabela);
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const handleClickExcluir = async (id) => {

        if (!(await validaRotaPermissao('excluir') === true)) {
            return;
        }

        setDialogoOpen({ ...dialogoOpen, visible: true, id });
    }

    useEffect(async () => {

        if (aba === 1) {
            if (parseInt(codigoCarregado, 10) > 0) {
                if (await validaRotaPermissao('editar') === false) {
                    //alem da mensagem, desativo o botao de gravar
                    setEnabledBotaoGravar(false);
                }
            }
            else {
                if (await validaRotaPermissao('inserir') === false) {
                    //alem da mensagem, desativo o botao de gravar
                    setEnabledBotaoGravar(false);
                }
            }

        }

    }, [aba])

    return (
        <>

            <DialogoConfirma
                open={dialogoOpen.visible}
                title={'Confirma Exclusão'}
                color={'error'}
                message={'Certeza que deseja excluir?'}
                handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
                handleConfirm={handleExcluir}
            />

            <MDBox mt={0} mb={9}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} lg={12}>
                        <Card>
                            <TabPanel value={aba} index={0}>

                                <MDBox width="100%" display="flex" justifyContent="space-between">
                                    <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => setOpenFiltrosAvancados(!openFiltrosAvancados)}
                                    >
                                        {openFiltrosAvancados ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        <Typography>Filtros</Typography>
                                    </IconButton>
                                    <Tooltip title="Novo" placement="top">
                                        <MDButton
                                            variant="gradient"
                                            circular
                                            onClick={handleClickNovo}
                                            color='info'
                                        >
                                            <Icon>add</Icon>
                                        </MDButton>
                                    </Tooltip>

                                </MDBox>

                                <Tabela
                                    openFiltrosAvancados={openFiltrosAvancados}
                                    setOpenFiltrosAvancados={setOpenFiltrosAvancados}
                                    abrirRegistro={handleClickAbrir}
                                    handleClickExcluir={handleClickExcluir}
                                    recarregaTabela={recarregaTabela}
                                    validaRotaPermissao={validaRotaPermissao} />
                            </TabPanel>

                            <TabPanel value={aba} index={1}>
                                <Cadastro
                                    setCodigoAbrir={setCodigoCarregado}
                                    codigoAbrir={codigoCarregado}
                                    setAba={setAba}
                                />
                            </TabPanel>

                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </>
    );
}

export default PlanoContas;
