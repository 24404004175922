import React, { useContext, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Checkbox, Grid, IconButton, Typography, Autocomplete, Tooltip } from '@mui/material';
import FormField from 'componentes/FormField';
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';

import MDTypography from 'components/MDTypography';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import MessagesContext from 'hooks/MessagesContext';
import MDButton from 'components/MDButton';
import AuthContext from 'hooks/AuthContext';
import TabelaOpcoes from './tabela';
import DialogoConfirma from 'componentes/DialogoConfirma';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';
import InfoIcon from '@mui/icons-material/Info';

const PessoaContatos = (props) => {


  const { handleSubmit, control, setValue, getValues, pessoaAtual, recarregaComponenteOpcoes, opcoesTipoTelefone } = props;

  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MensagemAviso } = messageContext;
  const authContext = useContext(AuthContext);
  const { getSessao } = authContext;
  const sessaolocal = getSessao();
  const [openCollapse, setOpenCollapse] = useState(false);
  const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });
  const opcaoDefault = [{ id: -1, value: -1, label: '<Selecione...>' }];
  const [enderecosPessoas, setEnderecosPessoas] = useState(opcaoDefault);
  const [recarregaTabela, setRecarregaTabela] = useState(false);
  const [codigoAtual, setCodigoAtual] = useState('');

  const objModeloPessoaContatos = {
    id: { type: 'text' },
    pessoa_id: { type: 'text' },
    principal: { type: 'checkbox' },
    nome: { type: 'text' },
    telefone: { type: 'text' },
    tipo_telefone: { type: 'autocomplete' },
    email: { type: 'text' },
    ativo: { type: 'text' },
  }

  const validaRotaPermissao = async (operacao = '', silencioso = false) => {
    if ((operacao !== 'inserir') &&
      (operacao !== 'editar') &&
      (operacao !== 'excluir') &&
      (operacao !== 'listar')) {
      MensagemErro('Escolha a operação que deseja validar a permissão!');
      return false;
    }

    //validando a permissao
    try {
      await api.get(`${rotafechada()}pessoa_contatos/permissao/${operacao}`);
      return true;
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;

      if (silencioso === false) {
        MensagemErro(`Erro: ${msg}`);
      }
      return false;
    }
  }

  const validaCampos = (data) => {

    let camposObrigatorios = ``;

    if (!data.nome) {
      camposObrigatorios = `${camposObrigatorios}/Nome`
    }

    if (!data.telefone) {
      camposObrigatorios = `${camposObrigatorios}/Telefone`
    }
    if (!data.tipo_telefone) {
      camposObrigatorios = `${camposObrigatorios}/TipoTelefone`
    }

    if (camposObrigatorios !== '') {
      throw new Error(`Campos obrigatórios: ${camposObrigatorios}`)
    }
  }

  const gravarRegistro = async (data) => {

    const dados = {};
    //no data esta todos os dados do RHF registrados, o que me interesse é 
    //apenas o objeto "pessoas"
    //assim resgato no data apenas os campos que se referem a pessoas
    //e monto o objeto que sera enviado para o back
    Object.keys(objModeloPessoaContatos).forEach((nome) => {
      dados[nome] = '';
      if (nome in data['contatos']) {
        if (typeof data['contatos'][nome] === 'object') {
          dados[nome] = data['contatos'][nome]?.value ? data['contatos'][nome]?.value : '';
        }
        else
          if (typeof data['contatos'][nome] === 'boolean') {
            dados[nome] = data['contatos'][nome] === true ? 'S' : 'N'
          }
          else dados[nome] = data['contatos'][nome];
      }
    })

    dados.empresa_id = sessaolocal.empresa_id || null;
    dados.pessoa_id = pessoaAtual
    dados.ativo = 'S'
    delete dados.id;

    try {
      validaCampos(dados);
    }
    catch (error) {
      MensagemErro(`${error.message}`);
      return;
    }
    try {
      let retorno;
      let codigoTemp = data.contatos.id;

      if ((data.contatos.id) && (parseInt(data.contatos.id, 10) > 0)) {
        retorno = await api.put(`${rotafechada()}pessoa_contatos/${data.contatos.id}`, dados);
      }
      else {
        retorno = await api.post(`${rotafechada()}pessoa_contatos`, dados);
        codigoTemp = retorno.data.data.id;
      }

      MensagemAviso(`Gravado com sucesso!`);
      setCodigoAtual('');
      setRecarregaTabela(!recarregaTabela);
    }
    catch (error) {
      console.log(error);
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const buscaenderecosPessoas = async () => {

    try {
      let url = `${rotafechada()}pessoas?limit=-1`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;
      let enderecosPessoasTemp = [];
      enderecosPessoasTemp.push({ ...opcaoDefault[0] });
      data.map((item) => {
        enderecosPessoasTemp.push({ id: item.id, value: item.id, label: item.nome })
      })
      setEnderecosPessoas(enderecosPessoasTemp);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const buscaOpcoes = async () => {
    await buscaenderecosPessoas();
  };

  const novoRegistro = () => {
    //limpo os campos
    Object.keys(objModeloPessoaContatos).forEach((nome) => {
      if (objModeloPessoaContatos[nome].type === 'autocomplete') {
        setValue(`contatos.${nome}`, opcaoDefault[0])
      }
      else
        if (objModeloPessoaContatos[nome].type === 'checkbox') {
          setValue(`contatos.${nome}`, false)
        }
        else
          if (objModeloPessoaContatos[nome].type === 'text') {
            setValue(`contatos.${nome}`, '')
          }
    })
  }

  const abrirRegistro = async (codigo) => {
    try {
      let url = `${rotafechada()}pessoa_contatos?id=${codigo}`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;

      if (!data.length > 0) {
        MensagemErro(`Componente ID ${codigo} não localizado`);
        return;
      }
      setValue('contatos.id', data[0].id);
      setValue('contatos.principal', data[0].principal === 'S' ? true : false);
      setValue(`contatos.nome`, data[0].nome,);
      setValue(`contatos.telefone`, data[0].telefone,);
      let t_telefone = data[0].tipo_telefone
      setValue(`contatos.tipo_telefone`, { value: t_telefone === 'C' ? 'C' : (t_telefone === 'F' ? 'F' : 'W'), label: t_telefone === 'C' ? 'Celular' : (t_telefone === 'F' ? 'Fixo' : 'WhatApp') });
      setValue(`contatos.email`, data[0].email,);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const excluirRegistro = async () => {

    setDialogoOpen({ ...dialogoOpen, visible: false });

    try {
      await api.delete(`${rotafechada()}pessoa_contatos/${dialogoOpen.id || 0}`);
      MensagemAviso('Excluído com sucesso!');
      novoRegistro();
      setRecarregaTabela(!recarregaTabela);

    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleClickExcluir = async (id) => {
    if (!(await validaRotaPermissao('excluir') === true)) {
      return;
    }
    setDialogoOpen({ ...dialogoOpen, visible: true, id });
  }

  useEffect(() => {
    buscaOpcoes();
    if (parseInt(codigoAtual, 10) > 0) {
      abrirRegistro(codigoAtual);
    }
    else novoRegistro();
  }, [pessoaAtual, codigoAtual, recarregaTabela, recarregaComponenteOpcoes])

  return (
    <MDBox p={2} bgColor={'#fff'} mt={2} borderRadius="lg" coloredShadow='secondary'>
      <DialogoConfirma
        open={dialogoOpen.visible}
        title={'Confirma Exclusão'}
        color={'error'}
        message={'Certeza que deseja excluir?'}
        handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
        handleConfirm={excluirRegistro}
      />
      <IconButton
        aria-label="expand row"
        size="small"
        onClick={() => setOpenCollapse(!openCollapse)}
      >
        {openCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        <Typography variant='h6' mb={2}>Contatos  </Typography>
      </IconButton>
      <Tooltip title="Você pode cadastrar mais de um contato para essa pessoa mas somente o contato marcado como principal
      aparecerá nos relatórios" placement="top">
        <InfoIcon color="info" sx={{ m: 0.5 }}></InfoIcon>
      </Tooltip>

      <Collapse in={openCollapse} timeout="auto" unmountOnExit>
        <form onSubmit={handleSubmit(gravarRegistro)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={2}>{/*id*/}
              <Controller
                name={`contatos.id`}
                control={props.control}
                defaultValue='Id'
                render={({ field: { ref, ...props } }) =>
                  <FormField
                    //autoComplete={false}
                    type='text'
                    label='Id'
                    rows={1}
                    multiline={false}
                    required={false}
                    disabled={true}
                    inputProps={{
                      disabled: true
                    }}
                    inputRef={ref}
                    {...props}
                  />}
              />
            </Grid>
            <Grid item xs={12} sm={8}>

            </Grid>
            <Grid item xs={12} sm={2}>{/*Principal*/}
              <MDBox width="100%" display="flex" alignItems='center' flexDirection='column'>
                <MDTypography fontWeight="bold" variant="caption">
                  {'Contato Principal'}
                </MDTypography>
                <Controller
                  name={`contatos.principal`}
                  control={control}
                  render={({ field: { value, ...otherprops } }) => (
                    <Checkbox
                      {...otherprops}
                      checked={value ? (value === true ? true : false) : false}
                      onChange={(e) => setValue(`contatos.principal`, e.target.checked)}
                    />
                  )}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={4}>{/*nome*/}
              <Controller
                name={`contatos.nome`}
                control={props.control}
                defaultValue='Nome'
                render={({ field: { ref, ...props } }) =>
                  <FormField

                    //autoComplete={false}
                    type='text'
                    label='Nome do contato'
                    rows={1}
                    multiline={false}
                    required={true}
                    disabled={false}
                    inputRef={ref}
                    {...props}

                  />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>{/*telefone*/}
              <Controller
                name={`contatos.telefone`}
                control={props.control}
                defaultValue='Telefone'
                render={({ field: { ref, ...props } }) =>
                  <FormField
                    //autoComplete={false}
                    type='text'
                    label='Telefone'
                    rows={1}
                    multiline={false}
                    required={true}
                    disabled={false}
                    inputRef={ref}
                    {...props}

                  />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>{/*tipo_telefone*/}
              <Controller
                name='contatos.tipo_telefone'
                control={control}
                defaultValue={{ value: -1 }}
                render={({ field: { onChange, ...props } }) => (
                  <AutoCompleteStyled
                    options={opcoesTipoTelefone}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    renderInput={(params) => <FormField required {...params} label='Tipo do contato' />}
                    onChange={(_, data) => {
                      return onChange(data);
                    }}
                    disabled={false}
                    {...props}

                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>{/*email*/}
              <Controller
                name={`contatos.email`}
                control={props.control}
                defaultValue='Email'
                render={({ field: { ref, ...props } }) =>
                  <FormField

                    //autoComplete={false}
                    type='text'
                    label='Email'
                    rows={1}
                    multiline={false}
                    required={false}
                    disabled={false}
                    inputRef={ref}
                    {...props}
                  />}
              />
            </Grid>
          </Grid>

          <MDBox width="100%" display="flex" justifyContent="flex-end" mt={2}>
            <MDButton variant="gradient" color="info"
             type="submit"
            // onClick={() => handleSubmit(gravarRegistro)()}
            >Gravar</MDButton>
            <MDButton variant="gradient" color="info" onClick={novoRegistro} sx={{ ml: 2 }}>Novo</MDButton>
          </MDBox>
          <MDBox width="100%" display="flex" mt={2}>
            <TabelaOpcoes
              pessoaAtual={pessoaAtual}
              abrirRegistro={abrirRegistro}
              excluirRegistro={handleClickExcluir}
              recarregaTabela={recarregaTabela}
              recarregaComponenteOpcoes={recarregaComponenteOpcoes}
              getValues={getValues}
            />
          </MDBox>
        </form>
      </Collapse>
    </MDBox>
  )
}

export default PessoaContatos;