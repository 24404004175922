
import React, { useContext, useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Box, Divider, Icon, Typography } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MessagesContext from "hooks/MessagesContext";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import AuthContext from "hooks/AuthContext";
import MenuModulos from "./modulos_sistema";
import { useParams } from "react-router-dom";
import PermissoesModulo from "./permissoesmodulo";

function Permissoes() {

  const params = useParams();
  const { id } = params;

  const messageContext = useContext(MessagesContext);
  const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;
  const authContext = useContext(AuthContext);
  const { getSessao } = authContext;

  const [nomeModuloCarregar, setNomeModuloCarregar] = useState(id);
  const [moduloCarregado, setModuloCarregado] = useState();
  const [permitido, setPermitido] = useState(false);
  const [permitidoVerReservado, setPermitidoVerReservado] = useState(false);

  const buscaModuloPeloNome = async () => {

    if (!nomeModuloCarregar) {
      return;
    }

    try {
      MostraTelaEspera(`Buscando Módulos`);
      const url = `${rotafechada()}modulos_sistema?nome=${nomeModuloCarregar}`;
      const retorno = await api.get(encodeURI(url));
      FechaTelaEspera();
      const { data } = retorno.data;

      if (data.length > 0) {
        setModuloCarregado(data[0].id);
      }
      setNomeModuloCarregar('');
    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`${msg}`);
    }
  }

  const validaPermissao = async () => {
    const baseUrl = `${rotafechada()}permissoes`;
    //validando a permissao
    try {
      await api.post(`${baseUrl}/autorizafront`);
      setPermitido(true);
    }
    catch (error) {
      setPermitido(false);
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const validaPermissaoReservados = async () => {
    const baseUrl = `${rotafechada()}permissoes`;
    //validando a permissao
    try {
      await api.post(`${baseUrl}/acessoreservado`);
      debugger;
      setPermitidoVerReservado(true);
    }
    catch (error) {
      debugger;
      setPermitidoVerReservado(false);
    }
  }

  useEffect(() => {
    buscaModuloPeloNome();
    validaPermissao();
    validaPermissaoReservados();
  }, [nomeModuloCarregar]);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />

        <MDBox mt={5} mb={9}>
          <Grid container justifyContent="center">
            <Grid item xs={12} lg={12}>
              <MDBox mt={6} mb={8} textAlign="center">
                <MDBox mb={1}>
                  <MDTypography variant="h3" fontWeight="bold">
                    {`Permissões`}
                  </MDTypography>
                </MDBox>
                <MDTypography variant="h5" fontWeight="regular" color="secondary">
                  { }
                </MDTypography>
              </MDBox>
            </Grid>

            

            <Grid item xs={12} lg={12}>
              <Card>
                <Grid container justifyContent="center" m={2}>
                  <Grid item xs={12} sm={3}>
                    <MenuModulos
                      moduloCarregado={moduloCarregado}
                      setModuloCarregado={setModuloCarregado}
                    />
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    {
                      permitido ?
                        <PermissoesModulo
                          moduloCarregado={moduloCarregado}
                          permitidoVerReservado={permitidoVerReservado}
                        />
                        :
                        <MDBox>
                          <Typography>
                            Sem permissão para alterar permissões
                          </Typography>
                        </MDBox>
                    }

                  </Grid>
                </Grid>
              </Card>
            </Grid>

          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default Permissoes;
