import { Box, FormControlLabel, Switch } from "@mui/material";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import AuthContext from "hooks/AuthContext";
import MessagesContext from "hooks/MessagesContext";
import { createRef, useContext, useEffect } from "react";
import { useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogoConfirma from "componentes/DialogoConfirma";

const TAMANHO_MAX_KB_IMAGEM = 150;

export default function ParametroImagemCabecalhoProposta(props) {

    const messageContext = useContext(MessagesContext);
    const { MensagemErro } = messageContext;
    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();

    const [personalizaImagem, setPersonalizaImagem] = useState(false);
    const [parametro_id, setParametro_id] = useState('');
    const [imagem, setImagem] = useState('');
    const [open, setOpen] = useState(false);
    const [dialogoOpen, setDialogoOpen] = useState({ open: false });

    let fileInput = createRef();

    const handleImageChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];

        //maximo em KB
        if (file?.size > (TAMANHO_MAX_KB_IMAGEM * 1024)) {
            MensagemErro(`Escolha uma imagem com tamanho menor que ${TAMANHO_MAX_KB_IMAGEM}kb`);
            return;
        }

        reader.onloadend = async () => {
            const ok = await enviarImagemServidor(file);
            if (ok) {
                //setImage(reader.result);
                e.target.value = null;
            }
            else {
                console.log('Erro ao tentar enviar');
                setImagem('');
            }
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleClickAlterar = () => {
        fileInput.current.click();
    };

    async function enviarImagemServidor(file) {

        let formData = new FormData();
        formData.append("file", file);
        let url = encodeURI(`${rotafechada()}vendas/parametroimagempersonalizadapedidocompacto/imagem`);
        try {

            await api.put(url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                //onUploadProgress,
            });

            //ja atualiza pegando a foto gravada no servidor
            buscaParametro();

            return true;
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro ao tentar enviar a imagem! ${msg}`);
        }
    };

    async function handleClickRemoverImagem() {
        setDialogoOpen({ ...dialogoOpen, open: false });

        let url = encodeURI(`${rotafechada()}vendas/parametroimagempersonalizadapedidocompacto/imagem`);
        try {

            await api.delete(url);
            //ja atualiza pegando a foto gravada no servidor
            buscaParametro();

            return true;
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro ao tentar excluir a imagem! ${msg}`);
        }
    };

    const handleClickExcluir = async () => {
        setDialogoOpen({ ...dialogoOpen, open: true });
    }

    const handleChangeSwitch = (e) => {
        alteraParametro(e.target.checked);
    }

    const alteraParametro = async (valor = '') => {

        try {
            await api.post(`${rotafechada()}parametro_empresas/altera`, {
                parametro_id,
                empresa_id: sessaolocal.empresa_id,
                valor: valor ? 'SIM' : 'NAO'
            });

            buscaParametro();

        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`${msg}`);
        }
    }

    const buscaParametro = async () => {

        try {
            debugger;
            const retorno = await api.get(encodeURI(`${rotafechada()}vendas/parametroimagempersonalizadapedidocompacto`));
            const { parametro_id: parametro_id_temp, nome_imagem, personaliza, imagem: imagem_temp } = retorno.data;
            setPersonalizaImagem(personaliza);
            setParametro_id(parametro_id_temp);
            if (imagem_temp !== '') {
                const src = `data:image/png[jpg];base64,${imagem_temp}`;
                setImagem(src);
            }
            else setImagem('');
            setOpen(true);
        }
        catch (error) {

            const msg = error.response?.data?.erro || error;
            MensagemErro(`${msg}`);
        }

    }

    useEffect(() => {
        buscaParametro();

    }, [])


    return (

        open &&
        <>

            <DialogoConfirma
                open={dialogoOpen.open}
                title={'Confirma Exclusão'}
                color={'error'}
                message={'Certeza que deseja retirar a imagem?'}
                handleCancel={() => setDialogoOpen({ ...dialogoOpen, open: false })}
                handleConfirm={handleClickRemoverImagem}
            />

            <FormControlLabel
                control={
                    <Switch
                        disabled={false}
                        checked={personalizaImagem}
                        onChange={(e) => handleChangeSwitch(e)}
                        color="default"
                    />
                }
                label='Mostrar Imagem Personalizada no Cabeçalho da Proposta'
            />
            {
                personalizaImagem ?
                    <>
                        <input type="file"
                            onChange={handleImageChange}
                            ref={fileInput}
                            style={{ display: 'none' }}
                            accept="image/png, image/gif, image/jpeg, image/bmp"
                        />
                        <div>
                            {
                                imagem !== '' &&
                                <img src={imagem} alt="Imagem do Cabeçalho do Projeto" style={{ maxHeight: 600, maxWidth: 800 }} />
                            }
                        </div>
                        <Box display={'flex'} >
                            <AddIcon onClick={() => handleClickAlterar()} color={'info'} fontSize='medium' />
                            {imagem !== '' &&
                                <DeleteIcon onClick={() => handleClickExcluir()} sx={{ ml: 2 }} color='error' fontSize='medium' />
                            }
                        </Box>
                    </>
                    : false
            }


        </>
    )


}