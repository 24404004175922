import { Autocomplete, Grid, Typography, Checkbox } from '@mui/material';
import FormField from 'componentes/FormField';
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import MDTypography from 'components/MDTypography';
import React, { useState, useEffect, useContext } from 'react';
import { Controller } from 'react-hook-form';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import MessagesContext from 'hooks/MessagesContext';
import AuthContext from 'hooks/AuthContext';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';


const Cabecalho = (props) => {
  const { control, opcoesAtivo, usuarioAtual, vetorPerfis, setVetorPerfis } = props;
  const authContext = useContext(AuthContext);
  const { getSessao } = authContext;
  const sessaolocal = getSessao();
  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MensagemAviso } = messageContext;

  const buscaPerfisAcessos = async (data) => {

    try {
      vetorPerfis.length = 0

      let url = `${rotafechada()}perfis_acessos?listaglobal=true&direction=asc&order=id`

      if (parseInt(usuarioAtual, 10) > 0) {
        //se tiver um usuario carregado entao peço que devolva a Usuario_Perfis
        url = `${url}&empresa_usuario_id=${usuarioAtual}`
      }

      const retorno = await api.get(encodeURI(url));
      const dados = retorno.data.data;

      let dadosTemp = [];
      dados?.map((item) => {

        let usuario_perfil_id = '';
        let selecionado = false;

        if (item.Usuario_Perfis?.length > 0) {
          usuario_perfil_id = item.Usuario_Perfis[0].id;
          selecionado = true;
        }

        dadosTemp.push(
          {
            usuario_perfil_id,
            selecionado,
            nome: item.nome,
            id: item.id
          })
      })

      setVetorPerfis(dadosTemp);
    }
    catch (error) {
      console.log(error);
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  useEffect(() => {
    buscaPerfisAcessos();
  }, [usuarioAtual]);


  const gravaClick = async (index, estado) => {

    const dadosTemp = [...vetorPerfis];
    dadosTemp[index].selecionado = estado;
    setVetorPerfis(dadosTemp);
  }

  return (
    <MDBox p={2}>

      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>{/*Dados de Usuários*/}
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>{/*id*/}
              <Controller
                name={'id'}
                control={props.control}
                defaultValue=''
                render={({ field: { ref, ...props } }) =>
                  <FormField

                    key={'id'}
                    id={'id'}
                    type='text'
                    label='ID'
                    rows={1}
                    multiline={false}
                    required={false}
                    disabled={true}
                    inputProps={{
                      disabled: true
                    }}
                    inputRef={ref}
                    {...props}
                  />}
              />
            </Grid>

            <Grid item xs={12} sm={6}>{/*Ativo*/}
              <Controller
                name='ativo'
                control={control}
                defaultValue={{ value: 'S' }}
                render={({ field: { onChange, ...props } }) => (
                  <AutoCompleteStyled
                    options={opcoesAtivo}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    renderInput={(params) => <FormField {...params} label='Ativo' />}
                    onChange={(_, data) => {
                      return onChange(data);
                    }}
                    disabled={false}
                    {...props}
                  />
                )}

              />
            </Grid>

            <Grid item xs={12} sm={12}>{/*nome*/}
              <Controller
                name={'nome'}
                defaultValue=''
                control={props.control}
                render={({ field: { ref, ...props } }) =>
                  <FormField

                    key={'nome'}
                    id={'nome'}
                    type='text'
                    label='Nome'
                    rows={1}
                    multiline={false}
                    required={true}
                    disabled={false}
                    inputRef={ref}
                    {...props}
                  />}
              />
            </Grid>

            <Grid item xs={12} sm={12}>{/*Email*/}
              <Controller
                name={'email'}
                defaultValue=''
                control={props.control}
                render={({ field: { ref, ...props } }) =>
                  <FormField

                    key={'email'}
                    id={'email'}
                    type='text'
                    label='Email'
                    rows={1}
                    multiline={false}
                    required={true}
                    disabled={false}
                    inputRef={ref}
                    {...props}
                  />}
              />
            </Grid>

            <Grid item xs={12} sm={12}>{/*Perfis*/}
              <Typography variant='h6' mb={2}>Perfis do usuário *</Typography>
              <MDBox width="100%" display="flex" justifyContent='space-between' alignItems='space-between' flexDirection='row'>
                {
                  vetorPerfis?.map((paramitem, index) => {
                    //Condição para não aparecer o perfil root.
                    return (
                      <div>
                        <MDTypography variant="caption">
                          <Checkbox 
                            checked={paramitem.selecionado ? paramitem.selecionado : false}
                            onChange={(e) => gravaClick(index, e.target.checked)}
                            />
                            {paramitem.nome}
                        </MDTypography>
                      </div>)
                  })
                }
              </MDBox>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MDBox>
  )
}

export default Cabecalho;