import { Box, Checkbox, Dialog,  Tooltip, Typography } from '@mui/material';
import defaultImage from "assets/images/products/choose-product.png";
import api from 'commons/api';
import { rotafechada } from 'commons/urlconf';
import DialogoConfirma from 'componentes/DialogoConfirma';
import MostraImagem from 'componentes/MostraImagem';
import MDInput from 'components/MDInput';
import MessagesContext from 'hooks/MessagesContext';
import React, { createRef, useContext, useState } from 'react';
import { Image as KonvaImage, Transformer } from 'react-konva';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import useImage from 'use-image';


const TAMANHO_MAX_KB_IMAGEM = 150;


const calculanovasdimensoes = (larguraoriginal,
    alturaoriginal,
    larguraanterior, alturaanterior,
    novalargura,
    novaaltura,
    aspectratio = false) => {

    debugger;

    let newwidth = novalargura;
    let newheight = novaaltura;

    if (aspectratio === false) {
        return { newwidth, newheight }
    }

    if (novalargura !== larguraanterior) {
        //alterou largura

        //largura 100
        //altura  X
        //x = (altura*100)/largura

        let proporcaoaltura = (alturaoriginal * 100) / larguraoriginal;
        newheight = Math.floor((novalargura * proporcaoaltura) / 100);
    }
    else
        if (novaaltura !== alturaanterior) {
            //alterou altura
            let proporcaolargura = (larguraoriginal * 100) / alturaoriginal;
            newwidth = Math.floor((novaaltura * proporcaolargura) / 100);
        }

    return ({ newwidth, newheight });
}

export const PropriedadesImagem = (props) => {

    const { itensTela, setItensTela, selectedId, open, setOpen } = props;

    const messageContext = useContext(MessagesContext);
    const { MensagemErro } = messageContext;
    const [openZoomImg, setOpenZoomImg] = useState(false);
    const [dialogoOpen, setDialogoOpen] = useState({ visible: false })
    const [file, setFile] = useState(null);

    //ao criar uma referencia posso usar o componente (metodos...)
    //usando essa variavel
    let fileInput = createRef();

    const handleChangeLargura = (e) => {
        const temp = itensTela.slice();

        const novalargura = parseInt(e.target.value, 10);
        const novaaltura = temp[selectedId].height;

        const { newwidth, newheight } = calculanovasdimensoes(temp[selectedId].width_original, temp[selectedId].height_original, temp[selectedId].width, temp[selectedId].height, novalargura, novaaltura, temp[selectedId].aspectratio);

        temp[selectedId].width = newwidth;
        temp[selectedId].height = newheight;
        setItensTela(temp);
    }

    const handleChangeAltura = (e) => {
        const temp = itensTela.slice();

        const novalargura = temp[selectedId].width;
        const novaaltura = parseInt(e.target.value, 10);

        const { newwidth, newheight } = calculanovasdimensoes(temp[selectedId].width_original, temp[selectedId].height_original, temp[selectedId].width, temp[selectedId].height, novalargura, novaaltura, temp[selectedId].aspectratio);

        temp[selectedId].width = newwidth;
        temp[selectedId].height = newheight;

        setItensTela(temp);
    }

    const handleChangeAspectRadio = (e) => {
        const temp = itensTela.slice();
        temp[selectedId].aspectratio = e.currentTarget.checked;

        if (e.currentTarget.checked){
            //alterou para manter a proporcao
            const {newwidth,newheight}=calculanovasdimensoes(
                temp[selectedId].width_original,
                temp[selectedId].height_original,
                temp[selectedId].width,
                temp[selectedId].height,
                temp[selectedId].width+1,//pra forcar o calculo
                temp[selectedId].height,
                true
            );
            temp[selectedId].width=newwidth;
            temp[selectedId].height=newheight;
        }

        setItensTela(temp);
    }

    const handleImageChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];

        //maximo em KB
        if (file?.size > (TAMANHO_MAX_KB_IMAGEM * 1024)) {
            MensagemErro(`Escolha uma imagem com tamanho menor que ${TAMANHO_MAX_KB_IMAGEM}kb`);
            return;
        }

        reader.onloadend = async () => {



            const ok = await enviarImagemServidor(file, reader.result);
            if (ok) {
                setFile(file);
                e.target.value = null;
            }
            else {
                console.log('Erro ao tentar enviar');
                setFile(null);
            }
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleClickAlterar = () => {
        fileInput.current.click();
    };

    function obterDimensoesBase64(base64) {
        return new Promise((resolve, reject) => {
            const img = new Image();

            img.onload = () => {
                // Quando a imagem estiver carregada, obtenha as dimensões
                const largura = img.naturalWidth;
                const altura = img.naturalHeight;
                resolve({ largura, altura });
            };

            // Em caso de erro ao carregar a imagem
            img.onerror = () => {
                reject(new Error('Erro ao carregar a imagem.'));
            };

            // Define o src da imagem como o base64
            img.src = base64;
        });
    }

    async function enviarImagemServidor(file, imagembase64) {

        let formData = new FormData();
        formData.append("file", file);
        let url = `${rotafechada()}relatorios_imagens/imagem/0`;
        try {

            const retorno = await api.put(url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                //onUploadProgress,
            });

            if (retorno?.data?.data?.nome_imagem) {
                const temp = itensTela.slice();
                temp[selectedId].nome_imagem = retorno?.data?.data?.nome_imagem;
                temp[selectedId].imagembase64 = imagembase64;
                debugger;

                const { altura: alturaoriginal, largura: larguraoriginal } = await obterDimensoesBase64(imagembase64);
                temp[selectedId].width = larguraoriginal;
                temp[selectedId].height = alturaoriginal;
                temp[selectedId].width_original = larguraoriginal;
                temp[selectedId].height_original = alturaoriginal;

                setItensTela(temp);
            } else {
                MensagemErro('Não retornado o nome da imagem do servidor')
            }

            //se de certo gravar no arquivo ele retorna true para a funcao mostrar a foto no componente
            return true;
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro ao tentar enviar a imagem! ${msg}`);
        }
    };

    async function handleClickRemoverImagem() {
        setDialogoOpen({ ...dialogoOpen, visible: false });
        try {
            const temp = itensTela.slice();
            temp[selectedId].imagembase64 = '';
            temp[selectedId].nome_imagem = '';

            setItensTela(temp);
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro ao tentar remover a imagem! ${msg}`);
        }
    };

    const handleClickExcluir = async () => {
        setDialogoOpen({ ...dialogoOpen, visible: true });
    }

    const handleClickAbrirImg = async () => {
        setOpenZoomImg(true);
    }

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            maxWidth={'md'}
            fullWidth
        >
            <Box m={2} >
                <MostraImagem
                    open={openZoomImg}
                    setOpen={setOpenZoomImg}
                    img={itensTela[selectedId]?.imagembase64}
                    alt='Imagem do projeto'
                />
                <DialogoConfirma
                    open={dialogoOpen.visible}
                    title={'Confirma Exclusão'}
                    color={'error'}
                    message={'Certeza que deseja retirar a imagem?'}
                    handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
                    handleConfirm={handleClickRemoverImagem}
                />
                <Box>
                    <Box display={'flex'} justifyContent={'center'}>
                        <input type="file"
                            onChange={handleImageChange}
                            ref={fileInput}
                            style={{ display: 'none' }}
                            accept="image/png, image/gif, image/jpeg, image/bmp"
                        />
                        <div>
                            {
                                itensTela[selectedId]?.imagembase64 !== '' ?
                                    <img src={itensTela[selectedId]?.imagembase64} alt="Imagem do Projeto" onClick={() => handleClickAbrirImg()} style={{ maxHeight: 200, maxWidth: 200, cursor: "zoom-in" }} />
                                    :
                                    <img src={defaultImage} alt="Imagem do Projeto" />
                            }
                        </div>
                    </Box>
                    <Box display={'flex'} justifyContent={'center'}>
                        <Tooltip title="Escolher uma nova imagem" placement="top">
                            <AddIcon onClick={() => handleClickAlterar()} color={'info'} fontSize='medium' />
                        </Tooltip>
                        {itensTela[selectedId]?.imagembase64 !== '' &&
                            <DeleteIcon onClick={() => handleClickExcluir()} sx={{ ml: 2 }} color='error' fontSize='medium' />
                        }
                        <ZoomInIcon onClick={() => handleClickAbrirImg()} sx={{ ml: 2 }} color={'info'} fontSize='medium' />
                    </Box>
                </Box>
                <Box>
                    <Typography variant='caption'>Manter aspecto original</Typography>
                    <Checkbox
                        checked={itensTela[selectedId].aspectratio}
                        onChange={handleChangeAspectRadio}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </Box>
                <Box mt={2} mb={2}>
                    <MDInput
                        label='Largura'
                        type='number'
                        value={itensTela[selectedId].width}
                        onChange={handleChangeLargura}
                        sx={{ mr: 2 }}
                    />
                    <MDInput
                        label='Altura'
                        type='number'
                        value={itensTela[selectedId].height}
                        onChange={handleChangeAltura}
                    />
                </Box>

            </Box>

            <Box m={2} >
            </Box>
        </Dialog>
    )

}

export const Imagem = ({ shapeProps, isSelected, onSelect, onChange }) => {
    const shapeRef = React.useRef();
    const trRef = React.useRef();

    React.useEffect(() => {
        if (isSelected) {
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    const [imageconvertida] = useImage(shapeProps.imagembase64);

    return (
        <React.Fragment>

            <KonvaImage
                onClick={onSelect}
                onTap={onSelect}
                ref={shapeRef}
                {...shapeProps}
                draggable
                image={imageconvertida}
                onDragEnd={(e) => {
                    onChange({
                        ...shapeProps,
                        x: parseInt(e.target.x(), 10),
                        y: parseInt(e.target.y(), 10),
                    });
                }}
                onTransformEnd={(e) => {

                    const node = shapeRef.current;
                    debugger;

                    let scaleX = node.scaleX();
                    let scaleY = node.scaleY();
                    
                    //quando altera uma das dimensoes a outra sofre uma pequena
                    //alteracao e fica com escala 0.99999
                    if ((scaleX<1)&&(scaleX>0.9)){
                        scaleX=1;
                    }
                    if ((scaleX<1)&&(scaleX>0.9)){
                        scaleX=1;
                    }

                    node.scaleX(1);
                    node.scaleY(1);

                    let novalargura = parseInt(Math.max(5, node.width() * scaleX), 10);
                    let novaaltura = parseInt(Math.max(node.height() * scaleY), 10);

                    const larguraoriginal = shapeProps.width_original;
                    const alturaoriginal = shapeProps.height_original;

                    let novasdimensoes = calculanovasdimensoes(larguraoriginal, alturaoriginal,
                        shapeProps.width, shapeProps.height,
                        novalargura, novaaltura,
                        shapeProps.aspectratio);

                    onChange({
                        ...shapeProps,
                        x: parseInt(node.x(), 10),
                        y: parseInt(node.y(), 10),
                        width: novasdimensoes.newwidth || larguraoriginal,
                        height: novasdimensoes.newheight || alturaoriginal
                    });
                }}
            />

            {isSelected && (
                <Transformer
                    ref={trRef}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                    rotateEnabled={false} // Desativar a rotação
                />
            )}
        </React.Fragment>
    );
}

