import { Autocomplete, Grid, Typography , Button} from '@mui/material';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';
import FormField from 'componentes/FormField';
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import React, { useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { ptBR } from "date-fns/locale";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Anexos } from 'componentes/Anexos';


const Cabecalho = (props) => {
  const { control, opcoesFisica_juridica, opcoesProdutor_rural, opcoesAtivo, getValues, setValues } = props;

  const [openAnexos, setOpenAnexos] = useState(false);
  const [dadosAnexo, setDadosAnexo] = useState({
    conta_id: '',
    parcela_id: '',
    venda_id: '',
    pessoa_id: '',
    title: 'Anexos'
  })

  const getId = useWatch({
    control,
    name: "id",
  });

  const handleClickOpenAnexoVenda = (pessoa_id) => {
    setDadosAnexo({
      pessoa_id,
      title: `Anexos da Pessoa ${pessoa_id}`
    })
    setOpenAnexos(true);
  }

  return (
    <>
      <Anexos
        pessoa_id={dadosAnexo.pessoa_id || ''}
        title={dadosAnexo.title || 'Anexos'}
        open={openAnexos}
        setOpen={setOpenAnexos}
      />
      <MDBox p={2}>
        <MDBox mb={3}>
          <Typography variant='h6' >Dados de Pessoas: {`${getValues('id') || ''}-${getValues('fantasia') || ''}`}</Typography>
        </MDBox>

        <Grid container spacing={1}>

          <Grid item xs={12} sm={12}>{/*dados de Pessoas*/}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>{/*id*/}
                <Controller
                  name={'id'}
                  control={props.control}
                  defaultValue=''
                  render={({ field: { ref, ...props } }) =>
                    <FormField

                      key={'id'}
                      id={'id'}
                      autoComplete={false}
                      type='text'
                      label='ID'
                      rows={1}
                      multiline={false}
                      required={false}
                      disabled={true}
                      inputProps={{
                        disabled: true
                      }}
                      inputRef={ref}
                      {...props}
                    />}
                />
              </Grid>
              <Grid item xs={12} sm={2}>{/*Fisica ou Juridica*/}
                <Controller
                  name='fisica_juridica'
                  control={control}
                  defaultValue={{ value: 'F' }}
                  render={({ field: { onChange, ...props } }) => (
                    <AutoCompleteStyled
                      options={opcoesFisica_juridica}
                      getOptionLabel={(option) => option.label || ''}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      renderInput={(params) => <FormField {...params} label='Fisica ou Juridica' />}
                      onChange={(_, data) => {
                        return onChange(data);
                      }}
                      disabled={false}
                      {...props}
                    />
                  )}

                />
              </Grid>
              <Grid item xs={12} sm={2}>{/*Produtor Rural*/}
                <Controller
                  name='produtor_rural'
                  control={control}
                  defaultValue={{ value: 'N' }}
                  render={({ field: { onChange, ...props } }) => (
                    <AutoCompleteStyled
                      options={opcoesProdutor_rural}
                      getOptionLabel={(option) => option.label || ''}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      renderInput={(params) => <FormField {...params} v label='Produtor Rural' />}
                      onChange={(_, data) => {
                        return onChange(data);
                      }}
                      disabled={false}
                      {...props}
                    />
                  )}

                />
              </Grid>
              <Grid item xs={12} sm={2}>{/*Ativo*/}
                <Controller
                  name='ativo'
                  control={control}
                  defaultValue={{ value: 'S' }}
                  render={({ field: { onChange, ...props } }) => (
                    <AutoCompleteStyled
                      options={opcoesAtivo}
                      getOptionLabel={(option) => option.label || ''}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      renderInput={(params) => <FormField {...params} label='Ativo' />}
                      onChange={(_, data) => {
                        return onChange(data);
                      }}
                      disabled={false}
                      {...props}
                    />
                  )}

                />
              </Grid>
              <Grid item xs={12} sm={3} p={0}>{/*anexos*/}
                {
                  parseInt(getId, 10) > 0 &&
                  <Button
                    onClick={() => handleClickOpenAnexoVenda(getId)}
                    variant="" // Estilo com borda
                    color="primary" // Cor do botão
                    startIcon={<AttachFileIcon color='info' />} // Ícone no início do botão
                    sx={{
                      borderRadius: '8px', // Borda levemente arredondada
                      padding: '8px 16px', // Espaçamento interno
                      textTransform: 'none', // Remove letras maiúsculas automáticas
                      fontWeight: 'bold', // Texto em negrito
                      fontSize: '14px', // Tamanho da fonte
                      '&:hover': {
                        //backgroundColor: '', // Cor de fundo ao passar o mouse
                        borderColor: 'primary.main', // Cor da borda no hover
                      },
                    }}
                  >
                    Anexos
                  </Button>
                }


              </Grid>
              <Grid item xs={12} sm={12}>{/*Razão Social*/}
                <Controller
                  name={'razao_social'}
                  defaultValue=''
                  control={props.control}
                  render={({ field: { ref, ...props } }) =>
                    <FormField

                      key={'razao_social'}
                      id={'razao_social'}
                      autoComplete={false}
                      type='text'
                      label={`${getValues('fisica_juridica.value')}` == 'J' ? 'Razão Social' : 'Nome'}
                      rows={1}
                      multiline={false}
                      required={true}
                      disabled={false}
                      inputRef={ref}
                      {...props}
                    />}
                />
              </Grid>
              <Grid item xs={12} sm={12}>{/*Fantasia*/}
                <Controller
                  name={'fantasia'}
                  defaultValue=''
                  control={props.control}
                  render={({ field: { ref, ...props } }) =>
                    <FormField

                      key={'fantasia'}
                      id={'fantasia'}
                      autoComplete={false}
                      type='text'
                      label='Fantasia'
                      rows={1}
                      multiline={false}
                      disabled={false}
                      inputRef={ref}
                      {...props}
                    />}
                />
              </Grid>
              <Grid item xs={12} sm={3}>{/*Data_nascimento*/}
                <Controller
                  name={'data_nascimento'}
                  defaultValue={null}
                  control={props.control}
                  render={({ field: { onChange, value } }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                      <DesktopDatePicker
                        label={`${getValues('fisica_juridica.value')}` == 'J' ? 'Data de abertura' : 'Data de Nascimento'}
                        inputFormat="dd/MM/yyyy"
                        value={value}
                        onChange={onChange}
                        renderInput={(params) => <FormField {...params} />}
                        disabled={false}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={3}>{/*CPF/CNPJ*/}
                <Controller
                  name={'cpf_cnpj'}
                  defaultValue=''
                  control={props.control}
                  render={({ field: { ref, ...props } }) =>
                    <FormField

                      key={'cpf_cnpj'}
                      id={'cpf_cnpj'}
                      autoComplete={false}
                      type='text'
                      label={`${getValues('fisica_juridica.value')}` == 'J' ? 'CNPJ' : 'CPF'}
                      rows={1}
                      multiline={false}
                      disabled={false}
                      inputRef={ref}
                      {...props}
                    />}
                />
              </Grid>
              <Grid item xs={12} sm={3}>{/*RG/IE*/}
                <Controller
                  name={'rg_ie'}
                  defaultValue=''
                  control={props.control}
                  render={({ field: { ref, ...props } }) =>
                    <FormField

                      key={'rg_ie'}
                      id={'rg_ie'}
                      autoComplete={false}
                      type='text'
                      label='RG ou Inscrição Estadual'
                      rows={1}
                      multiline={false}
                      required={false}
                      disabled={false}
                      inputRef={ref}
                      {...props}
                    />}
                />
              </Grid>
              <Grid item xs={12} sm={3}>{/*Inscrição Municipal*/}
                <Controller
                  name={'inscricao_municipal'}
                  defaultValue=''
                  control={props.control}
                  render={({ field: { ref, ...props } }) =>
                    <FormField

                      key={'inscricao_municipal'}
                      id={'inscricao_municipal'}
                      autoComplete={false}
                      type='text'
                      label='Inscrição Municipal'
                      rows={1}
                      multiline={false}
                      required={false}
                      disabled={false}
                      inputRef={ref}
                      {...props}
                    />}
                />
              </Grid>
              <Grid item xs={12} sm={12}>{/*Inscrição Municipal*/}
                <Controller
                  name={'observacao'}
                  defaultValue=''
                  control={props.control}
                  render={({ field: { ref, ...props } }) =>
                    <FormField

                      key={'observacao'}
                      id={'observacao'}
                      autoComplete={false}
                      type='text'
                      label='Observações'
                      rows={3}
                      multiline={true}
                      required={false}
                      disabled={false}
                      inputRef={ref}
                      {...props}
                    />}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
    </>
  )
}

export default Cabecalho;