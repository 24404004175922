import React, { useContext, useEffect, useState } from "react";

// @mui material components
import { Autocomplete, Collapse, Grid, Typography } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

//My componentes e functions
import MessagesContext from "hooks/MessagesContext";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import MDInput from "components/MDInput";
import FormField from "componentes/FormField";
import TabelaUsuarios from "./TabelaUsuarios";
import { Box } from "@mui/system";
import { converteDataSemUTC, formatDate } from "commons/utils";
import { AutoCompleteStyled } from "componentes/AutoCompleteStyled";


function Tabela(props) {

  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;
  const { handleClickEmail, abrirRegistro, excluirRegistro, recarregaTabela, openFiltrosAvancados, empresaAtual } = props;

  const [countAll, setCountAll] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('id');
  const [colunasTable, setColunasTable] = React.useState([]);
  const [linhas, setLinhas] = React.useState([]);


  // *****States relacionado do filtro de pesquisa ****
  const [campoPesquisa, setCampoPesquisa] = React.useState({ id: 'nome', label: 'Nome', buscaParcial: true });
  const [valorPesquisa, setValorPesquisa] = React.useState('');
  const [camposFiltro] = useState([
    { id: 'nome', label: 'Nome', buscaParcial: true },
    { id: 'id', label: 'Id' },
  ]);
  // ****************************

  const baseUrl = `${rotafechada()}empresa_usuarios`;
  const baseCaption = 'Usuários';

  const buscaDados = async () => {
    if (props.validaRotaPermissao) {
      if (await props.validaRotaPermissao('listar') === false) {
        return;
      }
    }

    try {

      let complementoUrl = '';

      if ((campoPesquisa.id) && (valorPesquisa)) {
        complementoUrl = `&${campoPesquisa.id}=${valorPesquisa}`;

        if ((valorPesquisa) && (valorPesquisa.length > 0) && (valorPesquisa[0] !== '%') && (valorPesquisa[valorPesquisa.length - 1] !== '%')
          && (camposFiltro) && (camposFiltro.length > 0)) {

          //se o texto a ser buscado nao terminar ou começar com %
          //e tiver uma configuracao no camposFiltro
          //entao localizo que o campo usado nesse momento é um campo que esteja configurado
          //o buscaParcial, se sim acrescento automaticamente o % no inicio e fim


          let encontrouCampo = false;
          for (let i = 0; i < camposFiltro.length; i++) {

            if ((camposFiltro[i].buscaParcial === true) && (camposFiltro[i].id === campoPesquisa.id)) {
              encontrouCampo = true;
            }
          }

          if (encontrouCampo === true) {
            complementoUrl = `&${campoPesquisa.id}=%${valorPesquisa}%`;
          }
        }
      }

      if (page > 0) {
        complementoUrl = `${complementoUrl}&offset=${page * rowsPerPage}`;
      }

      if (orderBy) {
        complementoUrl = `${complementoUrl}&order=${orderBy}`;
        if (order) {
          complementoUrl = `${complementoUrl}&direction=${order}`;
        }
      }

      MostraTelaEspera(`Buscando ${baseCaption}`);

      const url = `${baseUrl}?limit=${rowsPerPage}${complementoUrl}&empresa_id=${empresaAtual}`;
      const retorno = await api.get(encodeURI(url));
      const { data, countAll: countAllretorno } = retorno.data;
      //console.log("RETORNO EMPRESA_USUARIOS", retorno.data)

      const colunasTabletemp = [
        {
          id: 'id',
          numeric: false,
          disablePadding: true,
          label: 'Id'
        },
        {
          id: 'nome',
          numeric: false,
          disablePadding: true,
          label: 'Nome'
        },
        {
          id: 'email',
          numeric: false,
          disablePadding: true,
          label: 'Email'
        },
        {
          id: 'ativo',
          numeric: false,
          disablePadding: true,
          label: 'Ativo'
        },
        {
          id: 'reservado',
          numeric: false,
          disablePadding: true,
          label: 'Reservado'
        },
        {
          id: 'createdAt',
          numeric: false,
          disablePadding: true,
          label: 'Criado'
        },
        {
          id: 'botoes',
          numeric: false,
          disablePadding: true,
          label: 'Ações',
          disableOrder: true
        },
      ]

      data.map((item, index) => {
        const dataSemUtc=converteDataSemUTC(item.createdAt);
        let datatemp = `${dataSemUtc}`.substring(0, 10).split('-');
        datatemp = datatemp[1] + '/' + datatemp[2]+'/'+datatemp[0];
        item.createdAt = formatDate(new Date(datatemp), 'DD/MM/YYYY');
        return item;
      })

      setColunasTable([...colunasTabletemp]);
      setLinhas(data);
      setCountAll(countAllretorno);
      FechaTelaEspera();
    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  useEffect(() => {
    buscaDados();
  }, [recarregaTabela, page, rowsPerPage, order, orderBy])

  const handleClickPesquisar = () => {
    setPage(0);
    buscaDados();
  }

  const desenhaFiltro = () => {
    return (
      <Collapse in={openFiltrosAvancados} timeout="auto" unmountOnExit>
        <Box sx={{ margin: 1 }}>
          < Grid container spacing={2} mb={2} justifyContent="center" >
            <Grid item xs={12} sm={3} >
              <AutoCompleteStyled
                options={camposFiltro}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderInput={(params) => <FormField {...params} label="Campo" />}
                value={campoPesquisa}
                onChange={(event, newValue) => {
                  setCampoPesquisa(newValue);
                }}
                defaultValue={null}
              />
            </Grid>
            <Grid item xs={12} sm={7}  >
              <FormField type="text" label="Valor a ser Pesquisado" value={valorPesquisa} onChange={(e) => setValorPesquisa(e.target.value)}

                inputProps={
                  {
                    onKeyPress: (e) => {

                      if (e.key === 'Enter') {
                        handleClickPesquisar();
                      }
                    },
                  }
                }
              />
            </Grid>
            <Grid item xs={12} sm={2} >
              <MDButton onClick={handleClickPesquisar} color='info'> Pesquisar</MDButton>
            </Grid>
          </Grid >
        </Box>
      </Collapse>
    )
  }

  return (
    <>

      <MDBox p={3} lineHeight={1}>
        <MDTypography variant="h5" fontWeight="medium">
          {desenhaFiltro()}
        </MDTypography>
      </MDBox>

      <TabelaUsuarios
        colunas={colunasTable}
        linhas={linhas}
        countAll={countAll}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        handleClickEmail={handleClickEmail}
        abrirRegistro={abrirRegistro}
        excluirRegistro={excluirRegistro}
        recarregaTabela={recarregaTabela}

      />
    </>


  );
}

export default Tabela;
