import { Icon } from '@mui/material';
import api from 'commons/api';
import { rotafechada } from 'commons/urlconf';
import { formataValor } from 'commons/utils';
import MDButton from 'components/MDButton';
import MessagesContext from 'hooks/MessagesContext';
import React, { useContext, useEffect, useState } from 'react';
import TabelaLocal from './TabelaLocal';


const TabelaVendaProjetos = (props) => {

    const { vendaAtual, recarregaTabela, abrirRegistro, excluirRegistro, openAlteraValorProjeto, setOpenAlteraValorProjeto } = props;


    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;

    const [countAll, setCountAll] = React.useState(0);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');
    const [colunasTable, setColunasTable] = React.useState([]);
    const [linhas, setLinhas] = React.useState([]);

    const carregaTabela = async () => {
        MostraTelaEspera();
        try {

            let complementoUrl = '';

            if (page > 0) {
                complementoUrl = `${complementoUrl}&offset=${page * rowsPerPage}`;
            }

            if (orderBy) {
                complementoUrl = `${complementoUrl}&order=${orderBy}`;
                if (order) {
                    complementoUrl = `${complementoUrl}&direction=${order}`;
                }
            }
            const url = `${rotafechada()}venda_projetos?limit=${rowsPerPage}&venda_id=${vendaAtual}${complementoUrl}&retornaimagem=S`;
            const retorno = await api.get(encodeURI(url));
            const { data, countAll: countAllretorno } = retorno.data;
            const dadostemp = [];
            const colunasTabletemp = [
                {
                    id: 'imagem',
                    numeric: false,
                    disablePadding: true,
                    label: 'Imagem',
                    disableOrder: true
                },
                {
                    id: 'projeto_id',
                    numeric: false,
                    disablePadding: true,
                    label: 'Projeto'
                },
                {
                    id: 'nomeprojeto',
                    numeric: false,
                    disablePadding: true,
                    label: 'Nome Projeto',
                    disableOrder: true
                },
                {
                    id: 'ambiente_instalacao',
                    numeric: false,
                    disablePadding: true,
                    label: 'Ambiente'
                },
                {
                    id: 'valor_final',
                    numeric: false,
                    disablePadding: true,
                    label: 'Valor'
                },
                {
                    id: 'acoes',
                    numeric: false,
                    disablePadding: true,
                    label: 'Ações',
                    disableOrder: true
                },
                {
                    id: 'id',
                    numeric: false,
                    disablePadding: true,
                    label: 'Id'
                },
            ]
           
            data.forEach((item) => {

                dadostemp.push({
                    imagem:item.imagem,
                    id: `${item.id}${item.venda_projeto_id_pai>0?'-P'+item.venda_projeto_id_pai:''}`,
                    projeto_id: item.projeto_id,
                    nomeprojeto: item.Projeto?.nome || '',
                    ambiente_instalacao: item.ambiente_instalacao,
                    valor_final:formataValor(item.valor_final),
                    acoes: <>
                        <MDButton size="medium" onClick={() => abrirRegistro(item.id)} iconOnly >
                            <Icon fontSize="medium" color="success">edit_icon</Icon>
                        </MDButton>
                        <MDButton size="medium" onClick={() => excluirRegistro(item.id)} iconOnly >
                            <Icon fontSize="medium" color="error">delete_forever</Icon>
                        </MDButton>
                    </>
                })
            })

            setColunasTable([...colunasTabletemp]);
            setLinhas([...dadostemp]);
            setCountAll(countAllretorno);
            FechaTelaEspera();
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    useEffect(() => {
        carregaTabela();
    }, [recarregaTabela, page, rowsPerPage, order, orderBy])


    return (
        <TabelaLocal
            colunas={colunasTable}
            linhas={linhas}
            countAll={countAll}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            openAlteraValorProjeto={openAlteraValorProjeto} 
            setOpenAlteraValorProjeto={setOpenAlteraValorProjeto}
        />
    )
}


export default TabelaVendaProjetos;