// @mui material components
import { Autocomplete } from '@mui/material';
import { styled } from "@mui/material/styles";
 
export const AutoCompleteStyled = styled(Autocomplete)(({ theme, ownerState }) => {
  const newStyled = () => ({
        "& .MuiOutlinedInput-root": {
        padding: '4px'
    },
  });
 
  return {
    ...(newStyled())
  };
});