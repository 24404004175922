import { Card, Collapse, IconButton, Typography } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MDBox from "components/MDBox";
import { useState } from "react";
import FormaPagamento from "./Cadastro";



export default function FormaPagamentoAdmin(props) {

    const { openCollapseFormaPagamento, handleClickCollapseFormaPagamento } = props;
    const [recarregaTabela, setRecarregaTabela] = useState('');
    const [formaPagamentoAtual, setformaPagamentoAtual] = useState('');

    return (
        <Card id="cadastro-forma-pagamento" sx={{ overflow: "visible" }}>
            <MDBox p={2} bgColor='grey-100' mt={2}>
                <IconButton aria-label="expand row" size="small" onClick={() => handleClickCollapseFormaPagamento(!openCollapseFormaPagamento)}>
                    {openCollapseFormaPagamento ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    <Typography variant='h6' mb={2}>Formas de Pagamento</Typography>
                </IconButton>

                <Collapse in={openCollapseFormaPagamento} timeout="auto" unmountOnExit>
                    <FormaPagamento
                        recarregaTabela={recarregaTabela}
                        setRecarregaTabela={setRecarregaTabela}
                        formaPagamentoAtual={formaPagamentoAtual}
                        setformaPagamentoAtual={setformaPagamentoAtual}
                    />
                </Collapse>
            </MDBox>
        </Card>
    )
}