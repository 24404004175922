/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useContext, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import LoginLayout from "./LoginLayout";
import bgImage from "assets/images/personalizados/headerlogin4.png";

import { rotaaberta } from '../../commons/urlconf';
import api from '../../commons/api';
import AuthContext from "../../hooks/AuthContext";
import MessageContext from "../../hooks/MessagesContext";
import { Autocomplete, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import { useMaterialUIController, carregaTemplate } from "context";
import { adicionaMascaraCPFCNPJ } from "commons/utils";
import { Image, Visibility, VisibilityOff } from "@mui/icons-material";
import { AutoCompleteStyled } from "componentes/AutoCompleteStyled";
import FormField from "componentes/FormField";

function Login() {


  const [controller, dispatch] = useMaterialUIController();

  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);
  const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;

  const [rememberMe, setRememberMe] = useState(true);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [empresaSelecionada, setEmpresaSelecionada] = useState({});
  const [empresas, setEmpresas] = useState([]);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };



  const handleClickEntrar = async (e) => {
    e.preventDefault();


    if ((email.trim() === '') || ((senha.trim() === ''))) {
      MensagemErro('Prencha todos os campos!');
      return;
    }

    try {
      const dadosempresaid = {}

      if (empresaSelecionada?.empresa_id) {
        if (parseInt(empresaSelecionada.empresa_id, 10) > 0)
          dadosempresaid.empresa_id = empresaSelecionada.empresa_id;
      }

      MostraTelaEspera('Efetuando Login');

      let retorno = await api.post(`${rotaaberta()}login`,
        {
          email: email,
          senha: senha,
          ...dadosempresaid
        });
      FechaTelaEspera();

      const { data } = retorno;
      // console.log("Contexto navegador",data);
      
      //se nao veio o token pode ser que o usuario tenha mais de uma empresa
      if (!data.token) {
        if (data.empresas?.length > 1) {
          const dadosEmpresas = [];
          
          data.empresas.map((item, index) => {
            dadosEmpresas.push({
              empresa_id: item.Empresa.id,
              label: `${item.Empresa.fantasia} (${adicionaMascaraCPFCNPJ(item.Empresa.cnpj)} - ${item.Empresa.endereco_cidade}/${item.Empresa.endereco_estado})`,
              ativo: item.Empresa.ativo,
            })
          });
          
          // console.log("dados Empresas:",dadosEmpresas);
          setEmpresaSelecionada(dadosEmpresas[0]);
          setEmpresas(dadosEmpresas);
          MensagemAviso('Selecione a empresa que deseja acessar');
          return;

        }
        MensagemErro('Erro no login');
        return;
      }

      //refaz os cabecalhos da api(axios)
      api.defaults.headers.common['authorization'] = `bearer ${data.token || ''}`;
      api.defaults.withCredentials = true;

      MensagemAviso(`Seja bem Vindo(a)!`);

      const nivel_acesso = 0;

      authContext.setSessao(
        data.token,
        data.refreshToken,
        data.empresa_usuario_id,
        data.empresa_id,
        data.configuracoes,
        data.obj_perfil?.nome,
        data.obj_perfil?.fantasia,
        data.obj_perfil?.imagemPerfil,
        data.obj_perfil?.imagemEmpresa,
        nivel_acesso
      );

      //recarrega o tema visual no context
      if (data.configuracoes?.template) {
        carregaTemplate(dispatch, data.configuracoes?.template)
      }
    }
    catch (error) {
      FechaTelaEspera();
      const msgerro = error.response?.data?.msg || error;
      MensagemErro(`Erro no Login: ${msgerro}`);
      return;
    }
  }


  return (
    <LoginLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Acesse
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Entre com seu email e senha para acessar
          </MDTypography>
        </MDBox>

        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                disabled={empresas?.length > 0}
                id="email"
                type="email"
                label="Email"
                variant="outlined"
                fullWidth
                placeholder="seuemail@email.com"
                autoComplete="username"
                InputLabelProps={{ shrink: true }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </MDBox>
            {/* <MDBox mb={2}>
              <MDInput
                type="password"
                label="Senha"
                variant="outlined"
                fullWidth
                placeholder="************"
                autoComplete="current-password"
                InputLabelProps={{ shrink: true }}
                value={senha}
                onChange={(e) => setSenha(e.target.value)}
                inputProps={{
                  onKeyPress: (e) => {

                    if (e.key === 'Enter') {
                      handleClickEntrar(e);
                    }
                  },
                }}
              />
            </MDBox> */}
            <MDBox mb={2}>{/*senha com visibility */}
              <FormControl sx={{ width: '100%' }} variant="outlined">
                <InputLabel shrink={true} htmlFor="outlined-adornment-password" >Senha</InputLabel>
                <OutlinedInput
                  disabled={empresas?.length > 0}
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  label="Senha"
                  placeholder="*************"
                  value={senha}
                  onChange={(e) => setSenha(e.target.value)}
                  inputProps={{
                    onKeyPress: (e) => {

                      if (e.key === 'Enter') {
                        handleClickEntrar(e);
                      }
                    },
                    disabled: empresas?.length > 0
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>


            </MDBox>

            {
              empresas?.length > 0 ?
                <MDBox mb={2}>
                  <AutoCompleteStyled
                    options={empresas}
                    getOptionDisabled={(option) => option.ativo === 'N'}
                    getOptionLabel={(option) => option.label}
                    value={empresaSelecionada}
                    onChange={(event, newValue) => {
                      setEmpresaSelecionada(newValue);
                    }}
                    renderInput={(params) => <FormField {...params} label="Empresa" />}
                  />
                </MDBox>
                : false
            }

            <MDBox display="flex" alignItems="center" ml={-1}>
              {/* <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Lembrar de mim
              </MDTypography> */}
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={handleClickEntrar}>
                Entrar
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Esqueceu sua senha?{"  "}
                <MDTypography
                  component={Link}
                  to="/recuperasenha"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Clique aqui
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </LoginLayout>
  );
}

export default Login;
