import { Autocomplete, Box, Checkbox, Dialog, DialogTitle, FormControl, Icon, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';
import React, { Component, useState } from 'react';
import { Stage, Layer, Rect, Text, Shape, Circle, Line, Image, Transformer } from 'react-konva';
import { CompactPicker } from 'react-color';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDBox from 'components/MDBox';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';

export const PropriedadesMemo = (props) => {

    const { itensTela, setItensTela, selectedId, open, setOpen, opcoesTexto } = props;
    const [corAtual, setCorAtual] = useState('#FFF');
    const opcaoFontes = ['helvetica', 'courier', 'times'];


    const handleChangeColor = (color, event) => {
        const temp = itensTela.slice();
        temp[selectedId].texto.fill = color.hex;
        setCorAtual(color.hex);
        setItensTela(temp);
    }

    const handleChangeFontStyle = (estilo) => {

        const temp = itensTela.slice();

        let estiloatual = temp[selectedId].texto.fontStyle ? temp[selectedId].texto.fontStyle : '';
        const regex = new RegExp(estilo);

        if (regex.test(estiloatual)) {
            estiloatual = estiloatual.replace(estilo, '');
        } else {
            estiloatual = `${estiloatual} ${estilo}`
        }
        temp[selectedId].texto.fontStyle = estiloatual;

        setItensTela(temp);
    }

    const handleChangeX = (e) => {
        const temp = itensTela.slice();
        temp[selectedId].x = parseInt(e.target.value, 10) || 1;
        setItensTela(temp);
    }

    const handleChangeY = (e) => {
        const temp = itensTela.slice();
        temp[selectedId].y = parseInt(e.target.value, 10) || 1;
        setItensTela(temp);
    }

    const handleChangeTipoTexto = (newValue) => {
        // console.log(newValue);
        const temp = itensTela.slice();
        temp[selectedId].texto.tipo = newValue;
        if (newValue !== 'textosimples') {
            temp[selectedId].texto.text = newValue?.label;
        }
        setItensTela(temp);
    }

    const handleChangeTextoSimples = (e) => {
        const temp = itensTela.slice();
        temp[selectedId].texto.text = e.target.value;
        setItensTela(temp);
    }

    const handleChangeFontSize = (e) => {
        const temp = itensTela.slice();
        temp[selectedId].texto.fontSize = parseInt(e.target.value, 10) || 24;
        setItensTela(temp);
    }

    const handleChangeRotation = (e) => {
        const temp = itensTela.slice();
        temp[selectedId].texto.rotation = parseInt(e.target.value, 10) || 0;
        setItensTela(temp);
    }

    const retornaCorBotaoStyle = (estilo) => {

        const estiloatual = itensTela[selectedId].texto?.fontStyle ? itensTela[selectedId].texto?.fontStyle : ''

        if (estiloatual.includes(estilo)) {
            return {}
        }
        else {
            return { color: (theme) => theme.palette.grey[500], }
        }
    }

    const handleChangeAlign = (align) => {

        const temp = itensTela.slice();

        temp[selectedId].texto.align = align;

        setItensTela(temp);
    }

    const retornaAlignStyle = (align) => {

        if (itensTela[selectedId].texto?.align === align) {
            return {};
        }
        else {
            return { color: (theme) => theme.palette.grey[500], }
        }
    }

    const setFonteSelecionada = (opcao) => {
        debugger;
        const temp = itensTela.slice();
        temp[selectedId].texto.font = opcao;
        setItensTela(temp);
    }

    const handleChangeLength = (e) => {
        const temp = itensTela.slice();
        temp[selectedId].texto.length = parseInt(e.target.value, 10)<0?0:parseInt(e.target.value, 10) || 0;
        setItensTela(temp);
    }

    const handleChangeAutoSize= (e) => {
        const temp = itensTela.slice();
        temp[selectedId].texto.autosize = e.currentTarget.checked;
        setItensTela(temp);
    }

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            fullWidth
        >
            <DialogTitle>{'Texto'}</DialogTitle>
            <Box m={2}>
                <Typography variant='h6'>Cor</Typography>
                <CompactPicker onChange={handleChangeColor} />
                <Box mt={4} display={'flex'}>
                    <Box width={120}>
                        <MDInput
                            label='Tam. da Fonte'
                            type='number'
                            value={itensTela[selectedId].texto?.fontSize || 24}
                            onChange={handleChangeFontSize}
                            sx={{ mr: 2 }}
                        />
                    </Box>

                    <Box>
                        <IconButton
                            aria-label="bold"
                            onClick={() => handleChangeFontStyle('bold')}
                            sx={retornaCorBotaoStyle('bold')}
                        >
                            <FormatBoldIcon />
                        </IconButton>
                    </Box>
                    <Box>
                        <IconButton
                            aria-label="bold"
                            onClick={() => handleChangeFontStyle('italic')}
                            sx={retornaCorBotaoStyle('italic')}
                        >
                            <FormatItalicIcon />
                        </IconButton>
                    </Box>

                    <Box>
                        <IconButton
                            aria-label="bold"
                            onClick={() => handleChangeAlign('left')}
                            sx={retornaAlignStyle('left')}
                        >
                            <FormatAlignLeftIcon />
                        </IconButton>
                    </Box>

                    <Box>
                        <IconButton
                            aria-label="bold"
                            onClick={() => handleChangeAlign('center')}
                            sx={retornaAlignStyle('center')}
                        >
                            <FormatAlignCenterIcon />
                        </IconButton>
                    </Box>

                    <Box>
                        <IconButton
                            aria-label="bold"
                            onClick={() => handleChangeAlign('right')}
                            sx={retornaAlignStyle('right')}
                        >
                            <FormatAlignRightIcon />
                        </IconButton>
                    </Box>

                    <Box width={120}>
                        <MDInput
                            label='Nº Caracteres'
                            type='number'
                            value={itensTela[selectedId].texto?.length || 0}
                            onChange={handleChangeLength}
                            sx={{ mr: 2 }}
                        />
                    </Box>

                    <Box width={200}>
                        <Autocomplete
                            options={opcaoFontes}
                            renderInput={(params) => <TextField {...params} label="Escolha a Fonte" />}
                            value={itensTela[selectedId].texto?.font || ''}
                            onChange={(event, newValue) => setFonteSelecionada(newValue || null)}
                        />
                    </Box>
                </Box>

                <MDBox
                    mt={2}
                    fullWidth
                >

                </MDBox>
                <Box>
                    <Typography variant='caption'>Tamanho automático</Typography>
                    <Checkbox
                        checked={itensTela[selectedId].texto?.autosize}
                        onChange={handleChangeAutoSize}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </Box>
                {
                    itensTela[selectedId]?.texto?.tipo?.value === 'textosimples' &&
                    <MDBox
                        mt={2}
                        fullWidth>

                        <MDInput
                            fullWidth
                            label='Texto'
                            rows={3}
                            multiline
                            value={itensTela[selectedId].texto.text}
                            onChange={handleChangeTextoSimples}
                        />

                    </MDBox>
                }

            </Box>
        </Dialog>
    )

}


export const Memo = ({ shapeProps, isSelected, onSelect, onChange, encontraBandaporPosicao }) => {
    const shapeRef = React.useRef();
    const trRef = React.useRef();

    React.useEffect(() => {
        if (isSelected) {
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    return (
        <React.Fragment>
            <Text
                onClick={onSelect}
                onTap={onSelect}
                ref={shapeRef}
                {...shapeProps}
                {...shapeProps?.texto}
                fontFamily={shapeProps?.texto?.font||'Arial'}
                wrap='char'
                draggable
                onDragEnd={(e) => {
                    onChange({
                        ...shapeProps,
                        x: parseInt(e.target.x(), 10),
                        y: parseInt(e.target.y(), 10),
                    });
                }}
                onTransformEnd={(e) => {
                    // transformer is changing scale of the node
                    // and NOT its width or height
                    // but in the store we have only width and height
                    // to match the data better we will reset scale on transform end
                    const node = shapeRef.current;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();
                    const escala = scaleX > scaleY ? scaleX : scaleY;

                    // we will reset it back
                    node.scaleX(1);
                    node.scaleY(1);

                    // onChange({
                    //     ...shapeProps,
                    //     x: parseInt(node.x(), 10),
                    //     y: parseInt(node.y(), 10),


                    //     texto: {
                    //         ...shapeProps?.texto,
                    //         fontSize: parseInt(node.attrs.fontSize * escala, 10),
                    //         rotation: node.rotation()
                    //     }

                    // });
                    //teste com aumento igual ao retanglo sem redimensionar a fonte
                    onChange({
                        ...shapeProps,
                        x: parseInt(node.x(), 10),
                        //y: parseInt(node.y(), 10),
                        width: parseInt(Math.max(5, node.width() * scaleX), 10),
                        height: parseInt(Math.max(node.height() * scaleY),10),
                        rotation: node.rotation()
                    });
                }}
            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </React.Fragment>
    );
}
