import React, { useState, useEffect, useContext } from 'react';

// @mui material components
import Grid from "@mui/material/Grid";
import { Icon } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

//My Componentes
import FormField from "componentes/FormField";
//My componentes e functions
import MessagesContext from "hooks/MessagesContext";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import AuthContext from 'hooks/AuthContext';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';



function Cadastro(props) {

    const messageContext = useContext(MessagesContext);
    const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;

    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();

    const { setCodigoAbrir, codigoAbrir, setAba } = props;

    const [id, setId] = useState('');
    const [nome, setNome] = useState('');

    const [ativo, setAtivo] = useState({
        label: 'Sim',
        value: 'S'
    });
    const [optionAtivo, setOptionAtivo] = useState([
        {
            label: 'Não',
            value: 'N'
        },
        {
            label: 'Sim',
            value: 'S'
        },
    ])

    const buscaDados = async () => {

        if (!parseInt(props.codigoAbrir, 10) > 0)
            return;

        try {
            const retorno = await api.get(`${rotafechada()}centro_custo?id=${props.codigoAbrir}`);
            const { data, count } = retorno.data;

            if (!count > 0) {
                MensagemErro('Nenhuma informação foi selecionada');
                return;
            }

            if (data[0].empresa_id !== sessaolocal.empresa_id) {
                MensagemErro('Registro de outra empresa!');
                return;
            }

            setId(data[0].id);
            setNome(data[0].nome);

            if (data[0].ativo === 'N') {
                setAtivo(optionAtivo[0])
            }
            else setAtivo(optionAtivo[1])

        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }

    }

    const handleClickGravar = async (e) => {
        e.preventDefault();

        try {
            MostraTelaEspera('Gravando o centro de custo...');
            let retorno;


            const dados = {
                empresa_id: sessaolocal.empresa_id || null,
                nome,
                ativo: ativo.value,
            }
            //console.log(dados);

            if (codigoAbrir && parseInt(codigoAbrir, 10) > 0)
                retorno = await api.put(`${rotafechada()}centro_custo/${codigoAbrir}`, dados);
            else {
                retorno = await api.post(`${rotafechada()}centro_custo`, dados);
                props.setCodigoAbrir(retorno.data.data.id || 0)
            }

            MensagemAviso('Gravado com sucesso!');
            FechaTelaEspera();
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const limpaImputs = async () => {

        setCodigoAbrir('');
        setNome('');
        setId('');

    }

    useEffect(() => {
        buscaDados();

    }, [codigoAbrir])


    return (
        <MDBox p={2}>
            <MDBox width="100%" display="flex" justifyContent="space-between">
                <MDButton
                    variant="gradient"
                    color="secondary"
                    type="button"
                    onClick={() => setAba(0)}
                >Voltar</MDButton>
                <MDButton
                    variant="gradient"
                    circular
                    color="info"
                    type="button"
                    onClick={limpaImputs}
                >
                    <Icon>add</Icon>
                </MDButton>
            </MDBox>

            <form onSubmit={handleClickGravar}>
                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12} sm={3}> {/* ID */}
                        <FormField
                            type="text"
                            label="ID"
                            value={id || ''}
                            disabled={true}
                        //onChange={(e) => setNome(e.target.value)} 
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}> {/* ATIVO */}
                        <AutoCompleteStyled
                            defaultValue={optionAtivo ? optionAtivo[0] : { value: 'N' }}
                            options={optionAtivo}
                            getOptionLabel={(option) => option.label || ''}
                            renderInput={(params) => <FormField {...params} label="Ativo" />}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            value={ativo}
                            onChange={(event, newValue) => {
                                setAtivo(newValue);
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12}> {/* NOME */}
                        <FormField
                            required
                            type="text"
                            label="Nome"
                            value={nome}
                            id='nome-centro-custo'
                            onChange={(e) => setNome(e.target.value)}
                        />
                    </Grid>
                </Grid>

                <MDBox p={2}>{/*Botão gravar */}
                    <MDBox mt={3} width="100%" display="flex" justifyContent="flex-end">
                        <MDButton
                            variant="gradient"
                            color="info"
                            //onClick={handleClickGravar}
                            type="submit"
                        >
                            Gravar
                        </MDButton>
                    </MDBox>
                </MDBox>

            </form>

        </MDBox >
    )

}

export default Cadastro;