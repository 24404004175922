import { Box, Button, Checkbox, Dialog, DialogTitle, FormControlLabel, Icon, Tooltip, Typography } from '@mui/material';
import React, { Component, useState } from 'react';
import { Stage, Layer, Rect, Text, Shape, Circle, Line, Image, Transformer, Arrow } from 'react-konva';
import { CompactPicker } from 'react-color';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';

export const PropriedadesLinhaVertical = (props) => {

    const { itensTela, setItensTela, selectedId, open, setOpen } = props;

    const [corAtual, setCorAtual] = useState('#FFF');


    const handleChangeColor = (color, event) => {
        const temp = itensTela.slice();
        temp[selectedId].fill = color.hex;
        temp[selectedId].stroke = color.hex;
        setCorAtual(color.hex);
        setItensTela(temp);
    }

    const handleChangeEspessuraBorda = (e) => {
        const temp = itensTela.slice();
        temp[selectedId].strokeWidth = parseInt(e.target.value, 10) || 1;
        setItensTela(temp);
    }

    const handleChangeAltura = (e) => {
        const temp = itensTela.slice();
        temp[selectedId].points[3] = parseInt(e.target.value, 10) || 1;
        setItensTela(temp);
    }

    const handleChangeX = (e) => {
        const temp = itensTela.slice();
        temp[selectedId].x = parseInt(e.target.value, 10) || 1;
        setItensTela(temp);
    }

    const handleChangeY = (e) => {
        const temp = itensTela.slice();
        temp[selectedId].y = parseInt(e.target.value, 10) || 1;
        setItensTela(temp);
    }
    
    const handleChangeLinhaTracejada = (checked) => {
        const temp = itensTela.slice();
        temp[selectedId].dash = checked ? [10, 5] : null;
        setItensTela(temp);
    }


    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            {/* <DialogTitle>{'Propriedades da Linha'}</DialogTitle> */}
            <Box m={2}>
                <Typography variant='h6' mt={1} mb={1}>Cor da Linha</Typography>
                <CompactPicker onChange={handleChangeColor} />
                <Box mt={2} mb={2}>

                    <MDInput
                        label='Espessura da Linha'
                        type='number'
                        value={itensTela[selectedId].strokeWidth || 1}
                        onChange={handleChangeEspessuraBorda}
                        sx={{mr:2}}
                    />

                    <MDInput
                        label='Altura da Linha'
                        type='number'
                        value={itensTela[selectedId].points[3] || 1}
                        onChange={handleChangeAltura}
                    />
                </Box>
                <Box>
                <MDInput
                    label='Posição no eixo X'
                    type='number'
                    value={itensTela[selectedId].x}
                    onChange={handleChangeX}
                    sx={{mr:2}}
                />
                <MDInput
                    label='Posição no eixo Y'
                    type='number'
                    value={itensTela[selectedId].y}
                    onChange={handleChangeY}
                />
                <Box>
                    <FormControlLabel ml={2} control={<Checkbox checked={(itensTela[selectedId]?.dash)} onChange={(e) => handleChangeLinhaTracejada(e.target.checked)} />} label="Tracejada" />
                </Box>
                </Box>
            </Box>

        </Dialog>
    )
}

const LinhaVertical = ({ shapeProps, isSelected, onSelect, onChange }) => {

    const cotaRef = React.useRef();
    const trRef = React.useRef();

    React.useEffect(() => {
        if (isSelected) {
            trRef.current.nodes([cotaRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    return (
        <React.Fragment>

            {shapeProps.texto ?
                <Text
                    {...shapeProps.texto}
                />
                : false
            }
            <Line
                onClick={onSelect}
                onTap={onSelect}
                ref={cotaRef}
                {...shapeProps}
                draggable
                onDragEnd={(e) => {

                    const x = parseInt(e.target.x(), 10);
                    const y = parseInt(e.target.y(), 10);

                    onChange({
                        ...shapeProps,
                        x,
                        y,
                    });
                }}


                onTransformEnd={(e) => {

                    //posicional inicial da seta apos o redimensionamento
                    const x = parseInt(e.target.x(), 10);
                    const y = parseInt(e.target.y(), 10);
                    const alturalinha = parseInt(e.target.attrs.points[3], 10);

                    const node = cotaRef.current;
                    const scaleY = node.scaleY();

                    //redimensionando a seta com uma nova largura
                    const novaalturalinha = parseInt(alturalinha * scaleY, 10);
                    const points = [0, 0, 0, novaalturalinha];

                    //zerando a escala
                    node.scaleX(1);
                    node.scaleY(1);

                    onChange({
                        ...shapeProps,
                        x,
                        y,
                        points,
                        rotation: node.rotation(),
                    });
                }}
            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </React.Fragment>
    );
};

export default LinhaVertical;