import React, { useContext, useEffect, useState, createRef } from "react";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import MessagesContext from "hooks/MessagesContext";
import { Backdrop, CircularProgress, Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import AuthContext from "hooks/AuthContext";


const TAMANHO_MAX_MB_ARQUIVO = 200;

export default function UploadBancodeDados(props) {


  const { empresaAtual, setPastaimportacao } = props;
  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MensagemAviso } = messageContext;
  const authContext = useContext(AuthContext);
  const { getSessao } = authContext;
  const sessaolocal = getSessao();
  let fileInput = createRef();
  const [openBackDrop,setOpenBackDrop]=useState({
    msg:'',
    percentual:0,
    open:false
  })

  const handleSendFile = (e) => {

    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    console.log(e.target);

    //maximo em MB
    if (file?.size > (TAMANHO_MAX_MB_ARQUIVO * 1024 * 1024)) {
      MensagemErro(`Escolha uma imagem com tamanho menor que ${TAMANHO_MAX_MB_ARQUIVO}mb`);
      return;
    }

    reader.onprogress = async (e) => {

      const percentual=parseInt(e.loaded / e.total, 10) * 100;
      setOpenBackDrop({msg:'Carregando o arquivo',open:true,percentual});
    }

    reader.onloadstart=async ()=>{
      setOpenBackDrop({msg:'Carregando o arquivo',open:true,percentual:0});
    }

    reader.onloadend = async () => {
      const ok = await enviarArquivoServidor(file);
      if (ok) {
        e.target.value = null;//para que possa selecinar o mesmo arquivo mais de uma vez

      }
      else {
        MensagemErro('Erro ao tentar enviar');
        setOpenBackDrop({...openBackDrop,open:false});
        e.target.value = null;//para que possa selecinar o mesmo arquivo mais de uma vez
      }
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  async function enviarArquivoServidor(file) {
    let formData = new FormData();
    formData.append("file", file);
    let url = `${rotafechada()}admin/empresas/arquivoimportacao`;
    console.log(url);
    try {

      const retorno = await api.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (event) => {
          let percentual = Math.round(
            (event.loaded * 100) / event.total
          );
          const msg=percentual<100?'Enviando o arquivo':' Processando o arquivo no servidor';
          setOpenBackDrop({msg,open:true,percentual:percentual<100?`${percentual}%`:''});
        }
      });

      setOpenBackDrop({...openBackDrop,open:false});
      setPastaimportacao(retorno.data.nomepasta);
      MensagemAviso('Arquivo enviado');
      //se de certo gravar no arquivo ele retorna true para a funcao mostrar a foto no componente
      return true;
    }
    catch (error) {
      setOpenBackDrop({...openBackDrop,open:false});
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro ao tentar o arquivo! ${msg}`);
    }
  };

  useEffect(() => {
  }, [empresaAtual]);


  return (
    <>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackDrop.open}
      // onClick={handleClose}
      >
        <span style={{ position: 'absolute', top: '400px', left: '500px' }}>{openBackDrop?.msg||'Aguarde'} {openBackDrop?.percentual ||''}</span>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box display={'flex'} justifyContent={'flex-start'} mb={4}>

        <Typography variant='caption' mr={2} mt={1}>Enviar um novo arquivo com o banco de dados (zip) </Typography>
        {/* <AddIcon onClick={() => handleClickAlterar()} color={'info'} fontSize='medium' /> */}
        <input type="file"
          onChange={handleSendFile}
          ref={fileInput}
          // style={{ display: 'none' }}
          accept="*.zip"
        />
        <Divider />
      </Box>

    </>
  );
}