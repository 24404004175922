import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import MDBox from 'components/MDBox';
import MostraImagem from 'componentes/MostraImagem';

/*
colunas = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'Id'
  },
  {
    id: 'nome',
    numeric: false,
    disablePadding: true,
    label: 'Nome'
  },
  {
    id: 'ativo',
    numeric: false,
    disablePadding: true,
    label: 'Ativo',
    disableOrder: false
  },
  {
    id: 'acoes',
    numeric: false,
    disablePadding: true,
    label: 'Ações',
    disableOrder: true
  },
]

linhas = [
  {
    id:10,
    nome:'XXX',
    ativo: 'S',
    acoes:<button/>
  }
]
*/



function EnhancedTableHead(props) {
  const { order, orderBy, rowCount, onRequestSort, headCells } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (

    <MDBox component="thead" bgColor='grey-200' >
      {/* <TableHead> */}

      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >

            {

              headCell.disableOrder === true ?

                <Typography
                  variant="h6"
                  component="div"
                >
                  {headCell.label}
                </Typography>
                :

                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  <Typography
                    variant="h6"
                    component="div"
                  >
                    {headCell.label}
                  </Typography>

                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
            }
          </TableCell>
        ))}
      </TableRow>
    </MDBox>


  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,

  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


function TabelaLocal(props) {

  const { linhas: rows, colunas: headCells, countAll, page, setPage, rowsPerPage, setRowsPerPage, order, setOrder, orderBy, setOrderBy, openAlteraValorProjeto, setOpenAlteraValorProjeto } = props;
  const [openZoomImg, setOpenZoomImg] = React.useState({
    open: false,
    img: ''
  });

  const handleRequestSort = (event, property) => {
    //property é o ID da coluna que sera usada
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickAbrirImg = async (imagemstr) => {
    const data = {
      open: true,
      img: imagemstr
    }
    setOpenZoomImg(data);
  }



  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      <MostraImagem
       open={openZoomImg.open}
        setOpen={setOpenZoomImg}
        img={openZoomImg.img}
        alt='Imagem do projeto'
      />
      {/* {console.log("State Open",openZoomImg)} */}
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size='small'
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}

              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
            />
            <TableBody>
              {rows.map((row, index) => {
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={`tr${index}`}
                  >
                    {
                      headCells.map((item, index) => {
                         console.log(item, index)
                        return (
                          <TableCell
                            align={item.numeric ? 'right' : 'left'}
                            padding={item.disablePadding ? 'none' : 'normal'}
                            key={`tc${index}`}
                            onDoubleClick={()=>{
                              if(item.id === 'valor_final'){
                                setOpenAlteraValorProjeto({...openAlteraValorProjeto, open:true, venda_projeto:row.id})
                              }
                            }}
                            style={{ cursor: item.id === 'valor_final' ? 'pointer' : 'default',  }}
                          >
                            {
                              item.id === 'imagem' ?
                                row.imagem && <img src={`data:image/png[jpg];base64,${row.imagem}`} alt="Imagem do Projeto" onClick={() => handleClickAbrirImg(`data:image/png[jpg];base64,${row.imagem}`)} style={{ maxHeight: 100, maxWidth: 100,cursor: "zoom-in" }} />
                                :
                                row[item.id]
                            }
                          </TableCell>
                        )
                      })
                    }
                  </TableRow>
                );
              })}
              {/* {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 33 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={countAll}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage='Linhas por Página'
        />
      </Paper>
    </Box>
  );
}

export default React.memo(TabelaLocal);