import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import { useContext } from 'react';
import MessagesContext from 'hooks/MessagesContext';
import { useEffect } from 'react';
import { useState } from 'react';
import { Checkbox, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { copiaProfundaObjetos } from 'commons/utils';
import { Box } from '@mui/system';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import FormField from 'componentes/FormField';
import MDBox from 'components/MDBox';
import SearchIcon from '@mui/icons-material/Search';
import PesquisaPessoas from 'pages/vendas/vendas/Cadastro/cabecalho/pesquisapessoas';
import { incDay } from 'commons/utils';
import { incMonth } from 'commons/utils';
import { ptBR } from "date-fns/locale";
import MDButton from 'components/MDButton';

export default function FiltroVendasPorPeriodo(props) {

  const { open, setOpen, handleOk, parametrosVenda } = props;
  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MensagemAviso } = messageContext;

  const [cliente, setCliente] = useState('');
  const [nomeCliente, setNomeCliente] = useState('');
  const [vendedor, setVendedor] = useState('');
  const [nomeVendedor, setNomeVendedor] = useState('');

  const [dataInicial, setDataInicial] = useState(incMonth(new Date(),-1));
  const [dataFinal, setDataFinal] = useState(new Date());

  const [confPesquisaDinamica, setConfPesquisaDinamica] = useState({});
  const [campoTipoPessoaIdFiltroPesquisa, setCampoTipoPessoaIdFiltroPesquisa] = useState({ id: -1, label: 'Todos' });
  const [opcoesTiposPessoas, setOpcoesTiposPessoas] = useState([]);

  // const [somenteVenda, setSomenteVenda] = useState(true);
  // const [somenteOrcamento, setSomenteOrcamento] = useState(true);

  const [tipoVenda,setTipoVenda]=useState('venda');

  const handleClickOk = () => {

    if (!handleOk)
      return

    handleOk({
      dataInicial,
      dataFinal,
      tipoVenda,
      vendedor,
      cliente
    }
    );
  }

  const handleClickPesquisaPessoa = async (campo) => {

    //debugger;

    let tipo_pessoa_id_filtro = '';
    if (campo === 'vendedor') {
      tipo_pessoa_id_filtro = parametrosVenda?.tipo_pessoa_id_vendedor || 0;
    }
    else {
      tipo_pessoa_id_filtro = parametrosVenda?.tipo_pessoa_id_cliente || 0;
    }

    opcoesTiposPessoas?.map((item) => {
      if (parseInt(item.id, 10) === parseInt(tipo_pessoa_id_filtro, 10)) {
        setCampoTipoPessoaIdFiltroPesquisa({ ...item });
      }
    })
    setConfPesquisaDinamica({ ...confPesquisaDinamica, open: true, campo });
  }

  const handleBlurPessoa = async (campo) => {

    let id = campo === 'vendedor' ? vendedor : cliente;

    if (!(parseInt(id, 10) > 0)) {
      if (campo === 'vendedor') {
        setVendedor('');
        setNomeVendedor('');
      } else {
        setCliente('');
        setNomeCliente('');
      }
      return;
    }

    try {
      let url = `${rotafechada()}pessoas?id=${id}`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;

      let nome = '';
      id = '';

      if ((data) && (data?.length > 0)) {
        nome = data[0].razao_social || data[0].fantasia || '...';
        id = data[0].id;
      }

      if (campo === 'vendedor') {
        setVendedor(id);
        setNomeVendedor(nome);
      } else {
        setCliente(id);
        setNomeCliente(nome);
      }
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleFuncaoSelecaoPesquisaDinamica = (codigo, datasetPesquisa) => {
    /* Essa funcao é chamada pelo componente de pesquisa dinamica quando um registro é escolhido
    ele retorna os campos que foram configurados*/
    //debugger;
    setConfPesquisaDinamica({ ...confPesquisaDinamica, open: false });
    if (confPesquisaDinamica.campo === 'vendedor') {
      setVendedor(datasetPesquisa.id);
      setNomeVendedor(datasetPesquisa.razao_social || datasetPesquisa.fantasia || '');
    } else {
      setCliente(datasetPesquisa.id);
      setNomeCliente(datasetPesquisa.razao_social || datasetPesquisa.fantasia || '');
    }
  }

  const buscaOpcoesTiposPessoas = async () => {
    try {
      const url = `${rotafechada()}tipos_pessoas`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;

      const tipospessoastemp = [{ id: -1, label: 'Todos' }];

      data.map((item) => {

        tipospessoastemp.push({
          id: item.id,
          label: item.nome
        });

      })
      setOpcoesTiposPessoas(tipospessoastemp);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  useEffect(() => {
    if (open) {
      buscaOpcoesTiposPessoas();
    }
  }, [open])

  return (
    <>

      <PesquisaPessoas
        open={confPesquisaDinamica.open || false}
        handleClose={
          () => setConfPesquisaDinamica({ ...confPesquisaDinamica, open: false })
        }
        handleClick={handleFuncaoSelecaoPesquisaDinamica}
        opcoesTiposPessoas={opcoesTiposPessoas}
        campoTipoPessoaIdFiltroPesquisa={campoTipoPessoaIdFiltroPesquisa}
        setCampoTipoPessoaIdFiltroPesquisa={setCampoTipoPessoaIdFiltroPesquisa}
      />

      <Dialog
        maxWidth="xl"
        open={open}
        onClose={() => setOpen(false)}
      >

        <DialogTitle>{'Filtros do Relatório'}</DialogTitle>

        <DialogContent dividers>

          <Box display='flex' mt={2} mb={2}> {/*Periodo*/}
            <Box mr={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <DesktopDatePicker
                  label='Data Inicial'
                  inputFormat="dd/MM/yyyy"
                  value={dataInicial}
                  onChange={(e) => setDataInicial(e)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>

            <LocalizationProvider dateAdapter={AdapterDateFns} >
              <DesktopDatePicker
                label='Data Final'
                inputFormat="dd/MM/yyyy"
                value={dataFinal}
                onChange={(e) => setDataFinal(e)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Box>

          <Box display='flex' mb={2}>{/*Vendedor*/}
            <FormField
              type='text'
              label='Vendedor'
              rows={1}
              multiline={false}
              value={vendedor}
              onChange={(e) => setVendedor(e.target.value)}
              inputProps={{
                onKeyUp: (e) => {
                  e.preventDefault();
                  if (e.key === 'F9') {
                    handleClickPesquisaPessoa('vendedor');
                  }

                },
                onBlur: (e) => {
                  e.preventDefault();
                  handleBlurPessoa('vendedor');
                },
              }}
              InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
              sx={{ width: 100 }}
            />
            <MDBox display="flex" justifyContent='center' alignItems='center' >
              <SearchIcon sx={{ cursor: 'pointer' }}
                onClick={() => {
                  handleClickPesquisaPessoa('vendedor');
                }}
              />
              <Typography variant='caption'>{nomeVendedor}</Typography>
            </MDBox>
          </Box>

          <Box display='flex' mb={2}>{/*Cliente*/}
            <FormField
              type='text'
              label='Cliente'
              rows={1}
              multiline={false}
              value={cliente}
              onChange={(e) => setCliente(e.target.value)}
              inputProps={{
                onKeyUp: (e) => {
                  e.preventDefault();
                  if (e.key === 'F9') {
                    handleClickPesquisaPessoa('cliente');
                  }

                },
                onBlur: (e) => {
                  e.preventDefault();
                  handleBlurPessoa('cliente');
                },
              }}
              InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
              sx={{ width: 100 }}
            />
            <MDBox display="flex" justifyContent='flex-start' alignItems='center' >
              <SearchIcon sx={{ cursor: 'pointer' }}
                onClick={() => {
                  handleClickPesquisaPessoa('cliente');
                }}
              />
              <Typography variant='caption'>{nomeCliente}</Typography>
            </MDBox>
          </Box>

          <Typography variant='h6'>Opções</Typography>

          <Box display='flex'>
            <RadioGroup
              name="controlled-radio-buttons-group"
              value={tipoVenda}
              onChange={(e)=>setTipoVenda(e.target.value)}
              row
            >
              <FormControlLabel value="venda" control={<Radio />} label="Somente Venda" />
              <FormControlLabel value="orcamento" control={<Radio />} label="Somente Orçamento" />
            </RadioGroup>
          </Box>

        </DialogContent>

        <DialogActions>
          <MDButton autoFocus onClick={() => setOpen(false)} variant="outlined" color="secondary">
            Cancelar
          </MDButton>
          <MDButton onClick={handleClickOk} variant="gradient" color='info'>Ok</MDButton>
        </DialogActions>
      </Dialog>

    </>)
}