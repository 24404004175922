import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { Box } from "@mui/system";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import { strToDate } from "commons/utils";
import { formatDate } from "commons/utils";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import AuthContext from "hooks/AuthContext";
import MessagesContext from "hooks/MessagesContext";
import { useState } from "react";
import { useContext } from "react";


export default function OpcoesInsercaoProjeto(props) {

    const { open, handleClose, venda_projeto_id, datasetPedidosTemperasDisponiveis,handlePedidoTemperaEscolhido } = props;

    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;

    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();

    const [value, setValue] = useState(0);

    // const handleClickGravar = async () => {

    //     const datatemp = formatDate(strToDate(data), 'YYYY-MM-DD');


    //     try {
    //         let dados = {
    //             empresa_id: sessaolocal.empresa_id || null,
    //             fornecedor_id,
    //             data: datatemp,
    //             observacao,
    //             concluido: 'N',
    //             //data_previsao:'',
    //             //data_conclusao:'',
    //         }
    //         let retorno = await api.post(encodeURI(`${rotafechada()}pedidos_tempera`), dados);
    //         const { data } = retorno.data;
    //         const pedido_tempera_id = data.id;

    //         dados = {
    //             empresa_id: sessaolocal.empresa_id || null,
    //             pedido_tempera_id,
    //             venda_projeto_id
    //         }
    //         retorno = await api.post(encodeURI(`${rotafechada()}pedido_tempera_venda_projetos`), dados);

    //         MensagemAviso('Gravado');
    //         handleClose();

    //     }
    //     catch (error) {
    //         const msg = error.response?.data?.msg || error;
    //         const erro = error.response?.data?.erro || '';
    //         console.log(erro);
    //         MensagemErro(`${msg}`)
    //     }


    // }


    const handleChange = (e) => {
        setValue(e.target.value);
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    Escolha a Opção
                </DialogTitle>

                <DialogContent>
                    <FormControl>

                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            value={value}
                            onChange={handleChange}
                        >
                            <FormControlLabel value={0} control={<Radio />} label="Criar um Novo Pedido de Têmpera e Inserir o Projeto" />
                            {
                                datasetPedidosTemperasDisponiveis?.map((item) => {
                                    return (
                                        <FormControlLabel
                                            control={<Radio />}
                                            value={item.pedido_tempera_id}
                                            label={`Inserir no Pedido:  ${item.pedido_tempera_id}- Têmpera: ${item.fantasia} (${formatDate(new Date(item.data),'DD/MM/YY')})`}

                                        />
                                    )
                                })
                            }
                        </RadioGroup>
                    </FormControl>


                </DialogContent>

                <DialogActions>
                    <MDButton color='info' onClick={()=>handlePedidoTemperaEscolhido(value)}>Avançar</MDButton>

                </DialogActions>
            </Dialog>
        </>
    )
}