import { useState, useEffect, useMemo, useContext } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
// import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
// import MDBox from "components/MDBox";
// import MDAvatar from "components/MDAvatar";

// Material Dashboard 2 PRO React examples
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";

// Material Dashboard 2 PRO React routes
import routes from "routes";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setMiniSidenav } from "context";

// Images
import logoPadrao from "assets/images/invictos.jpg";

import Login from "./pages/login";
import AlterarSenha from "./pages/alterarsenha";
import AtivaCadastro from "./pages/ativacadastro";
import PrimeiroAcesso from "./pages/primeiroacesso";
import AuthContext from "hooks/AuthContext";
import { MessageProvider } from "hooks/MessagesContext";
import RecuperarSenha from "pages/recuperarsenha";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import Vendas from "pages/vendas/vendas";
import Produtos from "pages/produtos/produtos";
import Pessoas from "pages/pessoas/pessoas";
import Projetos from "pages/projetos/projetos";
import Servico from "pages/servicos/servico"
import MDBox from "components/MDBox";
import { Icon, Link, Tooltip } from "@mui/material";

import packageJson from '../package.json';
import logoWhats from 'assets/images/personalizados/whatsapp.png';
import logoWhats3d from 'assets/images/personalizados/whatsapp3d.png';
import logoInsta from 'assets/images/personalizados/instagram.png';
import logoFace from 'assets/images/personalizados/facebook.png';
import logoTeamViewer from 'assets/images/personalizados/teamviewer.png';
import logoYoutube from 'assets/images/personalizados/youtube.png';
import InstagramIcon from "@mui/icons-material/Instagram";
import PreContrato from "pages/precontrato";
import TermosDeUso from "pages/termosdeuso";
import PoliticaDePrivacidade from "pages/politicadeprivacidade";
import PoliticaDePrivacidadeFechada from "pages/politicadeprivacidade";
import GenEducacaoPreContrato from "pages/geneducacao/precontrato";
import GenEducacaoContratoPadrao from "pages/geneducacao/precontrato/contratopadrao";
import { GenEducacaoPreContratoTurmaRealizaMatricula } from "pages/geneducacao/precontrato/turma";
import { GenEducacaoPreContratoTurmaSolicitaMatricula } from "pages/geneducacao/precontrato/turma";
import GenEducacao from "pages/geneducacao";
import GenEducacaoContratoTurma from "pages/geneducacao/precontrato/turma/contratopadrao";
import MDTypography from "components/MDTypography";


export default function Rotas() {

  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    //openConfigurator,
    sidenavColor,
    // transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;

  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  const authContext = useContext(AuthContext);
  const Sessao = authContext.getSessao();
  const logo = `data:image/png[jpg];base64,${Sessao.imagemEmpresa}`;
  const logo2 = Sessao.imagemEmpresa ? logo : logoPadrao;


  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  //const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const whatsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3rem"
      height="3rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2.2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={() => window.open(packageJson?.urlsuporte)}
    >
      <MDBox px={2} lineHeight={1}>
        <img src={logoWhats} maxHeight={50} />
      </MDBox>
    </MDBox>
  );

  const whatsButtonLogin = (
    <>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="2.30rem"
        height="2.30rem"
        bgColor="white"
        shadow="sm"
        borderRadius="50%"
        position="fixed"
        right="2.5rem"
        bottom="6rem"
        zIndex={99}
        color="dark"
        sx={{ cursor: "pointer" }}
        onClick={() => window.open(packageJson?.urlteamviewer)}
      >
        <Tooltip title="Acesso remoto" placement="left-start">
          <MDBox px={2} lineHeight={1}>
            <img src={logoTeamViewer}
              style={{  maxHeight: 55 }}
            />
          </MDBox>

        </Tooltip>
      </MDBox>

      {/* <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="1.2rem"
        height="1.2rem"
        bgColor="white"
        shadow="sm"
        borderRadius="50%"
        position="fixed"
        right="2rem"
        bottom="11rem"
        zIndex={99}
        color="dark"
        sx={{ cursor: "pointer" }}
        onClick={() => window.open(packageJson?.urlyoutube)}
      >
        <MDBox px={2} lineHeight={1}>
          <img src={logoYoutube}
            style={{ maxHeight: '30px', maxWidth: '30px' }}
          />
        </MDBox>
      </MDBox>

      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="1.2rem"
        height="1.2rem"
        bgColor="white"
        shadow="sm"
        borderRadius="50%"
        position="fixed"
        right="2rem"
        bottom="8rem"
        zIndex={99}
        color="dark"
        sx={{ cursor: "pointer" }}
        onClick={() => window.open(packageJson?.urlfacebook)}
      >
        <MDBox px={2} lineHeight={1}>
          <img src={logoFace}
            style={{ maxHeight: '30px', maxWidth: '30px' }}
          />
        </MDBox>
      </MDBox>

      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="1.2rem"
        height="1.2rem"
        bgColor="white"
        shadow="sm"
        borderRadius="50%"
        position="fixed"
        right="2rem"
        bottom="5rem"
        zIndex={99}
        color="dark"
        sx={{ cursor: "pointer" }}
        onClick={() => window.open(packageJson?.urlinstagram)}
      >
        <MDBox px={2} lineHeight={1}>
          <img src={logoInsta}
            style={{ maxHeight: '30px', maxWidth: '30px' }}
          />
        </MDBox>
      </MDBox> */}

      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="3rem"
        height="3rem"
        bgColor="white"
        shadow="sm"
        borderRadius="50%"
        position="fixed"
        right="2.2rem"
        bottom="2rem"
        zIndex={99}
        color="dark"
        sx={{ cursor: "pointer" }}
        onClick={() => window.open(packageJson?.urlsuporte)}
      >
        <MDBox px={2} lineHeight={1}>
          <img src={logoWhats} maxHeight={50} />
        </MDBox>
      </MDBox>

    </>

  );

  async function validaToken() {
    try {
      //chama uma rota "/" só para testar o token ou renova-lo
      await api.get(`${rotafechada()}`);
      //se deu certo o interceptor ja recebeu o novo token e ja atualizou a localstorage
      return;
    }
    catch (e) {
      console.log('Tempo de Sessão acabou, faça o login novamente no sistema', e);
      return;
    }

  }


  useEffect(() => {//*  Validando se esta autenticado ou nao* */
    validaToken();
  }, []);


  return (
    <ThemeProvider theme={darkMode ? themeDark : theme} >
      <CssBaseline />
      <MessageProvider>
        {
          Sessao?.autenticado === true ?

            layout === "dashboard" && (
              <>
                {/* Barra de Menu Lateral */}
                <Sidenav
                  color={sidenavColor}
                  //brand={(transparentSidenav && !darkMode) || whiteSidenav ? logo : logo? logo : logoPadrao}
                  brand={whiteSidenav ? logo2 : logo2}
                  brandName={Sessao.fantasia.length > 23 ? Sessao.fantasia.substring(0, 20) + '...' : Sessao.fantasia}//23caracteres
                  routes={routes}
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
                />

                <Configurator />

                {/* Botao de configuração que fica "flutuando" no layout a direita  */}
                {whatsButton}

              </>
            )
            : false
        }

        {
          Sessao?.autenticado === true ?
            // Rotas Fechadas
            <Routes>

              {
                // Mapeia as rotas
                getRoutes(routes)
              }

              {/* Como o vendas tem :id precisei remapear aqui tmbm sem :id */}
              <Route path="/vendas/vendas/" element={<Vendas />} />
              <Route path="/produtos/produto/" element={<Produtos />} />
              <Route path="/projetos/projeto/" element={<Projetos />} />
              <Route path="/servicos/servico/" element={<Servico />} />
              <Route path="/pessoas/pessoa/" element={<Pessoas />} />
              <Route path="/geneducacao/" element={<GenEducacao />} />
              <Route path="/politicadeprivacidadefechada" element={<PoliticaDePrivacidadeFechada />} />


              {/* rota default se nao encontrar vai para essa rota */}
              {/* <Route path="*" element={<Navigate to="/dashboards/analytics" />} /> */}

              <Route path="*" element={<Navigate to="/desktop" />} />
            </Routes>
            :
            // Rotas abertas
            <>
              {whatsButtonLogin}
              <Routes>

                <Route exact path="/login" element={<Login />} />
                <Route exact path="/redefinirSenha/:token" element={<AlterarSenha />} />
                <Route exact path="/recuperasenha" element={<RecuperarSenha />} />
                <Route exact path="/ativacadastro/:token" element={<AtivaCadastro />} />
                <Route exact path="/primeiroacesso/:token" element={<PrimeiroAcesso />} />
                <Route exact path="/precontrato/:token" element={<PreContrato />} />
                <Route exact path="/precontrato" element={<PreContrato />} />
                <Route exact path="/termosdeuso" element={<TermosDeUso />} />
                <Route exact path="/politicadeprivacidade" element={<PoliticaDePrivacidade />} />

                <Route exact path="/geneducacao/precontrato/:token" element={<GenEducacaoPreContrato />} />
                <Route exact path="/geneducacao/precontrato" element={<GenEducacaoPreContrato />} />
                <Route exact path="/geneducacao/contratometodogen" element={<GenEducacaoContratoPadrao />} />
                <Route exact path="/geneducacao/contratoturma/:codigoturma" element={<GenEducacaoContratoTurma />} />
                <Route exact path="/geneducacao/precontrato/turma/" element={<GenEducacaoPreContratoTurmaSolicitaMatricula />} />
                <Route exact path="/geneducacao/precontrato/turma/:codigoturma" element={<GenEducacaoPreContratoTurmaSolicitaMatricula />} />
                <Route exact path="/geneducacao/precontrato/turma/matricula/:token" element={<GenEducacaoPreContratoTurmaRealizaMatricula />} />





                <Route path="*" element={<Navigate to="/login" />} />
              </Routes>
            </>
        }
      </MessageProvider>
    </ThemeProvider>
  );
}