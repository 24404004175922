import React, { useContext, useState, useEffect } from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import logoEmpresa from 'assets/images/personalizados/logologin.png';
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import MessagesContext from "hooks/MessagesContext";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import DashboardVendas from "pages/dashboards/dashboardvendas";
import AuthContext from "hooks/AuthContext";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link } from 'react-router-dom';
import MDButton from 'components/MDButton';
import { Checkbox } from '@mui/material';
import MDTypography from 'components/MDTypography';

export default function AreadeTrabalho(props) {

    const messageContext = useContext(MessagesContext);
    const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;
    const [openDashboard, setOpenDashboard] = useState(false);
    const [openNotificacao, setOpenNotificacao] = useState(false);

    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();

    const [checked, setChecked] = React.useState(false);
    const [dialogoOpen, setDialogoOpen] = useState({ visible: true, id: null });
    const { logout } = authContext;

    const validaRotaPermissao = async (silencioso = true) => {
        //validando a permissao
        try {
            MostraTelaEspera()
            let retorno = await api.get(`${rotafechada()}dashboard/permissao/listar`);
            setOpenDashboard(true)
            FechaTelaEspera()
            return true;
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            setOpenDashboard(false)
            if (silencioso === false) {
                MensagemErro(`Erro: ${msg}`);
            }
            FechaTelaEspera()
            return false;
        }
    }

    const validaNotificacoes = async () => {
        //Validando notificações
        try {
            MostraTelaEspera()
            let retorno = await api.get(`${rotafechada()}notificacoes_empresas?empresa_id=${sessaolocal.empresa_id}&empresa_usuario_id=${sessaolocal.empresa_usuario_id}`);
            const { data, countAll, count } = retorno.data;

            if (count == 0){
                setOpenNotificacao(true)
            } else {
                setOpenNotificacao(false)
            }

            FechaTelaEspera()
        }
        catch (error) {
            FechaTelaEspera()
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
            return false;
        }

        validaRotaPermissao();
    }

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const sair = (e) => {
        if (e = true)
            logout();
    }

    const handleClose = async () => {

        try {
            MostraTelaEspera()
            setDialogoOpen({ ...dialogoOpen, visible: false })

            const dados = {
                empresa_id: sessaolocal.empresa_id,
                empresa_usuario_id: sessaolocal.empresa_usuario_id,
                notificacao_id: 1 //id da notificacao taxado pois inseri ele via seeder
            }

            await api.post(`${rotafechada()}notificacoes_empresas/`, dados);
            FechaTelaEspera()
            validaNotificacoes();
        } catch (error) {
            FechaTelaEspera()
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }

    };

    useEffect(() => {
        validaNotificacoes()

    }, [])

    return (
        openNotificacao ?
            <>
                <DashboardLayout>
                    <DashboardNavbar />
                    <Dialog
                        open={dialogoOpen.visible}
                        onClose={() => {
                            //setDialogoOpen({ ...dialogoOpen, visible: false })
                            setChecked(false)
                        }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {'Atualização da Política de Privacidade de Software'}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {'Termo de aceite '}<strong>{'Lembre-se esta ação não poderá ser desfeita.'}</strong>
                                <MDBox pt={1} width="100%" display="flex" justifyContent='flex-start' alignItems='center' flexDirection='row'>
                                    <Checkbox
                                        checked={checked}
                                        onChange={handleChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    <MDTypography
                                        variant="caption" fontWeight="bold">
                                        {'Li e concordo com a Política de Privacidade. '}
                                    </MDTypography>
                                    <MDTypography variant="caption" fontWeight="bold" sx={{ cursor: "pointer" }}>
                                        <Link to="/politicadeprivacidadefechada" target="_blank" rel="noopener noreferrer">
                                            {' Clique aqui para visualizar'}
                                        </Link>
                                    </MDTypography>
                                </MDBox>

                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <MDButton
                                onClick={() => {
                                    setChecked(false)
                                    sair(true);
                                }}
                                variant="outlined"
                                color="secondary">
                                Cancelar
                            </MDButton>
                            <MDButton onClick={() => {
                                setChecked(false)
                                handleClose()
                                setDialogoOpen({ ...dialogoOpen, visible: false })
                            }}
                                disabled={checked === false ? true : false} variant="gradient" color={'info'} sx={{ height: "100%" }}>
                                Confirmar
                            </MDButton>
                        </DialogActions>
                    </Dialog>
                </DashboardLayout>
            </> :
            openDashboard ? (
                <DashboardVendas />
            ) : (
                <DashboardLayout>
                    <DashboardNavbar />
                    <MDBox mt={5} mb={9} display='flex' justifyContent='center'>
                        <Typography variant='h2'>Seja bem Vindo ao Sistema Online de Orçamento de Projetos!</Typography>
                    </MDBox>
                    <MDBox mt={5} mb={9} display='flex' justifyContent='center'>
                        <Box fullWidth><img src={logoEmpresa} /></Box>
                    </MDBox>
                    <MDBox mt={5} mb={9}>
                    </MDBox>
                </DashboardLayout>
            )
    )

}