import { Grid } from '@mui/material';
import FormField from 'componentes/FormField';
import MDBox from 'components/MDBox';
import React from 'react';
import { Controller } from 'react-hook-form';

const Cabecalho = (props) => {

  return (
    <MDBox p={2} mt={0}>

      <Grid container  >
        <Grid item xs={12} sm={12}>{/*Dados da Estatísticas*/}
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} mb={2}>
            </Grid>
            <Grid item xs={12} sm={12}>{/*Todas Estatísticas*/}
              <h6>Estatísticas</h6>
              <Controller
                name={'dados'}
                defaultValue=''
                control={props.control}
                render={({ field: { ref, ...props } }) =>
                  <FormField

                    key={'dados'}
                    id={'dados'}
                    type='text'
                    label=''
                    rows={20}
                    multiline={true}
                    required={false}
                    disabled={false}
                    inputRef={ref}
                    {...props}
                  />}
              />
            </Grid>

          </Grid>
        </Grid>
      </Grid>
    </MDBox>
  )
}

export default Cabecalho;