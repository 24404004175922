import { Autocomplete, Grid, Typography } from '@mui/material';
import api from 'commons/api';
import { rotafechada } from 'commons/urlconf';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';
import FormField from 'componentes/FormField';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MessagesContext from 'hooks/MessagesContext';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { Controller } from 'react-hook-form';

const PossibilidadesProjetos = (props) => {

    const { control, setValue, getValues, vendaAtual, handleSubmit,
        etapaAtual, setEtapaAtual, objFull, proximaEtapa, desenhaCabecalhoPadrao } = props;

    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;
    const opcaoDefault = [{ id: -1, value: -1, label: '<Selecione...>' }];
    //const [opcoesPossibilidades, setOpcoesPossibilildades] = useState([])

    function gravarTeste(data) {
        console.log('objfull-original:', objFull);
        console.log('obj-RHF:', data);
    }

    const validaProximaEtapa = () => {

        let msgerro = '';
        let erro = false;
        objFull.possibilidades?.map((item, index) => {
            const possibilidadeopcaoescolhida = getValues(`projetos.possibilidades[${index}].possibilidade_projeto_opcao_id`)?.value || '';
            console.log('PE', possibilidadeopcaoescolhida);
            if (item.obrigatorio === 'S') {
                if (!(parseInt(possibilidadeopcaoescolhida, 10) > 0)) {
                    msgerro = msgerro === '' ? item.Possibilidades_Projeto?.nome : `${msgerro} | ${item.Possibilidades_Projeto?.nome}`;
                    erro = true;
                }
            }
        })

        if (erro) {
            MensagemErro(`Possibilidade(s) obrigatória(s): ${msgerro}`);
            return;
        }

        objFull.produtos?.map((itemproduto, indexproduto) => {
            if (itemproduto.possibilidade_projeto_opcao_id) {
                setValue(`projetos.produtos[${indexproduto}].incluido`, false);

                objFull.possibilidades?.map((itempossibilidade, indexpossibilidade) => {
                    const possibilidadeopcaoescolhida = getValues(`projetos.possibilidades[${indexpossibilidade}].possibilidade_projeto_opcao_id`)?.value || '';
                    if (possibilidadeopcaoescolhida === itemproduto.possibilidade_projeto_opcao_id) {
                        setValue(`projetos.produtos[${indexproduto}].incluido`, true);
                    }
                });
            }

        });

        objFull.servicos?.map((itemservico, indexservico) => {
            if (itemservico.possibilidade_projeto_opcao_id) {
                setValue(`projetos.servicos[${indexservico}].incluido`, false);

                objFull.possibilidades?.map((itempossibilidade, indexpossibilidade) => {
                    const possibilidadeopcaoescolhida = getValues(`projetos.possibilidades[${indexpossibilidade}].possibilidade_projeto_opcao_id`)?.value || '';
                    if (possibilidadeopcaoescolhida === itemservico.possibilidade_projeto_opcao_id) {
                        setValue(`projetos.servicos[${indexservico}].incluido`, true);
                    }
                });
            }
        });

        proximaEtapa();
    }

    const etapaAnterior = () => {
        setEtapaAtual(etapaAtual - 1);
    }

    const opcoesPossibilidades = (index) => {

        const opcaoTemp = [];
        objFull?.possibilidades[index].Possibilidades_Projeto?.Possibilidade_Projetos_Opcoes?.map((itemopcao) => {
            opcaoTemp.push({
                value: itemopcao.id,
                label: itemopcao.nome,
            })

        });

        return opcaoTemp;
    }


    return (
        <>
            {desenhaCabecalhoPadrao('Possibilidades - ')}
            <Grid container spacing={1}>

                {
                    objFull?.possibilidades.map((item, index) => {
                        return (
                            <Grid item xs={12} sm={12} key={`possibilidade_${item.id}`}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} >
                                        <Typography variant='body2'>Possibilidade: {item.Possibilidades_Projeto.nome}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4} >
                                        <Controller
                                            name={`projetos.possibilidades[${index}].possibilidade_projeto_opcao_id`}
                                            control={control}
                                            defaultValue={{ value: -1 }}
                                            render={({ field: { onChange, ...props } }) => (
                                                <AutoCompleteStyled
                                                    options={opcoesPossibilidades(index)}
                                                    getOptionLabel={(option) => option.label || ''}
                                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                                    renderInput={(params) => <FormField {...params} label='Opção' />}
                                                    onChange={(_, data) => {
                                                        return onChange(data);
                                                    }}
                                                    disabled={false}
                                                    {...props}

                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )

                    })
                }

            </Grid>

            <MDBox width="100%" display="flex" justifyContent="space-between" mt={2}>
                <MDButton variant="gradient" color="info" onClick={etapaAnterior} sx={{ ml: 2 }}>Retornar</MDButton>
                {
                    (props.parametrosVenda.mostra_botao_obj || false) &&
                    <MDButton variant="gradient" color='warning' onClick={() => handleSubmit(gravarTeste)()} sx={{ ml: 2 }}>Mostra OBJ</MDButton>
                }
                <MDButton variant="gradient" color="info" onClick={validaProximaEtapa} sx={{ ml: 2 }}>Avançar</MDButton>
            </MDBox>
        </>
    )
}

export default PossibilidadesProjetos;

