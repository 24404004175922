
import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, Icon, Typography, IconButton, Tooltip } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import TabelaGruposProdutos from "./TabelaGrupoProduto";
import CadastroGruposProdutos from "./cadastro";
import MDButton from "components/MDButton";
import SettingsIcon from '@mui/icons-material/Settings';
import MenuConfiguracaoModulos from "componentes/MenuConfiguracaoModulos";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import MessagesContext from "hooks/MessagesContext";
import { useTrataRetorno } from "commons/authUtils";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.any,
  index: PropTypes.number,
  value: PropTypes.number,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function GruposProdutos() {

  const messageContext = useContext(MessagesContext);
  const { MensagemAviso, MensagemErro } = messageContext;
  const trataRetorno = useTrataRetorno();
  const [aba, setAba] = React.useState(0);
  const [codigoAbrir, setCodigoAbrir] = useState('');
  const [openMenuConfiguracao, setOpenMenuConfiguracao] = useState(false);
  const [currentTargetBotaoMenu, setCurrentTargetBotaoMenu] = useState(null);

  const baseUrl = `${rotafechada()}grupos_produtos`;
  const baseCaption = 'Grupo de Produtos';

  const validaRotaPermissao = async (operacao = '', silencioso = false) => {
    if ((operacao !== 'inserir') &&
      (operacao !== 'editar') &&
      (operacao !== 'excluir') &&
      (operacao !== 'listar')) {
      MensagemErro('Escolha a operação que deseja validar a permissão!');
      return false;
    }

    //validando a permissao
    try {
      await api.get(`${baseUrl}/permissao/${operacao}`);
      return true;
    }
    catch (error) {
      trataRetorno(error?.response?.data?.erro);
      const msg = error.response?.data?.msg || error;

      if (silencioso === false) {
        MensagemErro(`Erro: ${msg}`);
      }
      return false;
    }
  }

  const handleChangeAba = (event, newValue) => {
    setAba(newValue);
  };

  const handleClickNovo = async () => {

    if (await validaRotaPermissao('inserir') === false) {
      return;
    }
    setCodigoAbrir('0')
    setAba(1)
  }

  const abrirRegistro = async (value) => {
    if (await validaRotaPermissao('editar') === false) {
      return
    }
    setCodigoAbrir(value);
    setAba(1);
  }

  return (
    <>
      <MenuConfiguracaoModulos
        open={openMenuConfiguracao}
        setOpen={setOpenMenuConfiguracao}
        nomeModulo='GRUPOS_PRODUTOS'
        currentTarget={currentTargetBotaoMenu}
      />
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mt={5} mb={9}>
          <Grid container justifyContent="center">
            <Grid item xs={12} lg={12}>
              <MDBox mt={0} mb={0} textAlign="center">
                <MDBox mb={1}>
                  <MDTypography variant="h3" fontWeight="bold">
                    Grupos de Produtos
                  </MDTypography>
                </MDBox>
                {/* Botao de Configuracoes (Parametros e Permissoes) */}
                <MDBox width="100%" display="flex" justifyContent="flex-end">
                  <Tooltip title="Configurações e Permissões" placement="top">
                    <IconButton
                      onClick={(event) => {
                        setCurrentTargetBotaoMenu(event.currentTarget);
                        setOpenMenuConfiguracao(true);
                      }
                      }
                    >
                      <SettingsIcon />
                    </IconButton>
                  </Tooltip>
                </MDBox>
              </MDBox>

              <Card>
                {/* <MDBox mt={-3} mb={3} mx={2}>
                  <Tabs value={aba} onChange={handleChangeAba} aria-label="Aba do Grupo de Produtos">
                    <Tab label="Listagem" {...a11yProps(0)} />
                    <Tab label="Cadastro" {...a11yProps(1)} />
                  </Tabs>

                </MDBox> */}
                <TabPanel value={aba} index={0}>


                  <MDBox width="100%" display="flex" justifyContent="flex-end">
                    <MDButton
                      variant="gradient"
                      circular
                      onClick={handleClickNovo}
                      color='info'
                    >
                      <Icon>add</Icon>
                    </MDButton>
                  </MDBox>

                  <TabelaGruposProdutos abrirRegistro={abrirRegistro} validaRotaPermissao={validaRotaPermissao} />
                </TabPanel>

                <TabPanel value={aba} index={1}>
                  <CadastroGruposProdutos
                    handleClickNovo={handleClickNovo}
                    setAba={setAba}
                    codigoAbrir={codigoAbrir}
                    setCodigoAbrir={setCodigoAbrir} />
                </TabPanel>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default GruposProdutos;
