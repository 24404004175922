import React from "react";
import { BrowserRouter } from "react-router-dom";
import { MaterialUIControllerProvider } from "context";
import Rotas from "rotas";
import { AppProvider } from "hooks";

function App(){
  return(
  <BrowserRouter>
    <MaterialUIControllerProvider >
      <AppProvider>{/* Provider Geral, dentro dele estao outros providers */}
        <Rotas />
      </AppProvider>
    </MaterialUIControllerProvider>
  </BrowserRouter>
  );
}

export default App;