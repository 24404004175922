import React, { useContext, useEffect, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { Autocomplete, Collapse, Grid, IconButton, TextField, Typography, Checkbox, Tooltip } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import SearchIcon from '@mui/icons-material/Search';

import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import MessagesContext from 'hooks/MessagesContext';
import MDButton from 'components/MDButton';
import AuthContext from 'hooks/AuthContext';
import DialogoConfirma from 'componentes/DialogoConfirma';
import TabelaVendaProdutos from './tabela';
import PesquisaProdutoGradeVenda from './pesquisaprodutograde';
import FormField from 'componentes/FormField';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';
import MDTypography from 'components/MDTypography';
import { Link } from 'react-router-dom';
import HelpIcon from '@mui/icons-material/Help';
import { removeZeros } from 'commons/utils';


const ProdutosVenda = (props) => {

    const { control, setValue, getValues, vendaAtual, handleSubmit,
        recarregaVenda, openCollapseProduto, handleClickCollapseProduto, verificaVendaConcluida } = props;


    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;
    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();
    const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });
    const [recarregaTabela, setRecarregaTabela] = useState(false);
    const opcaoDefault = [{ id: -1, value: -1, label: '<Selecione...>' }];
    const [confPesquisaDinamica, setConfPesquisaDinamica] = useState({});
    const [opcoesVendaProjetos, setOpcoesVendaProjetos] = useState([]);
    const [disabledBotoesCampos, setDisabledBotoesCampos] = useState(true);



    const ObjModeloProduto = {
        id: { type: 'text' },
        produto_grade_id: { type: 'text' },
        quantidade: { type: 'text' },
        valor_unitario: { type: 'text' },
        valor_total: { type: 'text' },
        observacao: { type: 'text' },
        otimizar: { type: 'checkbox' },
        corte: { type: 'text' },
    }

    const validaRotaPermissao = async (operacao = '', silencioso = false) => {
        if ((operacao !== 'inserir') &&
            (operacao !== 'editar') &&
            (operacao !== 'excluir') &&
            (operacao !== 'listar')) {
            MensagemErro('Escolha a operação que deseja validar a permissão!');
            return false;
        }

        //validando a permissao
        try {
            await api.get(`${rotafechada()}venda_produtos/permissao/${operacao}`);
            return true;
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;

            if (silencioso === false) {
                MensagemErro(`Erro: ${msg}`);
            }
            return false;
        }
    }

    const novoRegistro = () => {
        //limpo os campos
        Object.keys(ObjModeloProduto).forEach((nome) => {
            if (ObjModeloProduto[nome].type === 'autocomplete') {
                setValue(`produtos.${nome}`, opcaoDefault[0])
            }
            else
                if (ObjModeloProduto[nome].type === 'checkbox') {
                    setValue(`produtos.${nome}`, false)
                }
                else
                    if (ObjModeloProduto[nome].type === 'text') {
                        setValue(`produtos.${nome}`, '')
                    }
        })
        setValue('produtos.nome_produto_grade_id', '');
        setValue('produtos.venda_projeto_id', opcoesVendaProjetos.length > 0 ? { ...opcoesVendaProjetos[0] } : '');
    }

    const abrirRegistro = async (codigoAbrir = '') => {

        if (!(parseInt(codigoAbrir, 10) > 0)) {
            return;
        }

        try {
            MostraTelaEspera()
            let url = `${rotafechada()}venda_produtos?id=${codigoAbrir}`;
            const retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;

            if (!data.length > 0) {
                MensagemErro(`Venda/Produtos ID "${codigoAbrir}" não localizado`);
                return;
            }
            setValue('produtos.id', data[0].id);
            setValue('produtos.venda_id', data[0].venda_id);

            if (parseInt(data[0].venda_projeto_id, 10) > 0) {
                const vendaProjetoIdAbrir = opcoesVendaProjetos.filter((item) => parseInt(item.value, 10) === parseInt(data[0].venda_projeto_id, 10));
                if ((vendaProjetoIdAbrir) && (vendaProjetoIdAbrir.length > 0)) {
                    setValue('produtos.venda_projeto_id', { ...vendaProjetoIdAbrir[0] });
                }
            }
            else setValue('produtos.venda_projeto_id', opcoesVendaProjetos.length > 0 ? { ...opcoesVendaProjetos[0] } : '');

            setValue('produtos.produto_grade_id', data[0].produto_grade_id);
            setValue('produtos.nome_produto_grade_id', data[0].Produto_Grade?.Produto?.nome || '');
            setValue('produtos.produto_id', data[0].Produto_Grade?.produto_id || '');
            setValue('produtos.servico_id', data[0].servico_id);
            setValue('produtos.quantidade', removeZeros(data[0].quantidade));
            setValue('produtos.valor_unitario', removeZeros(data[0].valor_unitario));
            setValue('produtos.valor_total', data[0].valor_total);
            setValue('produtos.observacao', data[0].observacao);
            
            if (data[0].otimizar === 'S')
                setValue('produtos.otimizar', true);
            else setValue('produtos.otimizar', false);
            
            setValue('produtos.corte', data[0].corte);

            FechaTelaEspera();
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    };

    const excluirRegistro = async () => {

        setDialogoOpen({ ...dialogoOpen, visible: false });

        try {
            let url = `${rotafechada()}venda_produtos?id=${dialogoOpen.id}`;
            let retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;

            if (!data.length > 0) {
                MensagemErro(`Venda/Produtos ID "${dialogoOpen.id}" não localizado`);
                return;
            }
            retorno = await api.delete(`${rotafechada()}venda_produtos/${dialogoOpen.id || 0}`);
            MensagemAviso(retorno?.data?.msg ? retorno?.data?.msg : 'Excluído!');
            novoRegistro();
            setRecarregaTabela(!recarregaTabela);
            recarregaVenda();
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    };

    const handleClickExcluir = async (id) => {

        if (disabledBotoesCampos) {//venda concluida
            MensagemErro('A venda está concluída!')
            return;
        }

        if (!(await validaRotaPermissao('excluir') === true)) {
            return;
        }
        setDialogoOpen({ ...dialogoOpen, visible: true, id });
    }

    const gravarRegistro = async (data) => {

        data.produtos.venda_projeto_id = data.produtos.venda_projeto_id?.value || '';

        if ((parseInt(data.produtos.venda_projeto_id, 10) < 0) || (data.produtos.venda_projeto_id === '')) {
            delete data.produtos.venda_projeto_id;
        }

        const dados = {
            ...data.produtos,
            empresa_id: sessaolocal.empresa_id || null,
            venda_id: vendaAtual
        }

        delete dados.id;
        delete dados.servico_id;
        dados.quantidade = `${dados.quantidade}`.replace(',', '.');
        dados.valor_unitario = `${dados.valor_unitario}`.replace(',', '.');

        if (dados.otimizar)
            dados.otimizar = 'S'
        else dados.otimizar = 'N';

        //console.log('dados antes post',dados)

        try {
            let url = `${rotafechada()}venda_produtos`;
            let retorno;
            if (parseInt(data.produtos.id, 10) > 0) {
                retorno = await api.put(`${url}/${data.produtos.id}`, dados);
            }
            else {
                retorno = await api.post(url, dados);
            }
            MensagemAviso(`Gravado com sucesso! ID: ${retorno.data.data.id}`);

            novoRegistro();
            recarregaVenda();
            setRecarregaTabela(!recarregaTabela);

        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`${msg}`);
            return;
        }
    }

    const getNomeProduto = useWatch({
        control,
        name: "produtos.nome_produto_grade_id",
    });

    const getIdProduto = useWatch({
        control,
        name: "produtos.produto_id",
    });

    const handleClickPesquisaProdutoGrade = async () => {

        const tabela_preco_id = getValues('tabela_preco_id').value || '';


        setConfPesquisaDinamica({ ...confPesquisaDinamica, tabela_preco_id, open: true });

        // fazer a pesquisa de produtograde e preencher o id e nome correto
        // fazer o inserir, editar e exclui

    }

    const handleFuncaoSelecaoPesquisaDinamica = async (codigo, datasetPesquisa) => {
        /* Essa funcao é chamada pelo componente de pesquisa dinamica quando um registro é escolhido
        ele retorna os campos que foram configurados*/
        setConfPesquisaDinamica({ ...confPesquisaDinamica, open: false });
        setValue('produtos.produto_grade_id', datasetPesquisa.id);

        const tabela_preco_id = getValues('tabela_preco_id').value || '';
        const produto_grade_id = datasetPesquisa.id || '';
        await buscaDadosProdutoGrade(produto_grade_id, tabela_preco_id);
        handleBlurQuantidade();

    }

    const buscaDadosProdutoGrade = async (produto_grade_id = '', tabela_preco_id = '') => {
        try {
            if ((parseInt(produto_grade_id, 10) > 0) && (parseInt(tabela_preco_id, 10) > 0)) {

                //reaproveito a rota do F9 da pesquisa porque ja tem preco, nome...
                let url = `${rotafechada()}produto_grade_preco/produtovenda?id=${getValues('produtos.produto_grade_id')}&tabela_preco_id=${tabela_preco_id}`;
                // console.log(url);
                const retorno = await api.get(encodeURI(url));
                const { data } = retorno.data;
                if ((data) && (data?.length > 0)) {
                    setValue('produtos.produto_grade_id', data[0].id);
                    setValue('produtos.nome_produto_grade_id', `${data[0].Produto.nome}-${data[0].Core.nome}`);
                    setValue('produtos.valor_unitario', removeZeros(data[0].Produto_Precos[0].valor_venda));
                    return;
                }
            }
            setValue('produtos.produto_grade_id', '');
            setValue('produtos.nome_produto_grade_id', '');
            setValue('produtos.valor_unitario', '');
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro na busca dos dados do produto: ${msg}`);
        }
    }

    const handleBlurProdutoGrade = async () => {
        const tabela_preco_id = getValues('tabela_preco_id').value || '0';
        const produto_grade_id = getValues('produtos.produto_grade_id');
        await buscaDadosProdutoGrade(produto_grade_id, tabela_preco_id);
        handleBlurQuantidade();
    }

    const handleBlurQuantidade = () => {

        let quantidade = getValues(`produtos.quantidade`);
        quantidade = `${quantidade}`.replace(',', '.');

        let valor_unitario = getValues(`produtos.valor_unitario`);
        valor_unitario = `${valor_unitario}`.replace(',', '.');

        if (!parseFloat(quantidade) > 0) {
            quantidade = 0;
        }

        if (!parseFloat(valor_unitario) > 0) {
            valor_unitario = 0;
        }

        const valor_total = (parseFloat(quantidade) * parseFloat(valor_unitario));
        setValue(`produtos.valor_total`, `${valor_total}`);

    }

    const buscaOpcoesVendaProjetos = async () => {
        try {
            let url = `${rotafechada()}venda_projetos?limit=-1&venda_id=${vendaAtual}`;
            const retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;

            let opcaoTemp = [];
            opcaoTemp.push({ id: -1, value: -1, label: '<Avulso>' });
            data.map((item) => {
                opcaoTemp.push({ value: item.id, label: `${item.id}-${item.Projeto.nome}/Ambiente:${item.ambiente_instalacao}` })
            })
            setOpcoesVendaProjetos(opcaoTemp);
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const habilitaBotoes = async () => {
        const disabledBotoesTemp = await verificaVendaConcluida();

        setDisabledBotoesCampos(disabledBotoesTemp);
    }

    useEffect(() => {
        buscaOpcoesVendaProjetos();
        setRecarregaTabela(!recarregaTabela);
        habilitaBotoes();
    }, [openCollapseProduto]);


    return (
        <MDBox p={2} bgColor='grey-100' mt={2} borderRadius="lg" coloredShadow='secondary'>

            <PesquisaProdutoGradeVenda
                open={confPesquisaDinamica.open || false}
                tabela_preco_id={confPesquisaDinamica.tabela_preco_id || ''}
                handleClose={
                    () => setConfPesquisaDinamica({ ...confPesquisaDinamica, open: false })
                }
                handleClick={handleFuncaoSelecaoPesquisaDinamica}
            />

            <DialogoConfirma
                open={dialogoOpen.visible}
                title={'Confirma Exclusão'}
                color={'error'}
                message={'Certeza que deseja excluir?'}
                handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
                handleConfirm={excluirRegistro}
            />

            <IconButton aria-label="expand row" size="small" onClick={() => handleClickCollapseProduto(!openCollapseProduto)}>
                {openCollapseProduto ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                <Typography variant='h6' mb={2}>Produtos</Typography>
            </IconButton>

            <Collapse in={openCollapseProduto} timeout="auto" unmountOnExit>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={2}>{/*id*/}
                                <Controller
                                    name={`produtos.id`}
                                    control={props.control}
                                    defaultValue=''
                                    render={({ field: { ref, ...props } }) =>
                                        <FormField

                                            autoComplete={false}
                                            type='text'
                                            label='Id'
                                            rows={1}
                                            multiline={false}
                                            required={false}
                                            disabled={true}
                                            inputProps={{
                                                disabled: true
                                            }}
                                            inputRef={ref}
                                            {...props}
                                        />}
                                />
                            </Grid>
                            <Grid item xs={12} sm={8}>{/*venda_projeto_id*/}
                                <Controller
                                    name='produtos.venda_projeto_id'
                                    control={control}
                                    defaultValue={{ value: -1 }}
                                    render={({ field: { onChange, ...props } }) => (
                                        <AutoCompleteStyled
                                            options={opcoesVendaProjetos}
                                            getOptionLabel={(option) => option.label || ''}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            renderInput={(params) => <FormField {...params} label='Projeto da Venda' />}
                                            onChange={(_, data) => {
                                                return onChange(data);
                                            }}
                                            disabled={disabledBotoesCampos}
                                            {...props}

                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} sm={2}>{/*otimizar*/}
                                <MDBox width="100%" display="flex" alignItems='center' flexDirection='column'>
                                    <MDTypography fontWeight="bold" variant="caption">
                                        {'Otimizar na barra '}
                                        <Tooltip
                                            title="Quando habilitado necessário usar a opção: 
                                            Recalcular Cobrando as Sobras das Barras de Perfil."
                                            placement="top"
                                        >
                                            <HelpIcon color="info" />
                                        </Tooltip>
                                    </MDTypography>
                                    <Controller
                                        name={`produtos.otimizar`}
                                        control={control}
                                        render={({ field: { value, ...otherprops } }) => (
                                            <Checkbox
                                                {...otherprops}
                                                checked={value ? (value === true ? true : false) : false}
                                                onChange={(e) => setValue(`produtos.otimizar`, e.target.checked)}
                                            />
                                        )}
                                    />
                                </MDBox>
                            </Grid>


                            <Grid item xs={12} sm={12}>{/*produto_grade_id*/}
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={2}>{/*produto_grade_id*/}
                                        <Controller
                                            name={'produtos.produto_grade_id'}
                                            control={control}
                                            render={({ field: { ref, ...props } }) =>
                                                <FormField
                                                    type='text'
                                                    label='Produto'
                                                    rows={1}
                                                    multiline={false}
                                                    required={true}
                                                    disabled={disabledBotoesCampos}
                                                    {...props}
                                                    inputProps={{
                                                        onKeyUp: (e) => {
                                                            e.preventDefault();
                                                            if (e.key === 'F9') {
                                                                handleClickPesquisaProdutoGrade();
                                                            }
                                                        },
                                                        onBlur: (e) => {
                                                            e.preventDefault();
                                                            handleBlurProdutoGrade();
                                                        },
                                                    }}
                                                    inputRef={ref}
                                                    InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                                                />}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={1}>{/*botao do produto_id*/}
                                        <MDBox width="100%" height='100%' display="flex" justifyContent='center' alignItems='center' >
                                            <SearchIcon disabled={disabledBotoesCampos} sx={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    handleClickPesquisaProdutoGrade();
                                                }}
                                            />
                                        </MDBox>
                                    </Grid>
                                    <Grid item xs={12} sm={9}>{/*label do produto_id*/}
                                        <MDBox width="100%" height='100%' display="flex" alignSelf='flex-start' alignItems='center' >
                                            <Typography variant='caption'>
                                                {/* {getIdProduto} */}
                                                <Link to={`/produtos/produto/${getIdProduto}`} style={{ textDecoration: "none", color: 'black' }}>{getNomeProduto}</Link>
                                            </Typography>
                                        </MDBox>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={3}>{/*quantidade*/}
                                <Controller
                                    name={`produtos.quantidade`}
                                    control={props.control}
                                    defaultValue=''
                                    render={({ field: { ref, ...props } }) =>
                                        <FormField

                                            autoComplete={false}
                                            type='text'
                                            label='Quantidade'
                                            rows={1}
                                            multiline={false}
                                            required={false}
                                            disabled={disabledBotoesCampos}
                                            inputRef={ref}
                                            inputProps={{
                                                onKeyUp: (e) => {
                                                },
                                                onBlur: (e) => {
                                                    e.preventDefault();
                                                    handleBlurQuantidade();
                                                },
                                            }}
                                            {...props}

                                        />}
                                />
                            </Grid>

                            <Grid item xs={12} sm={3}>{/*valor_unitario*/}
                                <Controller
                                    name={`produtos.valor_unitario`}
                                    control={props.control}
                                    defaultValue=''
                                    render={({ field: { ref, ...props } }) =>
                                        <FormField

                                            autoComplete={false}
                                            type='text'
                                            label='Valor'
                                            rows={1}
                                            multiline={false}
                                            required={false}
                                            inputRef={ref}
                                            //disabled={true}
                                            InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                                            inputProps={{
                                                onKeyUp: (e) => {
                                                },
                                                onBlur: (e) => {
                                                    e.preventDefault();
                                                    handleBlurQuantidade();
                                                },
                                            }}
                                            {...props}

                                        />}
                                />
                            </Grid>

                            <Grid item xs={12} sm={3}>{/*valor_total*/}
                                <Controller
                                    name={`produtos.valor_total`}
                                    control={props.control}
                                    defaultValue=''
                                    render={({ field: { ref, ...props } }) =>
                                        <FormField

                                            autoComplete={false}
                                            type='text'
                                            label='Valor Total'
                                            rows={1}
                                            multiline={false}
                                            required={false}
                                            inputRef={ref}
                                            disabled={true}
                                            InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                                            inputProps={{
                                                disabled: true
                                            }}
                                            {...props}

                                        />}
                                />
                            </Grid>

                            <Grid item xs={12} sm={10}>{/*observacao*/}
                                <Controller
                                    name={`produtos.observacao`}
                                    control={props.control}
                                    defaultValue=''
                                    render={({ field: { ref, ...props } }) =>
                                        <FormField
                                            fullWidth
                                            autoComplete={false}
                                            type='text'
                                            label='Observação'
                                            rows={1}
                                            multiline={false}
                                            required={false}
                                            disabled={disabledBotoesCampos}
                                            inputRef={ref}
                                            {...props}

                                        />}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>{/*corte*/}
                                <Controller
                                    name={`produtos.corte`}
                                    control={props.control}
                                    defaultValue=''
                                    render={({ field: { ref, ...props } }) =>
                                        <FormField
                                            fullWidth
                                            autoComplete={false}
                                            type='text'
                                            label='Ângulo de Corte'
                                            rows={1}
                                            multiline={false}
                                            required={false}
                                            disabled={disabledBotoesCampos}
                                            inputRef={ref}
                                            {...props}

                                        />}
                                />
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>

                <MDBox width="100%" display="flex" justifyContent="flex-end" mt={2}>
                    <MDButton disabled={disabledBotoesCampos} variant="gradient" color="info" onClick={() => handleSubmit(gravarRegistro)()} sx={{ ml: 2 }}>Gravar</MDButton>
                    <MDButton disabled={disabledBotoesCampos} variant="gradient" color="info" onClick={novoRegistro} sx={{ ml: 2 }}>Novo</MDButton>
                </MDBox>

                <MDBox width="100%" display="flex" mt={2}>
                    <TabelaVendaProdutos
                        vendaAtual={vendaAtual}
                        abrirRegistro={abrirRegistro}
                        excluirRegistro={handleClickExcluir}
                        recarregaTabela={recarregaTabela}
                        opcoesVendaProjetos={opcoesVendaProjetos}
                    />
                </MDBox>

            </Collapse>
        </MDBox >
    )
}

export default ProdutosVenda;
