import { Autocomplete, Box, Checkbox, Dialog, DialogTitle, FormControl, Icon, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';
import React, { Component, useState } from 'react';
import { Stage, Layer, Rect, Text, Shape, Circle, Line, Image, Transformer } from 'react-konva';
import { CompactPicker } from 'react-color';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDBox from 'components/MDBox';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import { copiaProfundaObjetos } from 'commons/utils';

export const PropriedadesGerais = (props) => {

    const {  open, setOpen, confRelatorio, setConfRelatorio, itensTela, setItensTela,FATORCONVERSAOPIXELMM} = props;

    const redimensionaLarguraBandas=(novalargura)=>{
        const itenstemp = itensTela.slice();

        for (let i = 0; i < itenstemp.length; i++) {
            if (itenstemp[i].tipo.indexOf('banda-') === 0) {
                itenstemp[i].width =novalargura*FATORCONVERSAOPIXELMM;
            }
        }
        setItensTela(itenstemp);
    }

    const opcaoOrientacao = [
        {
            value: 'portrait',
            label: 'Retrato'
        },
        {
            value: 'landscape',
            label: 'Paisagem'
        }];

    const opcaoTamanhoPapel = [
        {
            value: 'a4',
            label: 'A4',
            largurafolha: 210,
            alturafolha: 297
        },
        {
            value: 'a5',
            label: 'A5',
            largurafolha: 148,
            alturafolha: 210

        },
        {
            value: 'custom',
            label: 'Personalizado'
        }];

    const opcaoOrientacaoFiltrada = () => {

        const valor = confRelatorio.orientation || 'portrait';

        if (valor) {
            const resultado = opcaoOrientacao?.filter((opcao) => opcao.value === valor);
            if (resultado?.length > 0)
                return resultado[0]
            else return null;
        }

        return null;
    }

    const setOpcaoOrientacao = (opcao) => {
        const temp = copiaProfundaObjetos(confRelatorio);
        //anterior
        const anterior=temp.orientation;

        temp.orientation = opcao.value || 'portrait';

        //inverteu a orientacao
        if (temp.orientation!==anterior){
            const tempaltura=temp.alturafolha 
            temp.alturafolha=temp.largurafolha;
            temp.largurafolha=tempaltura;
            redimensionaLarguraBandas(temp.largurafolha);
        }

        setConfRelatorio(temp);
    }

    const opcaoTamanhoPapelFiltrada = () => {

        const valor = confRelatorio.format || 'portrait';

        if (valor) {
            const resultado = opcaoTamanhoPapel?.filter((opcao) => opcao.value === valor);
            if (resultado?.length > 0)
                return resultado[0]
            else return null;
        }

        return null;
    }

    const setOpcaoTamanhoPapel = (opcao) => {
        const temp = copiaProfundaObjetos(confRelatorio);

        temp.format = opcao.value || 'a4';

        if (temp.orientation=='portrait'){
            temp.alturafolha = opcao.alturafolha||temp.alturafolha||297;
            temp.largurafolha = opcao.largurafolha||temp.largurafolha||210;
        }
        else{
            temp.alturafolha = opcao.largurafolha||temp.largurafolha||210;
            temp.largurafolha = opcao.alturafolha||temp.alturafolha||297;
        }

        redimensionaLarguraBandas(temp.largurafolha);
        setConfRelatorio(temp);
    }

    const handleChangeLargura = (e) => {
        const temp = copiaProfundaObjetos(confRelatorio);
        temp.largurafolha = e.target.value;
        redimensionaLarguraBandas(temp.largurafolha);
        setConfRelatorio(temp);
        
    }

    const handleChangeAltura = (e) => {
        const temp = copiaProfundaObjetos(confRelatorio);
        temp.alturafolha = e.target.value;
        setConfRelatorio(temp);
    }

    const handleChangeMargemSuperior = (e) => {
        const temp = copiaProfundaObjetos(confRelatorio);
        temp.margemsuperior = e.target.value;
        setConfRelatorio(temp);
    }


    const handleChangeMargemInferior = (e) => {
        const temp = copiaProfundaObjetos(confRelatorio);
        temp.margeminferior = e.target.value;
        setConfRelatorio(temp);
    }



    if (!open) {
        return null;
    }

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            maxWidth={'xs'}
        >
            <DialogTitle>{'Configurações Gerais do Relatório'}</DialogTitle>
            <Box m={2}>
                <Box mt={4} display={'flex'}>

                    <Box width={200} mr={4}>
                        <Autocomplete
                            options={opcaoOrientacao}
                            renderInput={(params) => <TextField {...params} label="Escolha a Orientação" />}
                            value={opcaoOrientacaoFiltrada()}
                            onChange={(event, newValue) => setOpcaoOrientacao(newValue || '')}
                        />
                    </Box>

                    <Box width={200}>
                        <Autocomplete
                            options={opcaoTamanhoPapel}
                            renderInput={(params) => <TextField {...params} label="Tamanho do Papel" />}
                            value={opcaoTamanhoPapelFiltrada()}
                            onChange={(event, newValue) => setOpcaoTamanhoPapel(newValue || '')}
                        />

                    </Box>
                </Box>
                <Box mt={4} display={'flex'}>

                    <Box width={400} >
                        <MDInput
                            label='Largura (mm)'
                            type='number'
                            value={confRelatorio.largurafolha}
                            onChange={handleChangeLargura}
                            sx={{ mr: 2, mt: 2 }}

                        />
                        <MDInput
                            label='Altura (mm)'
                            type='number'
                            value={confRelatorio.alturafolha}
                            onChange={handleChangeAltura}
                            sx={{ mr: 2, mt: 2 }}
                        />

                    </Box>
                </Box>
                <Box mt={4} display={'flex'}>

                    <Box width={400} >
                        <MDInput
                            label='Margem Superior (mm)'
                            type='number'
                            value={confRelatorio.margemsuperior}
                            onChange={handleChangeMargemSuperior}
                            sx={{ mr: 2, mt: 2 }}

                        />
                        <MDInput
                            label='Margem Inferior (mm)'
                            type='number'
                            value={confRelatorio.margeminferior}
                            onChange={handleChangeMargemInferior}
                            sx={{ mr: 2, mt: 2 }}
                        />

                    </Box>
                </Box>
            </Box>
        </Dialog>
    )

}
