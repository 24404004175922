import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import PageLayout from "examples/LayoutContainers/PageLayout";

function PoliticaDePrivacidadeLayout({ children }) {
  return (
    <PageLayout>
      <MDBox px={1} width="700" mx="auto">
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={12} sm={12}>
            {children}
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
}
export default PoliticaDePrivacidadeLayout;
