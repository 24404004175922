import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MostraTabelaFormulas from './MostraTabelaFormulas';
import ErrorIcon from '@mui/icons-material/Error';
import { Box } from '@mui/system';


export default function MostraErroFormulas(props) {

  const { open, message, handleClose, title, tabela } = props;

  const [mostraFormulas, setMostraFormulas] = React.useState({ open: false, tabela: [] });

  const handleMostraFormulas = () => {
    handleClose();
    setMostraFormulas({ open: true, tabela })

  }

  return (
    <div>
      <MostraTabelaFormulas
        open={mostraFormulas.open || false}
        tabela={mostraFormulas.tabela || []}
        onClose={() => setMostraFormulas({ ...mostraFormulas, open: false })}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box alignItems={'center'} display={'flex'}>
              <ErrorIcon fontSize='large' color='error' style={{marginRight:'8'}} />
              {message}
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleMostraFormulas} >Auditar Fórmulas</Button>
          <Button onClick={handleClose}autoFocus >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
