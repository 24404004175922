
import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, Icon, IconButton, Tooltip, Typography } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Tabela from "./Tabela";
import Cadastro from "./Cadastro";
import MDButton from "components/MDButton";

import MessagesContext from "hooks/MessagesContext";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import AuthContext from "hooks/AuthContext";
import DialogoConfirma from 'componentes/DialogoConfirma';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SettingsIcon from '@mui/icons-material/Settings';
import MenuConfiguracaoModulos from "componentes/MenuConfiguracaoModulos";
import { useTrataRetorno } from "commons/authUtils";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.any,
  index: PropTypes.number,
  value: PropTypes.number,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


function PossibilidadesProjetos() {

  const [aba, setAba] = React.useState(0);
  const [codigoCarregado, setCodigoCarregado] = useState('');
  const messageContext = useContext(MessagesContext);
  const { MensagemAviso, MensagemErro } = messageContext;
  const [dataset, setDataset] = useState([]);
  const authContext = useContext(AuthContext);
  const { getSessao } = authContext;
  const sessaolocal = getSessao();
  const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });
  const [recarregaTabela, setRecarregaTabela] = useState(false);
  const [enabledBotaoGravar, setEnabledBotaoGravar] = useState(true);
  const [openFiltrosAvancados, setOpenFiltrosAvancados] = useState(false);
  const trataRetorno = useTrataRetorno;
  const [openMenuConfiguracao, setOpenMenuConfiguracao] = useState(false);
  const [currentTargetBotaoMenu, setCurrentTargetBotaoMenu] = useState(null);

  const baseUrl = `${rotafechada()}possibilidades_projetos`;
  const baseCaption = 'Possibilidades de Projetos';

  const validaRotaPermissao = async (operacao = '', silencioso = false) => {
    if ((operacao !== 'inserir') &&
      (operacao !== 'editar') &&
      (operacao !== 'excluir') &&
      (operacao !== 'listar')) {
      MensagemErro('Escolha a operação que deseja validar a permissão!');
      return false;
    }

    //validando a permissao
    try {
      await api.get(`${baseUrl}/permissao/${operacao}`);
      return true;
    }
    catch (error) {
      trataRetorno(error?.response?.data?.erro);
      const msg = error.response?.data?.msg || error;

      if (silencioso === false) {
        MensagemErro(`Erro: ${msg}`);
      }
      return false;
    }
  }

  const handleClickNovo = async () => {
    if (await validaRotaPermissao('inserir') === false) {
      return;
    }
    setCodigoCarregado('');
    setAba(1)
  }

  const abrirRegistro = async (id) => {

    if (!parseInt(id, 10) > 0)
      return;

    try {
      setCodigoCarregado(id);
      setAba(1);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleExcluir = async () => {
    setDialogoOpen({ ...dialogoOpen, visible: false });

    try {
      await api.delete(`${baseUrl}/${dialogoOpen.id || 0}`);
      MensagemAviso('Excluído com sucesso!');
      setCodigoCarregado('');
      setRecarregaTabela(!recarregaTabela);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleClickExcluir = async (id) => {

    if (!(await validaRotaPermissao('excluir') === true)) {
      return;
    }

    setDialogoOpen({ ...dialogoOpen, visible: true, id });
  }

  useEffect(async () => {

    if (aba === 1) {
      if (parseInt(codigoCarregado, 10) > 0) {
        if (await validaRotaPermissao('editar') === false) {
          //alem da mensagem, desativo o botao de gravar
          setEnabledBotaoGravar(false);
        }
      }
      else {
        if (await validaRotaPermissao('inserir') === false) {
          //alem da mensagem, desativo o botao de gravar
          setEnabledBotaoGravar(false);
        }
      }

    }

  }, [aba])

  return (
    <>

      <MenuConfiguracaoModulos
        open={openMenuConfiguracao}
        setOpen={setOpenMenuConfiguracao}
        nomeModulo='POSSIBILIDADES_PROJETOS'
        currentTarget={currentTargetBotaoMenu}
      />

      <DashboardLayout>
        <DashboardNavbar />

        <DialogoConfirma
          open={dialogoOpen.visible}
          title={'Confirma Exclusão'}
          color={'error'}
          message={'Certeza que deseja excluir?'}
          handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
          handleConfirm={handleExcluir}
        />

        <MDBox mt={5} mb={9}>
          <Grid container justifyContent="center">
            <Grid item xs={12} lg={12}>
              <MDBox mt={0} mb={0} textAlign="center">
                <MDBox mb={1}>
                  <MDTypography variant="h3" fontWeight="bold">
                    {`${baseCaption}`}
                  </MDTypography>
                </MDBox>
                <MDTypography variant="h5" fontWeight="regular" color="secondary">
                  { }
                </MDTypography>
                {/* Botao de Configuracoes (Parametros e Permissoes) */}
                <MDBox width="100%" display="flex" justifyContent="flex-end">
                  <Tooltip title="Configurações e Permissões" placement="top">
                    <IconButton
                      onClick={(event) => {
                        setCurrentTargetBotaoMenu(event.currentTarget);
                        setOpenMenuConfiguracao(true);
                      }
                      }
                    >
                      <SettingsIcon />
                    </IconButton>
                  </Tooltip>
                </MDBox>
              </MDBox>

              <Card>
                <TabPanel value={aba} index={0}>

                  <MDBox width="100%" display="flex" justifyContent="space-between">
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => setOpenFiltrosAvancados(!openFiltrosAvancados)}
                    >
                      {openFiltrosAvancados ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      <Typography>Filtros</Typography>
                    </IconButton>
                    <Tooltip title='Novo' placement="top">
                      <MDButton
                        variant="gradient"
                        circular
                        onClick={handleClickNovo}
                        color='info'
                      >
                        <Icon>add</Icon>
                      </MDButton>
                    </Tooltip>

                  </MDBox>

                  <Tabela
                    openFiltrosAvancados={openFiltrosAvancados}
                    setOpenFiltrosAvancados={setOpenFiltrosAvancados}
                    abrirRegistro={abrirRegistro}
                    handleClickExcluir={handleClickExcluir}
                    recarregaTabela={recarregaTabela}
                    validaRotaPermissao={validaRotaPermissao} />
                </TabPanel>

                <TabPanel value={aba} index={1}>
                  <Cadastro
                    handleClickNovo={handleClickNovo}
                    setPossibilidadeAtual={setCodigoCarregado}
                    possibilidadeAtual={codigoCarregado}
                    setAba={setAba}
                  />
                </TabPanel>

              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default PossibilidadesProjetos;
