// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";

import PageLayout from "examples/LayoutContainers/PageLayout";
import imgLogoLogin from "assets/images/personalizados/logologin.png";
import { Image } from "@mui/icons-material";
import { Box } from "@mui/system";
import MDAvatar from "components/MDAvatar";
import { Avatar } from "@mui/material";


function LoginLayout({ coverHeight, image, children }) {
  return (
    <PageLayout>
      <MDBox
        width="calc(100% - 2rem)"
        minHeight={coverHeight}
        borderRadius="xl"
        mx={2}
        my={2}
        pt={6}
        pb={28}
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            image &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.4),
              rgba(gradients.dark.state, 0.4)
            )}, url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        {/* <Box fullWidth sx={{ backgroundColor: 'red' }} display='flex' justifyContent={'center'} mt={-6} pt={-6}>
          <Avatar src={imgLogoLogin} alt="Logo da empresa" sx={{width:200,height:200}} 
          backgroundColor={'yellow'}
          />
          <MDAvatar src={imgLogoLogin} alt="Logo da empresa" sx={{width:200,height:200}}/>
          ...
        </Box> */}

      </MDBox>
      <MDBox mt={{ xs: -20, lg: -18 }} px={1} width="calc(100% - 2rem)" mx="auto">
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            {children}
          </Grid>
        </Grid>
      </MDBox>
      {/* <Footer /> */}
    </PageLayout>
  );
}

// Setting default props for the LoginLayout
LoginLayout.defaultProps = {
  coverHeight: "35vh",
};

// Typechecking props for the LoginLayout
LoginLayout.propTypes = {
  coverHeight: PropTypes.string,
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default LoginLayout;
