import React, { useContext, useEffect, useState } from 'react';
import MDBox from "components/MDBox";
import MessagesContext from 'hooks/MessagesContext';
import AuthContext from "hooks/AuthContext";
import { useForm, useWatch } from 'react-hook-form';
import MDButton from 'components/MDButton';
import { Autocomplete, Grid, Icon, Collapse, Typography, Box, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import MDInput from 'components/MDInput';
import { Controller } from 'react-hook-form';
import TabelaOpcoes from './tabela';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';
import FormField from 'componentes/FormField';
import { ptBR } from "date-fns/locale";


//INDEX QUE COMANDA O CABECALHO

function Geral(props) {

  const messageContext = useContext(MessagesContext);
  const authContext = useContext(AuthContext);
  const { getSessao } = authContext;
  const sessaolocal = getSessao();
  const { handleSubmit, control, setValue, getValues, setFocus } = useForm({});


  let dataHoje = new Date();
  const data7dias = new Date(dataHoje.setDate(dataHoje.getDate() - 7));
  dataHoje = new Date();
  const data15dias = new Date(dataHoje.setDate(dataHoje.getDate() - 15));
  dataHoje = new Date();
  const data30dias = new Date(dataHoje.setDate(dataHoje.getDate() - 30));

  const periodo = [
    { value: data7dias, label: 'Últimos 7 dias' },
    { value: data15dias, label: 'Últimos 15 dias' },
    { value: data30dias, label: 'Últimos 30 dias' },
    { value: null, label: 'Personalizado' },
  ];

  const opcaoAtivo = [
    {
      label: 'Todos',
      value: ''
    },
    {
      label: 'Ativos',
      value: 'S'
    },
    {
      label: 'Desativados',
      value: 'N'
    },
  ];
  
  const [filtroAtivo, setFiltroAtivo] = useState(opcaoAtivo[1]);

  const [dataInicial, setDataInicial] = useState(data7dias);

  const [dataFinal, setDataFinal] = useState(new Date());


  const [desabilitaData, setDesabilitaData] = useState(false);

  const [recarregaTabela, setRecarregaTabela] = useState(false);

  const handleClickPesquisar = () => {
    setRecarregaTabela(!recarregaTabela);
  }

  //UseWatch fica escutando o campo perído para quando o periodo mudar ele carregar o state dos datePicker e pesquisa
  const aguardaPeriodo = useWatch({
    control,
    name: "Periodo",
  });

  useEffect(async () => {
    if (aguardaPeriodo && aguardaPeriodo.value != null) {
      // console.log("effect que esperar a alteração do aguarda periodo", aguardaPeriodo)
      setDataInicial(aguardaPeriodo.value);
      setDataFinal(new Date());

      setDesabilitaData(true);

      handleClickPesquisar();
    }
    if (aguardaPeriodo && aguardaPeriodo.value == null) {
      setDataInicial(null);
      setDataFinal(null);
      setDesabilitaData(false);
    }
  }, [aguardaPeriodo])

  return (


    <MDBox width='100%' p={1}>

      <Box sx={{ margin: 0 }}>
        <Grid container justifyContent="flex start" >

          <Grid item xs={12} sm={3} paddingRight={1}>{/*Período*/}
            <Controller
              name='Periodo'
              control={control}
              //defaultValue={{value: }}
              render={({ field: { onChange, ...props } }) => (
                <AutoCompleteStyled
                  options={periodo}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  renderInput={(params) => <FormField {...params} label='Período' />}
                  onChange={(_, data) => {
                    return onChange(data);
                  }}
                  disabled={false}
                  {...props}
                />
              )}

            />
          </Grid>

          <Grid item xs={12} sm={3} paddingRight={1}> {/*Periodo ini*/}
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <DesktopDatePicker
                required={true}
                disabled={desabilitaData}
                label='Data Inicial'
                inputFormat="dd/MM/yyyy"
                value={dataInicial}
                onChange={(e) => setDataInicial(e)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} sm={3} paddingRight={1}>{/*Periodo fin*/}
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <DesktopDatePicker
                required={true}
                disabled={desabilitaData}
                label='Data Final'
                inputFormat="dd/MM/yyyy"
                value={dataFinal}
                onChange={(e) => setDataFinal(e)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={3} paddingRight={1}>{/*Ativo*/}
            <AutoCompleteStyled
              autoHighlight
              options={opcaoAtivo}
              getOptionLabel={(option) => option.label || ''}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderInput={(params) => <FormField {...params} label='Ativo'/>}
              value={filtroAtivo}
              onChange={(event, newValue) => {
                setFiltroAtivo(newValue);
              }}
              defaultValue={{ id: -1, value: -1, label: '<Escolha um opção>' }}
            />
          </Grid>

        </Grid>

        <Grid container justifyContent="flex-end" >

          <Grid item xs={12} sm={2} mt={2}>{/*Pesquisar*/}
            <MDButton onClick={handleClickPesquisar} color='info' > Pesquisar</MDButton>
          </Grid>

        </Grid>
      </Box>

      <Grid item xs={12}>{/*Tabela*/}
        <TabelaOpcoes
          dataInicial={dataInicial}
          dataFinal={dataFinal}
          recarregaTabela={recarregaTabela}
          filtroAtivo={filtroAtivo}


        />
      </Grid>
    </MDBox>
  )
}

export default Geral;
