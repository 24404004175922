import { Icon } from '@mui/material';
import api from 'commons/api';
import { rotafechada } from 'commons/urlconf';
import TabelaPadrao from 'componentes/TabelaPadrao';
import MDButton from 'components/MDButton';
import MessagesContext from 'hooks/MessagesContext';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';


const ProjetosProduto = (props) => {

    const { produtoAtual } = props;

    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;

    const [countAll, setCountAll] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [colunasTable, setColunasTable] = React.useState([]);
    const [linhas, setLinhas] = React.useState([]);

    const carregaTabela = async () => {

        if (!parseInt(produtoAtual)>0){
            return;
        }

        try {

            const retorno = await api.get(`${rotafechada()}produtos/projetos/${produtoAtual}`);
            const { data } = retorno.data;
            const countAllretorno=data.length;

            const dadostemp = [];

            const colunasTabletemp = [
                {
                    id: 'imagem',
                    numeric: false,
                    disablePadding: true,
                    label: 'Imagem'
                },
                {
                    id: 'id',
                    numeric: false,
                    disablePadding: true,
                    label: 'Id'
                },
                {
                    id: 'referencia',
                    numeric: false,
                    disablePadding: true,
                    label: 'Referência'
                },
                {
                    id: 'nome',
                    numeric: false,
                    disablePadding: true,
                    label: 'Nome'
                },
                {
                    id: 'ativo',
                    numeric: false,
                    disablePadding: true,
                    label: 'Ativo'
                },
            ]

            //mapeando os dados para a "rows"
            data.map((item, index) => {
                dadostemp.push({
                    imagem:item.imagem?<img src={`data:image/png[jpg];base64,${item.imagem}`} alt="Imagem do Projeto" style={{maxWidth:100,maxHeight: 100 }} />:'',
                    id: <Link to={`/projetos/projeto/${item['projeto_id']}`}  style={{textDecoration: "none", color:'black'}}>{item['projeto_id']}</Link>,
                    referencia: item['Projeto.referencia'],
                    nome: item['Projeto.nome'],
                    ativo: item['Projeto.ativo'],
                })
            })

            setColunasTable([...colunasTabletemp]);
            setLinhas([...dadostemp]);
            setCountAll(countAllretorno);
            setRowsPerPage(countAllretorno);
            FechaTelaEspera();
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    useEffect(() => {
        carregaTabela();
    }, [])

    return (
        <TabelaPadrao
            colunas={colunasTable}
            linhas={linhas}
            countAll={countAll}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            disablePagination={true}
        />
    )
}


export default ProjetosProduto;