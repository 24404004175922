import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MDButton from 'components/MDButton';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';


export default function DialogoValidaModulo(props) {

  const { open, message, handleCancel, handleConfirm, title, color, botaoOk } = props;

  return (
    <div>

      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <MDButton
            onClick={handleCancel}
            variant="outlined"
            color="secondary">
            Fechar
          </MDButton>

          <MDButton
            onClick={handleConfirm}
            variant="gradient"
            color={color ? color : 'info'}
            sx={{ height: "100%" }}>
            {botaoOk ? 'Ok' : 'Contato '}
            {
              botaoOk ? '' : <WhatsAppIcon fontSize="medium" color='Success' />
            }

          </MDButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
