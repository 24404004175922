import React, { useState, useEffect, useContext } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import MessagesContext from "hooks/MessagesContext";
import { Grid, Icon, Tooltip, Divider, Menu, MenuItem, ListItemIcon, capitalize } from "@mui/material";
import AuthContext from 'hooks/AuthContext';
import { formatDate, formataValor } from "commons/utils";
import { Link } from "react-router-dom";
import HelpIcon from '@mui/icons-material/Help';
import TabelaPadrao from "componentes/TabelaPadrao";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogoConfirmaValidacao from "componentes/DialogoConfirmaValidacao";
import { capitalizeInitials } from "commons/utils";
import { endOfMonth, format } from "date-fns";


export default function DialogoDetalhePlanoConta(props) {

    const { open, handleCancel, title, data_periodo, plano_conta_id, parametrosDRE } = props;

    const messageContext = useContext(MessagesContext);
    const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera, MensagemAlerta } = messageContext;

    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();

    //TABELA
    const [colunasTable, setColunasTable] = React.useState([]);
    const [linhas, setLinhas] = React.useState([]);
    const [countAll, setCountAll] = React.useState(0);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('data_competencia');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(1500);

    const buscaDados = async () => {
        try {
            MostraTelaEspera()

            /*DADOS TABELA*/
            let complementoUrl = '';

            if (page > 0) {
                complementoUrl = `${complementoUrl}&offset=${page * rowsPerPage}`;
            }

            if (orderBy) {
                complementoUrl = `${complementoUrl}&order=${orderBy}`;
                if (order) {
                    complementoUrl = `${complementoUrl}&direction=${order}`;
                }
            }

            let data1 = data_periodo + '/01 00:00:00' // data inicial
            let data2 = format(endOfMonth(new Date(data_periodo + '/01')), "yyyy/MM/dd 23:59:59") //data final

            const retornoDetalheParcela = await api.post(`${rotafechada()}imprimir/DRE/listar?plano_conta_id=${plano_conta_id}&data1=${data1}&data2=${data2}${complementoUrl}`, parametrosDRE);
            const { data, countAll: countAllretorno, retornoLancamentoPlanoConta, retornoLancamentoAvulso } = retornoDetalheParcela.data;
            const dadostemp = [];

            data.sort((a, b) => new Date(a.data) - new Date(b.data));
            
            const colunasTabletemp = [
                // {
                //     id: 'id',
                //     numeric: false,
                //     disablePadding: true,
                //     label: 'id',
                //     disableOrder: true
                // },
                {
                    id: 'data',
                    numeric: false,
                    disablePadding: true,
                    label: 'Data',
                    disableOrder: true
                },
                {
                    id: 'descricao',
                    numeric: false,
                    disablePadding: true,
                    label: 'Descrição',
                    disableOrder: true
                },
                {
                    id: 'cliente',
                    numeric: false,
                    disablePadding: true,
                    label: 'Cliente/Fornecedor',
                    disableOrder: true
                },
                {
                    id: 'valor',
                    numeric: true,
                    disablePadding: true,
                    label: 'Valor',
                    disableOrder: true
                },

            ]
            //console.log('data:', data)
            //mapeando os dados para a "rows"
            data.map((item, index) => {
                dadostemp.push({
                    //id: item.id,
                    data: formatDate(new Date(item.data), 'DD/MM/YYYY'),
                    //data: item.data,
                    descricao: item.descricao,
                    cliente: item.cliente,
                    valor: formataValor(item.valor)
                })
            })
            // if (retornoLancamentoPlanoConta.length > 0) {
            //     retornoLancamentoPlanoConta.map((item) => {
            //         dadostemp.push({
            //             id: item.id,
            //             data: formatDate(new Date(item.data), 'DD/MM/YYYY'),
            //             descricao: `Venda - ${item.venda_id}`,
            //             cliente: item.fisica_juridica === 'J' ? `${capitalizeInitials(item.razao_social)} / ${capitalizeInitials(item.fantasia)}` : capitalizeInitials(item.razao_social),
            //             valor: formataValor(item.total)
            //         })
            //     })
            // }
            // if (retornoLancamentoAvulso.length > 0) {
            //     retornoLancamentoAvulso.map((item, i) => {
            //         dadostemp.push({
            //             id: item.id,
            //             data: formatDate(new Date(item.data_competencia), 'DD/MM/YYYY'),
            //             descricao: item.nome,
            //             cliente: item.cliente,
            //             valor: formataValor(item.valor)
            //         })
            //     })
            // }

            setColunasTable([...colunasTabletemp]);
            setLinhas(dadostemp);
            setCountAll(countAllretorno);

            /*DADOS TABELA*/




            FechaTelaEspera()
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const limpaImputs = () => {

    }

    useEffect(async() => {
        if (open == true) {
            await buscaDados();
        }

    }, [open])

    useEffect(() => {
        buscaDados();
    }, [page, rowsPerPage, order, orderBy])

    return (
        <>
            <Dialog
                open={open}
                onClose={() => {
                    handleCancel()
                    limpaImputs()
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullScreen
            >
                <DialogTitle id="alert-dialog-title" sx={{ padding: '5px' }}>{/*HEADER*/}
                    <MDBox width="100%">
                        <MDBox display="flex" justifyContent="space-between">
                            <MDBox display="flex" justifyContent="space-start" flexDirection='column'>
                                <MDTypography fontWeight='bold' ml={5} mt={1} variant='h4'>{`${title} - ${data_periodo}`}</MDTypography>
                            </MDBox>

                            <Tooltip title="Fechar (Esc)">
                                <MDButton size="large" sx={{ padding: 0 }} onClick={() => {
                                    handleCancel()
                                    limpaImputs()
                                }}>
                                    <Icon>close</Icon>
                                </MDButton>

                            </Tooltip>
                        </MDBox>
                    </MDBox>
                </DialogTitle>

                <DialogContent dividers sx={{ backgroundColor: "#f1f4f9", borderColor: '#7bb5e4' }}>
                    <DialogContentText id="alert-dialog-description">

                        <MDBox ml={1} mr={1} mt={1} pb={1} bgColor={'#fff'} sx={{ borderRadius: '3px' }}>
                            <Grid container spacing={1} mt={1} pl={2} pr={2}>
                                <Grid item xs={12} sm={12}>
                                    <TabelaPadrao
                                        colunas={colunasTable}
                                        linhas={linhas}
                                        countAll={countAll}
                                        page={page}
                                        setPage={setPage}
                                        rowsPerPage={rowsPerPage}
                                        setRowsPerPage={setRowsPerPage}
                                        order={order}
                                        setOrder={setOrder}
                                        orderBy={orderBy}
                                        setOrderBy={setOrderBy}
                                    />
                                </Grid>

                            </Grid>
                        </MDBox>

                    </DialogContentText>
                </DialogContent>

                <DialogActions sx={{ padding: '7px', margin: 0.5 }}> {/*FOOTER*/}
                    {/* <MDBox width="100%"> */}
                    <MDBox width="100%" display="flex" justifyContent="flex-end">
                        <MDBox width="100%" display="flex" justifyContent="flex">
                            <MDButton onClick={() => {
                                handleCancel()
                                limpaImputs()
                            }} variant="gradient" type="button" color="secondary">
                                fechar
                            </MDButton>
                        </MDBox>
                    </MDBox>
                </DialogActions>
            </Dialog >
        </>
    );
}