import React, { useContext, useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import ApagaBancodeDadosAdmin from "./apagadados";
import ImportaEmpresaAdmin from "./importaempresa";
import MDTypography from "components/MDTypography";
import TabPanel from "componentes/Tabpanel";
import { Box, Tab, Tabs } from "@mui/material";
import ImportaVidrosFirebirdAdmin from "./importavidros";
import AjustesBancodeDadosAdmin from "./ajustes";
import ExportaDesenhosTemperaAdmin from "./exportadesenhos";
import ExportaProjetosAdmin from "./exportaprojetos";

function BancodeDadosAdmin(props) {

  const { empresaAtual, nomeEmpresaAtual } = props;
  const [aba, setAba] = useState(0);


  return (
    <>
      <MDBox>
        <MDBox textAlign="center">
          <MDBox mb={1}>
            <MDTypography variant="h3" fontWeight="bold">
              {`Ferramentas  -  Banco de Dados`}
            </MDTypography>
          </MDBox>
        </MDBox>

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={aba}
            onChange={(e, newValue) => setAba(newValue)}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            <Tab label="Importa Empresa" />
            <Tab label="Exclui Dados" />
            <Tab label="Importa Vidros(FB)" />
            <Tab label="Ajustes" />
            <Tab label="Desenhos Têmpera" />
            <Tab label="Projetos" />

          </Tabs>
        </Box>

        <MDBox>
          <TabPanel value={aba} index={0}>
            <ImportaEmpresaAdmin
              empresaAtual={empresaAtual}
              nomeEmpresaAtual={nomeEmpresaAtual}
            />
          </TabPanel>

          <TabPanel value={aba} index={1}>
            <ApagaBancodeDadosAdmin
              empresaAtual={empresaAtual}
              nomeEmpresaAtual={nomeEmpresaAtual}
            />
          </TabPanel>

          <TabPanel value={aba} index={2}>
            <ImportaVidrosFirebirdAdmin
              empresaAtual={empresaAtual}
              nomeEmpresaAtual={nomeEmpresaAtual}
            />
          </TabPanel>

          <TabPanel value={aba} index={3}>
            <AjustesBancodeDadosAdmin
              empresaAtual={empresaAtual}
              nomeEmpresaAtual={nomeEmpresaAtual}
            />
          </TabPanel>

          <TabPanel value={aba} index={4}>
            <ExportaDesenhosTemperaAdmin
              empresaAtual={empresaAtual}
              nomeEmpresaAtual={nomeEmpresaAtual}
            />
          </TabPanel>

          <TabPanel value={aba} index={5}>
            <ExportaProjetosAdmin
              empresaAtual={empresaAtual}
              nomeEmpresaAtual={nomeEmpresaAtual}
            />
          </TabPanel>

        </MDBox>
      </MDBox>
    </>
  );
}

export default BancodeDadosAdmin;
