import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box, Button, Icon, IconButton, Tooltip, Typography } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Tabela from "./Tabela";
import Cadastro from "./Cadastro";
import MDButton from "components/MDButton";

import MessagesContext from "hooks/MessagesContext";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import AuthContext from "hooks/AuthContext";
import DialogoConfirma from "componentes/DialogoConfirma";
import DialogoConfirmaEscolheNovo from "componentes/DialogoConfirmaEscolheNovo";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SettingsIcon from "@mui/icons-material/Settings";
import MenuConfiguracaoModulos from "componentes/MenuConfiguracaoModulos";
import { useNavigate, useParams } from "react-router-dom";
import DialogoValidaModulo from "componentes/ValidaModulo";
import packageJson from '../../../../package.json';
import { useTrataRetorno } from "commons/authUtils";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.any,
  index: PropTypes.number,
  value: PropTypes.number,
};

function Categoria() {
  let valorinicial = "";
  const trataRetorno = useTrataRetorno();
  const params = useParams();
  const { id } = params;
  const navigate = useNavigate();
  if (id && parseInt(id, 10) >= 0) {
    valorinicial = id;
  }

  const [aba, setAba] = React.useState(0);
  const [codigoCarregado, setCodigoCarregado] = useState(valorinicial);
  const [categoriaMae, setCategoriaMae] = useState(0);
  const [tipoMae, setTipoMae] = useState('');
  const [tipoEscolhido, setTipoEscolhido] = useState({
    label: '',
    value: ''
  });
  const messageContext = useContext(MessagesContext);
  const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera, MensagemAlerta } = messageContext;
  const [dataset, setDataset] = useState([]);
  const authContext = useContext(AuthContext);
  const { getSessao } = authContext;
  const sessaolocal = getSessao();
  const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null, nome: '' });
  const [dialogoOpenMae, setDialogoOpenMae] = useState({ visible: false, id: null, nome: '', tipo: '', nova_categoria: '' });
  const [novaCategoria, setNovaCategoria] = useState({ value: -1, label: "<Escolha uma Categoria>" });
  const [recarregaTabela, setRecarregaTabela] = useState(false);
  const [recarregaCategoria, setRecarregaCategoria] = useState(false);
  const [openFiltrosAvancados, setOpenFiltrosAvancados] = useState(false);

  const [openMenuConfiguracao, setOpenMenuConfiguracao] = useState(false);
  const [currentTargetBotaoMenu, setCurrentTargetBotaoMenu] = useState(null);
  const [openMensagemModuloAdicional, setOpenMensagemModuloAdicional] = useState(false);

  const baseUrl = `${rotafechada()}categoria`;
  const baseCaption = "Categorias de Receita e Despesa";

  const validaRotaPermissao = async (operacao = "", silencioso = false) => {
    if (
      operacao !== "inserir" &&
      operacao !== "editar" &&
      operacao !== "excluir" &&
      operacao !== "listar"
    ) {
      MensagemErro("Escolha a operação que deseja validar a permissão!");
      return false;
    }

    //validando a permissao
    try {
      await api.get(`${baseUrl}/permissao/${operacao}`);
      return true;
    } catch (error) {
      trataRetorno(error?.response?.data?.erro);
      const msg = error.response?.data?.msg || error;

      if (silencioso === false) {
        MensagemErro(`Erro: ${msg}`);
      }
      return false;
    }
  };

  const handleClickNovaReceita = async () => {
    if ((await validaRotaPermissao("inserir")) === false) {
      return;
    }
    setCodigoCarregado("");
    setCategoriaMae(0);
    setTipoMae('');
    setTipoEscolhido({
      label: "Receita",
      value: "R"
    });
    setAba(1);
  };

  const handleClickNovaDespesa = async () => {
    if ((await validaRotaPermissao("inserir")) === false) {
      return;
    }
    setCodigoCarregado("");
    setCategoriaMae(0);
    setTipoMae('');
    setTipoEscolhido({
      label: "Despesa",
      value: "D"
    }
    );
    setAba(1);
  };

  const handleClickAbrir = (id, tipo) => {
    setCodigoCarregado(id);
    if (tipo == 'D') {
      setTipoEscolhido({
        label: "Despesa",
        value: "D"
      });
    }
    if (tipo == 'R') {
      setTipoEscolhido({
        label: "Receita",
        value: "R"
      });
    }
    abrirRegistro(id, tipo);
  };

  const abrirRegistro = async (id, tipo) => {
    if (!parseInt(id, 10) > 0) return;

    try {
      setCodigoCarregado(id);
      if (tipo == 'D') {
        setTipoEscolhido({
          label: "Despesa",
          value: "D"
        });
      }
      if (tipo == 'R') {
        setTipoEscolhido({
          label: "Receita",
          value: "R"
        });
      }
      setCategoriaMae(0);
      setTipoMae('');
      setAba(1);
    } catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  };

  const handleClickCriaFilho = async (id, tipoMae) => {

    try {
      const retorno = await api.get(`${rotafechada()}contas?categoria_id=${id}`);
      const { count } = retorno.data;

      if (count) {
        MensagemAlerta(`Esta categoria possui lançamentos vinculados
        Para isso, altere os lançamentos da categoria original no financeiro e crie a subcategoria.
        `, 10);
      }
      else {
        setCodigoCarregado("");
        setCategoriaMae(id);
        setTipoMae(tipoMae);
        if (tipoMae == 'D') {
          setTipoEscolhido({
            label: "Despesa",
            value: "D"
          });
        }
        if (tipoMae == 'R') {
          setTipoEscolhido({
            label: "Receita",
            value: "R"
          });
        }

        setAba(1)
      }

    } catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }



  }


  // const handleExcluir = async () => {
  //   setDialogoOpen({ ...dialogoOpen, visible: false });

  //   try {
  //     await api.delete(`${baseUrl}/${dialogoOpen.id || 0}`);
  //     MensagemAviso("Excluído com sucesso!");
  //     setCodigoCarregado("");
  //     setRecarregaTabela(!recarregaTabela);
  //   } catch (error) {
  //     const msg = error.response?.data?.msg || error;
  //     MensagemErro(`Erro: ${msg}`);
  //   }
  // };

  const handleExcluirMoverCategoria = async () => {
    setDialogoOpenMae({ ...dialogoOpen, visible: false, id: null });
    setDialogoOpen({ ...dialogoOpen, visible: false, id: null });
    let dados = {
      id: codigoCarregado,
      nova_categoria: novaCategoria.value
    }
    try {
      await api.post(`${baseUrl}/full/`, dados);
      MensagemAviso("Excluído com sucesso!");
      setCodigoCarregado("");
      setRecarregaTabela(!recarregaTabela);
      setNovaCategoria({ value: -1, label: "<Escolha uma Categoria>" })
    } catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  };

  const handleClickExcluir = async (id, mae, nome, tipo) => {
    if (!((await validaRotaPermissao("excluir")) === true)) {
      return;
    }

    //validar se esse id tem contas vinculadas a ele
    try {


      const retorno = await api.get(`${rotafechada()}categoria/verificaexclusao?id=${id}`);
      const { data } = retorno.data;
      if (data) {
        setRecarregaCategoria(!recarregaCategoria)
        setDialogoOpenMae({ ...dialogoOpenMae, visible: true, id: id, nome: nome, tipo: tipo });
      } else {
        setDialogoOpen({ ...dialogoOpen, visible: true, id, nome: nome });
      }
      setCodigoCarregado(id);
    } catch (error) {

    }
  };

  const validaModulo = async () => {

    MostraTelaEspera('Validando acesso ao módulo');
    try {
      let url = `${rotafechada()}assinatura_modulos/validar?nome=${'FINANCEIRO'}`;
      await api.get(url);
    }
    catch (error) {
      setOpenMensagemModuloAdicional(true)
    }
    FechaTelaEspera();
  }

  useEffect(async () => {
    if (aba === 1) {
      if (parseInt(codigoCarregado, 10) > 0) {
        if ((await validaRotaPermissao("editar")) === false) {
        }
      } else {
        if ((await validaRotaPermissao("inserir")) === false) {
        }
      }
    }
  }, [aba]);

  useEffect(() => {
    validaModulo()
  }, [])


  return (
    <>

<DialogoValidaModulo
        open={openMensagemModuloAdicional}
        title={'Módulo adicional'}
        message={'Módulo Financeiro não contratado, para mais informações entre em contato com nossa equipe de suporte!'}
        handleCancel={() => {
          setOpenMensagemModuloAdicional(false);
          navigate('/desktop');
        }}
        handleConfirm={() => {
          setOpenMensagemModuloAdicional(false);
          navigate('/desktop');
          window.open(packageJson?.urlsuporte.replace('text', 'text=Tenho interesse em contratar o módulo Financeiro para o VidrosWeb📌 '))
        }
        }
      />
      <MenuConfiguracaoModulos
        open={openMenuConfiguracao}
        setOpen={setOpenMenuConfiguracao}
        nomeModulo="CATEGORIA"
        currentTarget={currentTargetBotaoMenu}
      />
      <DashboardLayout>
        <DashboardNavbar />

        <DialogoConfirma
          open={dialogoOpen.visible}
          title={'Confirma Exclusão'}
          color={'error'}
          message={<>Exclui a categoria <strong>{dialogoOpen.nome}</strong> ?</>}
          handleCancel={() => {
            setDialogoOpen({ ...dialogoOpen, visible: false })
          }}
          handleConfirm={handleExcluirMoverCategoria}
        />

        <DialogoConfirmaEscolheNovo
          open={dialogoOpenMae.visible}
          title={"Excluir categoria"}
          color='error'
          id={dialogoOpenMae.id}
          setNovaCategoria={setNovaCategoria}
          recarregaCategoria={recarregaCategoria}
          novaCategoria={novaCategoria}
          tipoEscolhido={dialogoOpenMae.tipo}
          message={<>Para excluir a categoria <strong>"{dialogoOpenMae.nome}"</strong> e suas subcategorias, você precisa enviar os lançamentos existentes para outra categoria:</>}
          handleCancel={() => { 
            setDialogoOpenMae({ ...dialogoOpenMae, visible: false })
            setNovaCategoria({ value: -1, label: "<Escolha uma Categoria>" })
          }}
          handleConfirm={handleExcluirMoverCategoria}
        />

        <MDBox mt={5} mb={9}>
          <Grid container justifyContent="center">
            <Grid item xs={12} lg={12}>
              <MDBox mt={0} mb={0} textAlign="center">
                <MDBox mb={1}>
                  <MDTypography variant="h3" fontWeight="bold">
                    {`${baseCaption}`}
                  </MDTypography>
                </MDBox>
                {/* Botao de Configuracoes (Parametros e Permissoes) */}
                <MDBox width="100%" display="flex" justifyContent="flex-end">
                  <Tooltip title="Configurações e Permissões" placement="top">
                    <IconButton
                      onClick={(event) => {
                        setCurrentTargetBotaoMenu(event.currentTarget);
                        setOpenMenuConfiguracao(true);
                      }}
                    >
                      <SettingsIcon />
                    </IconButton>
                  </Tooltip>
                </MDBox>
              </MDBox>

              <Card>
                <TabPanel value={aba} index={0}>
                  <Tabela //RECEITA
                    openFiltrosAvancados={openFiltrosAvancados}
                    setOpenFiltrosAvancados={setOpenFiltrosAvancados}
                    abrirRegistro={handleClickAbrir}
                    handleClickExcluir={handleClickExcluir}
                    recarregaTabela={recarregaTabela}
                    validaRotaPermissao={validaRotaPermissao}
                    tipo={"R"}
                    handleClickNovaReceita={handleClickNovaReceita}
                    handleClickCriaFilho={handleClickCriaFilho}
                  />
                  <Tabela //DESPESA
                    openFiltrosAvancados={openFiltrosAvancados}
                    setOpenFiltrosAvancados={setOpenFiltrosAvancados}
                    abrirRegistro={handleClickAbrir}
                    handleClickExcluir={handleClickExcluir}
                    recarregaTabela={recarregaTabela}
                    validaRotaPermissao={validaRotaPermissao}
                    tipo={"D"}
                    handleClickNovaDespesa={handleClickNovaDespesa}
                    handleClickCriaFilho={handleClickCriaFilho}
                  />
                </TabPanel>

                <TabPanel value={aba} index={1}>
                  <Cadastro
                    setCodigoAbrir={setCodigoCarregado}
                    codigoAbrir={codigoCarregado}
                    setAba={setAba}
                    tipoEscolhido={tipoEscolhido}
                    tipoMae={tipoMae}
                    categoriaMae={categoriaMae}
                  />
                </TabPanel>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default Categoria;
