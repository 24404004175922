import React, { useState, useEffect, useContext } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import MessagesContext from "hooks/MessagesContext";
import { Grid, Icon, InputAdornment, Tooltip, Divider } from "@mui/material";
import FormField from "componentes/FormField";
import AuthContext from 'hooks/AuthContext';
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AutoCompleteStyled } from "componentes/AutoCompleteStyled";
import { ptBR } from "date-fns/locale";

export default function DialogoTransferencia(props) {

    const { open, title, handleCancel, setCodigoAbrir, codigoAbrir, recarregaTabela, setRecarregaTabela, destinoFatura, valorFatura, data1, data2, descricaoFatura } = props;

    //console.log("destinoFatura", destinoFatura)
    //console.log("valorFatura", valorFatura)
    const messageContext = useContext(MessagesContext);
    const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera, MensagemAlerta, } = messageContext;

    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();

    const [optionBanco, setOptionBanco] = useState([
        { value: -1, label: "<Escolha uma conta de pagamento>" },
    ]);


    //Transferencia
    const [contaOrigem, setContaOrigem] = useState({ value: -1, label: '<Escolha banco/caixa>' });
    const [contaDestino, setContaDestino] = useState({ value: -1, label: '<Escolha banco/caixa>' });
    const [valor, setValor] = useState('');
    const [data, setData] = useState(new Date());
    const [descricao, setDescricao] = useState('');

    const buscaDados = async () => {

        if (!parseInt(props.codigoAbrir, 10) > 0)
            return;

        try {
            MostraTelaEspera();
            const retorno = await api.get(`${rotafechada()}transferencia_banco?id=${props.codigoAbrir}`);
            const { data, count } = retorno.data;

            //console.log("dados transferencia", data)

            if (!count > 0) {
                MensagemErro('Nenhuma informação foi selecionada');
                return;
            }

            if (data[0].empresa_id !== sessaolocal.empresa_id) {
                MensagemErro('Registro de outra empresa!');
                return;
            }
            setContaOrigem({ value: data[0].Origem.id, label: data[0].Origem.nome });
            setContaDestino({ value: data[0].Destino.id, label: data[0].Destino.nome });
            setValor(parseFloat(data[0].valor).toFixed(2));
            setData(data[0].data);
            setDescricao(data[0].historico);

            FechaTelaEspera();

        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }

    }

    const buscaBanco = async () => {
        try {
            const url = `${rotafechada()}banco?order=nome&limit=-1&ativo=S`;
            //console.log("***URL forma de pagamento:", url);
            let retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;
            let opcoesBancoTemp = [{ value: -1, label: '<Escolha banco/caixa>' }];

            data.map((item) => {
                opcoesBancoTemp.push({ value: item.id, label: item.nome });
                if (destinoFatura && (item.id == destinoFatura)) {
                    setContaDestino({ value: item.id, label: item.nome })
                }
            });
            setOptionBanco(opcoesBancoTemp);

            return opcoesBancoTemp;
        } catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    };

    const handleClickGravar = async (e) => {

        let msg = ''
        let retorno;
        if (valor == '' || valor <= 0)
            msg = msg + 'Valor, '

        if (contaOrigem?.value === -1 || null)
            msg = msg + 'Conta origem, '

        if (contaDestino?.value === -1 || null)
            msg = msg + 'Conta destino, '

        if (descricao === '')
            msg = msg + 'Descrição, '

        if (data === '')
            msg = msg + 'Data da transferência, '

        else
            if (msg.length > 0)
                MensagemAlerta(`Campo(s) obrigatório(s): ${msg}`)
            else
                if (valor <= 0)
                    MensagemAlerta('O total transferido deve ser superior a 0')
                else
                    if (contaOrigem?.value == contaDestino?.value)
                        MensagemAlerta('As contas devem ser diferentes')
                    else {

                        try {
                            MostraTelaEspera('Gravando a transferência...');
                            const dados = {
                                empresa_id: sessaolocal.empresa_id || null,
                                banco_origem_id: contaOrigem.value,
                                banco_destino_id: contaDestino.value,
                                data: data,
                                historico: descricao,
                                valor: valor,
                                tipo: 'TRANSFERENCIA'
                            }
                            // console.log(dados);

                            //Bloco de escolho para edição ou criação
                            if (codigoAbrir && parseInt(codigoAbrir, 10) > 0) {
                                //console.log("codigoAbrir", codigoAbrir)
                                retorno = await api.put(`${rotafechada()}transferencia_banco/transferencia/${codigoAbrir}`, dados);
                                setRecarregaTabela(!recarregaTabela);
                            }
                            else {
                                retorno = await api.post(`${rotafechada()}transferencia_banco/transferencia/`, dados);
                                //retorno = await api.post(`${rotafechada()}centro_custo`, dados);
                                //props.setCodigoAbrir(retorno.data.data.id || 0) desativo, depois verificar quais vão ser os efeitos colaterias
                                setRecarregaTabela(!recarregaTabela);


                            }



                            MensagemAviso('Gravado com sucesso!');
                            FechaTelaEspera();
                            handleCancel()
                            limpaImputs()
                        }
                        catch (error) {
                            FechaTelaEspera();
                            const msg = error.response?.data?.msg || error;
                            MensagemErro(`Erro: ${msg}`);
                        }
                    }

    }

    const handleClickPagarFatura = async (e) => {

        let msg = ''
        let retorno;
        if (valor == '' || valor <= 0)
            msg = msg + 'Valor, '

        if (contaOrigem?.value === -1 || null)
            msg = msg + 'Conta origem, '

        if (contaDestino?.value === -1 || null)
            msg = msg + 'Conta destino, '

        if (descricao === '')
            msg = msg + 'Descrição, '

        if (data === '')
            msg = msg + 'Data da transferência, '

        else
            if (msg.length > 0)
                MensagemAlerta(`Campo(s) obrigatório(s): ${msg}`)
            else
                if (valor <= 0)
                    MensagemAlerta('O total transferido deve ser superior a 0')
                else
                    if (contaOrigem?.value == contaDestino?.value)
                        MensagemAlerta('As contas devem ser diferentes')
                    else {

                        try {
                            MostraTelaEspera('Pagando a Fatura...');
                            const dados = {
                                empresa_id: sessaolocal.empresa_id || null,
                                banco_id: contaDestino.value,
                                banco_origem_id: contaOrigem.value,
                                banco_destino_id: contaDestino.value,
                                forma_pagamento_id: 3,
                                data: data,
                                dataInicial: new Date(data1),
                                dataFinal: new Date(data2),
                                valor: valor,
                                descricao: descricao
                            }
                            // console.log('dados necessarios para pagamento de fatura:', dados);
                            //aqui
                            retorno = await api.post(`${rotafechada()}parcelas_conta/pagarfatura`, dados);
                            setRecarregaTabela(!recarregaTabela);

                            MensagemAviso('Gravado com sucesso!');
                            FechaTelaEspera();
                            handleCancel()
                            limpaImputs()
                        }
                        catch (error) {
                            FechaTelaEspera();
                            const msg = error.response?.data?.msg || error;
                            MensagemErro(`Erro: ${msg}`);
                        }
                    }

    }

    const limpaImputs = () => {
        setContaOrigem({ value: -1, label: '<Escolha banco/caixa>' })
        setContaDestino({ value: -1, label: '<Escolha banco/caixa>' })
        setValor(0)
        setDescricao('')
        setData(new Date())
        //setCodigoAbrir('');
        //destinoFatura = -1
        //valorFatura = 0
    }

    useEffect(() => {
        if (!destinoFatura) {
            if (contaOrigem.value > 0 || contaDestino.value > 0)
                setDescricao(`Origem: ${contaOrigem.value > 0 ? contaOrigem.label : ''} / Destino: ${contaDestino.value > 0 ? contaDestino.label : ''}`)
        }
        if (destinoFatura) {
            setDescricao(descricaoFatura)
            setValor(valorFatura)
        }

    }, [contaOrigem, contaDestino])

    useEffect(() => {
        if (open == true) {
            buscaBanco();
            buscaDados();
            if (!destinoFatura) {
                setDescricao('')
                setValor('')
            }
        }

    }, [open, codigoAbrir])

    return (
        <>
            <Dialog
                open={open}
                onClose={() => {
                    handleCancel()
                    limpaImputs()
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullScreen
            >
                <DialogTitle id="alert-dialog-title" sx={{ padding: '5px' }}>{/*HEADER*/}
                    <MDBox width="100%">
                        <MDBox display="flex" justifyContent="space-between">
                            <MDBox display="flex" justifyContent="space-start" flexDirection='column'>
                                <MDTypography fontWeight='bold' ml={5} mt={1} variant='h4'>{title}</MDTypography>
                            </MDBox>
                            <MDBox display="flex" justifyContent="space-end" flexDirection='column'>
                                <Tooltip title="Fechar (Esc)">
                                    <MDButton size="large" sx={{ padding: 0 }} onClick={() => {
                                        handleCancel()
                                        limpaImputs()
                                    }}>
                                        <Icon>close</Icon>
                                    </MDButton>

                                </Tooltip>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                </DialogTitle>


                <DialogContent dividers sx={{ backgroundColor: "#f1f4f9", borderColor: '#7bb5e4' }}>
                    <DialogContentText id="alert-dialog-description">

                        <MDBox ml={2} mr={2} pb={2} bgColor={'#fff'} sx={{ borderRadius: '3px' }}> {/* CONTA */}
                            <Grid container spacing={3} mt={1} pl={2} pr={2}>

                                <Grid item xs={12} sm={12}> {/* TITULO*/}
                                    <MDTypography variant='subtitle2' fontWeight="bold">{'Informações de transferência'}</MDTypography>
                                    <Divider
                                        sx={{ bgcolor: (theme) => theme.palette.divider }}
                                        style={{
                                            marginTop: 5,
                                            marginBottom: 10,
                                            border: "none",
                                            height: 2,
                                            margin: 0,
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4}>{/*Conta de origem*/}
                                    <AutoCompleteStyled
                                        disableClearable //Retira o X (clear)
                                        options={optionBanco}
                                        getOptionLabel={(option) => option.label || ""}
                                        getOptionDisabled={(option) => option === optionBanco[0]}
                                        renderInput={(params) => <FormField {...params} label="Conta de origem:" required={true} />}
                                        isOptionEqualToValue={(option, value) => option.value === value.value}
                                        value={contaOrigem}
                                        onChange={(event, newValue) => {
                                            setContaOrigem(newValue);
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4}> {/* Conta de destino */}
                                    <AutoCompleteStyled
                                        disabled={destinoFatura ? true : false}
                                        disableClearable //Retira o X (clear)
                                        options={optionBanco}
                                        getOptionDisabled={(option) => option === optionBanco[0]}
                                        getOptionLabel={(option) => option.label || ""}
                                        renderInput={(params) => <FormField {...params} label="Conta de destino:" required={true} />}
                                        isOptionEqualToValue={(option, value) => option.value === value.value}
                                        value={contaDestino}
                                        onChange={(event, newValue) => {
                                            setContaDestino(newValue);
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4}> {/* Valor */}
                                    <FormField
                                        autoFocus
                                        required={true}
                                        type="number"
                                        label="Valor"
                                        value={valor}
                                        onChange={(e) => setValor(e.target.value.replace(/[^.,0-9]/g, ''))}
                                        InputProps={{
                                            startAdornment: <InputAdornment sx={{ width: '5%' }} position="start">R$</InputAdornment>,
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4} >{/*Data da transferência*/}
                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                                        <DesktopDatePicker
                                            required={true}
                                            label='Data da transferência'
                                            maxDate={new Date()}
                                            inputFormat="dd/MM/yyyy"
                                            value={data}
                                            onChange={(e) => {
                                                setData(e);
                                            }}
                                            renderInput={(params) => <FormField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>

                                <Grid item xs={12} sm={8}> {/*Descrição*/}
                                    <FormField
                                        required={true}
                                        type="text"
                                        label="Descrição"
                                        value={descricao}
                                        onChange={(e) => setDescricao(e.target.value)}
                                        rows={1}
                                        multiline={true}
                                        InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                                        inputProps={{ maxLength: 255 }} //limita a quantidade de caracteres do campo
                                    />
                                </Grid>

                            </Grid>
                        </MDBox>
                    </DialogContentText>
                </DialogContent>

                <DialogActions sx={{ padding: '7px', margin: 0.5 }}> {/*FOOTER*/}
                    <MDBox width="100%">
                        <MDBox width="100%" display="flex" justifyContent="space-between">
                            <MDButton onClick={() => {
                                handleCancel()
                                limpaImputs()
                            }} variant="gradient" type="button" color="secondary">
                                voltar
                            </MDButton>
                            <MDButton
                                //disabled={destinoFatura ? true : false}
                                onClick={destinoFatura ? handleClickPagarFatura : handleClickGravar}
                                variant="gradient"
                                type="submit"
                                //type="button" 
                                color="info">
                                {'salvar'}
                            </MDButton>
                        </MDBox>
                    </MDBox>
                </DialogActions>
            </Dialog>
        </>
    );
}