import React, { useEffect, useState } from 'react';
import Grid from "@mui/material/Grid";
import { useForm, Controller } from 'react-hook-form'
import { useConfigForm } from '../../hooks/ConfigForm'
import PesquisaDinamica from 'componentes/PesquisaDinamica';
import FormField from 'componentes/FormField';
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import { Autocomplete, Checkbox, FormControl, Radio, Switch, TextField } from '@mui/material';
import MDInput from 'components/MDInput';
import MDTypography from 'components/MDTypography';
import SearchIcon from '@mui/icons-material/Search';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';
import { ptBR } from "date-fns/locale";

export default function GenericForm(props) {

    const { permiteAlteracao, dataset, funcaoGravar } = props;

    const { configForm } = useConfigForm();
    let defaultValues = {};
    configForm.forEach((item) => {
        defaultValues[item.name] = item.value || '';
    })

    const { handleSubmit, control, setValue, getValues } = useForm({ defaultValues });
    const [confPesquisaDinamica, setConfPesquisaDinamica] = useState({});
    const [labelNome, setLabelNome] = useState([]);

    const handleClickGravar = async (data) => {

        if (!funcaoGravar) {
            alert('Função de gravação não configurada');
            return;
        }

        //alert(JSON.stringify(data));

        //antes de enviar para a funcao que grava vou tratar os campos autocomplete 
        //pois eles devolvem um objeto, preciso enviar apenas o "value" do objeto seleciondo

        configForm.forEach((item) => {
            if (item.type === 'autocomplete') {
                data[item.name] = data[item.name].value ? data[item.name].value : '';
            }
        })

        const retorno = await funcaoGravar(data);
        if (!retorno) {
            return;
        }

    }

    const onSubmit = (data) => {
        handleClickGravar(data);
    }

    const handleFuncaoSelecaoPesquisaDinamica = (codigo, datasetPesquisa, campoauxiliar) => {
        /* Essa funcao é chamada pelo componente de pesquisa dinamica quando um registro é escolhido
        ele retorna os campos que foram configurados*/

        const index = parseInt(campoauxiliar, 10)

        setConfPesquisaDinamica({ ...confPesquisaDinamica, open: false });

        if (index >= 0) {

            //alterando o input correspondente ao click            
            setValue(configForm[index].name, codigo);

            if ((configForm[index].confPesquisa.campoNomeLabel) && (configForm[index].confPesquisa.campoNomeLabel !== '')) {
                //Alterando a labelnome do input correspondente ao click
                const vetortemp = [...labelNome];
                //vetortemp[index] = codigo2;
                vetortemp[index] = datasetPesquisa[configForm[index].confPesquisa.campoNomeLabel];
                setLabelNome([...vetortemp]);
            }
        }
    }

    function updateLabel(index = -1, valor = '') {
        //chamado pelo "pai" que criou o cadastro
        //via callback do evento onblur

        //Usado em campos F9 (input + label)

        //A funcao Onblur faz uma chamada a uma funcao do Pai
        //que ira validar o valor do edit e buscar o nome 
        //no back, apos receber o nome chamara essa funcao que atualizará
        //o state de labels correspondente
        if (index >= 0) {
            const vetortemp = [...labelNome];
            vetortemp[index] = valor;
            setLabelNome([...vetortemp]);
        }
    }

    const handleClicktextButton = (index = -1) => {
        /*Essa função é chamada pelo o botao de pesquisa do input
        o Index é o indice no "vetor" de configuracao de campos (configForm)
        */

        if (!(index >= 0)) {
            alert('Campo não configurado');
            return;
        }

        if (configForm[index].disabled) {//edit esta desativado
            return;
        }

        //o campo tem que ter um objeto "confPesquisa" caso nao tenha nao tem como chamar a pesquisa
        if (typeof configForm[index].confPesquisa === 'undefined') {
            alert('Pesquisa não configurada para esse campo');
            return;
        }

        //copia as propriedades default do modelo
        //depois insere as que  foram configuradas pelo cadastro que esta usando o GenericForm
        //por fim seta o ponteiro com a funcao que ser achamada ao selecionar um registro
        //para uma funcao local
        setConfPesquisaDinamica({
            ...configForm[index].confPesquisa,
            open: true,
            campoAuxiliar: index,
        });
    }

    const handleBlurtextButton = async (index = -1) => {
        /*Essa função é chamada no onexit do edit
         o Index é o indice no "vetor" de configuracao de campos (configForm)
        */

        if (!(index >= 0)) {
            return;
        }

        if (configForm[index].disabled) {//edit esta desativado
            return;
        }

        //o campo tem que ter uma propriedade com "onBlur" 
        if (!configForm[index].onBlur) {
            return;
        }

        try {
            await configForm[index].onBlur(index, getValues(configForm[index].name), updateLabel, setValue, getValues);
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleClickLabeltextButton = (index = -1) => {
        /*Essa função é chamada pelo label do input textButton (que aceita pesquisa)
        o Index é o indice no "vetor" de configuracao de campos (configForm)
        Ao clicar na label é chamada umanova janela com a rota indicada
        */

        if (!(index >= 0)) {
            alert('Campo não configurado');
            return;
        }

        //o campo tem que ter um objeto "confPesquisa" caso nao tenha nao tem como chamar a pesquisa
        if (
            (typeof configForm[index].confPesquisa === 'undefined') ||
            (!configForm[index].confPesquisa.rotachamadaclicklabel)
        ) {

            return;
        }

        let rotachamada = configForm[index].confPesquisa.rotachamadaclicklabel;

        if (rotachamada !== '') {
            //substituindo o parametro pelo codigo que esta no edit
            rotachamada = rotachamada.replace(':ID', getValues(configForm[index].name));
            window.open(rotachamada, "_blank");
        }
    }

    useEffect(() => {
        //Alterando a labelnome do input correspondente ao click
        const vetortemp = [...labelNome];

        if ((typeof dataset) === 'object') {
            //se foram enviados dados, altero os fields pelo hook setValue do react-hook-forms
            configForm?.forEach((conf, index) => {

                //se o campo existe no dataset tmbm, altero o valor dele
                if (dataset.hasOwnProperty(conf.name)) {

                    if (conf.type === 'autocomplete') {
                        //o autocomplete recebe um objeto {value,label} entao
                        //preciso encontrar o value correspondente e setar ele
                        conf.items.forEach((item) => {
                            if (item.value === dataset[conf.name]) {
                                setValue(conf.name, item);
                            }
                        })
                    }
                    else setValue(conf.name, dataset[conf.name]);

                    if (conf.type === 'textButton') {
                        //se o tipo for textButton (uso para f9 de chaves estrangeiras)
                        //guardo em um vetor de states na mesma posicao do index
                        //o nome do cadastro corresponde (caso esse campo exista no datasetenviado)
                        vetortemp[index] = dataset[`LABELNOME_${conf.name}`];
                    }
                }
            });

            setLabelNome([...vetortemp]);
        }
    }, [dataset]);
    
    return (
        <div>

            <PesquisaDinamica
                open={confPesquisaDinamica.open || false}
                handleClose={
                    () => setConfPesquisaDinamica({ ...confPesquisaDinamica, open: false })
                }
                //setOpen={setOpenPesquisaDinamica}
                handleClick={handleFuncaoSelecaoPesquisaDinamica}
                campoRetorno={confPesquisaDinamica.campoRetorno}
                titulo={confPesquisaDinamica.titulo}
                urlBase={confPesquisaDinamica.urlBase}
                limit={confPesquisaDinamica.limit || 10}
                colunas={confPesquisaDinamica.colunas}
                camposFiltro={confPesquisaDinamica.camposFiltro}
                campoAuxiliar={confPesquisaDinamica.campoAuxiliar}
            />

            <form onSubmit={handleSubmit(onSubmit)}>

                <Grid container spacing={2}>

                    {
                        configForm?.map((cfg, index) => (
                            cfg.type === 'text' ?
                                <Grid item xs={cfg.xs} sm={cfg.sm} key={`GridItem_${cfg.id}`}>

                                    <Controller
                                        name={cfg.name}
                                        control={control}
                                        render={({ field }) => 
                                        <FormField
                                            {...field}
                                            key={cfg.id}
                                            id={cfg.id}
                                            autoComplete={cfg.autoComplete}
                                            type={cfg.type}
                                            label={cfg.label}
                                            rows={cfg.rows || 1}
                                            multiline={cfg.multiline || false}
                                            required={cfg.required || false}

                                            disabled={permiteAlteracao === false ? true : (cfg.readOnly ? true : cfg.disabled ? true : false)}

                                            inputProps={{
                                                ...field.inputProps,
                                                maxLength: cfg.maxLength || 256,
                                                placeholder: cfg.placeholder || '',
                                                onKeyUp: (e) => {
                                                },
                                                onBlur: (e) => {
                                                    e.preventDefault();
                                                    handleBlurtextButton(index);
                                                },
                                            }}

                                        />}
                                    />
                                </Grid>
                                :
                                cfg.type === 'autocomplete' ?
                                    <Grid item xs={cfg.xs} sm={cfg.sm} key={cfg.id}>
                                        <Controller
                                            key={cfg.id}
                                            name={cfg.name}
                                            control={control}

                                            render={({ field: { onChange, value } }) => (
                                                <AutoCompleteStyled
                                                    id={cfg.id}
                                                    options={cfg.items}
                                                    getOptionLabel={(option) => option.label}
                                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                                    renderInput={(params) => <FormField {...params} label={cfg.label} />}
                                                    onChange={(_, data) => {
                                                        onChange(data);
                                                        return data;
                                                    }}
                                                    disabled={permiteAlteracao === false ? true : (cfg.readOnly ? true : cfg.disabled ? true : false)}
                                                    value={value}
                                                />
                                            )}
                                        />

                                    </Grid>
                                    :
                                    cfg.type === 'textButton' ?
                                        <Grid item xs={cfg.xs} sm={cfg.sm} key={`GridTextButton${cfg.id}`}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={2} sm={2} key={`GridItemTextButton${cfg.id}`}>
                                                    <Controller
                                                        name={cfg.name}
                                                        control={control}
                                                        render={({ field }) => <FormField
                                                            {...field}
                                                            key={cfg.id}
                                                            id={cfg.id}
                                                            autoComplete={cfg.autoComplete}
                                                            type={cfg.type}
                                                            label={cfg.label}
                                                            rows={cfg.rows || 1}
                                                            multiline={cfg.multiline || false}
                                                            required={cfg.required || false}
                                                            disabled={permiteAlteracao === false ? true : (cfg.readOnly ? true : cfg.disabled ? true : false)}

                                                            inputProps={{
                                                                onKeyUp: (e) => {
                                                                    e.preventDefault();
                                                                    if (e.key === 'F9') {
                                                                        handleClicktextButton(index);
                                                                    }

                                                                },
                                                                onBlur: (e) => {
                                                                    e.preventDefault();
                                                                    handleBlurtextButton(index);
                                                                },
                                                            }}

                                                        />}
                                                    />
                                                </Grid>
                                                <Grid item xs={1} sm={1} key={`GridButtonTextButton${cfg.id}`}>
                                                    <SearchIcon key={`ButtonTextButton${cfg.id}`} sx={{ cursor: 'pointer' }}
                                                        disabled={permiteAlteracao === false ? true : (cfg.readOnly ? true : cfg.disabled ? true : false)}
                                                        onClick={() => {
                                                            handleClicktextButton(index);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={cfg.xs - 3} sm={cfg.sm - 3} key={`LabelTextButton${cfg.id}`}>
                                                    <MDTypography variant="button" sx={{ cursor: 'pointer' }}
                                                        disabled={permiteAlteracao === false ? true : (cfg.readOnly ? true : cfg.disabled ? true : false)}
                                                        onClick={() => {
                                                            handleClickLabeltextButton(index);
                                                        }}
                                                    >
                                                        {labelNome[index] ? labelNome[index] : '....'}
                                                    </MDTypography>
                                                </Grid>

                                            </Grid> {/*Container da linha do EditButton F9*/}
                                        </Grid>
                                        :
                                        cfg.type === 'date' ?
                                            <Grid item xs={cfg.xs} sm={cfg.sm} key={`GridItem_${cfg.id}`}>
                                                <Controller
                                                    key={cfg.id}
                                                    name={cfg.name}
                                                    control={control}

                                                    render={({ field: { onChange, value } }) => (
                                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                                                            <DesktopDatePicker
                                                                label={cfg.label}
                                                                inputFormat="dd/MM/yyyy"
                                                                value={value}
                                                                onChange={onChange}
                                                                renderInput={(params) => <TextField {...params} />}
                                                                disabled={permiteAlteracao === false ? true : (cfg.readOnly ? true : cfg.disabled ? true : false)}
                                                            />
                                                        </LocalizationProvider>
                                                    )}
                                                />
                                            </Grid>
                                            :
                                            cfg.type === 'time' ?
                                                <Grid item xs={cfg.xs} sm={cfg.sm} key={`GridItem_${cfg.id}`}>
                                                    <Controller
                                                        key={cfg.id}
                                                        name={cfg.name}
                                                        control={control}
                                                        render={({ field: { onChange, value } }) => (
                                                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                                                                <TimePicker
                                                                    label={cfg.label}
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    renderInput={(params) => <TextField {...params} />}
                                                                    ampm={false}
                                                                    disabled={permiteAlteracao === false ? true : (cfg.readOnly ? true : cfg.disabled ? true : false)}
                                                                />
                                                            </LocalizationProvider>
                                                        )}
                                                    />
                                                </Grid>
                                                :
                                                cfg.type === 'datetime' ?
                                                    <Grid item xs={cfg.xs} sm={cfg.sm} key={`GridItem_${cfg.id}`}>
                                                        <Controller
                                                            key={cfg.id}
                                                            name={cfg.name}
                                                            control={control}
                                                            render={({ field: { onChange, value } }) => (
                                                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                                                                    <DateTimePicker
                                                                        inputFormat="dd/MM/yyyy HH:mm"
                                                                        ampm={false}
                                                                        disabled={permiteAlteracao === false ? true : (cfg.readOnly ? true : cfg.disabled ? true : false)}
                                                                        label={cfg.label}
                                                                        value={value}
                                                                        onChange={onChange}
                                                                        renderInput={(params) => <TextField {...params} />}

                                                                    />
                                                                </LocalizationProvider>
                                                            )}
                                                        />
                                                    </Grid>
                                                    :
                                                    cfg.type === 'switch' ?
                                                        <Grid item xs={cfg.xs} sm={cfg.sm} key={`GridItem_${cfg.id}`}>
                                                            <MDTypography variant="caption">
                                                                {cfg.label}

                                                                <Controller
                                                                    key={cfg.id}
                                                                    name={cfg.name}
                                                                    control={control}
                                                                    render={({ field: { onChange, value } }) => (
                                                                        <Switch
                                                                            label={cfg.label}
                                                                            disabled={permiteAlteracao === false ? true : (cfg.readOnly ? true : cfg.disabled ? true : false)}
                                                                            //value={value ? value : false}
                                                                            checked={value ? value : false}
                                                                            onChange={(e) => setValue(cfg.name, e.target.checked)}
                                                                            inputProps={{ 'aria-label': 'controlled' }}

                                                                        />

                                                                    )}
                                                                />
                                                            </MDTypography>
                                                        </Grid>
                                                        :
                                                        cfg.type === 'checkbox' ?
                                                            <Grid item xs={cfg.xs} sm={cfg.sm} key={`GridItem_${cfg.id}`}>
                                                                <MDTypography variant="caption">
                                                                    {cfg.label}
                                                                    <Controller
                                                                        key={cfg.id}
                                                                        name={cfg.name}
                                                                        control={control}
                                                                        render={({ field: { onChange, value } }) => (
                                                                            <Checkbox
                                                                                disabled={permiteAlteracao === false ? true : (cfg.readOnly ? true : cfg.disabled ? true : false)}
                                                                                checked={value ? (value === true ? true : false) : false}
                                                                                onChange={(e) => setValue(cfg.name, e.target.checked)}
                                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                            />

                                                                        )}
                                                                    />
                                                                </MDTypography>

                                                            </Grid>
                                                            :
                                                            cfg.type === 'radiogroup' ?
                                                                <FormControl sx={{ mt: 1, ml: 2 }}>
                                                                    <MDTypography variant="button" >{cfg.label}</MDTypography>
                                                                    <div>
                                                                        <Controller
                                                                            key={cfg.id}
                                                                            name={cfg.name}
                                                                            control={control}
                                                                            render={({ field: { onChange, value } }) => (
                                                                                cfg.items.map((item) => {
                                                                                    return (
                                                                                        <MDTypography variant="caption" >{item.label}
                                                                                            <Radio
                                                                                                checked={value === item.value}
                                                                                                onChange={onChange}
                                                                                                value={item.value}
                                                                                                name={`radio-buttons-${cfg.id}-${index}`}
                                                                                                inputProps={{ 'aria-label': 'A' }}
                                                                                                disabled={permiteAlteracao === false ? true : (cfg.readOnly ? true : cfg.disabled ? true : false)}
                                                                                            />
                                                                                        </MDTypography>
                                                                                    )

                                                                                })


                                                                            )}
                                                                        />
                                                                    </div>
                                                                </FormControl>
                                                                : false
                        ))
                    }
                </Grid>

                <MDBox p={2}>
                    <MDBox mt={3} width="100%" display="flex" justifyContent="flex-end">
                        <MDButton
                            variant="gradient"
                            color="info"
                            disabled={funcaoGravar ? false : true}
                            type="submit"
                        >Gravar</MDButton>
                    </MDBox>
                </MDBox>
            </form>
        </div>
    )
}

/*
Arquivo exemplo de configuracao

export const configTextField = [


    { xs: 12, sm: 3, autoComplete: 'id', id: 'id', name: 'id', label: 'ID', type: 'text', value: '', readOnly: true, register: {}, disabled: true },
    { xs: 12, sm: 12, autoComplete: 'nome', id: 'nome', name: 'nome', label: 'Nome', type: 'text', value: '', readOnly: false, register: {}, disabled: false },
    { xs: 12, sm: 6, autoComplete: 'referencia', id: 'referencia', name: 'referencia', label: 'Referência', type: 'text', value: '', readOnly: false, register: {}, disabled: false },
    {
        xs: 12, sm: 6, autoComplete: 'ativo', id: 'ativo', name: 'ativo', label: 'Ativo', type: 'autocomplete',
        readOnly: false,
        register: {},
        disabled: false,
        value: { value: 'S', label: 'Sim' },
        items: [
            { value: 'S', label: 'Sim' },
            { value: 'N', label: 'Não' },

        ]
    },
    {
        xs: 12, sm: 12, autoComplete: 'grupoproduto', id: 'grupoproduto', name: 'grupoproduto', label: 'Grupos Produtos', type: 'textButton', value: '', readOnly: false, register: {}, disabled: false,
        rotachamadaclicklabel: ''
    },
    { xs: 12, sm: 12, autoComplete: 'data', id: 'data', name: 'data', label: 'Data', type: 'date', value: '', readOnly: false, register: {}, disabled: false },
    { xs: 12, sm: 12, autoComplete: 'hora', id: 'hora', name: 'hora', label: 'Hora', type: 'time', value: '', readOnly: false, register: {}, disabled: false },
    { xs: 12, sm: 12, autoComplete: 'datahora', id: 'datahora', name: 'datahora', label: 'Data/Hora', type: 'datetime', value: '', readOnly: false, register: {}, disabled: false },
    { xs: 12, sm: 4, autoComplete: 'switch', id: 'switch', name: 'switch', label: 'Switch', type: 'switch', value: '', readOnly: false, register: {}, disabled: false },
    { xs: 12, sm: 8, autoComplete: 'checkbox', id: 'checkbox', name: 'checkbox', label: 'checkbox', type: 'checkbox', value: '', readOnly: false, register: {}, disabled: false },
    {
        xs: 12, sm: 12, autoComplete: 'radiogroup', id: 'radiogroup', name: 'radiogroup', label: 'radiogroup', type: 'radiogroup', value: '', readOnly: false, register: {}, disabled: false,
        items: [
            { value: 'S', label: 'Sim' },
            { value: 'N', label: 'Não' },
        ]
    },
]

**Exemplo de configuracao de campos F9**


  configtext.map((conf, index) => {

    let confmodelo = { ...confPesquisaDinamicaInitialState }

    if (conf.name === 'grupoproduto') {
      const modelocoluna = {
        id: '',
        numeric: false,
        disablePadding: true,
        label: ''
      };
      confmodelo = {
        open: false,
        titulo: 'Pesquisa de Grupos de Produtos',
        urlBase: `${rotafechada()}grupos_produtos`,
        campoRetorno: 'id',
        campoNomeLabel:'nome',
        colunas: [
          { ...modelocoluna, id: 'id', label: 'Id' },
          { ...modelocoluna, id: 'nome', label: 'Nome' },
          { ...modelocoluna, id: 'referencia', label: 'Referência' },
          { ...modelocoluna, id: 'classificacao', label: 'Classificação' },
          { ...modelocoluna, id: 'ativo', label: 'Ativo' },
        ],
        camposFiltro: [{ id: 'nome', label: 'Nome' }, { id: 'id', label: 'Id' }],
        campoAuxiliar: '',
        //ao clicar na label a rota que ele vai chamar para abrir a janela
        //o :ID sera substitudo pelo valor que esteja no input correspondente do campo
        rotachamadaclicklabel: '/pages/produtos/grupos-produtos?id=:ID'
      }

      conf.confPesquisa = { ...confmodelo }
      conf.onBlur = handleOnBlurGrupoProduto
    }

    return conf;
  })

  **Exemplo de funcao OnBlur**

  async function handleOnBlurGrupoProduto(index = -1, valorinput = '', cbUpdateLabel) {
      //chamo uma rota para pegar o nome correspondente no back 
      //index = indice do vetor de configuracao
      //valorinput = valor atual que esta no input que sofreu o evento OnBlur
      //cbUpdateLabel é a funcaocallback que atualiza um state[] para atualizar a label
      //que fica junto do input
      //Assinatura da cbUpdateLabel(indice,textoparaatualiar)

      if (index < 0) {
          return;
      }

      try {
          if (valorinput !== '') {
              const retorno = await api.get(`${rotafechada()}grupos_produtos?id=${valorinput}`);
              cbUpdateLabel(index, retorno?.data?.data[0]?.nome || '');
          }
          else cbUpdateLabel(index, '');

      }
      catch (e) {
          console.log(e);
          const msgerro = e?.response?.data?.msgerro || '';
          MensagemErro(`Erro: ${msgerro}`);
          return false;
      }

  }


*/