import React, { useState, useEffect, useContext } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import {
  Box, Grid,
  TextField,
  Button,
  MenuItem,
  IconButton,
  Typography,
  Tooltip
} from "@mui/material";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { rotafechada } from "commons/urlconf";
import MessagesContext from "hooks/MessagesContext";
import api from "commons/api";
import TabelaPadrao from "componentes/TabelaPadrao";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { AutoCompleteStyled } from "componentes/AutoCompleteStyled";
import FormField from "componentes/FormField";
import AuthContext from "hooks/AuthContext";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import { removeZeros } from "commons/utils";
import LinkIcon from '@mui/icons-material/Link';
import DialogoConfirma from "componentes/DialogoConfirma";
import MenuConfiguracaoModulos from "componentes/MenuConfiguracaoModulos";
import SettingsIcon from '@mui/icons-material/Settings';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';



export function Anexos(props) {

  const {
    open, setOpen,
    conta_id, parcela_conta_id, lancamento_parcela_id, transferencia_banco_id,
    venda_id,
    pessoa_id,
    title } = props;

  const messageContext = useContext(MessagesContext);
  const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera, MensagemAlerta } = messageContext;

  //TABELA
  const [colunasTable, setColunasTable] = React.useState([]);
  const [linhas, setLinhas] = React.useState([]);
  const [countAll, setCountAll] = React.useState(0);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('id');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });
  const [action, setAction] = useState(false);
  const [currentTargetBotaoMenu, setCurrentTargetBotaoMenu] = useState(null);
  const [openMenuConfiguracao, setOpenMenuConfiguracao] = useState(false);


  const [parametros, setParametros] = useState({
    tamanho_maximo_kb: 0,
    tipos_permitidos: '',
    nome_tipos_permitidos: ''
  })

  const truncateUrl = (url) => {
    if (url.length <= 50) {
      return url; // Retorna a URL completa se for menor ou igual a 50 caracteres
    }
    return url.slice(0, 50) + "..."; // Trunca e adiciona "..."
  };

  const buscaDados = async () => {
    try {
      MostraTelaEspera();

      let url = `${rotafechada()}anexos`;
      let contamodulos = 0;
      let retorno

      //primeiro pego os parametros de tamanho maximo e tipos permitidos para bloquear já no front o envio
      retorno = await api.get(`${url}/parametros`);
      console.log(retorno.data.data);
      setParametros(retorno.data.data);


      if (conta_id > 0) {
        url = `${url}${contamodulos == 0 ? '?' : '&'}conta_id=${conta_id}`;
        contamodulos++;
      }
      if (parcela_conta_id > 0) {
        url = `${url}${contamodulos == 0 ? '?' : '&'}parcela_conta_id=${parcela_conta_id}`;
        contamodulos++;
      }

      if (lancamento_parcela_id > 0) {
        url = `${url}${contamodulos == 0 ? '?' : '&'}lancamento_parcela_id=${lancamento_parcela_id}`;
        contamodulos++;
      }

      if (transferencia_banco_id > 0) {
        url = `${url}${contamodulos == 0 ? '?' : '&'}transferencia_banco_id=${transferencia_banco_id}`;
        contamodulos++;
      }

      if (venda_id > 0) {
        url = `${url}${contamodulos == 0 ? '?' : '&'}venda_id=${venda_id}`;
        contamodulos++;
      }

      if (pessoa_id > 0) {
        url = `${url}${contamodulos == 0 ? '?' : '&'}pessoa_id=${pessoa_id}`;
        contamodulos++;
      }

      if (page > 0) {
        url = `${url}${contamodulos == 0 ? '?' : '&'}offset=${page * rowsPerPage}`;
      }

      if (orderBy) {
        url = `${url}${contamodulos == 0 ? '?' : '&'}order=${orderBy}`;
        if (order) {
          url = `${url}${contamodulos == 0 ? '?' : '&'}direction=${order}`;
        }
      }

      url = `${url}${contamodulos == 0 ? '?' : '&'}limit=${rowsPerPage}`;


      retorno = await api.get(url);

      const { data, count, countAll } = retorno.data;


      const colunasTabletemp = [
        // {
        //     id: 'id',
        //     numeric: false,
        //     disablePadding: true,
        //     label: 'id',
        //     disableOrder: true
        // },
        {
          id: 'descricao',
          numeric: false,
          disablePadding: true,
          label: 'Descrição',
          disableOrder: true
        },
        {
          id: 'tipo',
          numeric: false,
          disablePadding: true,
          label: 'Tipo',
          disableOrder: true
        },
        {
          id: 'nome_original',
          numeric: false,
          disablePadding: true,
          label: 'Nome',
          disableOrder: true
        },
        {
          id: 'tamanho_kb',
          numeric: true,
          disablePadding: true,
          label: 'Tamanho(Kb)',
          disableOrder: true
        },
        {
          id: 'opcoes',
          numeric: false,
          disablePadding: true,
          label: 'Opções',
          disableOrder: true
        },
      ]
      //mapeando os dados para a "rows"
      let dadostemp = [];
      data.map((item, index) => {
        dadostemp.push({
          id: item.id,
          descricao: item.descricao,
          tipo: item.tipo,
          nome_original: item.tipo == 'arquivo' ? item.nome_original : truncateUrl(`${item.link}`),
          tamanho_kb: removeZeros(item.tamanho_kb),
          opcoes: <>
            {item.tipo === 'arquivo' ?
              <MDButton
                variant="outlined"
                color="info"
                size="small"
                borderRadius="sm"
                onClick={(e) => {
                  handleClickDownload(item.id);
                }}
                sx={{ borderRadius: '4px', height: '25px', minHeight: '12px', marginRight: '5px' }}
                disableElevation

              >
                <CloudDownloadIcon />
              </MDButton>
              :

              <MDButton
                variant="outlined"
                color="info"
                size="small"
                borderRadius="sm"
                onClick={(e) => {
                  handleClickLink(item.id);
                }}
                sx={{ borderRadius: '4px', height: '25px', minHeight: '12px', marginRight: '5px' }}
                disableElevation

              >
                <LinkIcon />
              </MDButton>
            }
            <MDButton
              variant="outlined"
              color="info"
              size="small"
              borderRadius="sm"
              onClick={(e) => handleClickDelete(item.id, item.descricao)}
              sx={{ borderRadius: '4px', height: '25px', minHeight: '12px' }}
              disableElevation

            >
              <DeleteIcon />
            </MDButton>



          </>
        })
      })

      setColunasTable([...colunasTabletemp]);
      setLinhas(dadostemp);
      setCountAll(countAll);

      FechaTelaEspera()
    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleClickDownload = async (id) => {


    try {
      MostraTelaEspera();

      let url = `${rotafechada()}anexos`;
      let retorno;

      //primeiro pego os parametros de tamanho maximo e tipos permitidos para bloquear já no front o envio
      retorno = await api.get(`${url}/download/${id}`);
      const { urltemporaria, nome_original } = retorno.data.data;
      console.log(retorno);

      //com o codigo abaixo eu consigo renomear o arquivo mas quando o arquivo nao existe no S3 ele quebra o App porque retorna um XML 
      // const anchor = document.createElement("a");
      // anchor.href = urltemporaria;
      // anchor.download = nome_original; // Nome do arquivo
      // document.body.appendChild(anchor);
      // anchor.click();
      // document.body.removeChild(anchor);

      //neste codigo ele abre uma nova Aba e faz o download nela, porem nao conseguimos trocar o nome do arquivo
      const newTab = window.open(urltemporaria, "_blank");


      FechaTelaEspera()
    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
      return;
    }
  };

  const handleClickLink = async (id) => {

    try {
      MostraTelaEspera();

      let url = `${rotafechada()}anexos`;
      let retorno;
      let link;

      //primeiro pego os parametros de tamanho maximo e tipos permitidos para bloquear já no front o envio
      retorno = await api.get(`${url}?id=${id}`);
      const dados = retorno.data;

      if (!(dados.count > 0)) {
        MensagemErro('Anexo não encontrado');
        return;
      }

      link = dados.data[0].link;
      console.log(retorno);
      const newTab = window.open(link, "_blank");
      FechaTelaEspera();
    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
      return;
    }
  };

  const handleClickDelete = async (id, nome) => {

    const message = `Certeza que deseja excluir o anexo ${id}-${nome}?`;

    setDialogoOpen({ ...dialogoOpen, visible: true, message, id });

  }

  const deleteAnexo = async () => {

    const id = dialogoOpen.id || 0;
    setDialogoOpen({ ...dialogoOpen, visible: false });

    try {
      MostraTelaEspera();

      let url = `${rotafechada()}anexos`;
      let retorno;
      retorno = await api.delete(`${url}/${id}`);
      FechaTelaEspera();
      buscaDados();
    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
      return;
    }
  };

  const handleClose = () => {
    setOpen(false);
  }

  useEffect(() => {
    if (open) {
      buscaDados();
    }
  }, [open]);

  useEffect(() => {
    if (open)
      buscaDados();
  }, [page, rowsPerPage, order, orderBy])

  return (
    <>
      {dialogoOpen.visible &&
        <DialogoConfirma
          open={dialogoOpen.visible || false}
          title={'Confirma Exclusão'}
          color={'error'}
          message={dialogoOpen.message ? dialogoOpen.message : 'Certeza que deseja excluir?'}
          handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
          handleConfirm={deleteAnexo}
        />
      }

      <MenuConfiguracaoModulos
        open={openMenuConfiguracao}
        setOpen={setOpenMenuConfiguracao}
        nomeModulo='ANEXOS'
        currentTarget={currentTargetBotaoMenu}
        setAction={setAction}
      />


      <Dialog
        fullWidth={true}
        maxWidth={'xl'}
        open={open || false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"

      >
        <DialogTitle id="alert-dialog-title">

          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                {/* Ícone de fechar estilizado como botão circular */}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="Fechar"
                    sx={{
                      border: '1px solid', // Borda fina
                      borderColor: 'grey.400', // Cor da borda
                      borderRadius: '50%', // Forma circular
                      width: 36, // Largura do botão
                      height: 36, // Altura do botão (quadrado perfeito)
                      display: 'flex', // Centralizar o ícone
                      alignItems: 'center', // Centralizar o ícone verticalmente
                      justifyContent: 'center', // Centralizar o ícone horizontalmente
                      color: 'text.primary', // Cor do ícone
                      '&:hover': {
                        backgroundColor: 'grey.200', // Cor de fundo ao passar o mouse
                      },
                    }}
                  >
                    <CloseIcon />
                  </IconButton>

                  {/* Ícone de anexar e texto */}
                  <AttachFileIcon sx={{ color: 'info.main', ml: 2, mr: 1 }} />
                  <MDTypography variant="h3" fontWeight="bold" >
                    {title || 'Anexos'}
                  </MDTypography>
                </Box>

                {/* Ícone de configurações à direita */}
                <Box>
                  <Tooltip title="Configurações e Permissões" placement="top">
                    <IconButton
                      onClick={(event) => {
                        setCurrentTargetBotaoMenu(event.currentTarget);
                        setOpenMenuConfiguracao(true);
                      }}
                      aria-label="Configurações"
                    >
                      <SettingsIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Toolbar>
          </AppBar>


        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">

            <Grid container spacing={2} mt={1} pl={2} pr={2}>
              <Grid item xs={12} sm={12}>  {/* Linha de Inserção */}
                <FormAnexo

                  recarregaTabela={buscaDados}

                  conta_id={conta_id}
                  parcela_conta_id={parcela_conta_id}
                  lancamento_parcela_id={lancamento_parcela_id}
                  transferencia_banco_id={transferencia_banco_id}
                  venda_id={venda_id}
                  pessoa_id={pessoa_id}

                  parametros={parametros}

                />

              </Grid>

              <Grid item xs={12} sm={12}>  {/* TABELA*/}
                {
                  <TabelaPadrao
                    colunas={colunasTable}
                    linhas={linhas}
                    countAll={countAll}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    order={order}
                    setOrder={setOrder}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                  />

                  //:

                  //<MDTypography variant='caption'>{'Não há informações de lançamentos para esta parcela.'}</MDTypography>

                }
              </Grid>
            </Grid>


          </DialogContentText>
        </DialogContent>

        <DialogActions>
          {/* <MDButton
            onClick={handleCancel}
            variant="outlined"
            color="secondary">
            Fechar
          </MDButton> */}

          {/* <MDButton
            onClick={handleClose}
            variant="gradient"
            color={'info'}
            sx={{ height: "100%" }}>
            Fechar
          </MDButton> */}
        </DialogActions>
      </Dialog>



    </>

  );
}

const formDataDefault =
{
  tipo: { value: 'arquivo', label: 'Arquivo' },
  descricao: "",
  arquivo: null,
  link: '',

}

const FormAnexo = (props) => {

  const { recarregaTabela, conta_id, parcela_conta_id,
    lancamento_parcela_id, transferencia_banco_id, venda_id, parametros, pessoa_id } = props;

  const messageContext = useContext(MessagesContext);
  const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera, MensagemAlerta } = messageContext;

  const authContext = useContext(AuthContext);
  const { getSessao } = authContext;
  const sessaolocal = getSessao();

  const [formData, setFormData] = useState(formDataDefault);

  const limpaInputs = () => {
    setFormData({ ...formDataDefault });
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {


    const arquivo = e.target.files[0]; // O arquivo selecionado
    console.log(arquivo.type);


    if (!arquivo) {
      return;
    }

    // Verifica o tipo do arquivo
    if (!parametros.tipos_permitidos.includes(arquivo.type)) {
      MensagemErro(`Tipo de arquivo inválido! Tipos permitidos: ${parametros.nome_tipos_permitidos}`);
      return;
    }

    // Verifica o tamanho do arquivo
    const tamanhoArquivoKB = arquivo.size / 1024;
    if (tamanhoArquivoKB > parametros.tamanho_maximo_kb) {
      MensagemErro(`O arquivo excede o tamanho permitido de ${parametros.tamanho_maximo_kb} KB.`);
      return;
    }

    // Atualiza o estado se o arquivo for válido
    setFormData({ ...formData, arquivo });
  };

  const handleAddAnexo = async () => {

    console.log("Adicionando anexo:", formData);

    try {

      MostraTelaEspera('Enviando o anexo...');

      const empresa_id = sessaolocal.empresa_id || null;
      let retorno;

      // Criar um objeto FormData
      const formDataToSend = new FormData();
      formDataToSend.append('empresa_id', empresa_id);
      formDataToSend.append('descricao', formData.descricao);
      formDataToSend.append('tipo', formData.tipo?.value || 'link');
      formDataToSend.append('link', formData.link || '');
      formDataToSend.append('conta_id', conta_id || '');
      formDataToSend.append('parcela_conta_id', parcela_conta_id || '');
      formDataToSend.append('lancamento_parcela_id', lancamento_parcela_id || '');
      formDataToSend.append('transferencia_banco_id', transferencia_banco_id || '');
      formDataToSend.append('venda_id', venda_id || '');
      formDataToSend.append('pessoa_id', pessoa_id || '');

      // Adiciona o arquivo apenas se existir
      if (formData.arquivo) {
        formDataToSend.append('file', formData.arquivo);
      }


      retorno = await api.post(`${rotafechada()}anexos`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      MensagemAviso('Enviado com sucesso!');
      FechaTelaEspera();
      limpaInputs();

      if (recarregaTabela)
        recarregaTabela();
    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  };

  const optionsTipo = [
    { value: 'arquivo', label: 'Arquivo' },
    { value: 'link', label: 'Link' },

  ]

  return (
    <>
      <Grid container spacing={2} alignItems="center">

        {/* Descrição */}
        <Grid item xs={4}>
          <TextField
            name="descricao"
            label="Descrição"
            value={formData.descricao}
            onChange={handleChange}
            fullWidth
          />
        </Grid>

        {/* Tipo */}
        <Grid item xs={2}>
          <AutoCompleteStyled
            options={optionsTipo}
            getOptionLabel={(option) => option.label || ""}
            renderInput={(params) => <FormField {...params} label="Tipos" />}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            value={formData.tipo}
            onChange={(event, newValue) => {
              console.log(newValue);
              console.log(formData)
              setFormData({ ...formData, tipo: newValue })
            }}
            style={{ cursor: "not-allowed" }}
          />
        </Grid>

        {
          formData.tipo?.value == "arquivo" ?
            <Grid item xs={4}>
              <Button
                variant="text"
                component="label"
                fullWidth
                startIcon={<UploadFileIcon />}
              >
                {formData.arquivo ? formData.arquivo.name : "Escolha um arquivo"}
                <input
                  type="file"
                  hidden
                  onChange={handleFileChange}
                />
              </Button>
            </Grid>
            :
            <Grid item xs={4}>
              <TextField
                name="link"
                label="Link (url)"
                value={formData.link}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
        }

        {/* Botão Adicionar */}
        <Grid item xs={2}>
          <MDButton
            variant="gradient"
            color={'info'}
            onClick={handleAddAnexo}
            fullWidth
          >
            Enviar anexo
          </MDButton>
        </Grid>
      </Grid>
    </>
  );
};

