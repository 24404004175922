import { Autocomplete, Grid, Typography } from '@mui/material';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';
import FormField from 'componentes/FormField';
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';


const Cabecalho = (props) => {
  const { control, opcoesAtivo, getValues, setValue } = props;


  
  return (
    <MDBox p={2}>
      <MDBox mb={3}>
        <Typography variant='h6' >Dados da Turma: {`${getValues('id') || ''}-${getValues('nome') || ''}`}</Typography>
      </MDBox>

      <Grid container spacing={1}>

        <Grid item xs={12} sm={12}>{/*dados de Serviços*/}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>{/*id*/}
              <Controller
                name={'id'}
                control={props.control}
                defaultValue=''
                render={({ field: { ref, ...props } }) =>
                  <FormField

                    key={'id'}
                    id={'id'}
                    autoComplete={false}
                    type='text'
                    label='ID'
                    rows={1}
                    multiline={false}
                    required={false}
                    disabled={true}
                    inputProps={{
                      disabled: true
                    }}
                    inputRef={ref}
                    {...props}
                  />}
              />
            </Grid>
            
            <Grid item xs={12} sm={3}>{/*Ativo*/}
              <Controller
                name='ativo'
                control={control}
                defaultValue={{ value: 'S' }}
                render={({ field: { onChange, ...props } }) => (
                  <AutoCompleteStyled
                    options={opcoesAtivo}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    renderInput={(params) => <FormField {...params} label='Ativo' />}
                    onChange={(_, data) => {
                      return onChange(data);
                    }}
                    disabled={false}
                    {...props}
                  />
                )}

              />
            </Grid>
            <Grid item xs={12} sm={9}>{/*Nome*/}
              <Controller
                name={'nome'}
                defaultValue=''
                control={props.control}
                render={({ field: { ref, ...props } }) =>
                  <FormField

                    key={'nome'}
                    id={'nome'}
                    autoComplete={false}
                    type='text'
                    label='Nome'
                    rows={1}
                    multiline={false}
                    required={true}
                    disabled={false}
                    inputRef={ref}
                    {...props}
                  />}
              />
            </Grid>
            <Grid item xs={12} sm={3}>{/*cidade*/}
              <Controller
                name={'cidade'}
                defaultValue=''
                control={props.control}
                render={({ field: { ref, ...props } }) =>
                  <FormField

                    key={'cidade'}
                    id={'cidade'}
                    autoComplete={false}
                    type='text'
                    label='Cidade'
                    rows={1}
                    multiline={false}
                    required={true}
                    disabled={false}
                    inputRef={ref}
                    {...props}
                  />}
              />
            </Grid>
            <Grid item xs={12} sm={3}>{/*Valor*/}
              <Controller
                name={'valor'}
                defaultValue=''
                control={props.control}
                render={({ field: { ref, ...props } }) =>
                  <FormField

                    key={'valor'}
                    id={'valor'}
                    autoComplete={false}
                    type='text'
                    label='Valor'
                    rows={1}
                    multiline={false}
                    required={true}
                    disabled={false}
                    //inputRef={ref}
                    inputProps={{
                      onKeyUp: (e) => {
                      },
                      onBlur: (e) => {
                        e.preventDefault();
                        //handleBlurMargem();
                      },
                    }}
                    {...props}
                  />}
              />
            </Grid>

            <Grid item xs={12} sm={4} >{/*data_inicio*/}
                <Controller
                  name={'data_inicio'}
                  control={control}

                  render={({ field: { onChange, value } }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label='Data Início'
                        inputFormat="dd/MM/yyyy"
                        value={value}
                        onChange={onChange}
                        renderInput={(params) => <FormField {...params} />}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={4} >{/*data_termino*/}
                <Controller
                  name={'data_termino'}
                  control={control}

                  render={({ field: { onChange, value } }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label='Data Término'
                        inputFormat="dd/MM/yyyy"
                        value={value}
                        onChange={onChange}
                        renderInput={(params) => <FormField {...params} />}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={4} >{/*data_inicio_matricula*/}
                <Controller
                  name={'data_inicio_matricula'}
                  control={control}

                  render={({ field: { onChange, value } }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label='Data Início Matrícula'
                        inputFormat="dd/MM/yyyy"
                        value={value}
                        onChange={onChange}
                        renderInput={(params) => <FormField {...params} />}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={4} >{/*data_termino_matricula*/}
                <Controller
                  name={'data_termino_matricula'}
                  control={control}

                  render={({ field: { onChange, value } }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label='Data Término Matrícula'
                        inputFormat="dd/MM/yyyy"
                        value={value}
                        onChange={onChange}
                        renderInput={(params) => <FormField {...params} />}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>



            <Grid item xs={12} sm={12}>{/*texto_adicional*/}
              <Controller
                name={'texto_adicional'}
                defaultValue=''
                control={props.control}
                render={({ field: { ref, ...props } }) =>
                  <FormField

                    key={'texto_adicional'}
                    id={'texto_adicional'}
                    autoComplete={false}
                    type='text'
                    label='Texto Adicional'
                    rows={3}
                    multiline={true}
                    required={false}
                    disabled={false}
                    inputRef={ref}
                    {...props}
                  />}
              />
            </Grid>

            <Grid item xs={12} sm={12}>{/*clausula_opcional*/}
              <Controller
                name={'clausula_opcional'}
                defaultValue=''
                control={props.control}
                render={({ field: { ref, ...props } }) =>
                  <FormField

                    key={'clausula_opcional'}
                    id={'clausula_opcional'}
                    autoComplete={false}
                    type='text'
                    label='Cláusula Opcional'
                    rows={3}
                    multiline={true}
                    required={false}
                    disabled={false}
                    inputRef={ref}
                    {...props}
                  />}
              />
            </Grid>

            <Grid item xs={12} sm={12}>{/*observacao*/}
              <Controller
                name={'observacao'}
                defaultValue=''
                control={props.control}
                render={({ field: { ref, ...props } }) =>
                  <FormField

                    key={'observacao'}
                    id={'observacao'}
                    autoComplete={false}
                    type='text'
                    label='Observação'
                    rows={3}
                    multiline={true}
                    required={false}
                    disabled={false}
                    inputRef={ref}
                    {...props}
                  />}
              />
            </Grid>

            <Grid item xs={12} sm={12}>{/*arquivo_contrato*/}
              <Controller
                name={'arquivo_contrato'}
                defaultValue=''
                control={props.control}
                render={({ field: { ref, ...props } }) =>
                  <FormField

                    key={'arquivo_contrato'}
                    id={'arquivo_contrato'}
                    autoComplete={false}
                    type='text'
                    label='Arquivo do Contrato'
                    rows={1}
                    multiline={false}
                    required={false}
                    disabled={false}
                    inputRef={ref}
                    {...props}
                  />}
              />
            </Grid>

            <Grid item xs={12} sm={12}>{/*email_confirmacao*/}
              <Controller
                name={'email_confirmacao'}
                defaultValue=''
                control={props.control}
                render={({ field: { ref, ...props } }) =>
                  <FormField

                    key={'email_confirmacao'}
                    id={'email_confirmacao'}
                    autoComplete={false}
                    type='text'
                    label='Email(s) de Confirmação (separe por ,)'
                    rows={1}
                    multiline={false}
                    required={false}
                    disabled={false}
                    inputRef={ref}
                    {...props}
                  />}
              />
            </Grid>

          </Grid>
        </Grid>
      </Grid>
    </MDBox>
  )
}

export default Cabecalho;