import { useContext, useState } from "react";
import { Dialog, Grid, IconButton, Typography } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import MDBox from "components/MDBox";
import MessagesContext from "hooks/MessagesContext";
import { rotafechada } from "commons/urlconf";
import api from "commons/api";

import CabecalhoProjetos from "./cabecalhoprojetos";
import CoresProjetos from "./coresprojetos";
import PossibilidadeProjetos from "./possibilidadesprojetos";
import MedidasProjetos from "./medidasprojetos";
import ComponentesProjetos from "./componentesprojetos";
import ProdutosServicosOpcionaisProjetos from "./produtosservicosopcionaisprojetos";
import ProdutosCorProjetos from "./produtoscorprojetos";
import MDButton from "components/MDButton";
import { copiaProfundaObjetos } from "commons/utils";
import DialogoSimples from 'componentes/DialogoSimples';
import { useEffect } from "react";
import MostraErroFormulas from "./erroformulas/MostraErroFormulas";
import DialogoConfirma from "componentes/DialogoConfirma";
import MDTypography from "components/MDTypography";
import CloseIcon from '@mui/icons-material/Close';
import { Box } from "@mui/system";
import { formataFloat } from "commons/utils";
import { removeZeros } from "commons/utils";
import CalculaPorM2 from "./calculaporm2";


const ProjetosVenda = (props) => {

  const { control, setValue, getValues, vendaAtual, handleSubmit, unregister, recarregaVenda,
    openCollapseProjeto, handleClickCollapseProjeto, parametrosVenda, verificaVendaConcluida } = props;

  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;

  const [objFull, setObjFull] = useState({});
  const [etapaAtual, setEtapaAtual] = useState(0);
  const [etapas, setEtapas] = useState(['CABECALHO']);
  const [dialogoSimples, setDialogoSimples] = useState({ open: false, linhas: [] });
  const [dialogoErroFormulas, setDialogoErroFormulas] = useState({ open: false, msg: '', tabelaFormulas: [] });
  const [codigoProjetoAtual, setCodigoProjetoAtual] = useState('');
  const [recarregaTabela, setRecarregaTabela] = useState(false);
  const [urlImagem, setUrlImagem] = useState('');
  const [confirmaAbortar, setConfirmaAbortar] = useState(false);

  const [calculandoProjeto, setCalculandoProjeto] = useState(false);

  const nomeEtapaAtual = (paramEtapa) => {
    if ((etapas?.length > 0) && (paramEtapa < etapas.length)) {
      return etapas[paramEtapa];
    }

    return '';
  }

  const validaDadosAntesEtapas = () => {

    if (!(parseInt(getValues('projetos.projeto_id'), 10) > 0)) {
      MensagemErro('Não é possível avançar sem escolher um projeto!');
      return false;
    }

    return true;
  }

  const preparaEtapaCores = (novoObjFull) => {
    //se for uma edição o objetocores do RHF vai estar preenchido com as cores escolhidas
    //para essa venda
    const coresSelecionadas = getValues('projetos.cores');
    setValue(`projetos.cores[]`, []);

    //* Preparando o vetor de objeto cores do RHF com os valores que vieram no objfull do backend */
    novoObjFull.cores?.map((itemcor, index) => {
      //seta o valor default que esta no projeto
      setValue(`projetos.cores[${index}].cor_id`, { value: itemcor.cor_id || '', label: itemcor.Core.nome || '' });
      setValue(`projetos.cores[${index}].tipo_produto_id`, itemcor.tipo_produto_id || '');

      //verificando se esse tipo_produto_id ja tinha sido preenchido anteriormente
      //se sim a prioridade é a seleção do usuario e nao o projeto
      coresSelecionadas?.filter((element) => element.tipo_produto_id === itemcor.tipo_produto_id).map((itemanterior) => {
        if (itemanterior.cor_id?.value > 0) {
          setValue(`projetos.cores[${index}].cor_id`, itemanterior.cor_id);
        }
      })
    });
  }

  const preparaEtapaPossibilidades = (novoObjFull) => {
    const possibilidadesSelecionadas = getValues('projetos.possibilidades');
    //unregister('projetos.possibilidades');
    setValue(`projetos.possibilidades[]`, []);

    //* Preparando o vetor de objeto cores do RHF com os valores que vieram no objfull do backend */
    novoObjFull.possibilidades?.map((itemPossibilidade, index) => {

      setValue(`projetos.possibilidades[${index}].possibilidade_projeto_id`, itemPossibilidade.possibilidade_projeto_id);
      setValue(`projetos.possibilidades[${index}].possibilidade_projeto_opcao_id`, { value: -1, label: '' });

      //setando a padrao
      itemPossibilidade.Possibilidades_Projeto?.Possibilidade_Projetos_Opcoes?.filter((itemOpcao) => itemOpcao.padrao === 'S').map((itemOpcaoPadrao) => {
        setValue(`projetos.possibilidades[${index}].possibilidade_projeto_opcao_id`, { value: itemOpcaoPadrao.id, label: itemOpcaoPadrao.nome });
      })
    });
  }

  const preparaEtapaMedidas = (novoObjFull, objMedidasEdicao) => {

    const medidasSelecionadas = getValues('projetos.medidas');
    //unregister('projetos.medidas');
    setValue(`projetos.medidas[]`, []);

    //* Preparando o vetor de objeto cores do RHF com os valores que vieram no objfull do backend */
    novoObjFull.medidas?.map((itemMedida, index) => {

      let quantidade = itemMedida.valor_padrao || '';

      if (!(itemMedida.oculto === 'S') && (objMedidasEdicao?.length > 0)) {
        objMedidasEdicao.filter((item) => item.medida_projeto_id === itemMedida.medida_projeto_id).map((itemedicao) => {
          quantidade = formataFloat(itemedicao.quantidade, 4);
        })
      }

      setValue(`projetos.medidas[${index}].medida_projeto_id`, itemMedida.medida_projeto_id);
      setValue(`projetos.medidas[${index}].quantidade`, quantidade);
      setValue(`projetos.medidas[${index}].observacao`, itemMedida.observacao || '');
      setValue(`projetos.medidas[${index}].mostra_impressao`, itemMedida.mostra_impressao);
      setValue(`projetos.medidas[${index}].oculto`, itemMedida.oculto);
      setValue(`projetos.medidas[${index}].valor_padrao`, itemMedida.valor_padrao);
      setValue(`projetos.medidas[${index}].Medidas_Projeto`, itemMedida.Medidas_Projeto);

    });
  }

  const preparaEtapaComponentes = (novoObjFull) => {

    const componentesSelecionados = getValues('projetos.componentes');
    //unregister('projetos.componentes');
    setValue(`projetos.componentes[]`, []);

    //* Preparando o vetor de objeto cores do RHF com os valores que vieram no objfull do backend */
    novoObjFull.componentes?.map((itemComponente, index) => {

      setValue(`projetos.componentes[${index}].id`, itemComponente.id);
      setValue(`projetos.componentes[${index}].componente_id`, itemComponente.componente_id);
      setValue(`projetos.componentes[${index}].tipo_produto_id_destino`, itemComponente.tipo_produto_id_destino);
      setValue(`projetos.componentes[${index}].formula_altura`, itemComponente.formula_altura || '');
      setValue(`projetos.componentes[${index}].formula_largura`, itemComponente.formula_largura || '');
      setValue(`projetos.componentes[${index}].formula_medida_final`, itemComponente.formula_medida_final || '');
      setValue(`projetos.componentes[${index}].formula_quantidadeinsercao`, itemComponente.formula_quantidadeinsercao || '');
      setValue(`projetos.componentes[${index}].folga_altura`, itemComponente.folga_altura || '');
      setValue(`projetos.componentes[${index}].folga_largura`, itemComponente.folga_largura || '');
      setValue(`projetos.componentes[${index}].formula_altura_cobranca`, itemComponente.formula_altura_cobranca || '');
      setValue(`projetos.componentes[${index}].formula_largura_cobranca`, itemComponente.formula_largura_cobranca || '');
      setValue(`projetos.componentes[${index}].formula_medida_final_cobranca`, itemComponente.formula_medida_final_cobranca || '');
      setValue(`projetos.componentes[${index}].individual`, itemComponente.individual || '');
      setValue(`projetos.componentes[${index}].opcional`, itemComponente.opcional);
      setValue(`projetos.componentes[${index}].observacao`, itemComponente.observacao);
      setValue(`projetos.componentes[${index}].Componentes_Projeto.referencia`, itemComponente.Componentes_Projeto?.referencia);
      //sera escolhido  qual o Produto para o componente atual
      setValue(`projetos.componentes[${index}].incluido`, true);  //sera usado no opcional
      setValue(`projetos.componentes[${index}].produto_grade_id_destino`, '');
      setValue(`projetos.componentes[${index}].nome_produto_grade_id_destino`, '');
      //serao calculados pelas formulas
      setValue(`projetos.componentes[${index}].quantidade`, '');
      setValue(`projetos.componentes[${index}].altura`, '');
      setValue(`projetos.componentes[${index}].largura`, '');
      setValue(`projetos.componentes[${index}].altura_cobranca`, '');
      setValue(`projetos.componentes[${index}].largura_cobranca`, '');
      setValue(`projetos.componentes[${index}].quantidade_cobranca`, '');
      setValue(`projetos.componentes[${index}].quantidadeinsercao`, '');
    });
  }

  const preparaEtapaProdutos = async (novoObjFull) => {

    const produtosSelecionados = getValues('projetos.produtos');

    setValue(`projetos.produtos[]`, []);

    novoObjFull.produtos?.map((itemProduto, index) => {
      setValue(`projetos.produtos[${index}].id`, itemProduto.id);
      setValue(`projetos.produtos[${index}].formula_quantidade`, itemProduto.formula_quantidade);
      setValue(`projetos.produtos[${index}].formula_quantidadeinsercao`, itemProduto.formula_quantidadeinsercao);
      setValue(`projetos.produtos[${index}].observacao`, itemProduto.observacao);
      setValue(`projetos.produtos[${index}].possibilidade_projeto_opcao_id`, itemProduto.possibilidade_projeto_opcao_id);
      setValue(`projetos.produtos[${index}].opcional`, itemProduto.opcional);
      setValue(`projetos.produtos[${index}].otimizar`, itemProduto.otimizar);
      setValue(`projetos.produtos[${index}].corte`, itemProduto.corte);

      setValue(`projetos.produtos[${index}].Produto.referencia`, itemProduto.Produto?.referencia);

      //se estiver vinculado a uma opcao da possibilidade entao ele nao vai incluido como true default
      setValue(`projetos.produtos[${index}].incluido`, itemProduto.possibilidade_projeto_opcao_id ? false : true);

      setValue(`projetos.produtos[${index}].produto_id`, itemProduto.produto_id);
      setValue(`projetos.produtos[${index}].nome_produto_id`, itemProduto.Produto?.nome || '');
      //sera preenchido depois dos calculos
      setValue(`projetos.produtos[${index}].produto_grade_id`, '');
      setValue(`projetos.produtos[${index}].quantidade`, '');
      setValue(`projetos.produtos[${index}].quantidadeinsercao`, '');
      setValue(`projetos.produtos[${index}].valor_unitario`, '');
      setValue(`projetos.produtos[${index}].valor_total`, '');
    });
  }

  const preparaEtapaServicos = (novoObjFull) => {

    const servicosSelecionados = getValues('projetos.servicos');
    //unregister('projetos.servicos');
    setValue(`projetos.servicos[]`, []);

    //* Preparando o vetor de objeto cores do RHF com os valores que vieram no objfull do backend */
    novoObjFull.servicos?.map((itemServico, index) => {

      setValue(`projetos.servicos[${index}].id`, itemServico.id);
      setValue(`projetos.servicos[${index}].formula_quantidade`, itemServico.formula_quantidade);
      setValue(`projetos.servicos[${index}].formula_quantidadeinsercao`, itemServico.formula_quantidadeinsercao);
      setValue(`projetos.servicos[${index}].observacao`, itemServico.observacao);
      setValue(`projetos.servicos[${index}].opcional`, itemServico.opcional);
      setValue(`projetos.servicos[${index}].servico_id`, itemServico.servico_id);
      setValue(`projetos.servicos[${index}].nome_servico_id`, itemServico.Servico?.nome || '');
      setValue(`projetos.servicos[${index}].Servico.referencia`, itemServico.Servico?.referencia || '');
      setValue(`projetos.servicos[${index}].possibilidade_projeto_opcao_id`, itemServico.possibilidade_projeto_opcao_id);
      //sera preenchido depois dos calculos
      setValue(`projetos.servicos[${index}].quantidade`, '');
      setValue(`projetos.servicos[${index}].quantidadeinsercao`, '');
      setValue(`projetos.servicos[${index}].valor_unitario`, '');
      setValue(`projetos.servicos[${index}].valor_total`, '');
      //se estiver vinculado a uma opcao da possibilidade entao ele nao vai incluido como true default
      setValue(`projetos.servicos[${index}].incluido`, itemServico.possibilidade_projeto_opcao_id ? false : true);
    });
  }

  const IniciaEtapas = async () => {

    const erroVendaConcluida = await verificaVendaConcluida(true, ' Não é permitido inserir/alterar projetos');
    if (erroVendaConcluida) {
      return;
    }

    if (!validaDadosAntesEtapas()) {
      return;
    }

    //resgatando um Objeto com os dados de todo o projeto selecionado
    try {


      let url;
      let retorno;
      let objMedidasEdicao = [];

      if (parseInt(codigoProjetoAtual, 10) > 0) {//recálculo

        //buscando as medidas digitadas anteriormente
        url = `${rotafechada()}venda_projeto_medidas?limit=-1&venda_projeto_id=${codigoProjetoAtual}`;
        retorno = await api.get(encodeURI(url));
        const { data: objmedidas } = retorno.data;
        objmedidas?.map((item) => {
          objMedidasEdicao.push({ ...item })
        })

      }

      url = `${rotafechada()}projetos/full/${parseInt(getValues('projetos.projeto_id'), 10)}`;
      retorno = await api.get(encodeURI(url));
      const { data: novoObjFull } = retorno.data;

      console.log('Objeto recebido: ', novoObjFull);

      setValue('projetos.calcula_por_m2',novoObjFull.calcula_por_m2||'N');
      setValue('projetos.formula_calculo_m2',novoObjFull.formula_calculo_m2||'');
      setValue('projetos.valor_custo_m2',removeZeros(novoObjFull.valor_custo_m2)||'0');
      setValue('projetos.percentual_lucro_m2',removeZeros(novoObjFull.percentual_lucro_m2)||'0');

      preparaEtapaCores(novoObjFull);
      preparaEtapaPossibilidades(novoObjFull);
      preparaEtapaMedidas(novoObjFull, objMedidasEdicao);
      preparaEtapaComponentes(novoObjFull);

      preparaEtapaProdutos(novoObjFull);
      preparaEtapaServicos(novoObjFull);

      const sequenciaEtapasTemp = [];
      sequenciaEtapasTemp.push('CABECALHO');

      if (novoObjFull.cores?.length > 0) {
        sequenciaEtapasTemp.push('CORES');
      }
      if (novoObjFull.possibilidades?.length > 0) {
        sequenciaEtapasTemp.push('POSSIBILIDADES');
      }

      sequenciaEtapasTemp.push('MEDIDAS');

      if (novoObjFull.componentes?.length > 0) {
        sequenciaEtapasTemp.push('COMPONENTES');
      }

      if (novoObjFull.componentes?.length > 0) {
        sequenciaEtapasTemp.push('PRODUTOSSERVICOS');
      }
      sequenciaEtapasTemp.push('PRODUTOSCOR');

      if (novoObjFull.calcula_por_m2 === 'S') {
        sequenciaEtapasTemp.push('CALCULOPORM2');
      }

      sequenciaEtapasTemp.push('FINALIZAR');


      //criando um vetor em cada produto com as cores disponiveis
      //porem com o id da produto_grade
      //para serem usados no autocomplete
      novoObjFull.produtos?.map((item, index) => {
        //cada produto do projeto 
        //tem um obj Produto, dentro dele
        //uma Produto_Grades que contem as cores para o produto
        item.produtogradeautocomplete = [];
        item.Produto?.Produto_Grades?.map((itemprodutograde) => {
          item.produtogradeautocomplete.push({
            value: itemprodutograde.id,
            label: itemprodutograde.Core?.nome,
            cor_id: itemprodutograde.cor_id,
          })
        });
      })

      //console.log('ObjFull:', novoObjFull);
      //console.log('ObjRHF.projetos:', getValues('projetos'));

      setEtapas(sequenciaEtapasTemp);
      setObjFull(novoObjFull);
      setCalculandoProjeto(true);
      proximaEtapa();
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
      return;
    }
  }

  const calculaFormulasBackEnd = async (data) => {

    const dados = copiaProfundaObjetos(data);

    delete dados?.projetos?.imagem;

    try {
      
      //console.log('Antes do calculo das formulas',dados);
      let url = `${rotafechada()}venda_projetos/calcularprojetovenda`;
      const retorno = await api.post(url, dados);
      // console.log(retorno);
      // console.log('Após o calculo:', retorno.data.data);
      // console.log('Variaveis:', retorno.data.variaveis);

      //mapeando o objeto e setando no RHF os valores calculados

      retorno.data.data?.projetos?.medidas?.map((item, index) => {
        setValue(`projetos.medidas[${index}].quantidade`, item.quantidade);
      });

      retorno.data.data?.projetos?.componentes?.map((item, index) => {
        setValue(`projetos.componentes[${index}].altura`, item.altura);
        setValue(`projetos.componentes[${index}].largura`, item.largura);
        setValue(`projetos.componentes[${index}].quantidade`, item.quantidade);
        setValue(`projetos.componentes[${index}].altura_cobranca`, item.altura_cobranca);
        setValue(`projetos.componentes[${index}].largura_cobranca`, item.largura_cobranca);
        setValue(`projetos.componentes[${index}].quantidade_cobranca`, item.quantidade_cobranca);
        setValue(`projetos.componentes[${index}].quantidadeinsercao`, item.quantidadeinsercao);
      });

      retorno.data.data?.projetos?.produtos?.map((item, index) => {
        if (item.id) {
          setValue(`projetos.produtos[${index}].quantidade`, item.quantidade);
          setValue(`projetos.produtos[${index}].quantidadeinsercao`, item.quantidadeinsercao);
        }
      });

      retorno.data.data?.projetos?.servicos?.map((item, index) => {
        setValue(`projetos.servicos[${index}].quantidade`, item.quantidade);
        setValue(`projetos.servicos[${index}].quantidadeinsercao`, item.quantidadeinsercao);
      });

      
      setValue('projetos.quantidade_m2',retorno.data.data?.projetos?.quantidade_m2||'0');
      



      setEtapaAtual(etapaAtual + 1);
      //MensagemAviso('Calculado!');
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      if (error.response?.data?.variaveis) {
        console.log(error.response?.data?.variaveis);
        /*
        [
         {calculou: true,
          campo: "quantidade",
          formula: "1000",
          formulabackup: "1000",
          index: 1,
          nome: "L",
          tipo: "medidas",
          valor: "1000"}
        */

        setDialogoErroFormulas(
          {
            ...dialogoErroFormulas,
            msg,
            tabelaFormulas: error.response?.data?.variaveis,
            open: true
          });
      }
      else MensagemErro(`Erro no cálculo das fórmulas: ${msg}`);
      return;
    }
  }

  const insereProjetoVenda = async (data) => {

    const dados = copiaProfundaObjetos(data);

    //fazendo os ajustes no objeto que sera enviado

    //pegando o value dos autocompletes
    dados.projetos?.possibilidades?.map((item) => {
      item.possibilidade_projeto_opcao_id = item.possibilidade_projeto_opcao_id.value || null;
    })
    dados.projetos?.cores?.map((item) => {
      item.cor_id = item.cor_id.value || null;
    })

    //inserindo só os componentes "incluidos"
    let componentestemp = [];
    if (data.projetos?.componentes?.length > 0) {
      dados.projetos.componentes = [];
      componentestemp = copiaProfundaObjetos(data.projetos.componentes)
      dados.projetos.componentes = componentestemp.filter((item) => item.incluido);
    }

    //inserindo só os produtos "incluidos"
    let produtostemp = [];
    if (data.projetos?.produtos?.length > 0) {
      dados.projetos.produtos = [];
      produtostemp = copiaProfundaObjetos(data.projetos.produtos);

      //só insere os que foram incluidos e os que tem "id" pois pode ser uma posicao
      //e que a quantidadeinsercao seja maior que "0"
      //vazia devido a problema com o unregister do RHF
      dados.projetos.produtos = produtostemp.filter((item) => ((item.incluido) && (item.id) && (parseInt(item.quantidadeinsercao, 10) > 0)));
    }
    dados.projetos?.produtos?.map((item) => {
      item.produto_grade_id = item.produto_grade_id.value || null;
    })

    //inserindo só os servicos "incluidos"
    let servicostemp = [];
    if (data.projetos?.servicos?.length > 0) {
      dados.projetos.servicos = [];
      servicostemp = copiaProfundaObjetos(data.projetos.servicos)
      dados.projetos.servicos = servicostemp.filter((item) => (item.incluido) && (parseInt(item.quantidadeinsercao, 10) > 0));
    }

    dados.tabela_preco_id = dados.tabela_preco_id.value || null;

    delete dados?.projetos?.imagem;

    //se estiver alterando um projeto replicado ele deixa de ser filho
    //e se for um pai nao tera esse campo preenchido
    if (dados?.projetos?.venda_projeto_id_pai) {
      dados.projetos.venda_projeto_id_pai = null;
    }


    dados.projetos.valor_custo_m2 = parseFloat(`${dados?.projetos.valor_custo_m2}`.replace(',', '.')||'0');
    dados.projetos.percentual_lucro_m2 = parseFloat(`${dados?.projetos.percentual_lucro_m2}`.replace(',', '.')||'0');



    try {


      console.log('Dados a serem gravados na venda_projetos/full');
      console.log(dados);
      MostraTelaEspera('Aguarde...');
      let url = `${rotafechada()}venda_projetos/full`;

      const retorno = await api.post(url, dados);

      if (etapaAtual === 0) {
        setRecarregaTabela(!recarregaTabela);//projeto em branco nao sai da etapa 0
      }
      setCalculandoProjeto(false);
      setEtapaAtual(0);
      recarregaVenda();
      abrirRegistro(retorno?.data?.dados?.projetos?.id || '');
      FechaTelaEspera();
    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      if ((msg.indexOf('Produtos que não possuem a tabela de preço:') >= 0) || (msg.indexOf('Produtos SEM preço de venda:') >= 0)
        || (msg.indexOf('Serviços SEM preço de venda:') >= 0)) {
        mostraMensagemErroListaProdutos(msg);
        return;
      }
      MensagemErro(`${msg}`, null);
      return;
    }
  }

  const mostraMensagemErroListaProdutos = (msg = '') => {
    //Erro:Produtos que não possuem a tabela de preço:|produto1|produto2...
    msg = msg.replace('Erro:', '');
    let temp = '';
    const linhas = [];
    for (let i = 0; i <= msg.length - 1; i++) {
      if (msg[i] === '|') {
        linhas.push(temp);
        temp = '';
      }
      else temp = `${temp}${msg[i]}`;
    }
    if (temp !== '') {
      linhas.push(temp)
    }
    let title = '';
    if (linhas.length > 1) {
      title = linhas[0];
      linhas.shift();
    }
    setDialogoSimples({ ...dialogoSimples, open: true, linhas, title })
  }

  const proximaEtapa = async () => {

    if (nomeEtapaAtual(etapaAtual) === 'MEDIDAS') {
      //ao sair da medidas preciso calcular as formulas
      handleSubmit(calculaFormulasBackEnd)();
      return;
    }
    else
      if (nomeEtapaAtual(etapaAtual + 1) === 'FINALIZAR') {
        handleSubmit(insereProjetoVenda)();
        return;
      }

    setEtapaAtual(etapaAtual + 1);
  }

  const handleCloseDialogoSimples = () => {
    setDialogoSimples({ ...dialogoSimples, open: false });
  }

  const abrirRegistro = async (codigoAbrir = '') => {

    if (!codigoAbrir) {
      return;
    }

    try {
      MostraTelaEspera();

      //rota especifica que retorna somente o id enviando, devolve com imagem
      //e com a quantidade de replicados
      let url = `${rotafechada()}venda_projetos/abrir/${codigoAbrir}`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;
      console.log(data);

      setValue('projetos.id', data.id);
      setValue('projetos.projeto_id', data.projeto_id);
      setValue('projetos.nome_projeto_id', data.Projeto?.nome || '');
      setValue('projetos.ambiente_instalacao', data.ambiente_instalacao);
      setValue('projetos.valor_total',  parseFloat(`${removeZeros(data.valor_total)}`).toFixed(2));
      setValue('projetos.valor_desconto', removeZeros(data.valor_desconto));
      setValue('projetos.valor_final', parseFloat(`${removeZeros(data.valor_final)}`).toFixed(2));
      setValue('projetos.imagem', `${data.imagem}`);
      setValue('projetos.venda_projeto_id_pai', data.venda_projeto_id_pai);
      setValue('projetos.quantidadereplicados', data.quantidadereplicados);

      setValue('projetos.calcula_por_m2', data.calcula_por_m2);
      setValue('projetos.valor_custo_m2', data.valor_custo_m2);
      setValue('projetos.quantidade_m2', data.quantidade_m2);
      setValue('projetos.percentual_lucro_m2', data.percentual_lucro_m2);
      setValue('projetos.valor_total_m2', data.valor_total_m2);
      setValue('projetos.formula_calculo_m2', data.formula_calculo_m2);

      setCodigoProjetoAtual(data.id);
      FechaTelaEspera();
    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  };

  const handleSolicitaAbortarProcesso = async () => {
    setConfirmaAbortar(true);
  }

  const handleAbortar = () => {
    setCalculandoProjeto(false);
    setConfirmaAbortar(false);
    setEtapaAtual(0);
  }

  useEffect(() => {
    if (openCollapseProjeto) {
      console.log('useEffect-projetos.AbrirRegistro:', codigoProjetoAtual);
      abrirRegistro(codigoProjetoAtual);
    }
  }, [openCollapseProjeto])

  const desenhaCabecalhoPadrao = (title = '') => {
    return (
      <MDBox width="100%" display="flex" mt={1}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={10} lg={10}>
            <MDTypography>  {`${title}Projeto ${getValues('projetos.projeto_id')}-${getValues('projetos.nome_projeto_id')}`}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={2}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleSolicitaAbortarProcesso}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </MDBox>
    )
  }

  return (
    <MDBox p={2} bgColor='grey-100' mt={2} borderRadius="lg" coloredShadow='secondary'>

      <DialogoSimples
        open={dialogoSimples.open || false}
        linhas={dialogoSimples.linhas || []}
        onClose={handleCloseDialogoSimples}
        title={dialogoSimples.title || ''}
      />

      <DialogoConfirma
        open={confirmaAbortar}
        message={'Certeza que deseja abortar o processo?'}
        handleCancel={() => setConfirmaAbortar(false)}
        handleConfirm={handleAbortar}
        title={'Abortar o Processo'}
      />

      <MostraErroFormulas
        open={dialogoErroFormulas.open || false}
        message={dialogoErroFormulas.msg || false}
        handleClose={() => setDialogoErroFormulas({ ...dialogoErroFormulas, open: false })}
        title={'Erro nas Fórmulas'}
        tabela={dialogoErroFormulas.tabelaFormulas}
      />

      <IconButton aria-label="expand row" size="small" onClick={() => handleClickCollapseProjeto(!openCollapseProjeto)}>
        {openCollapseProjeto ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        <Typography variant='h6' mb={2}>Projetos</Typography>
      </IconButton>

      <Collapse in={openCollapseProjeto} timeout="auto" unmountOnExit>
        {(nomeEtapaAtual(etapaAtual) === 'CABECALHO') &&
          <CabecalhoProjetos
            control={control}
            setValue={setValue}
            getValues={getValues}
            vendaAtual={vendaAtual}
            handleSubmit={handleSubmit}
            etapaAtual={etapaAtual}
            setEtapaAtual={setEtapaAtual}
            objFull={objFull}
            setObjFull={setObjFull}
            unregister={unregister}
            IniciaEtapas={IniciaEtapas}
            abrirRegistro={abrirRegistro}
            recarregaVenda={recarregaVenda}
            codigoProjetoAtual={codigoProjetoAtual}
            setCodigoProjetoAtual={setCodigoProjetoAtual}
            recarregaTabela={recarregaTabela}
            setRecarregaTabela={setRecarregaTabela}
            urlImagem={urlImagem}
            setUrlImagem={setUrlImagem}
            verificaVendaConcluida={verificaVendaConcluida}
          />

        }
        <Dialog
          //fullScreen
          fullWidth={true}
          maxWidth={'xl'}
          open={calculandoProjeto}
          onClose={handleSolicitaAbortarProcesso}
        //TransitionComponent={Transition}
        >
          <Box
            sx={{
              padding: '12px',
              margin: '12px'
            }}
          >
            {(nomeEtapaAtual(etapaAtual) === 'CORES') &&
              <CoresProjetos
                control={control}
                setValue={setValue}
                getValues={getValues}
                vendaAtual={vendaAtual}
                handleSubmit={handleSubmit}
                etapaAtual={etapaAtual}
                setEtapaAtual={setEtapaAtual}
                objFull={objFull}
                setObjFull={setObjFull}
                proximaEtapa={proximaEtapa}
                desenhaCabecalhoPadrao={desenhaCabecalhoPadrao}
                parametrosVenda={parametrosVenda}
              />
            }
            {(nomeEtapaAtual(etapaAtual) === 'POSSIBILIDADES') &&
              <PossibilidadeProjetos
                control={control}
                setValue={setValue}
                getValues={getValues}
                vendaAtual={vendaAtual}
                handleSubmit={handleSubmit}
                etapaAtual={etapaAtual}
                setEtapaAtual={setEtapaAtual}
                objFull={objFull}
                setObjFull={setObjFull}
                proximaEtapa={proximaEtapa}
                desenhaCabecalhoPadrao={desenhaCabecalhoPadrao}
                parametrosVenda={parametrosVenda}
              />
            }
            {(nomeEtapaAtual(etapaAtual) === 'MEDIDAS') &&
              <MedidasProjetos
                control={control}
                setValue={setValue}
                getValues={getValues}
                vendaAtual={vendaAtual}
                handleSubmit={handleSubmit}
                etapaAtual={etapaAtual}
                setEtapaAtual={setEtapaAtual}
                objFull={objFull}
                setObjFull={setObjFull}
                proximaEtapa={proximaEtapa}
                urlImagem={urlImagem}
                setUrlImagem={setUrlImagem}
                desenhaCabecalhoPadrao={desenhaCabecalhoPadrao}
                parametrosVenda={parametrosVenda}
              />
            }
            {(nomeEtapaAtual(etapaAtual) === 'COMPONENTES') &&
              <ComponentesProjetos
                control={control}
                setValue={setValue}
                getValues={getValues}
                vendaAtual={vendaAtual}
                handleSubmit={handleSubmit}
                etapaAtual={etapaAtual}
                setEtapaAtual={setEtapaAtual}
                objFull={objFull}
                setObjFull={setObjFull}
                proximaEtapa={proximaEtapa}
                urlImagem={urlImagem}
                setUrlImagem={setUrlImagem}
                desenhaCabecalhoPadrao={desenhaCabecalhoPadrao}
                parametrosVenda={parametrosVenda}
              />
            }
            {(nomeEtapaAtual(etapaAtual) === 'PRODUTOSSERVICOS') &&
              <ProdutosServicosOpcionaisProjetos
                control={control}
                setValue={setValue}
                getValues={getValues}
                vendaAtual={vendaAtual}
                handleSubmit={handleSubmit}
                etapaAtual={etapaAtual}
                setEtapaAtual={setEtapaAtual}
                objFull={objFull}
                setObjFull={setObjFull}
                proximaEtapa={proximaEtapa}
                desenhaCabecalhoPadrao={desenhaCabecalhoPadrao}
                parametrosVenda={parametrosVenda}
              />
            }
            {(nomeEtapaAtual(etapaAtual) === 'PRODUTOSCOR') &&
              <ProdutosCorProjetos
                control={control}
                setValue={setValue}
                getValues={getValues}
                vendaAtual={vendaAtual}
                handleSubmit={handleSubmit}
                etapaAtual={etapaAtual}
                setEtapaAtual={setEtapaAtual}
                objFull={objFull}
                setObjFull={setObjFull}
                proximaEtapa={proximaEtapa}
                desenhaCabecalhoPadrao={desenhaCabecalhoPadrao}
                parametrosVenda={parametrosVenda}
              />
            }
            {(nomeEtapaAtual(etapaAtual) === 'CALCULOPORM2') &&
              <CalculaPorM2
                control={control}
                setValue={setValue}
                getValues={getValues}
                vendaAtual={vendaAtual}
                handleSubmit={handleSubmit}
                etapaAtual={etapaAtual}
                setEtapaAtual={setEtapaAtual}
                objFull={objFull}
                setObjFull={setObjFull}
                proximaEtapa={proximaEtapa}
                desenhaCabecalhoPadrao={desenhaCabecalhoPadrao}
                parametrosVenda={parametrosVenda}
              />
            }
            {(nomeEtapaAtual(etapaAtual) === 'FINALIZAR') &&
              <>
                <Typography>Finalizar</Typography>
                <MDButton variant="gradient" color="info" onClick={() => setEtapaAtual(etapaAtual - 1)} sx={{ ml: 2 }}>Retornar</MDButton>
                {
                  (props.parametrosVenda.mostra_botao_obj || false) &&
                  <MDButton variant="gradient" color="warning" onClick={() => handleSubmit((data) => console.log(data))()} sx={{ ml: 2 }}>Mostra OBJ</MDButton>
                }
              </>

            }
          </Box>
        </Dialog>



      </Collapse>
    </MDBox>
  )
}

export default ProjetosVenda;

