import PropTypes from "prop-types";
import { Box, Card, Grid, Typography } from "@mui/material";
import DialogoConfirma from "componentes/DialogoConfirma";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useContext, useState, useEffect } from "react";
import Footer from "examples/Footer";
import MDButton from "components/MDButton";
import Gen_Turmas from "./gen_turmas";


function GenEducacao() {

    const [dialogoOpen,setDialogoOpen]=useState({visible:false});
    const [aba,setAba]=useState(0);
 

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />

        <DialogoConfirma
          open={dialogoOpen.visible}
          title={'Confirma Exclusão'}
          message={'Certeza que deseja excluir?'}
          handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
          //handleConfirm={handleExcluir}
        />

        <MDBox mt={5} mb={9}>
          <Grid container justifyContent="center">
            <Grid item xs={12} lg={12}>
              <MDBox mt={0} mb={0} textAlign="center">
                <MDBox mb={1}>
                  <MDTypography variant="h3" fontWeight="bold">
                    Módulo de Cursos - Gen Educação
                  </MDTypography>
                </MDBox>
                <MDTypography variant="h5" fontWeight="regular" color="secondary">
                  { }
                </MDTypography>
              </MDBox>

              <Card>
                <TabPanel value={aba} index={0}>

                    <MDButton
                     onClick={()=>setAba(1)}
                    >Turmas</MDButton>

                </TabPanel>

                <TabPanel value={aba} index={1}>
                    <MDButton onClick={()=>setAba(0)}>Voltar</MDButton>
                    <Gen_Turmas/>
                
                </TabPanel>

              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
        
      
    </>
  );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.any,
    index: PropTypes.number,
    value: PropTypes.number,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  
export default GenEducacao;
