import { Autocomplete, Grid, TextField, Box, ThemeProvider } from '@mui/material';
import FormField from 'componentes/FormField';
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import MDTypography from 'components/MDTypography';
import React, { useState, useEffect, useContext } from 'react';
import { Controller } from 'react-hook-form';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import MessagesContext from 'hooks/MessagesContext';
import AuthContext from 'hooks/AuthContext';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';
import { ptBR } from "date-fns/locale";


const Cabecalho = (props) => {
  const { control, opcoesAtivo, periodicidade } = props;
  const authContext = useContext(AuthContext);
  const { getSessao } = authContext;
  const sessaolocal = getSessao();
  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MensagemAviso } = messageContext;
  const [name, setName] = React.useState('Cat in the Hat');

  return (
    <MDBox p={2}>

      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>{/*Dados da Assinatura*/}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={2}>{/*id*/}
              <Controller
                name={'id'}
                control={props.control}
                defaultValue=''
                render={({ field: { ref, ...props } }) =>
                  <FormField
                    key={'id'}
                    id={'id'}
                    type='text'
                    label='ID'
                    rows={1}
                    multiline={false}
                    required={false}
                    disabled={true}
                    inputProps={{
                      disabled: true
                    }}
                    inputRef={ref}
                    {...props}
                  />}
              />
            </Grid>

            <Grid item xs={12} sm={5}>{/*Ativo*/}
              <Controller
                name='ativo'
                control={control}
                defaultValue={{ value: 'S', label: 'Sim'}}
                render={({ field: { onChange, ...props } }) => (
                  <AutoCompleteStyled
                    options={opcoesAtivo}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    renderInput={(params) => <FormField {...params} label='Ativo' />}
                    onChange={(_, data) => {
                      return onChange(data);
                    }}
                    disabled={false}
                    {...props}
                  />
                )}

              />
            </Grid>

            <Grid item xs={12} sm={5}>{/*Periodicidade*/}
              <Controller
                name='periodicidade'
                control={control}
                defaultValue={{ value: 'M', label: 'Mensal'}}
                render={({ field: { onChange, ...props } }) => (
                  <AutoCompleteStyled
                    options={periodicidade}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    renderInput={(params) => <FormField {...params}  label='Periodicidade' />}
                    onChange={(_, data) => {
                      return onChange(data);
                    }}
                    disabled={false}
                    {...props}
                  />
                )}

              />
            </Grid>

            <Grid item xs={12} sm={4}>{/*valor_base*/}
              <Controller
                name={'valor_base'}
                defaultValue={0}
                control={props.control}
                render={({ field: { ref, ...props } }) =>
                  <FormField
                    key={'valor_base'}
                    id={'valor_base'}
                    type='text'
                    label='Valor Base'
                    rows={1}
                    multiline={false}
                    required={true}
                    disabled={false}
                    inputRef={ref}
                    {...props}
                  />}
              />
            </Grid>

            <Grid item xs={12} sm={4}>{/*valor_total*/}
              <Controller
                name={'valor_total'}
                defaultValue={0}
                control={props.control}
                render={({ field: { ref, ...props } }) =>
                  <FormField
                    key={'valor_total'}
                    id={'valor_total'}
                    type='text'
                    label='Valor Total'
                    rows={1}
                    multiline={false}
                    required={true}
                    disabled={false}
                    // inputProps={{
                    //   startAdornment: <InputAdornment sx={{ width: '10%' }} position="start">R$</InputAdornment>,
                    // }}
                    // inputProps={{
                    //   disabled: true
                    // }}
                    inputRef={ref}
                    {...props}
                  />}
              />
            </Grid>

            <Grid item xs={12} sm={4}>{/*data assinatura*/}
              <Controller
                name={'data'}
                defaultValue={new Date()}
                control={props.control}
                render={({ field: { onChange, value } }) => (
                  <ThemeProvider theme={{color: 'secondary'}}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                      <DesktopDatePicker
                        label='Data contratação'
                        inputFormat="dd/MM/yyyy"
                        value={value}
                        onChange={onChange}
                        renderInput={(params) => <FormField {...params} />}
                        disabled={false}
                        //sx={{ color: 'secondary' }}
                      />
                    </LocalizationProvider>
                  </ThemeProvider>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4}>{/*dia_vencimento*/}
              <Controller
                name={'dia_vencimento'}
                defaultValue={0}
                control={props.control}
                render={({ field: { ref, ...props } }) =>
                  <FormField
                    sx={ {label: { color: "primary" }, input: {color: "secondary"}}}
                    key={'dia_vencimento'}
                    id={'dia_vencimento'}
                    type='number'
                    label='Dia do vencimento'
                    rows={1}
                    multiline={false}
                    required={true}
                    disabled={false}
                    inputRef={ref}
                    {...props}
                  />}
              />
            </Grid>

            <Grid item xs={12} sm={4}>{/*data_cancelamento*/}
              <Controller
                name={'data_cancelamento'}
                defaultValue={null}
                control={props.control}
                render={({ field: { onChange, value } }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                    <DesktopDatePicker
                      label='Data cancelamento'
                      inputFormat="dd/MM/yyyy"
                      value={value}
                      onChange={onChange}
                      renderInput={(params) => <FormField {...params} />}
                      disabled={false}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4}>{/*data_limite_uso*/}
              <Controller
                name={'data_limite_uso'}
                defaultValue={null}
                control={props.control}
                render={({ field: { onChange, value } }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                    <DesktopDatePicker
                      label='Data limite de uso do sistema'
                      inputFormat="dd/MM/yyyy"
                      value={value}
                      onChange={onChange}
                      renderInput={(params) => <FormField {...params} />}
                      disabled={false}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12}>{/*observacao*/}
              <Box
                sx={{
                  '& > :not(style)': { width: '100%' },
                }}
              >
                <Controller
                  name={'observacao'}
                  defaultValue=''
                  control={props.control}
                  render={({ field: { ref, ...props } }) =>
                    <FormField
                      //focused
                      label={'Observação'}
                      key={'observacao'}
                      id={'observacao'}
                      type='text'
                      rows={4}
                      multiline={true}
                      required={false}
                      disabled={false}
                      inputRef={ref}
                      {...props}
                    />}
                />
              </Box>
            </Grid>

          </Grid>
        </Grid>
      </Grid>
    </MDBox>
  )
}

export default Cabecalho;