import React, { useContext, useEffect, useState } from 'react';
import { Checkbox, Grid, Icon, IconButton, Tooltip, Typography } from '@mui/material';
import MDBox from 'components/MDBox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import MDTypography from 'components/MDTypography';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import MessagesContext from 'hooks/MessagesContext';
import AuthContext from 'hooks/AuthContext';
import InfoIcon from '@mui/icons-material/Info';

const PessoaTipos = (props) => {

  //console.log('renderizou Tipos Pessoas');

  const { pessoaAtual, recarregaComponenteOpcoes, unregister, foco } = props;
  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MensagemAviso } = messageContext;
  const authContext = useContext(AuthContext);
  const { getSessao } = authContext;
  const sessaolocal = getSessao();
  const [openCollapse, setOpenCollapse] = useState(true);
  const [tiposPessoas, setTiposPessoas] = useState([{}]);

  const buscaTiposPessoas = async (data) => {

    const retornoTiposPessoas = await api.get(`${rotafechada()}/tipos_pessoas/?pessoa_id=${pessoaAtual}&direction=asc&order=id`);
    const dadosTiposPessoas = retornoTiposPessoas.data.data;

    let dadosTiposPessoasTemp = []
    dadosTiposPessoas?.map((item, index) => {
      dadosTiposPessoasTemp.push({ pessoa_tipos_id: item.Pessoa_Tipos[0]?.id || '', selecionado: item.Pessoa_Tipos[0]?.id ? true : false, nome: item.nome, tipo_pessoa_id: item.id })
    })

    setTiposPessoas(dadosTiposPessoasTemp)//Trouxe todos os tipos de pessoas com o selecionado true para os chekado
  }

  useEffect(() => {
    buscaTiposPessoas();
  }, []);

  const gravaClick = async (index, estado) => {

    try {
      try {
        const dados = {
          empresa_id: sessaolocal.empresa_id || null,
          pessoa_id: pessoaAtual,
          tipo_pessoa_id: tiposPessoas[index].tipo_pessoa_id
        };
        const idExclusao = tiposPessoas[index].pessoa_tipos_id
        if (estado) {
          await api.post(`${rotafechada()}pessoa_tipos`, dados);
          //await buscaTiposPessoas();
          MensagemAviso('Gravado com sucesso!');
        }
        else {
          await api.delete(`${rotafechada()}pessoa_tipos/${idExclusao}`);
          //await buscaTiposPessoas();
          MensagemAviso('Excluído com sucesso!');
        }

      }
      catch (error) {
        const msg = error.response?.data?.msg || error;
        MensagemErro(`Erro: ${msg}`);
      }
    }
    finally {
      await buscaTiposPessoas();
    }
  }

  return (
    <MDBox p={2} bgColor={'#fff'} mt={2} borderRadius="lg" coloredShadow='secondary'>
      <IconButton
        aria-label="expand row"
        size="small"
        onClick={() => setOpenCollapse(!openCollapse)}
      >
        {openCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        <Typography variant='h6' mb={2}>Tipos</Typography>
      </IconButton>
      <Tooltip title="Você pode escolher mais de um tipo para o mesmo cadastro.
          Exemplo: o cadastro pode ser cliente e fornecedor." placement="top">
        <InfoIcon color="info" sx={{ m: 0.5 }}></InfoIcon>
      </Tooltip>

      <Collapse in={openCollapse} timeout="auto" unmountOnExit>
        <Grid container spacing={1}>

          <Grid item xs={12} sm={12}>{/*Tipos*/}
            <MDBox width="100%" display="flex" justifyContent='space-between' alignItems='center' flexDirection='row'>
              {
                tiposPessoas?.map((paramitem, index) => {
                  return (
                    <div>
                      <MDTypography variant="caption" fontWeight="bold">
                        {paramitem.nome}
                      </MDTypography>
                      <Checkbox
                        autoFocus={foco}
                        checked={paramitem.selecionado || false}
                        onChange={(e) => gravaClick(index, e.target.checked)}
                      />
                    </div>)
                })
              }
            </MDBox>
          </Grid>
        </Grid>
      </Collapse>
    </MDBox>
  )
}

export default PessoaTipos;