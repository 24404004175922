import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Table, TableCell, TableContainer, TableRow } from '@mui/material';
import MDBox from 'components/MDBox';

export default function DialogoSimples(props) {
  const { onClose, open, linhas, title } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{title || ''}</DialogTitle>
      <MDBox m={2}>
        <TableContainer>
          <Table size='small'>
            {
              linhas?.map((linha, index) => (
                <TableRow  hover index={`tr-${index}`}><TableCell>{linha}</TableCell></TableRow>
              ))

            }
          </Table>
        </TableContainer>
      </MDBox>

    </Dialog>
  );
}