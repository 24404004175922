import React, { useContext, useEffect, useState } from 'react';
import MDBox from "components/MDBox";
import MessagesContext from 'hooks/MessagesContext';
import AuthContext from "hooks/AuthContext";
import { useForm, useWatch } from 'react-hook-form';
import MDButton from 'components/MDButton';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import Cabecalho from './cabecalho';
import ProjetosVenda from './projetos';
import ProdutosVenda from './produtos';
import ServicosVenda from './servicos';
import { useNavigate } from 'react-router-dom';
import DialogoSimples from 'componentes/DialogoSimples';
import DialogoOpcoes from 'componentes/DialogoOpcoes';
import { Dialog, DialogActions, DialogContent, Icon, Tooltip, Typography } from '@mui/material';
import ConcluirVenda from './concluir';
import { formataValor } from 'commons/utils';
import RelatorioListaDeMateriaisPedido from 'pages/vendas/relatorios/listademateriais';
import RelatorioListaDeMateriaisPedidoProjetos from 'pages/vendas/relatorios/listademateriaisprojetos';
import RelatorioListaDeComponentesVenda from 'pages/vendas/relatorios/componentesvenda';
import RelatorioVendasPorPeriodo from 'pages/vendas/relatorios/vendasporperiodo';
import { formatDate } from 'commons/utils';
import RelatorioCustoeLucroPedido from 'pages/vendas/relatorios/custoelucro';
import AlteraPrecosMateriaisVenda from './alteraprecos';
import DialogoOpcoesImpressao from './dialogoopcoesimpressao';
import PedidoTempera from './pedidotempera';
import DialogoConfirma from 'componentes/DialogoConfirma';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import packageJson from '../../../../../package.json';
import ConcluirVendaFinanceiro from '../../../financeiro/contas/Cadastro';
import RelatorioListaDeMateriaisAluminioPedido from 'pages/vendas/relatorios/listademateriaisaluminio';
import { format } from 'date-fns';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ptBR } from "date-fns/locale";
import FormField from 'componentes/FormField';
import { dateIsValid } from 'commons/utils';

/*import ComponentesProjeto from './componentesprojeto';
import ProdutosProjeto from './produtosprojeto';
import PossibilidadesProjeto from './possibilidadesprojeto';
import ProjetoVariaveis from './variaveisprojeto';
import ServicosProjeto from './servicosprojeto';
import CoresTiposProjeto from './corestiposprojeto';*/

function Cadastro(props) {

  const navigate = useNavigate();
  const { vendaAtual, setVendaAtual, setAba, parametrosVenda, verificaVendaConcluida, validaRotaPermissao } = props;
  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;
  const authContext = useContext(AuthContext);
  const { getSessao } = authContext;
  const sessaolocal = getSessao();

  const { handleSubmit, control, setValue, getValues, unregister } = useForm();
  const [opcoesAtivo, setOpcoesAtivo] = useState([
    { value: -1, label: '<Selecione uma opção>' },
    { value: 'S', label: 'Sim' },
    { value: 'N', label: 'Não' },
  ]);
  const [opcoesTabelaPreco, setOpcoesTabelaPreco] = useState([{ value: -1, label: 'Tabela de Preço' }]);
  const [opcoesTiposPessoas, setOpcoesTiposPessoas] = useState([{ value: -1, label: 'Todos' }]);

  const [dialogoSimples, setDialogoSimples] = useState({ open: false, linhas: [] });

  const nomeModulo = 'PEDIDOS_TEMPERA';
  const captionModulo = 'Desenho para Têmpera';


  //Usado na table do componente "possibilidadesprojeto" e no autocomplete da  "produtosproduto"
  const opcaoDefault = [{ id: -1, value: -1, label: '<Selecione...>' }];
  //******************************** */

  const [openCollapseProjeto, setOpenCollapseProjeto] = useState(false);
  const [openCollapseProduto, setOpenCollapseProduto] = useState(false);
  const [openCollapseServico, setOpenCollapseServico] = useState(false);
  const [openPedidoTempera, setOpenPedidoTempera] = useState(false);
  const [openMensagemModuloAdicional, setOpenMensagemModuloAdicional] = useState(false);
  const [etapaAtualPedidoTepera, setEtapaAtualPedidoTempera] = useState(false);
  const [dialogoConclusao, setDialogoConclusao] = useState({
    open: false,
    valor: 0,
    parcelas: []
  });
  const [dialogoConclusaoFinanceiro, setDialogoConclusaoFinanceiro] = useState({
    open: false,
    valor: 0,
    dados: {},
  });

  const [dialogoDataOrcamentoIgualDataVenda, setDialogoDataOrcamentoIgualDataVenda] = useState({
    open: false,
    data: new Date(),
  });

  const [opcoes, setOpcoes] = useState({
    open: false,
    items: [{}]
  });

  const [opcoesImpressao, setOpcoesImpressao] = useState({
    open: false,
    items: [{}]
  });

  const [openRelatorioListaMateriais, setOpenRelatorioListaMateriais] = useState(false);
  const [openRelatorioListaMateriaisProjetos, setOpenRelatorioListaMateriaisProjetos] = useState(false);
  const [openRelatorioListaComponentes, setOpenRelatorioListaComponentes] = useState(false);
  const [openRelatorioVendaPorPeriodo, setOpenRelatorioVendaPorPeriodo] = useState(false);
  const [openRelatorioCustoLucro, setOpenRelatorioCustoLucro] = useState(false);
  const [openAlteraPrecos, setOpenAlteraPrecos] = useState(false);
  const [openRelatorioListaMateriaisAluminio, setOpenRelatorioListaMateriaisAluminio] = useState(false);

  const [openConfirmacao, setOpenConfirmacao] = useState({ open: false });
  const [openConfirmacaoRecalculo, setOpenConfirmacaoRecalculo] = useState({ open: false, message: '' });

  const [dataVenda, setDataVenda] = useState(new Date());

  const ObjModeloVendas = {
    id: { type: 'text' },
    cliente_id: { type: 'text' },
    nome_cliente_id: { type: 'text' },
    vendedor_id: { type: 'text' },
    nome_vendedor_id: { type: 'text' },
    tabela_preco_id: { type: 'autocomplete' },
    nome: { type: 'text' },
    ativo: { type: 'autocomplete' },
    data_orcamento: { type: 'date' },
    prazo_dias: { type: 'text' },
    observacao: { type: 'text' },
    cobranca_barra: { type: 'text' },

    data_venda: { type: 'text' },
    valor_total: { type: 'text' },
    valor_desconto: { type: 'text' },
    valor_final: { type: 'text' },
    conta_id: { type: 'text' },
  }

  const onSubmit = (data) => {
    handleClickGravar(data);
  }

  const limpaInputs = () => {
    //limpo os campos
    Object.keys(ObjModeloVendas).forEach((nome) => {
      if (ObjModeloVendas[nome].type === 'autocomplete') {
        setValue(`${nome}`, opcaoDefault[0])
      }
      else
        if (ObjModeloVendas[nome].type === 'checkbox') {
          setValue(`${nome}`, false)
        }
        else
          if (ObjModeloVendas[nome].type === 'text') {
            setValue(`${nome}`, '')
          }
          else
            if (ObjModeloVendas[nome].type === 'date') {
              setValue(`${nome}`, new Date())
            }
    })
  }

  const validaCampos = (data) => {

    let camposObrigatorios = ``;

    if ((!data.valor_total) || (!(parseFloat(data.valor_total) >= 0))) {
      data.valor_total = 0;
    }
    if ((!data.valor_desconto) || (!(parseFloat(data.valor_desconto) >= 0))) {
      data.valor_desconto = 0;
    }
    if ((!data.valor_final) || (!(parseFloat(data.valor_final) >= 0))) {
      data.valor_final = 0;
    }

    if (camposObrigatorios !== '') {
      throw new Error(`Campos obrigatórios: ${camposObrigatorios}`)
    }
  }

  const handleClickGravar = async (data) => {
    const dados = {};

    Object.keys(ObjModeloVendas).forEach((nome) => {
      dados[nome] = '';
      if (nome in data) {
        if (ObjModeloVendas[nome].type === 'autocomplete') {

          dados[nome] = data[nome]?.value ? data[nome]?.value : '';
        }
        else
          if (typeof data[nome] === 'boolean') {
            dados[nome] = data[nome] === true ? 'S' : 'N'
          }
          else dados[nome] = data[nome];
      }
    })

    if (!(parseInt(dados.tabela_preco_id, 10) > 0)) {
      if (opcoesTabelaPreco?.length > 1) {
        dados.tabela_preco_id = opcoesTabelaPreco[1].value;
      }
    }

    try {
      validaCampos(dados);
    }
    catch (error) {
      MensagemErro(`${error.message}`);
      return;
    }


    try {

      let retorno;
      let codigoVendaTmp = '';
      dados.empresa_id = sessaolocal.empresa_id || null;
      delete dados.id;
      delete dados.valor_total;
      delete dados.valor_desconto;

      if (!(parseInt(dados.conta_id, 10) > 0)) {
        delete dados.data_venda;
      }


      if (parseInt(vendaAtual, 10) > 0) {
        retorno = await api.put(`${rotafechada()}vendas/${vendaAtual}`, dados);
        codigoVendaTmp = vendaAtual;
      }
      else {
        retorno = await api.post(`${rotafechada()}vendas`, dados);
        codigoVendaTmp = retorno.data.data.id;
      }

      MensagemAviso(`Gravado com sucesso! Venda: ${codigoVendaTmp}`);

      //se for um novo produto entao "recarrego" a pagina
      if (codigoVendaTmp !== vendaAtual) {
        navigate(`/vendas/vendas/${codigoVendaTmp}`, { replace: true });
        setVendaAtual(codigoVendaTmp);
      }
      else recarregaVenda();
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;

      const erro = error.response?.data?.erro || '';

      MensagemErro(`${msg}`);

      if (erro.indexOf('Produtos que não possuem a tabela de preço:') >= 0) {
        mostraMensagemErroListaProdutos(erro);
      }
    }
  }

  const mostraMensagemErroListaProdutos = (msg = '') => {
    //Erro:Produtos que não possuem a tabela de preço:|produto1|produto2...
    msg = msg.replace('Erro:', '');
    let temp = '';
    const linhas = [];
    for (let i = 0; i <= msg.length - 1; i++) {
      if (msg[i] === '|') {
        linhas.push(temp);
        temp = '';
      }
      else temp = `${temp}${msg[i]}`;
    }
    if (temp !== '') {
      linhas.push(temp)
    }
    let title = '';
    if (linhas.length > 1) {
      title = linhas[0];
      linhas.shift();
    }
    setDialogoSimples({ ...dialogoSimples, open: true, linhas, title })
  }

  const buscaOpcoesTabelaPreco = async () => {

    try {
      let url = `${rotafechada()}tabelas_precos?limit=-1&ativo=S`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;
      let opcaoTemp = [];
      opcaoTemp.push({ ...opcaoDefault[0] });
      data.map((item) => {
        opcaoTemp.push({ id: item.id, value: item.id, label: item.nome })
      })
      setOpcoesTabelaPreco(opcaoTemp);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const buscaOpcoesTiposPessoas = async () => {
    try {
      const url = `${rotafechada()}tipos_pessoas`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;

      const tipospessoastemp = [{ id: -1, label: 'Todos' }];

      data.map((item) => {

        tipospessoastemp.push({
          id: item.id,
          label: item.nome
        });

      })
      setOpcoesTiposPessoas(tipospessoastemp);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const buscaDatasets = async () => {
    buscaOpcoesTabelaPreco();
    buscaOpcoesTiposPessoas();
  }

  const novoRegistro = async () => {

    limpaInputs();
    if (opcoesAtivo.length > 0) {
      setValue('ativo', opcoesAtivo[1]);
    }
    setVendaAtual('');
    navigate(`/vendas/vendas/0`, { replace: true });
    setValue('prazo_dias', `${parametrosVenda.prazo_dias}`);
    setValue('observacao', `${parametrosVenda.texto_padrao_observacao}`);

    if (opcoesTabelaPreco?.length > 1) {
      setValue('tabela_preco_id', opcoesTabelaPreco[1]);
    }
  }

  const abrirRegistro = async () => {
    MostraTelaEspera();
    try {
      let url = `${rotafechada()}vendas?id=${vendaAtual}`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;

      if (!data.length > 0) {
        MensagemErro(`Venda ${vendaAtual} não localizado`);
        return;
      }

      let datavenda = '';

      if (data[0].data_venda) {
        datavenda = new Date(data[0].data_venda);
        datavenda = formatDate(datavenda, 'DD/MM/YYYY');
      }

      limpaInputs();

      setValue('id', data[0].id);
      setValue('nome', data[0].nome);
      setValue('data_orcamento', data[0].data_orcamento);
      setValue('cobranca_barra', data[0].cobranca_barra);
      setValue('prazo_dias', data[0].prazo_dias);
      setValue('valor_total', data[0].valor_total);
      setValue('valor_desconto', data[0].valor_desconto);
      setValue('conta_id', data[0].conta_id);
      setValue('data_venda', datavenda);
      setValue('cliente_id', data[0].cliente_id);
      setValue('vendedor_id', data[0].vendedor_id);
      setValue('nome_cliente_id', data[0].Cliente?.razao_social || data[0].Cliente?.fantasia || '');
      setValue('nome_vendedor_id', data[0].Vendedor?.razao_social || data[0].Cliente?.fantasia || '');
      setValue('valor_final', data[0].valor_final);
      setValue('observacao', data[0].observacao);
      setValue(`ativo`, { value: data[0].ativo === 'S' ? 'S' : 'N', label: data[0].ativo === 'S' ? 'Sim' : 'Não' });
      setValue('tabela_preco_id', { value: data[0].tabela_preco_id, label: data[0].Tabelas_Preco?.nome || '' });

      //por segurança limpo as variaveis do RHF
      setValue('projetos', {});
      setValue('produtos', {});
      setValue('servicos', {});



      FechaTelaEspera();
    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const recarregaVenda = () => {
    abrirRegistro();
  }

  useEffect(async () => {

    await buscaDatasets();

    if (parseInt(vendaAtual, 10) > 0) {
      await abrirRegistro();
    }
    else {
      await novoRegistro();
    }
  }, [vendaAtual])


  const handleClickOpcoesVenda = () => {

    const items = [
      { value: 0, label: (parseInt(getConta_id, 10) > 0) ? 'Estornar Venda' : 'Gerar Venda' },
      { value: 1, label: 'Recalcular Preços do Orçamento' },
      { value: 2, label: 'Tela de Alteração de Preços dos Materiais em lote' },
      { value: 3, label: 'Gerar Pedido de Têmpera (desenho)' },
      //{ value: 4, label: 'Recalcular Preços por Cobrança de Barras.' },
      { value: 4, label: 'Recalcular Cobrando as Sobras das Barras de Perfil.' },

    ];
    setOpcoes({ ...opcoes, open: true, tipo: 'opcoes', items });
  }

  const handleClickOpcoesImpressao = () => {
    setOpcoesImpressao({ ...opcoesImpressao, open: true });
  }

  const handleClickCollapseProjeto = (estado) => {
    setOpenCollapseProjeto(estado);
    if (estado === true) {
      setOpenCollapseProduto(false);
      setOpenCollapseServico(false);
    }
  }

  const handleClickCollapseProduto = (estado) => {
    setOpenCollapseProduto(estado);
    if (estado === true) {
      setOpenCollapseProjeto(false);
      setOpenCollapseServico(false);
    }
  }

  const handleClickCollapseServico = (estado) => {
    setOpenCollapseServico(estado);
    if (estado === true) {
      setOpenCollapseProjeto(false);
      setOpenCollapseProduto(false);
    }
  }

  const handleClickVoltar = () => {
    navigate(`/vendas/vendas/`, { replace: true });
    setVendaAtual('');
    setAba(0);
  }

  const handleCloseDialogoSimples = () => {
    setDialogoSimples({ ...dialogoSimples, open: false });
  }

  const recalculaPrecos = async () => {
    try {
      await api.post(`${rotafechada()}vendas/recalcula/${vendaAtual}`, {});
      MensagemAviso(`Recalculado com sucesso! Venda: ${vendaAtual}`);
      recarregaVenda();
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      const erro = error.response?.data?.erro || '';

      MensagemErro(`${msg}`);

      if (erro.indexOf('Produtos que não possuem a tabela de preço:') >= 0) {
        mostraMensagemErroListaProdutos(erro);
      }
    }
  }

  const recalculaPrecosCobrancaBarrasPerfil = async () => {
    try {
      MostraTelaEspera('Calculando Barras de alumínio');
      await api.post(`${rotafechada()}vendas/otimizarbarras/${vendaAtual}`, { tamanho_padrao_descarte: parametrosVenda.tamanho_padrao_descarte });
      MensagemAviso(`Recalculado com sucesso! Venda: ${vendaAtual}`);
      recarregaVenda();
      FechaTelaEspera();
    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      const erro = error.response?.data?.erro || '';

      MensagemErro(`${msg}`);
    }
  }

  const handleClickGerarVenda = async () => {

    const valorVenda = parseFloat(`${formataValor(getValues('valor_final'))}`.replace('R$', '').replaceAll('.', '').replace(',', '.'));


    formataValor()
    //setDialogoConclusao({ ...dialogoConclusao, open: true, valorVenda })
    const dados = {
      nome: getValues('nome'),
      data_orcamento: parametrosVenda.grava_data_venda_com_data_conclusao == 'SIM' ? new Date() : dialogoDataOrcamentoIgualDataVenda.data,
      vendedor_id: getValues('vendedor_id'),
      categoria_id: parseInt(parametrosVenda.categoria_venda) || 0,
      centro_custo_id: parseInt(parametrosVenda.centro_custo_venda) || 0,
      plano_conta_id: parseInt(parametrosVenda.plano_conta_venda) || 0,
      nome_vendedor_id: getValues('nome_vendedor_id'),
      cliente_id: getValues('cliente_id'),
      nome_cliente_id: getValues('nome_cliente_id'),
      valor_desconto: getValues('valor_desconto'),
      plano_conta_desconto_venda: parseInt(parametrosVenda.plano_conta_desconto_venda),
      valor_total: getValues('valor_total'),
      valor_final: getValues('valor_final'),
      //observacao: getValues('observacao'),
      id: getValues('id'),
    }
    setDialogoConclusaoFinanceiro({ ...dialogoConclusaoFinanceiro, open: true, valorVenda, dados: dados })
  }

  const handleClickDataOrcamentoIgualDataVenda = async () => {

    setDialogoDataOrcamentoIgualDataVenda({ ...dialogoDataOrcamentoIgualDataVenda, open: true });

  }


  const estornarVenda = async () => {
    try {
      await api.post(`${rotafechada()}vendas/estornar/${vendaAtual}`);
      MensagemAviso(`Estornada com sucesso! Venda: ${vendaAtual}`);
      recarregaVenda();
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      const erro = error.response?.data?.erro || '';
      MensagemErro(`${msg}`);
      recarregaVenda();
    }
  }

  const handleClickEstornarVenda = async () => {

    //confirmando se essa venda tem um pedido de tempera atrelada a ela
    let temPedidoTempera = false;

    try {
      const retorno = await api.get(encodeURI(`${rotafechada()}pedido_tempera_venda_projetos/listarvendaprojetos?venda_id=${vendaAtual}`));
      const { data } = retorno.data;

      for (let i = 0; i < data.length; i++) {
        if (data[i].Pedido_Tempera_Venda_Projetos?.length > 0) {
          temPedidoTempera = true;
          break;
        }
      }
    }
    catch (error) {

    }

    const message = temPedidoTempera ? 'Essa venda possui um "Pedido de Têmpera" e uma "Conta" vinculadas a ela. Ao estornar ambos serão excluídos!! Certeza que deseja estornar a Venda?' :
      'Ao estornar uma venda a conta vinculada a ela será excluida. Certeza que deseja estornar a Venda?';

    setOpenConfirmacao({
      open: true,
      message,
      handleCancel: () => setOpenConfirmacao({ ...openConfirmacao, open: false }),
      handleConfirm: () => {
        setOpenConfirmacao({ ...openConfirmacao, open: false });
        estornarVenda();
      }
    });
  }

  const gerarVenda = async (parcelas = []) => {

    try {

      // console.log("CONCLUIR VENDA", parcelas)
      await api.post(`${rotafechada()}vendas/concluir/${vendaAtual}`, {
        parcelas
      });
      MensagemAviso(`Concluído com sucesso! Venda: ${vendaAtual}`);
      recarregaVenda();
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      const erro = error.response?.data?.erro || '';

      MensagemErro(`${msg}-${erro}`);
      if (erro.indexOf('Produtos que não possuem a tabela de preço:') >= 0) {
        mostraMensagemErroListaProdutos(erro);
      }
      recarregaVenda();
    }
  }

  const gerarVenda2 = async () => {

    MensagemAviso(`Concluído com sucesso! Venda: ${vendaAtual}`);
    recarregaVenda();
  }

  const handleImprimirProposta = async (modelo = 'compacto', downloadArquivo = false, comMedidas = true, comValores = true) => {
    let data = new Date()
    const offSet = data.getTimezoneOffset()
    try {
      const resultado = await api.get(`${rotafechada()}imprimir/vendas/proposta?venda_id=${vendaAtual}&modelo=${modelo}&comMedidas=${comMedidas}&comValores=${comValores}&timeoffset=${offSet}`, {
        responseType: 'arraybuffer'
      });

      const file = new Blob([resultado.data], { type: 'application/pdf' });

      if (downloadArquivo) {
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(file);
        link.download = `proposta_${vendaAtual}.pdf`;
        link.click();
        link.remove();
      } else {
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);

      }
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`${msg}`);
    }
  }

  const handleImprimirContrato = async () => {
    let data = new Date()
    const offSet = data.getTimezoneOffset()
    try {
      const resultado = await api.get(`${rotafechada()}imprimir/vendas/contrato?venda_id=${vendaAtual}&empresa_id=${sessaolocal.empresa_id}&timeoffset=${offSet}`, {
        responseType: 'arraybuffer'
      });

      const file = new Blob([resultado.data], { type: 'application/pdf' });

      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);

    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`${msg}`);
    }
  }

  const onCloseDialogoOpcoes = async (value) => {

    //fechando o dialogo
    setOpcoes({ ...opcoes, open: false, tipo: '' });

    switch (parseInt(value, 10)) {
      case 0:
        if (parseInt(getConta_id, 10) > 0) {
          handleClickEstornarVenda();
        } else {
          //gravar o que esta em tela antes de ir para tela de criação da conta e concluir o orçamento
          await handleSubmit(onSubmit)()

          if (parametrosVenda.grava_data_venda_com_data_conclusao == 'SIM') {
            await handleClickGerarVenda();
          } else {
            if (format(new Date(getValues('data_orcamento')), 'yyyy-MM-dd 00:00:00') == format(new Date(), 'yyyy-MM-dd 00:00:00')) {
              await handleClickGerarVenda();
            } else {
              await handleClickDataOrcamentoIgualDataVenda();
            }
          }
        }
        break;
      case 1:
        recalculaPrecos();
        break;
      case 2:
        if (parseInt(vendaAtual, 10) > 0) {
          const permissaotemp = await validaRotaPermissao('moduloalteracaoprecos');
          if (permissaotemp) {
            handleClickAlteraPrecos();
          }

        }
        else MensagemAviso('Escolha a venda que deseja alterar o preço dos materiais');
        break;
      case 3:
        if (parseInt(getConta_id, 10) > 0) {
          ValidaModulo()
          //setOpenMensagemModuloAdicional(true);
          //handleClickPedidoTempera();
          //aqui
        } else {
          MensagemErro('Só é possível gerar pedido de têmpera para "Vendas", transforme o orçamento em uma venda antes!')
        }
        break;
      case 4:
        recalculaPrecosCobrancaBarrasPerfil()
        break
      default:
        break;
    }

    //forçando fechamento das abas, para que ao abrir recarregue novamente
    setOpenCollapseProjeto(false)
    setOpenCollapseProduto(false)
    setOpenCollapseServico(false)

  }

  const onCloseDialogoOpcoesImpressao = (value, downloadArquivo = false, comMedidas = true, comValores = true) => {

    //fechando o dialogo
    setOpcoesImpressao({ ...opcoesImpressao, open: false, tipo: '' });

    switch (parseInt(value, 10)) {
      case 0:
        handleImprimirProposta('compacto', downloadArquivo, comMedidas, comValores);
        break;

      case 1:
        handleImprimirProposta('completo', downloadArquivo, comMedidas, comValores);
        break;

      case 2:
        setOpenRelatorioListaMateriais(true);
        break;

      case 3:
        setOpenRelatorioCustoLucro(true);
        break;

      case 4:
        setOpenRelatorioVendaPorPeriodo(true);
        break;

      case 5:
        setOpenRelatorioListaComponentes(true);
        break;

      case 6:
        setOpenRelatorioListaMateriaisProjetos(true);
        break;

      case 7:

        const message = 'Para garantir que a quantidade dos perfis listados esteja correta conforme os projetos Listados é necessário calcular as sobras das barras, deseja fazer isso agora?';

        setOpenConfirmacaoRecalculo({
          ...openConfirmacaoRecalculo,
          open: true,
          message,
          // handleCancel: () => {
          //   setOpenConfirmacaoRecalculo({...openConfirmacaoRecalculo, open: false })
          //   setOpenRelatorioListaMateriaisAluminio(true)        
          // },
          // handleConfirm: async() => {
          //   setOpenConfirmacaoRecalculo({...openConfirmacaoRecalculo, open: false })
          //   await recalculaPrecosCobrancaBarrasPerfil()
          //   setOpenRelatorioListaMateriaisAluminio(true)
          // }
        });
        break;

      case 8:
        handleImprimirContrato();
        break;

      default:

        break;
    }

  }

  const getConta_id = useWatch({
    control,
    name: "conta_id",
  });

  const handleClickAlteraPrecos = async () => {

    setOpenAlteraPrecos(true);
  }

  const handleClickPedidoTempera = async () => {
    setEtapaAtualPedidoTempera(0);
    setOpenPedidoTempera(true);

  }

  const ValidaModulo = async () => {

    handleClickPedidoTempera();

    // MostraTelaEspera('Validando acesso ao módulo');
    // try {
    //   let url = `${rotafechada()}assinatura_modulos/validar?nome=${nomeModulo}`;
    //   const retorno = await api.get(url);
    //   handleClickPedidoTempera();

    // }
    // catch (error) {
    //   const msg = error.response?.data?.msg || error;
    //   const erro = error.response?.data?.erro || '';
    //   setOpenMensagemModuloAdicional(true)
    // }
    // FechaTelaEspera();

  }

  return (
    <>
      <Dialog open={openMensagemModuloAdicional} onClose={() => {
        setOpenMensagemModuloAdicional(false);
        //handleClickPedidoTempera();
      }}>

        <DialogContent>
          <Typography variant='h5'>Módulo adicional</Typography>
          <Typography variant='h6'> </Typography>
          <Typography variant='h7'>Módulo {captionModulo} não contratado, para mais informações entre em contato com nossa equipe de suporte!</Typography>
        </DialogContent>
        <DialogActions>
          <MDButton color='Success' onClick={() => window.open(packageJson?.urlsuporte.replace('text', 'text=Tenho interesse em contratar o módulo Desenho de Têmpera para o VidrosWeb📌 '))} >
            Contato<WhatsAppIcon fontSize="medium" color='Success' />
          </MDButton>
          <MDButton onClick={() => {
            setOpenMensagemModuloAdicional(false);
            //handleClickPedidoTempera();
          }}>Fechar</MDButton>
        </DialogActions>
      </Dialog>

      <Dialog open={dialogoDataOrcamentoIgualDataVenda.open}
        onClose={() => {
          setDialogoDataOrcamentoIgualDataVenda({ ...dialogoDataOrcamentoIgualDataVenda, open: false });
          //handleClickPedidoTempera();
        }}>

        <DialogContent>
          <Typography variant='h5'>Data da Venda</Typography>
          <Typography variant='h6'> </Typography>

          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
            <DesktopDatePicker
              required={true}
              label='Data venda'
              inputFormat="dd/MM/yyyy"
              value={dataVenda}
              onChange={(e) => {
                if (new Date(e) > new Date()) {
                  console.log('dataVenda', new Date(e));
                  setDataVenda(null)
                } else {
                  setDataVenda(e)
                }
                setDialogoDataOrcamentoIgualDataVenda({ ...dialogoDataOrcamentoIgualDataVenda, data: e })
              }}
              renderInput={(params) => <FormField {...params} required />}
            />
          </LocalizationProvider>

        </DialogContent>
        <DialogActions>
          <MDButton
            onClick={() => { setDialogoDataOrcamentoIgualDataVenda({ ...dialogoDataOrcamentoIgualDataVenda, open: false }) }}
            variant="outlined" color="secondary">
            Cancelar
          </MDButton>
          <MDButton
            onClick={() => {
              setDialogoDataOrcamentoIgualDataVenda({ ...dialogoDataOrcamentoIgualDataVenda, open: false })
              handleClickGerarVenda()
            }}
            disabled={dataVenda == null || dateIsValid(dataVenda) == false ? true : false}
            variant="gradient" color={'info'} sx={{ height: "100%" }} >
            Confirmar
          </MDButton>
        </DialogActions>
      </Dialog>

      <DialogoSimples
        open={dialogoSimples.open || false}
        linhas={dialogoSimples.linhas || []}
        onClose={handleCloseDialogoSimples}
        title={dialogoSimples.title || ''}
      />

      <DialogoConfirma
        open={openConfirmacao?.open || false}
        message={openConfirmacao?.message || ''}
        title={openConfirmacao?.title || 'Confirmação'}
        handleCancel={openConfirmacao.handleCancel}
        handleConfirm={openConfirmacao.handleConfirm}
      />



      {/* 
      <DialogoConfirma
        open={openConfirmacaoRecalculo?.open || false}
        message={openConfirmacaoRecalculo?.message || ''}
        title={openConfirmacaoRecalculo?.title || 'Confirmação'}
        handleCancel={openConfirmacaoRecalculo.handleCancel}
        handleConfirm={openConfirmacaoRecalculo.handleConfirm}
      /> */}


      <DialogoConfirma
        open={openConfirmacaoRecalculo?.open || false}
        message={openConfirmacaoRecalculo?.message || ''}
        title={openConfirmacaoRecalculo?.title || 'Confirmação'}
        handleCancel={() => {
          setOpenConfirmacaoRecalculo({ ...openConfirmacaoRecalculo, open: false })
          setOpenRelatorioListaMateriaisAluminio(true)
        }}
        handleConfirm={async () => {
          setOpenConfirmacaoRecalculo({ ...openConfirmacaoRecalculo, open: false })
          await recalculaPrecosCobrancaBarrasPerfil()
          setOpenRelatorioListaMateriaisAluminio(true)
        }}
      />
      <DialogoOpcoes
        value={''}
        open={opcoes.open || false}
        onClose={onCloseDialogoOpcoes}
        options={opcoes.items || []}
      />

      <DialogoOpcoesImpressao
        value={''}
        open={opcoesImpressao.open || false}
        onClose={onCloseDialogoOpcoesImpressao}
        vendaAtual={vendaAtual}
      />

      <AlteraPrecosMateriaisVenda
        vendaAtual={vendaAtual}
        open={openAlteraPrecos}
        setOpen={setOpenAlteraPrecos}
      />

      <RelatorioListaDeMateriaisPedido
        open={openRelatorioListaMateriais}
        setOpen={setOpenRelatorioListaMateriais}
        vendaAtual={vendaAtual}
      />


      <RelatorioListaDeMateriaisPedidoProjetos
        open={openRelatorioListaMateriaisProjetos}
        setOpen={setOpenRelatorioListaMateriaisProjetos}
        vendaAtual={vendaAtual}
      />

      <RelatorioListaDeComponentesVenda
        open={openRelatorioListaComponentes}
        setOpen={setOpenRelatorioListaComponentes}
        vendaAtual={vendaAtual}
      />

      <RelatorioCustoeLucroPedido
        open={openRelatorioCustoLucro}
        setOpen={setOpenRelatorioCustoLucro}
        vendaAtual={vendaAtual}
      />

      <RelatorioVendasPorPeriodo
        open={openRelatorioVendaPorPeriodo}
        setOpen={setOpenRelatorioVendaPorPeriodo}
        parametrosVenda={parametrosVenda}
      />

      {/* <ConcluirVenda
        open={dialogoConclusao.open || false}
        handleClose={() => setDialogoConclusao({ ...dialogoConclusao, open: false })}
        valorVenda={dialogoConclusao.valorVenda || 0}
        handleConcluir={gerarVenda}

      /> */}

      <RelatorioListaDeMateriaisAluminioPedido
        open={openRelatorioListaMateriaisAluminio}
        setOpen={setOpenRelatorioListaMateriaisAluminio}
        vendaAtual={vendaAtual}
      />

      <ConcluirVendaFinanceiro //tela nova do financeiro
        open={dialogoConclusaoFinanceiro.open || false}
        handleCancel={() => setDialogoConclusaoFinanceiro({ ...dialogoConclusaoFinanceiro, open: false })}
        valorVenda={dialogoConclusaoFinanceiro.valorVenda || 0}
        handleConcluir={gerarVenda2}
        tipo={'R'}
        title={`Nova Venda - ${vendaAtual}`}
        dadosVenda={dialogoConclusaoFinanceiro.dados}
      />

      <PedidoTempera
        open={openPedidoTempera || false}
        handleClose={() => setOpenPedidoTempera(false)}
        vendaAtual={vendaAtual}
        etapaAtual={etapaAtualPedidoTepera}
        setEtapaAtual={setEtapaAtualPedidoTempera}
        parametrosVenda={parametrosVenda}

      />

      {
        //se na abertura do preview do relatorio nao esconder os outros elementos eles geram paginas adicionais na impressao
        //inclusive no firefox ele mostra os elementos em tela na impressao
        ((!openRelatorioListaMateriais) && (!openRelatorioVendaPorPeriodo) && (!openRelatorioCustoLucro)) &&
        <MDBox p={2}>
          <MDBox width="100%" display="flex" justifyContent="space-between">
            <MDButton
              variant="gradient"
              color="secondary"
              type="button"
              onClick={handleClickVoltar}
            >Voltar
            </MDButton>

            <MDButton
              variant="gradient"
              circular
              onClick={novoRegistro}
              color='info'
            >
              <Icon>add</Icon>
            </MDButton>
          </MDBox>

          <form onSubmit={handleSubmit(onSubmit)}>

            <Cabecalho
              control={control}
              opcoesAtivo={opcoesAtivo}
              getValues={getValues}
              setValue={setValue}
              opcoesTabelaPreco={opcoesTabelaPreco}
              recarregaVenda={recarregaVenda}
              parametrosVenda={parametrosVenda}
              opcoesTiposPessoas={opcoesTiposPessoas}
            />

            <MDBox width="100%" display="flex" justifyContent="flex-end"> {/* botoes */}
              <MDButton variant="gradient" color="info"
                type="submit"
                disabled={getConta_id > 0 ? true : false}
              //onClick={() => handleSubmit(onSubmit)()}
              >
                Gravar
              </MDButton>


              {
                parseInt(vendaAtual, 10) > 0 &&
                <MDBox ml={2}>
                  <MDButton variant="gradient" color="dark" onClick={handleClickOpcoesVenda}>
                    Opções
                  </MDButton>
                </MDBox>
              }

              <MDBox ml={2}>
                <MDButton variant="gradient" color="info" onClick={handleClickOpcoesImpressao}>
                  <Tooltip title="Relatórios" placement="top">
                    <Icon>printer</Icon>
                  </Tooltip>
                </MDButton>
              </MDBox>

            </MDBox>
          </form>

          {
            parseInt(vendaAtual, 10) > 0 ?
              <>
                <ProjetosVenda
                  control={control}
                  getValues={getValues}
                  setValue={setValue}
                  vendaAtual={vendaAtual}
                  handleSubmit={handleSubmit}
                  unregister={unregister}
                  recarregaVenda={recarregaVenda}
                  openCollapseProjeto={openCollapseProjeto}
                  handleClickCollapseProjeto={handleClickCollapseProjeto}
                  parametrosVenda={parametrosVenda}
                  verificaVendaConcluida={verificaVendaConcluida}
                />
                <ProdutosVenda
                  control={control}
                  getValues={getValues}
                  setValue={setValue}
                  vendaAtual={vendaAtual}
                  handleSubmit={handleSubmit}
                  unregister={unregister}
                  recarregaVenda={recarregaVenda}
                  openCollapseProduto={openCollapseProduto}
                  handleClickCollapseProduto={handleClickCollapseProduto}
                  verificaVendaConcluida={verificaVendaConcluida}
                />
                <ServicosVenda
                  control={control}
                  getValues={getValues}
                  setValue={setValue}
                  vendaAtual={vendaAtual}
                  handleSubmit={handleSubmit}
                  unregister={unregister}
                  recarregaVenda={recarregaVenda}
                  openCollapseServico={openCollapseServico}
                  handleClickCollapseServico={handleClickCollapseServico}
                  verificaVendaConcluida={verificaVendaConcluida}
                />
              </>
              : false
          }
          {/* </form> */}
        </MDBox>
      }
    </>
  )

}

export default Cadastro;
