import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import { useContext } from 'react';
import MessagesContext from 'hooks/MessagesContext';
import { useEffect } from 'react';
import { useState } from 'react';
import { CheckBox } from '@mui/icons-material';
import { alertTitleClasses, Checkbox, FormControlLabel, Icon, Typography } from '@mui/material';
import { copiaProfundaObjetos } from 'commons/utils';
import { Box } from '@mui/system';
import TabelaAlteracaPreco from './tabela';
import { Link } from 'react-router-dom';
import MenuOpcoesProduto from './menuopcoesproduto';
import MDButton from 'components/MDButton';
import { removeZeros } from 'commons/utils';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  link: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));


export default function AlteraPrecosMateriaisVenda(props) {

  const { open, setOpen, vendaAtual } = props;
  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera  } = messageContext;

  const [dataset, setDataset] = useState([]);
  const [colunas, setColunas] = useState([]);
  const [linhas, setLinhas] = useState([]);
  const [nomeTabelaPreco, setNomeTabelaPreco] = useState('');
  const [openMenuConfiguracao, setOpenMenuConfiguracao] = useState(false);
  const [currentTargetBotaoMenu, setCurrentTargetBotaoMenu] = useState(null);
  const [produtoPrecoId, setProdutoPrecoId] = useState('');

  const linkRef = React.useRef(null);

  const handleMouseOver = () => {
    linkRef.current.style.cursor = 'pointer'; // Muda o cursor para a mão
  };

  const handleMouseOut = () => {
    linkRef.current.style.cursor = 'default'; // Volta ao cursor padrão
  };


  const buscaMateriaisVenda = async () => {

    let url = '';
    let retorno;

    try {
      MostraTelaEspera()
      url = `${rotafechada()}vendas/materiaisalteracaopreco?venda_id=${vendaAtual}&retornaimagem=S`;
      retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;
      const nometabelapreco = retorno.data?.Venda?.nometabelapreco;

      const linhastemp = [];
      const colunastemp = [
        { id: 'projeto', label: 'Proj. Venda', sx: { width: 80 } },
        { id: 'material', label: 'Material', sx: { width: 100 } },
        { id: 'nomematerial', label: 'Nome Material', sx: { width: 500 }  },
        { id: 'referenciamaterial', label: 'Referência Material', sx: { width: 150 } },
        { id: 'nomecor', label: 'Cor', sx: { width: 200 } },
        { id: 'valor_custo', label: 'Valor Custo', sx: { width: 100 } },
        { id: 'percentual_margem', label: 'Margem(%)', sx: { width: 100 } },
        { id: 'preco_sugerido', label: 'Sugerido', sx: { width: 100 } },
        { id: 'valor_venda', label: 'Valor de Venda', sx: { width: 100 } },
        { id: 'icon_opcoes', label: '', sx: { width: 10 } },
        // { id: 'unidade_medida', label: 'Un. Medida', sx: { width: 50 } },
        // { id: 'peso', label: 'Peso', sx: { width: 50 } },
        // { id: 'tamanho_barra', label: 'Tamanho Barra', sx: { width: 50 } },
        { id: 'produto_grade_id', label: '', disabled: true },
        { id: 'produto_id', label: '', disabled: true },
        { id: 'produto_preco_id', label: '', disabled: true },
        { id: 'servico_id', label: '', disabled: true },
        { id: 'servico_preco_id', label: '', disabled: true },
      ];

      data?.map((item) => {

        let addnovalinha = true;

        if (item?.produto_grade_id) {
          //procura se esse produto_grade_id ja foi inserido
          addnovalinha = !(linhastemp.filter((itemlinha) => parseInt(itemlinha.produto_grade_id, 10) === parseInt(item.produto_grade_id)).length > 0);
        }
        else
          if (item?.servico_preco_id) {
            //procura se esse servico_id ja foi inserido
            addnovalinha = !(linhastemp.filter((itemlinha) => parseInt(itemlinha.servico_id, 10) === parseInt(item.servico_id)).length > 0);
          }

        if (addnovalinha) {

          let valor_custo = item?.Servico ? item?.Servico?.valor_custo : item?.Produto_Grade?.valor_custo || '';
          let valor_venda = item?.Servico?.Servico_Precos?.length > 0 ? item?.Servico?.Servico_Precos[0].valor_venda : item?.Produto_Grade?.Produto_Precos[0]?.valor_venda || '';
          let percentual_margem = item?.Servico?.Servico_Precos?.length > 0 ? item?.Servico?.Servico_Precos[0].percentual_margem : item?.Produto_Grade?.Produto_Precos[0]?.percentual_margem || '';
          let preco_sugerido = parseFloat(valor_custo) + (parseFloat(valor_custo) * parseFloat(percentual_margem)) / 100;
          preco_sugerido = preco_sugerido.toFixed(2);

          if (parseFloat(valor_custo) > 0) {
            valor_custo = parseFloat(valor_custo).toFixed(2);
          }

          if (parseFloat(valor_venda) > 0) {
            valor_venda = parseFloat(valor_venda).toFixed(2);
          }

          if (parseFloat(percentual_margem) > 0) {
            percentual_margem = removeZeros(percentual_margem);
          }

          linhastemp.push({
            material: item?.Servico ? 'SERVIÇO' : item?.Produto_Grade?.Produto?.Tipos_Produto?.nome || '....',

            nomematerial: item?.Servico ?
              <Link
                style={{ textDecoration: "none", color: 'black' }}
                to={`/servicos/servico/${item.servico_id}`}
              >
                {`${item?.servico_id}-${item?.Servico?.nome}`}
              </Link>
              :
              <Link
                //ref={linkRef}
                style={{ cursor: 'pointer', textDecoration: "none", color: 'black' }}
                //onMouseOver={handleMouseOver}
                //onMouseOut={handleMouseOut}
                to={`/produtos/produto/${item.Produto_Grade?.produto_id}`}
              >
                {`${item.Produto_Grade?.produto_id}-${item?.Produto_Grade?.Produto?.nome || '...'}`}
              </Link>,

            referenciamaterial: item?.Servico ? item?.Servico?.referencia : item?.Produto_Grade?.Produto?.referencia || '...',
            produto_id: item?.Produto_Grade?.produto_id,
            // unidade_medida: item?.Produto_Grade?.Produto?.Unidades_Medida?.unidade,
            peso: removeZeros(item?.Produto_Grade?.Produto?.peso),
            tamanho_barra: removeZeros(item?.Produto_Grade?.Produto?.tamanho_barra),
            projeto: item?.Venda_Projeto?.id,
            nomecor: item?.Servico ? '' : item?.Produto_Grade?.Core?.nome || '...',
            valor_custo: removeZeros(valor_custo),
            valor_venda: removeZeros(valor_venda),
            percentual_margem: removeZeros(percentual_margem),
            preco_sugerido: removeZeros(preco_sugerido),
            icon_opcoes: item?.Produto_Grade?.Produto_Precos?.length > 0 ? <Icon onClick={(event) => {
              setCurrentTargetBotaoMenu(event.currentTarget);
              abreMenuProduto(item?.Produto_Grade?.Produto_Precos[0].id);
            }
            }>more_horiz</Icon> : '',
            produto_grade_id: item?.produto_grade_id || '',
            produto_preco_id: item?.Produto_Grade?.Produto_Precos?.length > 0 ? item?.Produto_Grade?.Produto_Precos[0].id : '',
            servico_id: item?.servico_id || '',
            servico_preco_id: item?.Servico?.Servico_Precos?.length > 0 ? item?.Servico?.Servico_Precos[0].id : '',
          })
        }
      })

      setDataset(data);
      setColunas(colunastemp);
      console.log(linhastemp);
      setLinhas(linhastemp);
      setNomeTabelaPreco(nometabelapreco);
      FechaTelaEspera();
    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleClickOk = () => {
    MensagemAviso('Caso tenha alterado algum preço recalcule a proposta atual!');
    setOpen(false);
  }

  useEffect(() => {
    if (open) {
      buscaMateriaisVenda();
    }
  }, [open])

  const abreMenuProduto = (id) => {
    setProdutoPrecoId(id);
    setOpenMenuConfiguracao(true);
  }


  return (
    <>
      <MenuOpcoesProduto
        open={openMenuConfiguracao}
        setOpen={setOpenMenuConfiguracao}
        produto_preco_id={produtoPrecoId}
        currentTarget={currentTargetBotaoMenu}
      />

      <Dialog
        maxWidth="xl"
        open={open}
        onClose={() => setOpen(false)}
        fullScreen
      >
        <DialogTitle>{'Alteração em Lote do Preço dos Materiais da Venda'}</DialogTitle>

        <DialogContent dividers>
          <Typography variant='h6'>Tabela de Preço da Venda: {nomeTabelaPreco}</Typography>
          <Box>
            <TabelaAlteracaPreco
              dataset={dataset}
              linhas={linhas}
              setLinhas={setLinhas}
              colunas={colunas}
            />

          </Box>

        </DialogContent>

        <DialogActions>
          <MDButton variant="gradient"
            color="info"
            type="button"
            onClick={handleClickOk}>Ok</MDButton>
        </DialogActions>
      </Dialog>

    </>)
}