import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import { useContext } from 'react';
import MessagesContext from 'hooks/MessagesContext';
import { useEffect } from 'react';
import { useState } from 'react';
import { Autocomplete, Checkbox, FormControlLabel, Grid, Typography, Icon } from '@mui/material';
import { Box } from '@mui/system';
import MDInput from 'components/MDInput';
import FormField from 'componentes/FormField';


export default function FiltroInserePrecosTabelaPreco(props) {

  const { open, setOpen, handleOk } = props;


  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MensagemAviso } = messageContext;
  const opcaoDefault = { value: -1, label: 'Não copiar de outra tabela' };

  const [opcoesTabela, setOpcoesTabela] = useState([{ ...opcaoDefault }]);
  const [tabelaEscolhida, setTabelaEscolhida] = useState({ ...opcaoDefault });
  const [percentual_margem, setPercentual_margem] = useState('100');


  const buscaTabelas = async () => {

    let url = '';
    let retorno;

    try {

      url = `${rotafechada()}tabelas_precos?limit=-1`;
      retorno = await api.get(url);
      const { data } = retorno.data;
      const temp = [];
      temp.push({...opcaoDefault});
      data.map((item) => {
        temp.push({ value: item.id, label: item.nome })
      })
      setOpcoesTabela(temp);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleClickOk = () => {

    if (!handleOk)
      return

    handleOk(tabelaEscolhida.value||-1, percentual_margem);
  }

  const handleClickCancelar = () => {
    setOpen(false);
  }

  useEffect(() => {

    if (open) {
      buscaTabelas();

    }

  }, [open])


  return (
    <>
      <Dialog
        maxWidth="xl"
        open={open}
        onClose={handleClickCancelar}
      >
        <DialogTitle>{'Insere Preços na Tabela Escolhida'}</DialogTitle>

        <DialogContent dividers>

          <Typography variant='h6'>Escolha a Tabela Modelo:</Typography>
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>{/*Projeto*/}
                <Autocomplete
                  options={opcoesTabela}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  renderInput={(params) => <MDInput {...params} variant="standard" />}
                  onChange={(event, newValue) => {
                    setTabelaEscolhida(newValue);
                  }}
                  defaultValue={opcaoDefault}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormField
                  type='number'
                  label='Percentual da Margem (%)'
                  rows={1}
                  multiline={false}
                  required={true}
                  disabled={false}
                  value={percentual_margem}
                  onChange={(e)=>setPercentual_margem(e.target.value)}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={handleClickCancelar}>
            Cancelar
          </Button>
          <Button onClick={handleClickOk}>Ok</Button>
        </DialogActions>
      </Dialog>

    </>)
}