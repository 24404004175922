
import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Box, Icon, IconButton, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CategoriaCentralRelatorios from "./TabelaCategoriaCentralRelatorios";
import MDButton from "components/MDButton";
import { configTextField } from "./configfields";
import { useConfigForm } from "hooks/ConfigForm";
import MessagesContext from "hooks/MessagesContext";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import AuthContext from "hooks/AuthContext";
import DialogoConfirma from 'componentes/DialogoConfirma';
import { confPesquisaDinamicaInitialState } from "componentes/PesquisaDinamica";
import PesquisaDinamica from "componentes/PesquisaDinamica";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SettingsIcon from '@mui/icons-material/Settings';
import MenuConfiguracaoModulos from "componentes/MenuConfiguracaoModulos";
import GenericForm from "componentes/GenericForm";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.any,
  index: PropTypes.number,
  value: PropTypes.number,
};

function Categoria_Central_Relatorios() {

  const [aba, setAba] = React.useState(0);
  const [codigoCarregado, setCodigoCarregado] = useState('');

  const messageContext = useContext(MessagesContext);
  const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;

  const [dataset, setDataset] = useState([]);

  const configtext = configTextField;
  const { configForm, setConfig } = useConfigForm();

  const [openFiltrosAvancados, setOpenFiltrosAvancados] = useState(false);

  const [openMenuConfiguracao, setOpenMenuConfiguracao] = useState(false);
  const [currentTargetBotaoMenu, setCurrentTargetBotaoMenu] = useState(null);


  configtext.map((conf, index) => {

    let confmodelo = { ...confPesquisaDinamicaInitialState }

    if (conf.name === 'categoriacentralelatorios') {
      const modelocoluna = {
        id: '',
        numeric: false,
        disablePadding: true,
        label: ''
      };
      confmodelo = {
        open: false,
        titulo: 'Pesquisa de Categoria Central dos Relatórios',
        urlBase: `${rotafechada()}categoria_central_relatorios`,
        campoRetorno: 'id',
        campoNomeLabel: 'nome',
        colunas: [
          { ...modelocoluna, id: 'id', label: 'Id' },
          { ...modelocoluna, id: 'nome', label: 'Nome' },
          { ...modelocoluna, id: 'ativo', label: 'Ativo' },
        ],
        camposFiltro: [{ id: 'nome', label: 'Nome' }, { id: 'id', label: 'Id' }],
        campoAuxiliar: '',
        //ao clicar na label a rota que ele vai chamar para abrir a janela
        //o :ID sera substitudo pelo valor que esteja no input correspondente do campo
        rotachamadaclicklabel: '/pages/servicos/tipospessoas?id=:ID'
      }

      conf.confPesquisa = { ...confmodelo }
      conf.onBlur = handleOnBlurCategoriaCentralRelatorios
    }

    return conf;
  })

  setConfig(configtext); //->setConfig

  const authContext = useContext(AuthContext);
  const { getSessao } = authContext;
  const sessaolocal = getSessao();

  const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });
  const [recarregaTabela, setRecarregaTabela] = useState(false);

  const [openPesquisaDinamica, setOpenPesquisaDinamica] = useState(false);

  const limpaDataset = () => {

    let dadoslimpos = {};
    configForm.map((item) => {
      dadoslimpos[item.name] = '';
      if ((item.type === 'autocomplete') && (item.items?.length > 0)) {
        dadoslimpos[item.name] = item.items[0];
      }
    });
    setDataset(dadoslimpos);
  }

  const handleClickNovo = () => {
    limpaDataset();

    setCodigoCarregado('');
    setAba(1)
  }

  const abrirRegistro = async (id) => {

    if (!parseInt(id, 10) > 0)
      return;

    try {

      const retorno = await api.get(`${rotafechada()}categoria_central_relatorios?id=${id}`);
      const { data, count, countAll } = retorno.data;

      if (!count > 0) {
        MensagemErro('Nenhuma informação foi selecionada');
        return;
      }

      let dados = { ...data[0] };

      setDataset(dados);
      setCodigoCarregado(id);
      setAba(1);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleGravar = async (dados) => {

    delete dados.id;

    try {
      dados = { ...dados, empresa_id: sessaolocal.empresa_id || null };

      let retorno;

      if (codigoCarregado && parseInt(codigoCarregado, 10) > 0)
        retorno = await api.put(`${rotafechada()}categoria_central_relatorios/${codigoCarregado}`, dados);
      else {
        retorno = await api.post(`${rotafechada()}categoria_central_relatorios`, dados);
        setCodigoCarregado(retorno.data.data.id || 0);
        setDataset(retorno.data.data);
      }
      MensagemAviso('Gravado com sucesso!');
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleExcluir = async () => {
    setDialogoOpen({ ...dialogoOpen, visible: false });

    try {
      const retorno = await api.delete(`${rotafechada()}categoria_central_relatorios/${dialogoOpen.id || 0}`);
      MensagemAviso('Excluído com sucesso!');
      limpaDataset();
      setRecarregaTabela(!recarregaTabela);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }

  }

  const handleClickExcluir = (id) => {
    setDialogoOpen({ ...dialogoOpen, visible: true, id });

  }

  const handleClickSelecaoPesquisaDinamica = (field, datasetPesquisaDinamica) => {
    alert(`${field} ->${JSON.stringify(datasetPesquisaDinamica)}`);
  }

  async function handleOnBlurCategoriaCentralRelatorios(index = -1, valorinput = '', cbUpdateLabel) {

    if (index < 0) {
      return;
    }

    try {
      if (valorinput !== '') {
        const retorno = await api.get(`${rotafechada()}categoria_central_relatorios?id=${valorinput}`);
        cbUpdateLabel(index, retorno?.data?.data[0]?.nome || '');
      }
      else cbUpdateLabel(index, '');

    }
    catch (e) {
      console.log(e);
      const msgerro = e?.response?.data?.msgerro || '';
      MensagemErro(`Erro: ${msgerro}`);
      return false;
    }

  }

  return (
    <>
      <MenuConfiguracaoModulos
        open={openMenuConfiguracao}
        setOpen={setOpenMenuConfiguracao}
        nomeModulo='CATEGORIA_CENTRLA_RELATORIOS'
        currentTarget={currentTargetBotaoMenu}
      />

      <PesquisaDinamica
        open={openPesquisaDinamica}
        setOpen={setOpenPesquisaDinamica}
        handleClick={handleClickSelecaoPesquisaDinamica}
        campoRetorno='id'
        titulo='Categoria Central de Relatórios'
        urlBase={`${rotafechada()}categoria_central_relatorios`}
        limit={10}
        colunas={[
          {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'ID',
          },
          {
            id: 'nome',
            numeric: false,
            disablePadding: true,
            label: 'Nome',
          },
          {
            id: 'ativo',
            numeric: false,
            disablePadding: true,
            label: 'Ativo',
          },
        ]}
        camposFiltro={[
          { id: 'id', label: 'Id' },
          { id: 'nome', label: 'Nome', buscaParcial: true },
        ]}
      />

      <DialogoConfirma
        open={dialogoOpen.visible}
        title={'Confirma Exclusão'}
        color={'error'}
        message={'Certeza que deseja excluir?'}
        handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
        handleConfirm={handleExcluir}
      />

      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={12}>
            <Card>
              <MDBox mt={-3} mb={3} mx={2}>
              </MDBox>

              <TabPanel value={aba} index={0}>
                <MDBox width="100%" display="flex" justifyContent="space-between">
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpenFiltrosAvancados(!openFiltrosAvancados)}
                  >
                    {openFiltrosAvancados ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    <Typography>Filtros</Typography>
                  </IconButton>

                  <MDButton
                    variant="gradient"
                    circular
                    onClick={handleClickNovo}
                    color='info'
                  >
                    <Icon>add</Icon>
                  </MDButton>
                </MDBox>

                <CategoriaCentralRelatorios
                  openFiltrosAvancados={openFiltrosAvancados}
                  setOpenFiltrosAvancados={setOpenFiltrosAvancados}
                  abrirRegistro={abrirRegistro}
                  handleClickExcluir={handleClickExcluir}
                  recarregaTabela={recarregaTabela} />

              </TabPanel>

              <TabPanel value={aba} index={1}>
                <MDBox p={2}>
                  <MDBox width="100%" display="flex" justifyContent="space-between">
                    <MDButton
                      variant="gradient"
                      color="secondary"
                      type="button"
                      onClick={() => setAba(0)}
                    >Voltar</MDButton>
                    <MDButton
                      variant="gradient"
                      circular
                      color="info"
                      type="button"
                      onClick={handleClickNovo}
                    >
                      <Icon>add</Icon>
                    </MDButton>
                  </MDBox>

                  <MDBox>
                    <MDBox mb={3}>
                      <Typography variant='h6' ></Typography>
                    </MDBox>
                    
                    <GenericForm
                      setCodigoAbrir={setCodigoCarregado}
                      permiteAlteracao={true}
                      funcaoGravar={handleGravar} //precisa retornar um boolean
                      campoPrimaryKey={'id'}
                      dataset={dataset}
                    />
                  </MDBox>

                </MDBox>
              </TabPanel>

            </Card>
          </Grid>
        </Grid>
      </MDBox>

    </>
  );
}

export default Categoria_Central_Relatorios;
