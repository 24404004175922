import React, { useRef, useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import { Box } from "@mui/material";

import MDButton from "components/MDButton";

import format from 'date-fns/format'
import { ptBR } from "date-fns/locale";
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import FormField from "componentes/FormField";


function FiltroDataPersonalizado(props) {

    const { dataInicial, setDataInicial, dataFinal, setDataFinal, onClickAplicar } = props;

    const dataInicialRef = useRef(null);
    const dataFinalRef = useRef(null);
    const botaoAplicarRef = useRef(null);

    return (

        <Card>
            <Box paddingBottom={1} paddingTop={1}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                    <DesktopDatePicker
                        required={true}
                        //disabled={desabilitaData}
                        label='Data Inicial'
                        inputFormat="dd/MM/yyyy"
                        value={dataInicial}
                        //onChange={(e) => setDataInicial(e)}
                        //renderInput={(params) => <FormField {...params} required />}

                        onChange={(e) => setDataInicial(e)}
                        renderInput={(params) => (
                            <FormField
                                {...params}
                                inputRef={dataInicialRef}
                                onKeyDown={(e) => {
                                    if (e.key === "Tab") {
                                        e.preventDefault(); // Previne o comportamento padrão
                                        if (dataFinalRef.current) {
                                            dataFinalRef.current.focus(); // Foca no próximo campo
                                        }
                                    }
                                }}
                                required
                            />
                        )}
                    />
                </LocalizationProvider>
            </Box>
            <Box>
                até
            </Box>
            <Box paddingBottom={1} paddingTop={1}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                    <DesktopDatePicker
                        required={true}
                        //disabled={desabilitaData}
                        label='Data Final'
                        inputFormat="dd/MM/yyyy"
                        value={dataFinal}
                        onChange={(e) => setDataFinal(e)}
                        //renderInput={(params) => <FormField {...params} required />}
                        renderInput={(params) => (
                            <FormField {...params}
                                inputRef={dataFinalRef}
                                onKeyDown={(e) => {
                                    if ((e.key === "Tab") && (e.shiftKey)) {
                                        e.preventDefault(); // Previne o comportamento padrão
                                        if (dataInicialRef.current) {
                                            dataInicialRef.current.focus(); // Foca no próximo campo
                                        }
                                    }
                                    else
                                        if (e.key === "Tab") {
                                            e.preventDefault(); // Previne o comportamento padrão
                                            if (botaoAplicarRef.current) {
                                                botaoAplicarRef.current.focus(); // Foca no botao
                                            }
                                        }
                                        else
                                            if (e.key === "Enter") {
                                                e.preventDefault(); // Previne o comportamento padrão

                                                if (botaoAplicarRef.current) {
                                                    botaoAplicarRef.current.click(); // Simula o clique no botão
                                                }

                                            }
                                }}
                                required />
                        )}
                    />
                </LocalizationProvider>
            </Box>
            <MDButton
                ref={botaoAplicarRef} // Cria uma referência para o botão
                variant="gradient"
                color={'info'}

                onClick={onClickAplicar}
                onKeyDown={(e) => {
                    if ((e.key === "Tab") && (e.shiftKey)) {
                        e.preventDefault(); // Previne o comportamento padrão
                        if (dataFinalRef.current) {
                            dataFinalRef.current.focus(); // Foca no próximo campo
                        }
                    }
                    else
                        if (e.key === "Tab") {
                            e.preventDefault(); // Previne o comportamento padrão
                            if (dataInicialRef.current) {
                                dataInicialRef.current.focus(); // Foca no próximo campo
                            }
                        }
                }}
            >Aplicar filtro </MDButton>
        </Card>


    )
}

export default FiltroDataPersonalizado;