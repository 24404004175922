import React, { Component, useState } from 'react';
import { Stage, Layer, Rect, Text, Shape, Circle, Line, Image, Transformer, Arrow } from 'react-konva';
import {Circulo} from './objetos/circulo';
import {CotaHorizontal} from './objetos/cotahorizontal';
import CotaVertical from './objetos/cotavertical';
import {LinhaHorizontal} from './objetos/linhahorizontal';
import LinhaVertical from './objetos/linhavertical';
import {Retangulo} from './objetos/retangulo';
import Seta from './objetos/seta';
import {Texto} from './objetos/texto';

export default function CamadaDesenho(props) {

    const { itensTela, selectShape, setItensTela, LARGURA, ALTURA, selectedId,stageRef} = props;


    return (
        <Stage
            width={LARGURA}
            height={ALTURA}
            ref={stageRef}
        >
            <Layer>
                {
                    itensTela?.map((item, i) => {

                        switch (item.tipo) {
                            case 'rect':
                                return (<Retangulo
                                    key={i}
                                    shapeProps={item}
                                    isSelected={i === selectedId}
                                    onSelect={() => {
                                        selectShape(i);
                                    }}
                                    onChange={(newAttrs) => {
                                        const itenstemp = itensTela.slice();
                                        itenstemp[i] = newAttrs;
                                        setItensTela(itenstemp);
                                    }}
                                />)

                            case 'circle':
                                return (<Circulo
                                    key={i}
                                    shapeProps={item}
                                    isSelected={i === selectedId}
                                    onSelect={() => {
                                        selectShape(i);
                                    }}
                                    onChange={(newAttrs) => {
                                        const itenstemp = itensTela.slice();
                                        itenstemp[i] = newAttrs;
                                        setItensTela(itenstemp);
                                    }}
                                />)

                            case 'text':
                                return (<Texto
                                    key={i}
                                    shapeProps={item}
                                    isSelected={i === selectedId}
                                    onSelect={() => {
                                        selectShape(i);
                                    }}
                                    onChange={(newAttrs) => {
                                        const itenstemp = itensTela.slice();
                                        itenstemp[i] = newAttrs;
                                        setItensTela(itenstemp);
                                    }}
                                />)

                            case 'arrow':
                                return (<Seta
                                    key={i}
                                    shapeProps={item}
                                    isSelected={i === selectedId}
                                    onSelect={() => {
                                        selectShape(i);
                                    }}
                                    onChange={(newAttrs) => {
                                        const itenstemp = itensTela.slice();
                                        itenstemp[i] = newAttrs;
                                        setItensTela(itenstemp);
                                    }}

                                />)
                            case 'cotahorizontal':
                                return (<CotaHorizontal
                                    key={i}
                                    shapeProps={item}
                                    isSelected={i === selectedId}
                                    onSelect={() => {
                                        selectShape(i);
                                    }}
                                    onChange={(newAttrs) => {
                                        const itenstemp = itensTela.slice();
                                        itenstemp[i] = newAttrs;
                                        setItensTela(itenstemp);
                                    }}

                                />)

                            case 'cotavertical':
                                return (<CotaVertical
                                    key={i}
                                    shapeProps={item}
                                    isSelected={i===selectedId}
                                    onSelect={() => {
                                        selectShape(i);
                                    }}
                                    onChange={(newAttrs) => {
                                        const itenstemp = itensTela.slice();
                                        itenstemp[i] = newAttrs;
                                        setItensTela(itenstemp);
                                    }}

                                />)

                            case 'linhahorizontal':
                                return (<LinhaHorizontal
                                    key={i}
                                    shapeProps={item}
                                    isSelected={i===selectedId}
                                    onSelect={() => {
                                        selectShape(i);
                                    }}
                                    onChange={(newAttrs) => {
                                        const itenstemp = itensTela.slice();
                                        itenstemp[i] = newAttrs;
                                        setItensTela(itenstemp);
                                    }}

                                />)

                            case 'linhavertical':
                                return (<LinhaVertical
                                    key={i}
                                    shapeProps={item}
                                    isSelected={i===selectedId}
                                    onSelect={() => {
                                        selectShape(i);
                                    }}
                                    onChange={(newAttrs) => {
                                        const itenstemp = itensTela.slice();
                                        itenstemp[i] = newAttrs;
                                        setItensTela(itenstemp);
                                    }}

                                />)

                            default:
                                break;
                        }

                    })
                }

            </Layer>
        </Stage>
    )
}