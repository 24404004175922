import React, { useContext, useState } from 'react';
import RelatorioPadrao from '../../../relatorios/padrao';
import FiltroListaMateriaisImpressao from './filtro';
import DataPrinterContext from 'hooks/DataPrinter';
import MessagesContext from 'hooks/MessagesContext';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import FiltroListaComponentesImpressao from './filtro';


export default function RelatorioListaDeComponentesVenda(props) {

    const { vendaAtual, open, setOpen } = props;
    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MensagemAviso } = messageContext;
    const [openRelatorioPadrao, setOpenRelatorioPadrao] = useState(false);
    const dataPrinterContext = useContext(DataPrinterContext);

    const geraRelatorio = async (params, data) => {


        dataPrinterContext.setEstiloColunas({ backgroundColor: '#F2F4F4', fontSize: '14px' })
        dataPrinterContext.setEstiloLinhas({ backgroundColor: 'white', fontSize: '12px' })

        dataPrinterContext.setTitulo(`Lista de Componentes do Pedido ${vendaAtual}`);

        const subtitulo = [];

        let nomestipos = ``;
        params?.tipos?.map((item) => {
            nomestipos = `${nomestipos}${item.nome} / `;
        });

        if (nomestipos !== '') {
            nomestipos = `Tipos ${nomestipos}`;
        }

        if (nomestipos !== '') {
            subtitulo.push('Filtros: ' + nomestipos);
        }

        dataPrinterContext.setSubtitulo(subtitulo);

        const colunas = [];

        //colunas.push({ id: 'projeto', label: 'Projeto', sx: { width: '100px' } });
        colunas.push({ id: 'componente', label: 'Componente', sx: { width: '80px' } });
        colunas.push({ id: 'produto', label: 'Produto', sx: { width: '50px' } });
        colunas.push({ id: 'cor', label: 'Cor', sx: { width: '20px' } });
        colunas.push({ id: 'altura_final', label: 'Altura Final', sx: { width: '20px' } });
        colunas.push({ id: 'largura_final', label: 'Largura Final', sx: { width: '20px' } });
        colunas.push({ id: 'altura_projeto', label: 'Altura Dig.', sx: { width: '20px' } });
        colunas.push({ id: 'largura_projeto', label: 'Largura Dig.', sx: { width: '20px' } });


        dataPrinterContext.setColunas(colunas);

        const linhasTemp = [];
        /*
        const idprojeto
        pega o id do primeiro Projetos e imprime uma primeira linha
        só com o nome do projeto e os outros dados em branco, lembra de por o negrito

        */
        let id_projeto = data[0].venda_projeto_id
        linhasTemp.push({
            //projeto: '',
            componente: data[0]?.Venda_Projeto?.Projeto.nome + '-' + data[0]?.Venda_Projeto.ambiente_instalacao,
            produto: '-',
            cor: '-',
            altura_final: '-',
            largura_final: '-',
            altura_projeto: '-',
            largura_projeto: '-',
            sx: {
                fontWeight: 'bold',
                fontSize: '14px'
            }
        })
        data?.map((item, index) => {

            /*

            if o projeto atual for difetrente do idprojeto salvo
            entao imprime uma nova linha com o nome do projeto
            e atualiza a variavel pro novo id


            */


            if (item.venda_projeto_id != id_projeto) {

                linhasTemp.push({
                    //projeto: '',
                    componente: item?.Venda_Projeto?.Projeto.nome + '-' + item?.Venda_Projeto.ambiente_instalacao,
                    produto: '-',
                    cor: '-',
                    altura_final: '-',
                    largura_final: '-',
                    altura_projeto: '-',
                    largura_projeto: '-',
                    sx: {
                        fontWeight: 'bold',
                        fontSize: '14px'
                    }
                })

                id_projeto = item.venda_projeto_id

            }

            linhasTemp.push({

                //projeto: item?.Venda_Projeto?.Projeto.nome,
                componente: item?.Componentes_Projeto?.nome + (item?.observacao ? "(" + item?.observacao + ")" : ""),
                produto: item?.Venda_Produto?.Produto_Grade?.Produto?.nome,
                cor: item?.Venda_Produto?.Produto_Grade?.Core?.nome,
                altura_final: parseFloat(item?.altura_final).toFixed(2),//medida digitada-folga
                largura_final: parseFloat(item?.largura_final).toFixed(2),//medida digitada-folga
                altura_projeto: parseFloat(item?.altura_projeto).toFixed(2),//medida digitada
                largura_projeto: parseFloat(item?.largura_projeto).toFixed(2),//medida digitada
            })



        })

        dataPrinterContext.setLinhas(linhasTemp);
    }

    const handleImprimir = async (params = { tipos: [], projetos: [] }) => {

        //console.log("obj vindo do ok do filtro", params)
        let complementoUrl = '';
        params?.tipos?.map((item) => {

            if (item.checked === true) {
                complementoUrl = `${complementoUrl}&tipo_produto_id=${item.id}`;
            }

        });

        params?.projetos?.map((item) => {

            complementoUrl = `${complementoUrl}&venda_projeto_id=${item}`;

        });

        if (!(params?.tipos?.length > 0)) {
            MensagemErro('Selecione pelo menos um tipo de material para imprimir o relatório');
            return;
        }

        try {
            let url = `${rotafechada()}vendas/dadosrelatorios/componentesvenda?venda_id=${vendaAtual}${complementoUrl}`;
            //console.log("URL", url)
            const retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;

            if (!(data.length > 0)) {
                MensagemAviso('Nenhuma informação foi selecionada');
                return;
            }

            await geraRelatorio(params, data);
            setOpenRelatorioPadrao(true);
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`${msg}`);
        }
    }

    return (
        <>
            <RelatorioPadrao open={openRelatorioPadrao} setOpen={setOpenRelatorioPadrao} />
            <FiltroListaComponentesImpressao
                open={open}
                setOpen={setOpen}
                handleOk={handleImprimir}
                vendaAtual={vendaAtual}
            />

        </>
    )
}