import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import StorageIcon from '@mui/icons-material/Storage';
import CloudSync from '@mui/icons-material/CloudSync';
import BorderColor from '@mui/icons-material/BorderColor';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import Settings from '@mui/icons-material/Settings';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { useNavigate } from 'react-router-dom';

export default function MenuOpcoesEmpresa(props) {


    const navigate = useNavigate();
    const { open, setOpen, currentTarget, handleClickUsuarios, handleClickAssinatura, handleClickEstatisticaCs, handleClickPermissoes,
        handleClickImportaDados, handleClickImportaPreContrato, empresaAtual } = props;

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Menu
                anchorEl={currentTarget}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >

                <MenuItem
                    onClick={handleClickUsuarios}
                    disabled={((parseInt(empresaAtual, 10) > 0)>0)?false:true}
                    
                >
                    <ListItemIcon>
                        <ManageAccountsIcon fontSize="small" />
                    </ListItemIcon>
                    Usuários
                </MenuItem>
                <MenuItem
                    onClick={handleClickPermissoes}
                    disabled={((parseInt(empresaAtual, 10) > 0)>0)?false:true}
                >
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    Processa Permissões
                </MenuItem>

                <MenuItem
                    onClick={handleClickImportaDados}
                    disabled={((parseInt(empresaAtual, 10) > 0)>0)?false:true}
                >
                    <ListItemIcon>
                        <StorageIcon fontSize="small" />
                    </ListItemIcon>
                    Banco de Dados
                </MenuItem>

                <MenuItem
                    onClick={handleClickAssinatura}
                    disabled={((parseInt(empresaAtual, 10) > 0)>0)?false:true}
                >
                    <ListItemIcon>
                        <BorderColor fontSize="small" />
                    </ListItemIcon>
                    Assinatura
                </MenuItem>

                <MenuItem
                    onClick={handleClickEstatisticaCs}
                    disabled={((parseInt(empresaAtual, 10) > 0)>0)?false:true}
                >
                    <ListItemIcon>
                        <QueryStatsIcon fontSize="small" />
                    </ListItemIcon>
                    Estatísticas CS
                </MenuItem>
                
                <MenuItem
                    onClick={handleClickImportaPreContrato}
                    disabled={((parseInt(empresaAtual, 10) > 0)>0)?true:false}
                >
                    <ListItemIcon>
                        <CloudSync fontSize="small" />
                    </ListItemIcon>
                    Carrega Pré-Contrato
                </MenuItem>

            </Menu>
        </React.Fragment>
    );
}
