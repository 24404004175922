import { Card, Collapse, Grid, IconButton, Typography } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MDBox from "components/MDBox";
import { useContext, useEffect, useState } from "react";
import MDButton from "components/MDButton";
import MessagesContext from "hooks/MessagesContext";
import DialogoConfirma from "componentes/DialogoConfirma";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import { AutoCompleteStyled } from "componentes/AutoCompleteStyled";
import FormField from "componentes/FormField";
import CancelIcon from '@mui/icons-material/Cancel';



export default function AjustaBaseFinanceiroAdmin(props) {

    const { openCollapseAjustaBaseFinanceiro, handleClickCollapseAjustaBaseFinanceiro } = props;
    const messageContext = useContext(MessagesContext);
    const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;

    const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });
    const [parametros, setParametros] = useState({ value: -1, label: '<Escolha um parâmetro>' });
    const [valorPadrao, setValorPadrao] = useState('');
    const [optionParametros, setOptionParametros] = useState([
        { value: -1, label: "<Escolha um Parametro>" },
    ]);

    const buscaDados = async () => {

        try {

            MostraTelaEspera(`Buscando`);

            const url = `${rotafechada()}parametros`;
            //console.log("***URL parametros", url);
            let retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;
            let opcoesParametrosTemp = [{ value: -1, label: '<Escolha um parâmetro>' }];

            data.map((item) => {
                opcoesParametrosTemp.push({ value: item.id, label: item.nome });
            });
            setOptionParametros(opcoesParametrosTemp);
            FechaTelaEspera();

            return opcoesParametrosTemp;


        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const handleClickAjustaBaseFinanceiro = async () => {

        setDialogoOpen({ ...dialogoOpen, visible: false });

        try {
            MostraTelaEspera('Aguarde o processamento');

            let complementoUrl = '';

            if (valorPadrao && parametros) {
                console.log("parametro:", parametros)
                console.log("Valor padrão:", valorPadrao)
                complementoUrl = `${complementoUrl}&valor_padrao=${valorPadrao}`;
                complementoUrl = `${complementoUrl}&parametro_id=${parametros.value}`;
            }

            await api.get(`${rotafechada()}admin/empresas/ajustabasefinanceiro?empresa_id=1${complementoUrl}`, {
            });
            FechaTelaEspera();
            MensagemAviso('Ajuste processado com Sucesso!');
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const handleConfirmDialogo = () => {

        if (dialogoOpen.funcao === 'ajustaBaseFinanceiro') {
            handleClickAjustaBaseFinanceiro();
        }
    }

    const handleClickPermissoes = () => {
        setDialogoOpen({
            ...dialogoOpen,
            visible: true,
            id: null,
            title: 'Ajuste na base para entrada do Financeiro',
            message: `Certeza que deseja ajustar a base para todas as Empresas?`,
            funcao: 'ajustaBaseFinanceiro'
        });
    }

    useEffect(() => {
        buscaDados();
    }, [])

    return (
        <>
            <DialogoConfirma
                open={dialogoOpen.visible}
                title={dialogoOpen.title || ''}
                message={dialogoOpen.message || ''}
                handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
                handleConfirm={handleConfirmDialogo}
            />
            <Card id="ajustta-base-financeiro" sx={{ overflow: "visible" }}>
                <MDBox p={2} bgColor='grey-100' mt={2}>
                    <IconButton aria-label="expand row" size="small" onClick={() => handleClickCollapseAjustaBaseFinanceiro(!openCollapseAjustaBaseFinanceiro)}>
                        {openCollapseAjustaBaseFinanceiro ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        <Typography variant='h6' mb={2}>Ajusta base para entrada do financeiro</Typography>
                    </IconButton>

                    <Collapse in={openCollapseAjustaBaseFinanceiro} timeout="auto" unmountOnExit>
                        <Grid container spacing={3}>

                            <Grid item xs={12} sm={12}>
                                <Typography variant='caption' mb={2}>Obs. Este procedimento deve ser executado após
                                    a virada do sistema para o financeiro, com as migrates e seeders ja executadas, Plano de Contas,
                                    Categorias, Centro de Custo, Caixa Local, Formas de Pagamento, todas já criadas e o Reprocessar Permissões e
                                    executado. Sendo tudo isso feito préviamente basta escolher o paramentro(Categoria padrão da venda) para todas
                                    as outras empresas partindo da empresa 1 MODELO e informar o valor do id da categoria na empresa 1 que esse parametro será
                                    criado em todas elas.
                                    PS. Não á problema em executalo mais de uma vez pois ele não replicará os dados.
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6}> {/* Parametros */}
                                <AutoCompleteStyled
                                    disableClearable //Retira o X (clear)
                                    options={optionParametros}
                                    getOptionLabel={(option) => option.label}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    renderInput={(params) => <FormField {...params} label='Parâmetro' required />}
                                    value={parametros}
                                    onChange={(event, newValue) => {
                                        setParametros(newValue);
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={3}> {/* VALOR Padrão */}
                                <FormField
                                    required={true}
                                    type="number"
                                    label="Valor Padrão"
                                    value={valorPadrao}
                                    onChange={(e) => setValorPadrao(e.target.value.replace(/[^.,0-9]/g, ''))}
                                    helperText={valorPadrao > 0 ? false :
                                        <>
                                            <CancelIcon color={'error'} />
                                            <Typography pl={1} fontWeight="small" variant="caption" color={'error'}>O valor padrão precisa ser maior 0</Typography>
                                        </>}
                                />

                            </Grid>

                            <Grid item xs={12} sm={3}> {/* Botão */}
                                <MDButton
                                    disabled={valorPadrao > 0 ? false : true}
                                    color='info'
                                    onClick={handleClickPermissoes}
                                >
                                    Executar
                                </MDButton>
                            </Grid>
                        </Grid>
                    </Collapse>
                </MDBox>
            </Card>
        </>
    )
}