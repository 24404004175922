import MDBox from "components/MDBox";
import { useContext, useEffect, useState } from "react";
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import MessagesContext from 'hooks/MessagesContext';
import TabelaPadrao from "componentes/TabelaPadrao";
import { useWatch } from "react-hook-form";
import { Grid, Typography } from "@mui/material";


const DetalhamentoProjeto = (props) => {

    const { venda_projeto_id } = props;
    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;

    //  Medida
    const [pageMedida, setPageMedida] = useState(0);
    const [rowsPerPageMedida, setRowsPerPageMedida] = useState(25);
    const [countAllMedida, setCountAllMedida] = useState(0);
    const [colunasTableMedida, setColunasTableMedida] = useState([]);
    const [linhasMedida, setLinhasMedida] = useState([]);
    const [orderMedida, setOrderMedida] = useState('asc');
    const [orderByMedida, setOrderByMedida] = useState('');

    //  Possibilidade
    const [pagePossibilidade, setPagePossibilidade] = useState(0);
    const [rowsPerPagePossibilidade, setRowsPerPagePossibilidade] = useState(25);
    const [countAllPossibilidade, setCountAllPossibilidade] = useState(0);
    const [colunasTablePossibilidade, setColunasTablePossibilidade] = useState([]);
    const [linhasPossibilidade, setLinhasPossibilidade] = useState([]);
    const [orderPossibilidade, setOrderPossibilidade] = useState('asc');
    const [orderByPossibilidade, setOrderByPossibilidade] = useState('');

    //  CorTipo
    const [pageCorTipo, setPageCorTipo] = useState(0);
    const [rowsPerPageCorTipo, setRowsPerPageCorTipo] = useState(25);
    const [countAllCorTipo, setCountAllCorTipo] = useState(0);
    const [colunasTableCorTipo, setColunasTableCorTipo] = useState([]);
    const [linhasCorTipo, setLinhasCorTipo] = useState([]);
    const [orderCorTipo, setOrderCorTipo] = useState('asc');
    const [orderByCorTipo, setOrderByCorTipo] = useState('');

    //Componente
    const [pageComponente, setPageComponente] = useState(0);
    const [rowsPerPageComponente, setRowsPerPageComponente] = useState(25);
    const [countAllComponente, setCountAllComponente] = useState(0);
    const [colunasTableComponente, setColunasTableComponente] = useState([]);
    const [linhasComponente, setLinhasComponente] = useState([]);
    const [orderComponente, setOrderComponente] = useState('asc');
    const [orderByComponente, setOrderByComponente] = useState('');

    const buscaMedidas = async () => {

        try {

            let complementoUrl = '';

            if (pageMedida > 0) {
                complementoUrl = `${complementoUrl}&offset=${pageMedida * rowsPerPageMedida}`;
            }

            const url = `${rotafechada()}venda_projeto_medidas?limit=${rowsPerPageMedida}&venda_projeto_id=${venda_projeto_id}${complementoUrl}`;
            const retorno = await api.get(encodeURI(url));
            const { data, countAll: countAllretorno } = retorno.data;
            const dadostemp = [];
            const colunasTabletemp = [
                {
                    id: 'id',
                    numeric: false,
                    disablePadding: true,
                    label: 'Id',
                    disableOrder: true
                },
                {
                    id: 'medida_projeto_id',
                    numeric: false,
                    disablePadding: true,
                    label: 'Medida',
                    disableOrder: true
                },
                {
                    id: 'quantidade',
                    numeric: false,
                    disablePadding: true,
                    label: 'Quantidade',
                    disableOrder: true
                },
                {
                    id: 'observacao',
                    numeric: false,
                    disablePadding: true,
                    label: 'Observação',
                    disableOrder: true
                },
            ]

            data.forEach((item) => {

                dadostemp.push({
                    id: item.id,
                    medida_projeto_id: `${item.Medidas_Projeto?.referencia || ''}-${item.Medidas_Projeto?.nome || ''}`,
                    quantidade: parseFloat(item.quantidade).toFixed(2).replace(".00",""),
                    observacao: item.observacao || ''
                })
            })

            setColunasTableMedida([...colunasTabletemp]);
            setLinhasMedida([...dadostemp]);
            setCountAllMedida(countAllretorno);
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro Medidas: ${msg}`);
        }

    }

    const buscaPossibilidades = async () => {

        try {

            let complementoUrl = '';

            if (pagePossibilidade > 0) {
                complementoUrl = `${complementoUrl}&offset=${pagePossibilidade * rowsPerPagePossibilidade}`;
            }

            const url = `${rotafechada()}venda_projeto_possibilidades?limit=${rowsPerPagePossibilidade}&venda_projeto_id=${venda_projeto_id}${complementoUrl}`;
            const retorno = await api.get(encodeURI(url));
            const { data, countAll: countAllretorno } = retorno.data;
            const dadostemp = [];
            const colunasTabletemp = [
                {
                    id: 'id',
                    numeric: false,
                    disablePadding: true,
                    label: 'Id',
                    disableOrder: true
                },
                {
                    id: 'possibilidade_projeto_id',
                    numeric: false,
                    disablePadding: true,
                    label: 'Possibilidade',
                    disableOrder: true
                },
                {
                    id: 'possibilidade_projeto_opcao_id',
                    numeric: false,
                    disablePadding: true,
                    label: 'Opção',
                    disableOrder: true
                }
            ]

            data.forEach((item) => {

                dadostemp.push({
                    id: item.id,
                    possibilidade_projeto_id: `${item.Possibilidades_Projeto?.nome || ''}`,
                    possibilidade_projeto_opcao_id: `${item.Possibilidade_Projetos_Opco?.nome || ''}`,
                })
            })

            setColunasTablePossibilidade([...colunasTabletemp]);
            setLinhasPossibilidade([...dadostemp]);
            setCountAllPossibilidade(countAllretorno);
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro Possibilidades: ${msg}`);
        }

    }

    const buscaCoresTipos = async () => {

        try {

            let complementoUrl = '';

            if (pageCorTipo > 0) {
                complementoUrl = `${complementoUrl}&offset=${pageCorTipo * rowsPerPageCorTipo}`;
            }

            const url = `${rotafechada()}venda_projeto_cores_tipos?limit=${rowsPerPageCorTipo}&venda_projeto_id=${venda_projeto_id}${complementoUrl}`;
            const retorno = await api.get(encodeURI(url));
            const { data, countAll: countAllretorno } = retorno.data;
            const dadostemp = [];
            const colunasTabletemp = [
                {
                    id: 'id',
                    numeric: false,
                    disablePadding: true,
                    label: 'Id',
                    disableOrder: true
                },
                {
                    id: 'tipo_produto_id',
                    numeric: false,
                    disablePadding: true,
                    label: 'Tipo Produto',
                    disableOrder: true
                },
                {
                    id: 'cor_id',
                    numeric: false,
                    disablePadding: true,
                    label: 'Cor',
                    disableOrder: true
                }
            ]

            data.forEach((item) => {

                dadostemp.push({
                    id: item.id,
                    tipo_produto_id: `${item.Tipos_Produto?.nome || ''}`,
                    cor_id: `${item.Core?.nome || ''}`,
                })
            })

            setColunasTableCorTipo([...colunasTabletemp]);
            setLinhasCorTipo([...dadostemp]);
            setCountAllCorTipo(countAllretorno);
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro CorTipos: ${msg}`);
        }

    }

    const buscaComponentes = async () => {

        try {

            let complementoUrl = '';

            if (pageComponente > 0) {
                complementoUrl = `${complementoUrl}&offset=${pageComponente * rowsPerPageComponente}`;
            }

            const url = `${rotafechada()}venda_projeto_componentes?limit=${rowsPerPageComponente}&venda_projeto_id=${venda_projeto_id}${complementoUrl}`;
            const retorno = await api.get(encodeURI(url));
            const { data, countAll: countAllretorno } = retorno.data;
            const dadostemp = [];
            const colunasTabletemp = [
                {
                    id: 'id',
                    numeric: false,
                    disablePadding: true,
                    label: 'Id',
                    disableOrder: true
                },
                {
                    id: 'componente_id',
                    numeric: false,
                    disablePadding: true,
                    label: 'Componente',
                    disableOrder: true
                },
                {
                    id: 'observacao',
                    numeric: false,
                    disablePadding: true,
                    label: 'Observação',
                    disableOrder: true
                },
                {
                    id: 'altura_projeto',
                    numeric: false,
                    disablePadding: true,
                    label: 'Altura (digitada)',
                    disableOrder: true
                },
                {
                    id: 'largura_projeto',
                    numeric: false,
                    disablePadding: true,
                    label: 'Largura (digitada)',
                    disableOrder: true
                },
                {
                    id: 'quantidade_projeto',
                    numeric: false,
                    disablePadding: true,
                    label: 'Qtde',
                    disableOrder: true
                },
                {
                    id: 'folga_altura',
                    numeric: false,
                    disablePadding: true,
                    label: 'Folga Altura',
                    disableOrder: true
                },
                {
                    id: 'altura_final',
                    numeric: false,
                    disablePadding: true,
                    label: 'Altura (desc.folga)',
                    disableOrder: true
                },
                {
                    id: 'folga_largura',
                    numeric: false,
                    disablePadding: true,
                    label: 'Folga Largura',
                    disableOrder: true
                },
                {
                    id: 'largura_final',
                    numeric: false,
                    disablePadding: true,
                    label: 'Largura (desc.folga)',
                    disableOrder: true
                },
                {
                    id: 'altura_cobranca',
                    numeric: false,
                    disablePadding: true,
                    label: 'Altura (cobrança)',
                    disableOrder: true
                },
                {
                    id: 'largura_cobranca',
                    numeric: false,
                    disablePadding: true,
                    label: 'Largura (cobrança)',
                    disableOrder: true
                },
                {
                    id: 'quantidade_cobranca',
                    numeric: false,
                    disablePadding: true,
                    label: 'Qtde(cobrança)',
                    disableOrder: true
                },
            ]

            data.forEach((item) => {

                dadostemp.push({
                    id: item.id,
                    componente_id: `${item.Componentes_Projeto?.referencia}-${item.Componentes_Projeto?.nome}`,
                    observacao: item.observacao||'',
                    altura_projeto: parseFloat(item.altura_projeto).toFixed(2).replace(".00",""),
                    largura_projeto: parseFloat(item.largura_projeto).toFixed(2).replace(".00",""),
                    quantidade_projeto: parseFloat(item.quantidade_projeto).toFixed(2).replace(".00",""),
                    folga_altura: parseFloat(item.folga_altura).toFixed(2).replace(".00",""),
                    folga_largura: parseFloat(item.folga_largura).toFixed(2).replace(".00",""),
                    altura_final: parseFloat(item.altura_final).toFixed(2).replace(".00",""),
                    largura_final: parseFloat(item.largura_final).toFixed(2).replace(".00",""),
                    altura_cobranca: parseFloat(item.altura_cobranca).toFixed(2).replace(".00",""),
                    largura_cobranca: parseFloat(item.largura_cobranca).toFixed(2).replace(".00",""),
                    quantidade_cobranca: parseFloat(item.quantidade_cobranca).toFixed(2).replace(".00",""),
                })
            })

            setColunasTableComponente([...colunasTabletemp]);
            setLinhasComponente([...dadostemp]);
            setCountAllComponente(countAllretorno);
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro Componentes: ${msg}`);
        }
    }

    useEffect(() => {
        buscaMedidas();
        buscaComponentes();
        buscaCoresTipos();
        buscaPossibilidades();
    }, [venda_projeto_id,
        pageMedida, rowsPerPageMedida, orderMedida, orderByMedida,
        pagePossibilidade, rowsPerPagePossibilidade, orderPossibilidade, orderByPossibilidade,
        pageCorTipo, rowsPerPageCorTipo, orderCorTipo, orderByCorTipo,
        pageComponente, rowsPerPageComponente, orderComponente, orderByComponente])



    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
                {linhasMedida.length > 0 &&
                    <>
                        <Typography variant='h6' mb={2}>Medidas</Typography>
                        <TabelaPadrao
                            colunas={colunasTableMedida}
                            linhas={linhasMedida}
                            countAll={countAllMedida}
                            page={pageMedida}
                            setPage={setPageMedida}
                            rowsPerPage={rowsPerPageMedida}
                            setRowsPerPage={setRowsPerPageMedida}
                            order={orderMedida}
                            setOrder={setOrderMedida}
                            orderBy={orderByMedida}
                            setOrderBy={setOrderByMedida}
                        />
                    </>
                }
            </Grid>
            <Grid item xs={12} sm={12}>
                {linhasComponente.length > 0 &&
                    <>
                        <Typography variant='h6' mb={2}>Componentes</Typography>
                        <TabelaPadrao
                            colunas={colunasTableComponente}
                            linhas={linhasComponente}
                            countAll={countAllComponente}
                            page={pageComponente}
                            setPage={setPageComponente}
                            rowsPerPage={rowsPerPageComponente}
                            setRowsPerPage={setRowsPerPageComponente}
                            order={orderComponente}
                            setOrder={setOrderComponente}
                            orderBy={orderByComponente}
                            setOrderBy={setOrderByComponente}
                        />
                    </>
                }
            </Grid>
            <Grid item xs={12} sm={12}>
                {linhasCorTipo.length > 0 &&
                    <>
                        <Typography variant='h6' mb={2}>Cores e Tipos</Typography>
                        <TabelaPadrao
                            colunas={colunasTableCorTipo}
                            linhas={linhasCorTipo}
                            countAll={countAllCorTipo}
                            page={pageCorTipo}
                            setPage={setPageCorTipo}
                            rowsPerPage={rowsPerPageCorTipo}
                            setRowsPerPage={setRowsPerPageCorTipo}
                            order={orderCorTipo}
                            setOrder={setOrderCorTipo}
                            orderBy={orderByCorTipo}
                            setOrderBy={setOrderByCorTipo}
                        />
                    </>
                }
            </Grid>

            <Grid item xs={12} sm={12}>
                {linhasPossibilidade.length > 0 &&
                    <>
                        <Typography variant='h6' mb={2}>Possibilidades</Typography>
                        <TabelaPadrao
                            colunas={colunasTablePossibilidade}
                            linhas={linhasPossibilidade}
                            countAll={countAllPossibilidade}
                            page={pagePossibilidade}
                            setPage={setPagePossibilidade}
                            rowsPerPage={rowsPerPagePossibilidade}
                            setRowsPerPage={setRowsPerPagePossibilidade}
                            order={orderPossibilidade}
                            setOrder={setOrderPossibilidade}
                            orderBy={orderByPossibilidade}
                            setOrderBy={setOrderByPossibilidade}
                        />
                    </>
                }
            </Grid>
        </Grid>
    )
}

export default DetalhamentoProjeto;