import React, { useContext, useEffect, useState } from 'react';
import MDBox from "components/MDBox";
import MessagesContext from 'hooks/MessagesContext';
import AuthContext from "hooks/AuthContext";
import { useForm, useWatch } from 'react-hook-form';
import MDButton from 'components/MDButton';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import Cabecalho from './cabecalho';
import DadosPrecos from './precos';
import { Icon } from "@mui/material";
import id from 'date-fns/esm/locale/id/index.js';
import { useNavigate } from 'react-router-dom';

function Cadastro(props) {


  const navigate = useNavigate();

  const { servicoAtual, setServicoAtual, setAba } = props;
  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MensagemAviso } = messageContext;
  const authContext = useContext(AuthContext);
  const { getSessao } = authContext;
  const sessaolocal = getSessao();
  const { handleSubmit, control, setValue, getValues, setFocus } = useForm({});
  const indice = 0;

  const [opcoesGrupo, setOpcoesGrupo] = useState([]);
  const [tabelapreco, setTabelaPreco] = useState([]);

  const [opcoesAtivo, setOpcoesAtivo] = useState([
    { value: -1, label: '<Selecione uma opção>' },
    { value: 'S', label: 'Sim' },
    { value: 'N', label: 'Não' },
  ]);
  const opcaoDefaultGrupoServico = [{ id: -1, value: -1, label: '<Selecione um Grupo>' }];

  const [recarregaComponenteOpcoes, setRecarregaComponenteOpcoes] = useState(false);

  const onSubmit = (data) => {
    handleClickGravar(data);
  }

  const limpaInputs = () => {
    setValue('id', '');
    setValue('referencia', '');
    setValue('nome', '');
    setValue('id_externo', '');
    setValue('valor_custo', '');
    setValue(`ativo`, { value: 'S', label: 'Sim' });
    setValue(`grupo_servico_id`, { value: '-1', label: '<Selecione um Grupo>' });

    tabelapreco.map((item, key) => {

      setValue(`servico_precos[${key}].id`, '');
      setValue(`servico_precos[${key}].percentual_margem`, '');
      setValue(`servico_precos[${key}].sugerido`, 0);
      setValue(`servico_precos[${key}].valor_venda`, '');
      setValue(`servico_precos[${key}].ativo`, false);
    })

  }

  const validaCampos = (data) => {

    let camposObrigatorios = ``;

    if (!data.nome) {
      camposObrigatorios = `${camposObrigatorios}/Nome`
    }

    if (!data.ativo) {
      camposObrigatorios = `${camposObrigatorios}/Ativo`
    }

    if (camposObrigatorios !== '') {
      throw new Error(`Campos obrigatórios: ${camposObrigatorios}`)
    }
  }

  const handleClickGravar = async (data) => {

    //tratando os campos autocomplete
    data.ativo = data.ativo?.value ? data.ativo.value : '';
    //data.grupo_servico_id = data.grupo_servico_id?.value ? data.grupo_servico_id.value : '';

    try {
      validaCampos(data);
    }
    catch (error) {
      MensagemErro(`${error.message}`);
      return;
    }

    try {
      const dados = { empresa_id: sessaolocal.empresa_id || null };

      //pegando do OBJ do RHF apenas os campos que sao do serviço
      dados.id = servicoAtual || '';
      dados.ativo = data.ativo;
      dados.referencia = data.referencia;
      dados.nome = data.nome;
      dados.id_externo = data.id_externo;
      dados.valor_custo = `${data.valor_custo}`.replace(',', '.');
      //custo = `${custo}`.replace(',', '.');

      dados.grupo_servico_id = data.grupo_servico_id?.value ? data.grupo_servico_id.value : null;
      dados.servico_precos = []

      data.servico_precos.map((item, index) => {
        const obj = {}
        obj.id = item.id || '';
        obj.empresa_id = sessaolocal.empresa_id || null
        obj.servico_id = data.id || '';
        obj.tabela_preco_id = item.tabela_preco_id || '';
        obj.percentual_margem = `${item.percentual_margem}`.replace(',', '.') || '';
        obj.valor_venda = `${item.valor_venda}`.replace(',', '.') || '';
        if (item.ativo === true) {
          obj.ativo = 'S';
        } else obj.ativo = 'N';

        dados.servico_precos.push(obj);
      })

      let retorno;
      let codigoServico = '';

      if (parseInt(servicoAtual, 10) > 0) {
        //retorno = await api.put(`${rotafechada()}servicos/${servicoAtual}`, dados);
        // console.log("Dados indo pro back",dados);
        retorno = await api.post(`${rotafechada()}servicos/full`, dados);
        codigoServico = servicoAtual;
        setRecarregaComponenteOpcoes(!recarregaComponenteOpcoes);
      }
      else {
        retorno = await api.post(`${rotafechada()}servicos/full`, dados);
        codigoServico = retorno.data.data.id;
        navigate(`/servicos/servico/${codigoServico}`, { replace: true });
      }

      MensagemAviso(`Gravado com sucesso! Serviço: ${codigoServico}`);

      //se for um novo serviço entao "recarrego" a pagina
      if (codigoServico !== servicoAtual) {
        setServicoAtual(codigoServico);
      }
    }
    catch (error) {
      console.log(error);
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const novoservico = async () => {
    limpaInputs();
    setServicoAtual('');
    navigate(`/servicos/servico/0`, { replace: true });
  }

  const abrirServico = async () => {
    //console.log('abrir servico:', servicoAtual);
    try {
      let url = `${rotafechada()}servicos?id=${servicoAtual}`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;

      if (!data.length > 0) {
        MensagemErro(`Servico ${servicoAtual} não localizado`);
        return;
      }
      limpaInputs();

      setValue('id', data[0].id);
      setValue('referencia', data[0].referencia);
      setValue('nome', data[0].nome);
      setValue('id_externo', data[0].id_externo);
      setValue('valor_custo', `${data[0].valor_custo}`.replace('.', ','));
      setValue(`ativo`, { value: data[0].ativo === 'S' ? 'S' : 'N', label: data[0].ativo === 'S' ? 'Sim' : 'Não' });
      setValue(`grupo_servico_id`, { value: data[0].grupo_servico_id, label: data[0].Grupos_Servico?.nome ?  data[0].Grupos_Servico.nome  : '<Selecione um Grupo>' });
      setRecarregaComponenteOpcoes(!recarregaComponenteOpcoes);

      buscaTabelasPrecos();

    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const buscaGrupos = async () => {

    try {
      let url = `${rotafechada()}grupos_servicos?limit=-1`;
      //console.log("URL:", url);
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;
      let opcoesGrupoServicoTemp = [];
      opcoesGrupoServicoTemp.push({ ...opcaoDefaultGrupoServico[0] });

      data.map((item) => {
        opcoesGrupoServicoTemp.push({ value: item.id, label: item.nome || '' })
      })

      setOpcoesGrupo(opcoesGrupoServicoTemp);


    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }

  }

  const buscaTabelasPrecos = async () => {
    try {
      let url = `${rotafechada()}tabelas_precos/tabelaservico?servico_id=${servicoAtual}`;//?servico_id=${servicoAtual}?limit=-1`;
      //console.log("URL tabelas_preços:", url);
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;

      let tabelaPrecoTemp = [];

      //apagando os dados do objeto cores no RHF, pois o projeto pode ter sido modificado
      //unregister('projetos.cores');
      setValue(`servico_precos[]`, []);

      data.map((item, key) => {
        tabelaPrecoTemp.push({ value: item.id, label: item.nome })

        let custo = getValues(`valor_custo`);
        custo = `${custo}`.replace(',', '.');
        if (!parseFloat(custo) > 0) {
          custo = 0;
        }
        let margem = item?.Servico_Precos?.length > 0 ? item?.Servico_Precos[0]?.percentual_margem : ''
        margem = `${margem}`.replace(',', '.');
        if (!parseFloat(margem) > 0) {
          margem = 0;
        }

        const valorsugerido = parseFloat(custo) + ((parseFloat(custo) * parseFloat(margem)) / 100);
        //valorsugerido = `${valorsugerido}`.replace('.',',');
        setValue(`servico_precos[${key}].sugerido`, `${valorsugerido}`.replace('.', ','));

        setValue(`servico_precos[${key}].id`, item.Servico_Precos?.length > 0 ? item.Servico_Precos[0].id : '');
        setValue(`servico_precos[${key}].tabela_preco`, item.nome);
        setValue(`servico_precos[${key}].tabela_preco_id`, item.id);
        setValue(`servico_precos[${key}].percentual_margem`, item?.Servico_Precos?.length > 0 ? item?.Servico_Precos[0]?.percentual_margem : '');
        setValue(`servico_precos[${key}].valor_venda`, item?.Servico_Precos?.length > 0 ? item?.Servico_Precos[0]?.valor_venda.replace('.', ',') : '');

        if (parseInt(servicoAtual, 10) > 0) {
          setValue(`servico_precos[${key}].ativo`, item?.Servico_Precos?.length > 0 && item?.Servico_Precos[0]?.ativo === 'S' ? true : false);
        }
        else setValue(`servico_precos[${key}].ativo`, true);

      })

      setTabelaPreco(tabelaPrecoTemp);


    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }
  const handleClickVoltar = () => {
    navigate(`/servicos/servico/`, { replace: true });
    setServicoAtual('');
    setAba(0);
  }

  useEffect(async () => {
    //await buscaDatasets();
    if (parseInt(servicoAtual, 10) > 0) {
      await abrirServico();
    }
    else {
      await novoservico();
    }
  }, [servicoAtual])

  useEffect(async () => {
    buscaGrupos();
    buscaTabelasPrecos();
  }, [])

  return (
    <MDBox p={2}>
      <MDBox width="100%" display="flex" justifyContent="space-between">
        <MDButton 
          variant="gradient"
          color="secondary"
          type="button"
          onClick={handleClickVoltar}
        >Voltar</MDButton>

        <MDButton 
          variant="gradient"
          circular
          color="info"
          type="button"
          onClick={novoservico}
        >
          <Icon>add</Icon>
        </MDButton>
      </MDBox>
      

      <form onSubmit={handleSubmit(onSubmit)}>
        <Cabecalho
          control={control}
          opcoesAtivo={opcoesAtivo}
          opcoesGrupo={opcoesGrupo}
          setValue={setValue}
          getValues={getValues}
          tabelapreco={tabelapreco}
          setTabelaPreco={setTabelaPreco}
        />
        {tabelapreco?.map((itempreco, key) => {
          return (
            <DadosPrecos
              control={control}
              setValue={setValue}
              getValues={getValues}
              tabelapreco={tabelapreco}
              setTabelaPreco={setTabelaPreco}
              indice={key}
            />
          )
        })}
        <MDBox width="100%" display="flex" justifyContent="flex-end" mt={2}>
          <MDButton
            variant="gradient"
            color="info"
            type="submit"
          >
            Gravar
          </MDButton>
          {/* <MDBox ml={2}>
            <MDButton variant="gradient" color="info" onClick={novoservico}>
              Novo
            </MDButton>
          </MDBox> */}
        </MDBox>
        {/* {parseInt(servicoAtual, 10) > 0 ? console.log("NADA") : limpaInputs() } */}
        {/*
          parseInt(servicoAtual, 10) > 0 ?
            <PessoaEnderecos
              control={control}
              getValues={getValues}
              setValue={setValue}
              servicoAtual={servicoAtual}
              handleSubmit={handleSubmit}
              recarregaComponenteOpcoes={recarregaComponenteOpcoes}
            />
            : false
        */}

      </form>

    </MDBox>
  )

}

export default Cadastro;
