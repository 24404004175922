import { Icon } from '@mui/material';
import api from 'commons/api';
import { rotafechada } from 'commons/urlconf';
import TabelaPadrao from 'componentes/TabelaPadrao';
import MDButton from 'components/MDButton';
import MessagesContext from 'hooks/MessagesContext';
import React, { useContext, useEffect, useState } from 'react';


const TabelaPossibilidades = (props) => {

    const { projetoAtual,recarregaTabela,abrirRegistro,excluirRegistro,setPossibilidadeProjetos} = props;
    

    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;

    const [countAll, setCountAll] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');
    const [colunasTable, setColunasTable] = React.useState([]);
    const [linhas, setLinhas] = React.useState([]);

    const carregaTabela = async () => {

        try {

            let complementoUrl = '';

            if (page > 0) {
                complementoUrl = `${complementoUrl}&offset=${page * rowsPerPage}`;
            }

            if (orderBy) {
                complementoUrl = `${complementoUrl}&order=${orderBy}`;
                if (order) {
                    complementoUrl = `${complementoUrl}&direction=${order}`;
                }
            }
            const url=`${rotafechada()}projeto_possibilidades?limit=${rowsPerPage}&projeto_id=${projetoAtual}${complementoUrl}`;
            const retorno = await api.get(encodeURI(url));
            const { data, countAll: countAllretorno } = retorno.data;
            const dadostemp = [];
            const colunasTabletemp = [
                {
                    id: 'id',
                    numeric: false,
                    disablePadding: true,
                    label: 'Id'
                },
                {
                    id: 'nomepossibilidade',
                    numeric: false,
                    disablePadding: true,
                    label: 'Possibilidade'
                },
                {
                    id: 'obrigatorio',
                    numeric: false,
                    disablePadding: true,
                    label: 'Obrigatório'
                },
                {
                    id: 'acoes',
                    numeric: false,
                    disablePadding: true,
                    label: 'Ações',
                    disableOrder: true
                },
            ]

           
            //sempre que atualizo a tabela de possiblidades do projeto
            //aproveito para setar o state  possibilidadesProjeto
            //que sera usado no componente "produtosprojeto"
            //no autocomplete das possibilidades do projeto

            //* Sera usado no select no componente "produtosprojeto" */
            const opcaoDefault = [{ id: -1, value: -1, label: '<Selecione...>' }];
            const possibilidadesProjetosTemp=opcaoDefault;
            //****************************************** */
            

            //console.log('Dados possibilidades:',data);
            data.forEach((item) => {
                possibilidadesProjetosTemp.push({
                    value:item.possibilidade_projeto_id,
                    label:item.Possibilidades_Projeto?.nome
                })
                dadostemp.push({
                    id: item.id,
                    nomepossibilidade: item.Possibilidades_Projeto?.nome || '',
                    obrigatorio: item.obrigatorio,
                    acoes: <><MDButton size="medium" onClick={() => abrirRegistro(item.id)} iconOnly >
                        <Icon fontSize="medium" color="success">edit_icon</Icon>
                    </MDButton>
                        <MDButton size="medium" onClick={() => excluirRegistro(item.id)} iconOnly >
                            <Icon fontSize="medium" color="error">delete_forever</Icon>
                        </MDButton>
                    </>
                })
            })

            setPossibilidadeProjetos(possibilidadesProjetosTemp);

            setColunasTable([...colunasTabletemp]);
            setLinhas([...dadostemp]);
            setCountAll(countAllretorno);
            FechaTelaEspera();
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    useEffect(() => {
        carregaTabela();
    }, [recarregaTabela,page,rowsPerPage,order,orderBy])


    return (
        <TabelaPadrao
            colunas={colunasTable}
            linhas={linhas}
            countAll={countAll}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
        />
    )
}


export default TabelaPossibilidades;