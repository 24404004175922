import React, { useContext, useEffect, useState } from "react";

// @mui material components
import { Collapse, Grid, MenuItem, Menu, ListItemIcon, Checkbox, Typography, InputAdornment } from "@mui/material";
import { AutoCompleteStyled } from "componentes/AutoCompleteStyled";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

//My componentes e functions
import MessagesContext from "hooks/MessagesContext";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import FormField from "componentes/FormField";
import { Box, margin } from "@mui/system";

import TabelaPadrao from "componentes/TabelaPadrao";
import { formataValor, formataFloat, formatDate, retornaSomenteNumeros } from "commons/utils";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Divider from '@mui/material/Divider';
import DeleteIcon from '@mui/icons-material/Delete';
import TotalizadoresContas from "../../Componentes/TotalizadoresContas";
import CurrencyInput from "componentes/MascaraMonetaria";
import CancelIcon from '@mui/icons-material/Cancel';
import HelpIcon from '@mui/icons-material/Help';
import { Anexos } from "componentes/Anexos";


function Tabela(props) {

    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;
    const { handleClickExcluir, abrirRegistro, recarregaTabela, openFiltrosAvancados,
        handleClickInformarPagamento, handleClickDetalheParcela, handleClickEditarParcela,
        handleClickDetalheConta, handleClickEditarConta, dataFinalFiltro, dataInicialFiltro, handeClickOperacaoEmLote, valorPesquisa, setValorPesquisa, filtroSituacao, setFiltroSituacao, campoPesquisa, setCampoPesquisa } = props;

    const [countAll, setCountAll] = React.useState(0);
    const [totalizadores, setTotalizadores] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('vencimento');
    const [colunasTable, setColunasTable] = React.useState([]);
    const [linhas, setLinhas] = React.useState([]);
    const [idParcela, setIdParcela] = useState(0);
    const [idConta, setIdConta] = useState(0);
    const [situacaoParcela, setSituacaoParcela] = useState('');

    const [openAnexos, setOpenAnexos] = useState(false);
    const [dadosAnexo, setDadosAnexo] = useState({
        conta_id: '',
        parcela_id: '',
        title: 'Anexos'
    })


    /*****************Menu Item*******************/
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
        setOpen(true)
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false)
    };


    // *****States relacionado do filtro de pesquisa ****

    const [camposFiltro, setCamposFiltro] = useState([
        { id: 'nome', label: 'Descrição', buscaParcial: true },
        { id: 'fantasia', label: 'Fantasia/Razão Social', buscaParcial: true },
        { id: 'valor_total', label: 'Valor total' },
        { id: 'conta_id', label: 'ID da Conta' },
        { id: 'id', label: 'ID da Parcela' },

        //{ id: 'id', label: 'Id' },
    ]);
    const opcoesFiltroSituacao = ([
        { value: '', label: 'Todos' },
        { value: 'PAGO', label: 'Pago' },
        { value: 'EM ABERTO', label: 'Em Aberto' },
        { value: 'PAGO PARCIAL', label: 'Com quitação Parcial' },
    ]);

    // ****************************

    const baseUrl = `${rotafechada()}parcelas_conta`;
    const baseCaption = 'Parcelas a pagar';

    const CustomWidthTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 'none',
            //backgroundColor: '#1A73E8',
            //color: 'rgb(255, 255, 255)',
            //boxShadow: theme.shadows[1],
            //fontSize: 12,
        },
    }));



    //******************* */
    const [todosSelecionados, setTodosSelecionados] = useState(false);

    // Função para selecionar/desmarcar todas as linhas
    const selecionarTodos = (checked) => {
        const novasLinhas = linhas.map((linha) => ({ ...linha, selecionado: checked }));
        setLinhas(novasLinhas);
        setTodosSelecionados(checked);
    };

    // Função para selecionar/desmarcar uma linha específica
    const selecionarLinha = (id, checked) => {
        const novasLinhas = linhas.map((linha) =>
            linha.id === id ? { ...linha, selecionado: checked } : linha
        );
        setLinhas(novasLinhas);
        setTodosSelecionados(novasLinhas.every((linha) => linha.selecionado));
    };

    const alterarValorLancamento = (id, valorinput) => {
        const tempValor = parseFloat(valorinput.replace(/\./g, '').replace(',', '.'))

        const novasLinhas = linhas.map((linha) =>
            linha.id === id ? { ...linha, valor_lancamento: tempValor } : linha
        );
        setLinhas(novasLinhas);


    }
    //******************* */

    const buscaDados = async () => {
        if (props.validaRotaPermissao) {
            if (await props.validaRotaPermissao('listar') === false) {
                return;
            }
        }

        if ((!dataInicialFiltro) || (!dataFinalFiltro)) {
            MensagemErro('É necessário escolher um período');
            return;
        }

        try {

            let complementoUrl = '';

            if ((campoPesquisa.id) && (valorPesquisa)) {
                complementoUrl = `&${campoPesquisa.id}=${valorPesquisa}`;

                if (campoPesquisa.id === 'id') {
                    const temp = retornaSomenteNumeros(valorPesquisa);
                    if ((`${temp}`.length !== `${valorPesquisa}`.length) ||
                        (!(parseInt(`${valorPesquisa}`, 10) > 0))) {
                        MensagemErro('A pesquisa por ID aceita só números, escolha outro campo para busca');
                        return;
                    }
                }

                if ((valorPesquisa) && (valorPesquisa.length > 0) && (valorPesquisa[0] !== '%') && (valorPesquisa[valorPesquisa.length - 1] !== '%')
                    && (camposFiltro) && (camposFiltro.length > 0)) {

                    //se o texto a ser buscado nao terminar ou começar com %
                    //e tiver uma configuracao no camposFiltro
                    //entao localizo que o campo usado nesse momento é um campo que esteja configurado
                    //o buscaParcial, se sim acrescento automaticamente o % no inicio e fim


                    let encontrouCampo = false;
                    for (let i = 0; i < camposFiltro.length; i++) {

                        if ((camposFiltro[i].buscaParcial === true) && (camposFiltro[i].id === campoPesquisa.id)) {
                            encontrouCampo = true;
                        }
                    }

                    if (encontrouCampo === true) {
                        complementoUrl = `&${campoPesquisa.id}=%${valorPesquisa}%`;
                    }
                }
            }

            if (page > 0) {
                complementoUrl = `${complementoUrl}&offset=${page * rowsPerPage}`;
            }

            if (orderBy) {
                complementoUrl = `${complementoUrl}&order=${orderBy}`;
                if (order) {
                    complementoUrl = `${complementoUrl}&direction=${order}`;
                }
            }

            if (filtroSituacao) {
                complementoUrl = `${complementoUrl}&situacao_parcela=${filtroSituacao.value}`;
            }

            //complementoUrl = `${complementoUrl}&tipo_categoria=D`; //Despesa

            //complementoUrl = encodeURI(complementoUrl)

            MostraTelaEspera(`Buscando ${baseCaption}`);

            // const url = `${baseUrl}/situacao?limit=${rowsPerPage}&tipo='D'${complementoUrl}`;

            //devido a problemas de UTC enviei so a data sem horario e tratei no back
            const data1string = formatDate(new Date(dataInicialFiltro), 'YYYY-MM-DD');
            const data2string = formatDate(new Date(dataFinalFiltro), 'YYYY-MM-DD');
            //const url = `${baseUrl}/situacao?tipo=D&data1=${new Date(dataInicialFiltro)}&data2=${new Date(dataFinalFiltro)}&limit=${rowsPerPage}${complementoUrl}`;
            const url = `${baseUrl}/situacao?tipo=D&data1=${data1string}&data2=${data2string}&limit=${rowsPerPage}${complementoUrl}`;
            //console.log("URL", url)


            const retorno = await api.get(encodeURI(url));
            const { data, somaTotal, somaSaldo, somaVencidos, somaNaoVencidos, somaHoje, somaPagos, countAll: countAllretorno } = retorno.data;
            const dadostemp = [];

            let somaTotalTemp = formataValor(somaTotal).replace("R$", "");
            let somaSaldoTemp = formataValor(somaSaldo).replace("R$", "");
            let somaVencidosTemp = formataValor(somaVencidos).replace("R$", "");
            let somaNaoVencidosTemp = formataValor(somaNaoVencidos).replace("R$", "");
            let somaHojeTemp = formataValor(somaHoje).replace("R$", "");
            let somaPagosTemp = formataValor(somaPagos).replace("R$", "");

            // const colunasTabletemp = [
            //     {
            //         id: 'selecao',
            //         numeric: false,
            //         disablePadding: true,
            //         disableOrder: true,
            //         label: ''
            //     },

            //     {
            //         id: 'vencimento',
            //         numeric: false,
            //         disablePadding: true,
            //         label: 'Vencimento'
            //     },
            //     {
            //         id: 'pagamento',
            //         numeric: false,
            //         disablePadding: true,
            //         label: 'Pagamento',

            //     },
            //     {
            //         id: 'descricao',
            //         numeric: false,
            //         disablePadding: true,
            //         label: 'Descrição',

            //     },
            //     {
            //         id: 'total',
            //         numeric: false,
            //         disablePadding: true,
            //         label: 'Total(R$)',

            //     },
            //     {
            //         id: 'saldo',
            //         numeric: false,
            //         disablePadding: true,
            //         label: 'Pagar(R$)',

            //     },
            //     {
            //         id: 'situacao',
            //         numeric: false,
            //         disablePadding: true,
            //         label: 'Situação',

            //     },
            //     {
            //         id: 'acoes',
            //         numeric: false,
            //         disablePadding: true,
            //         label: '',
            //         disableOrder: true
            //     },
            // ]

            //mapeando os dados para a "rows"
            data.map((item, index) => {
                dadostemp.push({
                    id: item.id_parcela,
                    conta_id: item.id_conta,
                    vencimento: formatDate(new Date(item.vencimento), 'DD/MM/YYYY'),
                    pagamento: item.pagamento ? formatDate(new Date(item.pagamento), 'DD/MM/YYYY') : null,
                    descricao: <>
                        <CustomWidthTooltip
                            title={<>
                                <p align='left'>
                                    {item.descricao}<br></br>
                                    Categoria: {item.qtd_categoria_rateada > 0 ? item.categorias_rateio : item.categoria.categoria} <br></br>
                                    Fornecedor: {`${item.pessoa?.razao_social} - ${item.pessoa?.fantasia}`}
                                </p>
                            </>}
                            placement="top"
                            disableInteractive
                        >
                            <Box>
                                <MDBox width='100%' display='flex' justifyContent="flex-start" flexDirection='row'>
                                    <MDTypography variant="body2" >{item.descricao}</MDTypography>
                                </MDBox>
                                <MDBox width='100%' display='flex' justifyContent="flex-start" flexDirection='row'>
                                    <MDTypography variant="caption" >{item.qtd_categoria_rateada > 0 ? `${item.qtd_categoria_rateada} Categorias` : item.categoria.categoria} - {item.pessoa?.razao_social}</MDTypography>
                                </MDBox>
                            </Box>
                        </CustomWidthTooltip>
                    </>,
                    total: formataValor(item.total).replace("R$", ""),
                    saldo: formataValor(item.saldo).replace("R$", ""),
                    lancamento: <></>,
                    valor_lancamento: item.saldo,
                    situacao:
                        <MDBox display="flex" alignText={'center'} width="100px" justifyContent={'center'}
                            sx={{ background: `${item.situacao == 'PAGO' ? '#bce2be' : item.situacao == 'EM ABERTO' ? '#ffd59f' : '#aecef7'}` }}
                            borderRadius="sm"
                            p={0.5}
                        >
                            <MDTypography fontWeight="medium" variant="caption" textTransform="capitalize"
                            //color={item.situacao == 'PAGO' ? 'success' : item.situacao == 'EM ABERTO' ? 'warning' : 'info'}
                            >
                                {item.situacao}
                            </MDTypography>
                        </MDBox>,
                    acoes: <>
                        <MDButton
                            variant="outlined"
                            color="info"
                            size="small"
                            borderRadius="sm"
                            onClick={(e) => {
                                setSituacaoParcela(item.situacao)
                                setIdParcela(item.id_parcela)
                                setIdConta(item.id_conta)
                                setAnchorEl(e.currentTarget);
                                setOpen(true)
                            }}
                            sx={{ borderRadius: '4px', height: '25px', minHeight: '12px' }}
                            disableElevation
                            endIcon={<KeyboardArrowDownIcon />}
                        >
                            ações
                        </MDButton>
                    </>,
                    selecionado: false
                })
            })

            //setColunasTable([...colunasTabletemp]); //vai ser mapeado no useeffect por conta do checkbox seleciona todos
            setTodosSelecionados(false);
            setLinhas(dadostemp);
            setCountAll(countAllretorno);
            setTotalizadores({
                somaTotal: somaTotalTemp,
                somaSaldo: somaSaldoTemp,
                somaVencidos: somaVencidosTemp,
                somaHoje: somaHojeTemp,
                somaNaoVencidos: somaNaoVencidosTemp,
                somaPagos: somaPagosTemp
            });
            FechaTelaEspera();
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }


    // Atualiza colunasTable sempre que `linhas` mudar
    useEffect(() => {
        if (selecionados?.length > 0) {
            setColunasTable([
                {
                    id: 'selecao',
                    numeric: false,
                    disablePadding: true,
                    disableOrder: true,
                    label: (
                        <Checkbox
                            indeterminate={!todosSelecionados && linhas.some((linha) => linha.selecionado)}
                            checked={todosSelecionados}
                            onChange={(e) => selecionarTodos(e.target.checked)}
                        />
                    ),
                },

                {
                    id: 'vencimento',
                    numeric: false,
                    disablePadding: true,
                    label: 'Vencimento'
                },
                {
                    id: 'pagamento',
                    numeric: false,
                    disablePadding: true,
                    label: 'Pagamento',

                },
                {
                    id: 'descricao',
                    numeric: false,
                    disablePadding: true,
                    label: 'Descrição',

                },
                {
                    id: 'total',
                    numeric: false,
                    disablePadding: true,
                    label: 'Total(R$)',

                },
                {
                    id: 'saldo',
                    numeric: false,
                    disablePadding: true,
                    label: 'Pagar(R$)',

                },
                {
                    id: 'lancamento',
                    numeric: false,
                    disablePadding: true,
                    label: 'Lançamento(R$)',

                },
                {
                    id: 'situacao',
                    numeric: false,
                    disablePadding: true,
                    label: 'Situação',

                },
            ]);
        }
        else {
            setColunasTable([
                {
                    id: 'selecao',
                    numeric: false,
                    disablePadding: true,
                    disableOrder: true,
                    label: (
                        <Checkbox
                            indeterminate={!todosSelecionados && linhas.some((linha) => linha.selecionado)}
                            checked={todosSelecionados}
                            onChange={(e) => selecionarTodos(e.target.checked)}
                        />
                    ),
                },

                {
                    id: 'vencimento',
                    numeric: false,
                    disablePadding: true,
                    label: 'Vencimento'
                },
                {
                    id: 'pagamento',
                    numeric: false,
                    disablePadding: true,
                    label: 'Pagamento',

                },
                {
                    id: 'descricao',
                    numeric: false,
                    disablePadding: true,
                    label: 'Descrição',

                },
                {
                    id: 'total',
                    numeric: false,
                    disablePadding: true,
                    label: 'Total(R$)',

                },
                {
                    id: 'saldo',
                    numeric: false,
                    disablePadding: true,
                    label: 'Pagar(R$)',

                },
                {
                    id: 'situacao',
                    numeric: false,
                    disablePadding: true,
                    label: 'Situação',

                },
                {
                    id: 'acoes',
                    numeric: false,
                    disablePadding: true,
                    label: '',
                    disableOrder: true
                },
            ]);
        }

    }, [linhas, todosSelecionados]);
    // Filtra as linhas selecionadas dinamicamente
    const selecionados = linhas.filter((linha) => linha.selecionado);

    // Soma os valores das linhas selecionadas
    const somaSelecionados = selecionados.reduce((acc, linha) => acc + linha.valor_lancamento || 0, 0);
    //**************************** */

    useEffect(() => {
        buscaDados();
    }, [recarregaTabela, page, rowsPerPage, order, orderBy])

    const handleClickPesquisar = () => {
        setPage(0);
        buscaDados();
    }

    const limparFiltros = () => {

        setValorPesquisa('');
        setFiltroSituacao(opcoesFiltroSituacao[0]);
        setCampoPesquisa(camposFiltro[0]);
    }

    const handleClickAbreAnexosParcela = (parcela_conta_id) => {
        setDadosAnexo({
            conta_id: null,
            parcela_conta_id,
            title: `Anexos da Parcela ${parcela_conta_id}`
        })
        setOpenAnexos(true);

    }

    const desenhaFiltro = () => {
        return (
            <Collapse in={openFiltrosAvancados} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                    < Grid container spacing={2} justifyContent="center" >
                        <Grid item xs={12} sm={3} >
                            <AutoCompleteStyled
                                options={opcoesFiltroSituacao}
                                getOptionLabel={(option) => option.label}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                renderInput={(params) => <FormField {...params} label='Situação' />}
                                value={filtroSituacao}
                                onChange={(event, newValue) => {
                                    setFiltroSituacao(newValue);
                                }}
                                defaultValue={{ value: '', label: 'Todos' }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={9} >
                        </Grid>
                        <Grid item xs={12} sm={3}   >{/*Filtro Campo*/}
                            <AutoCompleteStyled
                                options={camposFiltro}
                                getOptionLabel={(option) => option.label}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                renderInput={(params) => <FormField {...params} label='Campo' />}
                                value={campoPesquisa}
                                onChange={(event, newValue) => {
                                    setCampoPesquisa(newValue);
                                }}
                                defaultValue={null}
                            />
                        </Grid>
                        <Grid item xs={12} sm={7}   >{/*Valor a ser Pesquisado*/}
                            <FormField type="text" label="Valor a ser Pesquisado" value={valorPesquisa} onChange={(e) => setValorPesquisa(e.target.value)}

                                inputProps={
                                    {
                                        onKeyPress: (e) => {

                                            if (e.key === 'Enter') {
                                                handleClickPesquisar();
                                            }
                                        },
                                    }
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={2} >{/*Botão do Filtro*/}
                            <MDButton onClick={handleClickPesquisar} color='info'> Pesquisar</MDButton>
                            <MDButton onClick={limparFiltros} > Limpar Filtros</MDButton>
                        </Grid>
                    </Grid >
                </Box>
            </Collapse>
        )
    }


    return (
        <>

            <Anexos
                conta_id={dadosAnexo.conta_id || ''}
                parcela_conta_id={dadosAnexo.parcela_conta_id || ''}
                title={dadosAnexo.title || 'Anexos'}
                open={openAnexos}
                setOpen={setOpenAnexos}
            />

            <Menu

                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem
                    dense={true}
                    disabled={situacaoParcela == 'PAGO' ? true : false}
                    onClick={() => {
                        handleClickInformarPagamento(idParcela)
                        setAnchorEl(null);
                        setOpen(false)
                    }}>
                    Informar pagamento
                </MenuItem>
                <MenuItem
                    dense={true}
                    onClick={() => {
                        handleClickDetalheParcela(idParcela)
                        setAnchorEl(null);
                        setOpen(false)
                    }}>
                    Ver detalhes da parcela
                </MenuItem>
                <MenuItem
                    dense={true}
                    onClick={() => {
                        handleClickEditarParcela(idParcela)
                        setAnchorEl(null);
                        setOpen(false)
                    }}>
                    Editar parcela
                </MenuItem>
                <MenuItem
                    dense={true}
                    onClick={() => {
                        handleClickAbreAnexosParcela(idParcela)
                        setAnchorEl(null);
                        setOpen(false)
                    }}>
                    Anexos da parcela
                </MenuItem>
                <MenuItem
                    dense={true}
                    onClick={() => {
                        handleClickDetalheConta(idParcela)
                        setAnchorEl(null);
                        setOpen(false)
                    }}>
                    Ver detalhes da conta
                </MenuItem>
                <MenuItem
                    dense={true}
                    onClick={() => {
                        handleClickEditarConta(idConta)
                        setAnchorEl(null);
                        setOpen(false)
                    }}>
                    Editar conta
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem
                    dense={true}
                    onClick={() => {
                        handleClickExcluir(idParcela, situacaoParcela)
                        setAnchorEl(null);
                        setOpen(false)
                    }}>
                    <ListItemIcon>
                        <DeleteIcon />
                    </ListItemIcon>
                    Excluir
                </MenuItem>
            </Menu>

            <MDBox p={3} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                    {desenhaFiltro()}
                </MDTypography>
            </MDBox>
            <MDBox pl={2} pr={1} pb={2}>
                <TotalizadoresContas totalizadores={totalizadores} tipo={'D'} />
            </MDBox>

            {selecionados.length > 0 && (
                <MDBox p={3}>
                    <MDTypography variant='small' >{selecionados.length} Registros Selecionados. Valor Total: {formataValor(somaSelecionados)}</MDTypography>
                    {
                        selecionados.length > 1 && (
                            <MDButton color='success' sx={{ marginLeft: 5 }}
                                onClick={() => handeClickOperacaoEmLote(selecionados)}
                            >Operação em Lote </MDButton>
                        )
                    }
                </MDBox>

            )}

            <TabelaPadrao
                colunas={colunasTable}

                linhas={linhas.map((linha) => ({
                    ...linha,
                    selecao: (
                        <Checkbox
                            checked={linha.selecionado}
                            onChange={(e) => selecionarLinha(linha.id, e.target.checked)}
                        />
                    ),
                    lancamento: (
                        <MDBox width='100%' display='flex' alignItems='flext-start' flexDirection='row'>
                            <CurrencyInput
                                //autoFocus
                                disabled={!linha.selecionado}
                                required={true}
                                label="Valor Lançamento"
                                value={linha.valor_lancamento}

                                onChange={(e) => {
                                    alterarValorLancamento(linha.id, e.target.value);
                                    // const tempValor = parseFloat(e.target.value.replace(/\./g, '').replace(',', '.'))
                                    // setValorPago(tempValor)
                                }}
                                helperText={linha.valor_lancamento >= 0 ? false :
                                    <>
                                        <CancelIcon color={'error'} />
                                        <Typography pl={1} fontWeight="small" variant="caption" color={'error'}>Valor de lançamento deve ser maior que 0</Typography>
                                    </>
                                }
                                InputProps={{
                                    startAdornment: <InputAdornment sx={{ width: '5%' }} position="start">R$</InputAdornment>,
                                    disabled: false
                                }}
                            />
                            <Tooltip
                                title="Você pode fazer um lançamento no saldo total ou parcial da parcela"
                                placement="top"
                            >
                                <HelpIcon color="info" />
                            </Tooltip>
                        </MDBox>
                    )
                }))}
                countAll={countAll}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                order={order}
                setOrder={setOrder}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
            />
        </>
    );
}


export default Tabela;