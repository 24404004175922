import { Icon } from '@mui/material';
import api from 'commons/api';
import { rotafechada } from 'commons/urlconf';
import TabelaPadrao from 'componentes/TabelaPadrao';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MessagesContext from 'hooks/MessagesContext';
import React, { useContext, useEffect, useState } from 'react';


const TabelaOpcoes = (props) => {

    const { planoCorteAtual, recarregaTabela, abrirRegistro, excluirRegistro, getValues, recarregaComponenteOpcoes } = props;

    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;

    const [countAll, setCountAll] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [colunasTable, setColunasTable] = React.useState([]);
    const [linhas, setLinhas] = React.useState([]);

    const carregaTabela = async () => {

        try {

            let complementoUrl = '';

            if (page > 0) {
                complementoUrl = `${complementoUrl}&offset=${page * rowsPerPage}`;
            }

            if (orderBy) {
                complementoUrl = `${complementoUrl}&order=${orderBy}`;
                if (order) {
                    complementoUrl = `${complementoUrl}&direction=${order}`;
                }
            }

            const retorno = await api.get(`${rotafechada()}registro_plano_corte_barras?limit=${rowsPerPage}&plano_corte_barra_id=${planoCorteAtual}${complementoUrl}`);

            const { data, countAll: countAllretorno } = retorno.data;
            // console.log('Retorno registro_plano_corte_barras', data) 
            const dadostemp = [];

            const colunasTabletemp = [
                {
                    id: 'id',
                    numeric: false,
                    disablePadding: true,
                    label: 'Id'
                },
                {
                    id: 'produto',
                    numeric: false,
                    disablePadding: true,
                    label: 'Produto'
                },
                {
                    id: 'cor',
                    numeric: false,
                    disablePadding: true,
                    label: 'Cor'
                },
                {
                    id: 'tamanho',
                    numeric: false,
                    disablePadding: true,
                    label: 'Tamanho (mm)'
                },
                {
                    id: 'barra',
                    numeric: false,
                    disablePadding: true,
                    label: 'Barra'
                },
                {
                    id: 'pedido',
                    numeric: false,
                    disablePadding: true,
                    label: 'Venda'
                },
                // {
                //     id: 'acoes',
                //     numeric: false,
                //     disablePadding: true,
                //     label: 'Ações',
                //     disableOrder: true
                // },
            ]


            //mapeando os dados para a "rows"
            data.map((item, index) => {
                dadostemp.push({
                    id: item.id,
                    tamanho: item.tamanho,
                    produto: item.Produto_Grade.Produto.nome || '',
                    cor: item.Produto_Grade.Core.nome,
                    barra: item.barra_plano_corte_barra_id,
                    pedido: item.Venda_Produto.venda_id,
                    acoes: <>
                        <MDButton size="medium" onClick={() => abrirRegistro(item.id)} iconOnly >
                            <Icon fontSize="medium" color="success">edit_icon</Icon>
                        </MDButton>
                        <MDButton size="medium" onClick={() => excluirRegistro(item.id)} iconOnly >
                            <Icon fontSize="medium" color="error">delete_forever</Icon>
                        </MDButton>
                    </>
                })
            })

            setColunasTable([...colunasTabletemp]);
            setLinhas([...dadostemp]);
            setCountAll(countAllretorno);
            FechaTelaEspera();
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    useEffect(() => {
        carregaTabela();
    }, [recarregaTabela, page, rowsPerPage, order, orderBy, recarregaComponenteOpcoes])


    return (
        <TabelaPadrao
            colunas={colunasTable}
            linhas={linhas}
            countAll={countAll}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
        />
    )
}


export default TabelaOpcoes;