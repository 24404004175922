import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import { strToDate } from "commons/utils";
import { formatDate } from "commons/utils";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import AuthContext from "hooks/AuthContext";
import MessagesContext from "hooks/MessagesContext";
import { useState } from "react";
import { useContext } from "react";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import MDBox from "components/MDBox";
import FormField from "componentes/FormField";
import SearchIcon from '@mui/icons-material/Search';
import PesquisaPessoas from 'pages/vendas/vendas/Cadastro/cabecalho/pesquisapessoas';
import { useEffect } from "react";
import { ptBR } from "date-fns/locale";

/*
Componente que mostra os dados do pedido de tempera


*/

export default function DadosPedidoTempera(props) {

    const { open, handleClose, dadosPedidoTempera, setDadosPedidoTempera, parametrosVenda } = props;

    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;

    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();

    const [confPesquisaDinamica, setConfPesquisaDinamica] = useState({});
    const [campoTipoPessoaIdFiltroPesquisa, setCampoTipoPessoaIdFiltroPesquisa] = useState({ id: -1, label: 'Todos' });
    const [opcoesTiposPessoas, setOpcoesTiposPessoas] = useState([]);
    const [nomeFornecedor, setNomeFornecedor] = useState('');

    // const [ fornecedor_id, setFornecedor_id ] = useState('');
    // const datainicial=formatDate(new Date(),'DD/MM/YYYY');
    // const [ data, setData ] = useState(datainicial);
    // const [ observacao, setObservacao ] = useState('');

    const handleClickGravar = async () => {

        const { id, fornecedor_id, data, observacao, venda_projeto_id } = dadosPedidoTempera;

        //const datatemp = formatDate(strToDate(data), 'YYYY-MM-DD');


        if (!data){
            MensagemErro('É necessário informar uma data!');
            return;
        }

        if (!(parseInt(fornecedor_id,10)>0)){
            MensagemErro('É necessário informar um fornecedor!');
            return;
        }

        try {

            MostraTelaEspera('Aguarde a gravação dos dados...');

            let retorno;
            let dados = {
                empresa_id: sessaolocal.empresa_id || null,
                fornecedor_id,
                //data: datatemp,
                data,
                observacao,
                concluido: 'N',
                //data_previsao:'',
                //data_conclusao:'',
            }
            // console.log(dados);
            debugger;
            if (parseInt(id, 10) > 0) {
                retorno = await api.put(encodeURI(`${rotafechada()}pedidos_tempera/${id}`), dados);
            }
            else {
                retorno = await api.post(encodeURI(`${rotafechada()}pedidos_tempera`), dados);
            }

            const pedido_tempera_id = retorno.data.data.id;

            for (let i = 0; i < venda_projeto_id.length; i++) {
                dados = {
                    empresa_id: sessaolocal.empresa_id || null,
                    pedido_tempera_id,
                    venda_projeto_id: venda_projeto_id[i]
                }
                retorno = await api.post(encodeURI(`${rotafechada()}pedido_tempera_venda_projetos`), dados);
            }

            FechaTelaEspera();
            MensagemAviso('Gravado');
            handleClose();

        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            const erro = error.response?.data?.erro || '';
            console.log(erro);
            MensagemErro(`${msg}`)
        }
    }


    const handleClickPesquisaPessoa = async () => {

        const tipo_pessoa_id_filtro = parametrosVenda?.tipo_pessoa_id_fornecedor || 0;

        opcoesTiposPessoas?.map((item) => {
            if (parseInt(item.id, 10) === parseInt(tipo_pessoa_id_filtro, 10)) {
                setCampoTipoPessoaIdFiltroPesquisa({ ...item });
            }
        })

        setConfPesquisaDinamica({ ...confPesquisaDinamica, open: true, campo: 'fornecedor' });
    }

    const handleBlurPessoa = async () => {

        let id = dadosPedidoTempera.fornecedor_id;

        if (!(parseInt(id, 10) > 0)) {
            setDadosPedidoTempera({ ...dadosPedidoTempera, fornecedor_id: '' })
            setNomeFornecedor('');
            return;
        }

        try {
            let url = `${rotafechada()}pessoas?id=${id}`;
            const retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;

            let nome = '';
            id = '';

            if ((data) && (data?.length > 0)) {
                nome = data[0].razao_social || data[0].fantasia || '...';
                id = data[0].id;
            }


            setDadosPedidoTempera({ ...dadosPedidoTempera, fornecedor_id: id });
            setNomeFornecedor(nome);

        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const handleFuncaoSelecaoPesquisaDinamica = (codigo, datasetPesquisa) => {
        /* Essa funcao é chamada pelo componente de pesquisa dinamica quando um registro é escolhido
        ele retorna os campos que foram configurados*/
        setConfPesquisaDinamica({ ...confPesquisaDinamica, open: false });
        setDadosPedidoTempera({ ...dadosPedidoTempera, fornecedor_id: datasetPesquisa.id });
        setNomeFornecedor(datasetPesquisa.razao_social || datasetPesquisa.fantasia || '');
    }

    const buscaOpcoesTiposPessoas = async () => {
        try {
            const url = `${rotafechada()}tipos_pessoas`;
            const retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;

            const tipospessoastemp = [{ id: -1, label: 'Todos' }];

            data.map((item) => {

                tipospessoastemp.push({
                    id: item.id,
                    label: item.nome
                });

            })
            setOpcoesTiposPessoas(tipospessoastemp);
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    useEffect(() => {
        buscaOpcoesTiposPessoas();
    }, [])

    return (
        <>
            <PesquisaPessoas
                open={confPesquisaDinamica.open || false}
                handleClose={
                    () => setConfPesquisaDinamica({ ...confPesquisaDinamica, open: false })
                }
                handleClick={handleFuncaoSelecaoPesquisaDinamica}
                opcoesTiposPessoas={opcoesTiposPessoas}
                campoTipoPessoaIdFiltroPesquisa={campoTipoPessoaIdFiltroPesquisa}
                setCampoTipoPessoaIdFiltroPesquisa={setCampoTipoPessoaIdFiltroPesquisa}
            />

            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={"md"}
            >
                <DialogTitle>
                    Pedido de Têmpera {dadosPedidoTempera?.id || ''}
                </DialogTitle>
                <DialogContent>
                 
                    <Box m={2}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                            <DesktopDatePicker
                                label='Data Inicial'
                                inputFormat="dd/MM/yyyy"
                                value={dadosPedidoTempera.data}
                                onChange={(e) => {
                                    setDadosPedidoTempera({ ...dadosPedidoTempera, data: e })
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Box>

                    <Box display='flex' m={2}>{/*Fornecedor*/}
                        <FormField
                            type='text'
                            label='Fornecedor'
                            rows={1}
                            multiline={false}
                            value={dadosPedidoTempera.fornecedor_id}
                            onChange={(e) => {
                                e.preventDefault();
                                setDadosPedidoTempera({ ...dadosPedidoTempera, fornecedor_id: e.target.value })
                            }}

                            inputProps={{
                                onKeyUp: (e) => {
                                    e.preventDefault();
                                    if (e.key === 'F9') {
                                        handleClickPesquisaPessoa();
                                    }

                                },
                                onBlur: (e) => {
                                    e.preventDefault();
                                    handleBlurPessoa();
                                },
                            }}
                            InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                            sx={{ width: 100 }}
                        />
                        <MDBox display="flex" justifyContent='center' alignItems='center' >
                            <SearchIcon sx={{ cursor: 'pointer' }}
                                onClick={() => {
                                    handleClickPesquisaPessoa();
                                }}
                            />
                            <Typography variant='caption'>{nomeFornecedor}</Typography>
                        </MDBox>
                    </Box>


                    <Box m={2}>
                        <MDInput
                            fullWidth
                            label='Obs'
                            value={dadosPedidoTempera.observacao}
                            multiline={true}
                            rows={5}
                            onChange={(e) => {
                                e.preventDefault();
                                setDadosPedidoTempera({ ...dadosPedidoTempera, observacao: e.target.value })
                            }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <MDButton color='info' onClick={handleClickGravar}>Gravar</MDButton>

                </DialogActions>
            </Dialog>
        </>
    )
}