import { Autocomplete, Box, Divider, Grid, Typography } from '@mui/material';
import api from 'commons/api';
import { rotafechada } from 'commons/urlconf';
import { strToInt } from 'commons/utils';
import FormField from 'componentes/FormField';
import MostraImagem from 'componentes/MostraImagem';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MessagesContext from 'hooks/MessagesContext';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

const MedidasProjetos = (props) => {

    const { control, setValue, getValues, vendaAtual, handleSubmit,
        etapaAtual, setEtapaAtual, objFull, proximaEtapa, urlImagem, setUrlImagem, desenhaCabecalhoPadrao } = props;
    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;

    //inicia o botao desativado para evitar um  click antes do Useeffect que valida
    //se tem produtos a corrigir, porque se nao tiver a etapa ja passa sozinha
    const [disabledBotaoProximaEtapa, setDisabledBotaoProximaEtapa] = useState(true);
    const [openZoomImg, setOpenZoomImg] = useState(false);

    function gravarTeste(data) {
        console.log('objfull-original:', objFull);
        console.log('obj-RHF:', data);
    }

    const validaProximaEtapa = () => {

        let msgerro = '';
        let erro = false;
        objFull.medidas?.map((item, index) => {

            if ((item.oculto !== 'S') &&
                !(parseFloat(getValues(`projetos.medidas[${index}].quantidade`)) >= 0)
            ) {
                msgerro = msgerro === '' ? item.Medidas?.nome : `${msgerro} | ${item.Medidas_Projeto?.nome}`;
                erro = true;
            }
        })

        if (erro) {
            MensagemErro(`O Preenchimento dos Valores é obrigatório. Valores inválidos: ${msgerro}`);
            return;
        }

        proximaEtapa();

    }

    const etapaAnterior = () => {
        setEtapaAtual(etapaAtual - 1);
    }

    const verificaSeTemMedidas = async () => {
        if (!(objFull.medidas?.length > 0)) {
            validaProximaEtapa();
        }
        else setDisabledBotaoProximaEtapa(false);

    }

    useEffect(() => {
        verificaSeTemMedidas();
    }, [])


    const handleEnterProximoFoco = (event) => {
        if (event.key.toLowerCase() === "enter") {
            const form = event.target.form;
            const index = [...form].indexOf(event.target);
            form.elements[index + 1].focus();
            event.preventDefault();
        }
    }

    const handleEnterProximaEtapa = (event) => {
        if (event.key.toLowerCase() === "enter") {
            validaProximaEtapa();
        }
    }

    const handleClickAbrirImg = async () => {
        setOpenZoomImg(true);
    }


    return (
        <>
            <MostraImagem
                open={openZoomImg}
                setOpen={setOpenZoomImg}
                img={`data:image/png[jpg];base64,${urlImagem}`}
                alt='Imagem do projeto'
            />
            {
                desenhaCabecalhoPadrao('Medidas - ')
                
            }
            <Typography variant='subtitle2'mb={2}>Informe as medidas do projeto</Typography>
            <form>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={9}>
                        <Grid container spacing={1}>
                            {
                                objFull?.medidas?.map((item, index) => {
                                    return (

                                        (item.oculto !== 'S') ?
                                            <Grid item xs={12} sm={12} key={`medidas_${item.id}`}>
                                                {/* <Divider light={false} /> */}
                                                <Grid container mt={2}>
                                                    <Grid item xs={12} sm={4} >
                                                        <Controller
                                                            name={`projetos.medidas[${index}].quantidade`}
                                                            control={props.control}
                                                            defaultValue=''
                                                            render={({ field: { ref, ...props } }) =>
                                                                <FormField
                                                                    autoFocus={index === 0 ? true : false}
                                                                    placeholder={item.Medidas_Projeto.nome.toUpperCase() == 'LARGURA' || item.Medidas_Projeto.nome.toUpperCase() == 'ALTURA' ? '0 mm' : ''}
                                                                    autoComplete={false}
                                                                    type='text'
                                                                    label={item.Medidas_Projeto.nome.toUpperCase() == 'LARGURA' || item.Medidas_Projeto.nome.toUpperCase() == 'ALTURA' ? `${item.Medidas_Projeto.nome} (mm)` : item.Medidas_Projeto.nome}
                                                                    rows={1}
                                                                    multiline={false}
                                                                    InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                                                                    required={false}
                                                                    disabled={false}
                                                                    inputRef={ref}
                                                                    {...props}
                                                                    onKeyDown={(index < objFull?.medidas?.length - 1) ? handleEnterProximoFoco : handleEnterProximaEtapa}

                                                                />}
                                                        />

                                                    </Grid>
                                                    <Grid item xs={12} sm={8} >
                                                        <Typography variant='body2'>{item.observacao ? `Obs: ${item.observacao}` : ''}</Typography>
                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                            : false
                                    )

                                })
                            }
                        </Grid>

                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <MDBox m={2} p={2} shadow="lg" borderRadius="lg" width="100%" display='flex' justifyContent='center'>
                            {
                                urlImagem !== '' ?
                                    <img src={`data:image/png[jpg];base64,${urlImagem}`} alt="Imagem do Projeto" onClick={() => handleClickAbrirImg()} style={{ maxHeight: 200, maxWidth: 200 ,cursor: "zoom-in" }} />
                                    :
                                    false
                            }
                        </MDBox>
                        <Box display={'flex'} justifyContent={'center'}>
                            <ZoomInIcon onClick={() => handleClickAbrirImg()} sx={{ ml: 2 }} color={'info'} fontSize='medium' />
                        </Box>
                    </Grid>
                </Grid>
            </form>

            <MDBox width="100%" display="flex" justifyContent="space-between" mt={2}>
                <MDButton variant="gradient" color="info" onClick={etapaAnterior} sx={{ ml: 2 }}>Retornar</MDButton>
                {
                    (props.parametrosVenda.mostra_botao_obj || false) &&
                    <MDButton variant="gradient" color="warning" onClick={() => handleSubmit(gravarTeste)()} sx={{ ml: 2 }}>Mostra OBJ</MDButton>
                }
                <MDButton variant="gradient" color="info" disabled={disabledBotaoProximaEtapa} onClick={validaProximaEtapa} sx={{ ml: 2 }}>Avançar</MDButton>
            </MDBox>
        </>
    )
}

export default MedidasProjetos;
