import { Autocomplete, Grid, Typography } from '@mui/material';
import FormField from 'componentes/FormField';
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';

// Images
import image1 from "assets/images/products/choose-product.png";
import ImagemProduto from './imagemproduto';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';

const Cabecalho = (props) => {
  const { produtoAtual, setProdutoAtual, control, opcoesAtivo, opcoesGrupo, opcoesTipo, opcoesUnidadeMedida, getValues, setValue } = props;
  const [currentImage, setCurrentImage] = useState(image1);

  const [imagePreviewUrl, setImagePreviewUrl] = useState('');

  const handleClickNovo = () => {
    setImagePreviewUrl('');//limpa a imagem atual
    //novoProjeto();
  }


  return (
    <MDBox p={2}>
      <MDBox mb={3}>
        <Typography variant='h6' >Dados do Produto: {`${getValues('id') || ''}-${getValues('nome') || ''}`}</Typography>
      </MDBox>

      <Grid container spacing={1}>

        <Grid item xs={12} sm={9}>{/*dados do produto*/}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={2}>{/*id*/}
              <Controller
                name={'id'}
                control={props.control}
                defaultValue=''
                render={({ field }) =>
                  <FormField
                    {...field}
                    key={'id'}
                    id={'id'}
                    autoComplete={false}
                    type='text'
                    label='ID'
                    rows={1}
                    multiline={false}
                    required={false}
                    disabled={true}
                    inputProps={{
                      disabled: true
                    }}
                  />}
              />
            </Grid>
            <Grid item xs={12} sm={6}>{/*Referencia*/}
              <Controller
                name={'referencia'}
                defaultValue=''
                control={props.control}
                render={({ field }) =>
                  <FormField
                    {...field}
                    key={'referencia'}
                    id={'referencia'}
                    autoComplete={false}
                    type='text'
                    label='Referência'
                    rows={1}
                    multiline={false}
                    required={true}
                    disabled={false}

                  />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>{/*Ativo*/}
              <Controller
                name='ativo'
                control={control}
                defaultValue={{ value: 'N' }}
                render={({ field: { onChange, ...props } }) => (
                  <AutoCompleteStyled
                    options={opcoesAtivo}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    renderInput={(params) => <FormField {...params}  label='Ativo' />}
                    onChange={(_, data) => {
                      return onChange(data);
                    }}
                    disabled={false}
                    {...props}
                  />
                )}

              />
            </Grid>


            <Grid item xs={12} sm={12}>{/*Nome*/}
              <Controller
                name={'nome'}
                defaultValue=''
                control={props.control}
                render={({ field }) =>
                  <FormField
                    {...field}
                    key={'nome'}
                    id={'nome'}
                    autoComplete={false}
                    type='text'
                    label='Nome'
                    rows={1}
                    multiline={false}
                    required={true}
                    disabled={false}
                  />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>{/*Tipo*/}
              <Controller
                name='tipo_produto_id'
                control={control}
                defaultValue={{ value: -1 }}
                render={({ field: { onChange, ...props } }) => (
                  <AutoCompleteStyled
                    id='tipo_produto_id'
                    disableClearable //Retira o X (clear)
                    options={opcoesTipo}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    renderInput={(params) => <FormField required {...params} label='Tipo' />}
                    onChange={(_, data) => {
                      return onChange(data);
                    }}
                    disabled={false}
                    {...props}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>{/*Grupo*/}
              <Controller
                name='grupo_produto_id'
                control={control}
                defaultValue={{ value: -1 }}
                render={({ field: { onChange, ...props } }) => (
                  <AutoCompleteStyled
                    id='grupo_produto_id'
                    disableClearable //Retira o X (clear)
                    options={opcoesGrupo}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    renderInput={(params) => <FormField required {...params} label='Grupo' />}
                    onChange={(_, data) => {
                      return onChange(data);
                    }}
                    disabled={false}
                    {...props}

                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>{/*Unidade de Medida*/}
              <Controller
                name='unidade_medida_id'
                disableClearable //Retira o X (clear)
                control={control}
                defaultValue={{ value: -1 }}
                render={({ field: { onChange, ...props } }) => (
                  <AutoCompleteStyled
                    id='unidade_medida_id'
                    options={opcoesUnidadeMedida}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    renderInput={(params) => <FormField required {...params} label='Unidade de Medida' />}
                    onChange={(_, data) => {
                      return onChange(data);
                    }}
                    {...props}

                  />
                )}
              />
            </Grid>


            <Grid item xs={12} sm={4}>{/*peso*/}
              <Controller
                name={'peso'}
                defaultValue=''
                control={props.control}
                render={({ field }) =>
                  <FormField
                    {...field}
                    key={'peso'}
                    id={'peso'}
                    autoComplete={false}
                    type='number'
                    label='Peso (Kg por M)'
                    rows={1}
                    multiline={false}
                    disabled={false}
                  />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>{/*tamanho_barra*/}
              <Controller
                name={'tamanho_barra'}
                defaultValue=''
                control={props.control}
                render={({ field }) =>
                  <FormField
                    {...field}
                    key={'tamanho_barra'}
                    id={'tamanho_barra'}
                    autoComplete={false}
                    type='number'
                    label='Tamanho Barra  (Metro)'
                    rows={1}
                    multiline={false}
                    disabled={false}
                  />}
              />
            </Grid>




            <Grid item xs={12} sm={12}>{/*observacao*/}
              <Controller
                name={'observacao'}
                defaultValue=''
                control={props.control}
                render={({ field }) =>
                  <FormField
                    {...field}
                    key={'observacao'}
                    id={'observacao'}
                    autoComplete={false}
                    type='text'
                    label='Observação'
                    rows={2}
                    multiline={true}
                    required={false}
                    disabled={false}
                  />}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={3}>{/*Foto*/}
          <ImagemProduto
            produtoAtual={produtoAtual}
            setValue={setValue}//do RHF
            imagePreviewUrl={imagePreviewUrl}
            setImagePreviewUrl={setImagePreviewUrl}

          />

        </Grid>

      </Grid>


    </MDBox>
  )
}

export default Cabecalho;