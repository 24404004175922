import { Card, Collapse, IconButton, Typography } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MDBox from "components/MDBox";
import { useState } from "react";
import CategoriaCentralRelatorios from "./Cadastro";



export default function CategoriaCentralRelatoriosAdmin(props) {

    const { openCollapseCategoriaCentralRelatorios, handleClickCollapseCategoriaCentralRelatorios } = props;
    const [recarregaTabela, setRecarregaTabela] = useState('');
    const [categoriaCentralRelatoriosAtual, setcategoriaCentralRelatoriosAtual] = useState('');

    return (
        <Card id="cadastro-categoria-central-relatorios" sx={{ overflow: "visible" }}>
            <MDBox p={2} bgColor='grey-100' mt={2}>
                <IconButton aria-label="expand row" size="small" onClick={() => handleClickCollapseCategoriaCentralRelatorios(!openCollapseCategoriaCentralRelatorios)}>
                    {openCollapseCategoriaCentralRelatorios ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    <Typography variant='h6' mb={2}>Categoria Central de Relatórios</Typography>
                </IconButton>

                <Collapse in={openCollapseCategoriaCentralRelatorios} timeout="auto" unmountOnExit>
                    <CategoriaCentralRelatorios
                        recarregaTabela={recarregaTabela}
                        setRecarregaTabela={setRecarregaTabela}
                        categoriaCentralRelatoriosAtual={categoriaCentralRelatoriosAtual}
                        setcategoriaCentralRelatoriosAtual={setcategoriaCentralRelatoriosAtual}
                    />
                </Collapse>
            </MDBox>
        </Card>
    )
}