
// @mui material components
import { Collapse, Grid, MenuItem, Menu, ListItemIcon, } from "@mui/material";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";


function TotalizadoresContas(props) {

    const { totalizadores, tipo } = props;

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={2}>
                <MDBox pt={1} pb={1}
                    variant="gradient"
                    //bgColor="error"
                    //color="white"
                    borderRadius="lg"
                    coloredShadow='error'
                    // shadow="lg"
                    sx={{
                        fontSize: '18px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        lineHeight: 1.5,
                        color: 'red'
                    }}
                >
                    Vencidos <br />
                    {totalizadores.somaVencidos} </MDBox>
            </Grid>
            <Grid item xs={12} md={2}>
                <MDBox pt={1} pb={1}
                    variant="gradient"
                    //bgColor="error"
                    //color="white"
                    borderRadius="lg"
                    coloredShadow='warning'
                    // shadow="lg"
                    sx={{
                        fontSize: '18px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        lineHeight: 1.5,
                        color: 'orange'
                    }}
                >
                    Hoje <br /> {totalizadores.somaHoje} </MDBox>
            </Grid>
            <Grid item xs={12} md={2}>
                <MDBox pt={1} pb={1}
                    variant="gradient"
                    //bgColor="error"
                    //color="white"
                    borderRadius="lg"
                    coloredShadow='info'
                    // shadow="lg"
                    sx={{
                        fontSize: '18px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        lineHeight: 1.5,
                        color: 'blue'
                    }}
                >Não Vencidos<br /> {totalizadores.somaNaoVencidos} </MDBox>
            </Grid>
            <Grid item xs={12} md={2}>
                <MDBox pt={1} pb={1}
                    variant="gradient"
                    //bgColor="error"
                    //color="white"
                    borderRadius="lg"
                    coloredShadow='secondary'
                    //shadow="lg"
                    sx={{
                        fontSize: '18px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        lineHeight: 1.5,
                        color: 'black'
                    }}
                >
                    Saldo Total<br /> {totalizadores.somaSaldo} </MDBox>
            </Grid>
            <Grid item xs={12} md={2}>
                <MDBox pt={1} pb={1}
                    variant="gradient"
                    //bgColor="error"
                    //color="white"
                    borderRadius="lg"
                    coloredShadow='success'
                    // shadow="lg"
                    sx={{
                        fontSize: '18px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        lineHeight: 1.5,
                        color: 'green'
                    }}
                >{tipo == 'R' ? 'Soma Recebidos' : 'Soma Pagos'} <br /> {totalizadores.somaPagos} </MDBox>
            </Grid>
            <Grid item xs={12} md={2}>
                <MDBox pt={1} pb={1}
                    variant="gradient"
                    //bgColor="error"
                    //color="white"
                    borderRadius="lg"
                    coloredShadow='secondary'
                    // shadow="lg"
                    sx={{
                        fontSize: '18px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        lineHeight: 1.5,
                        color: 'black'
                    }}
                >Total do Período <br /> {totalizadores.somaTotal} </MDBox>
            </Grid>
        </Grid>

    )
}


export default TotalizadoresContas;