import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import MDBox from 'components/MDBox';
import { useMaterialUIController } from "context";
import { createTheme, ThemeProvider } from '@mui/material/styles';
/*
colunas = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'Id'
    fixed: true
  },
  {
    id: 'nome',
    numeric: false,
    disablePadding: true,
    label: 'Nome'
  },
  {
    id: 'ativo',
    numeric: false,
    disablePadding: true,
    label: 'Ativo',
    disableOrder: false
  },
  {
    id: 'acoes',
    numeric: false,
    disablePadding: true,
    label: 'Ações',
    disableOrder: true
  },
]

linhas = [
  {
    id:10,
    nome:'XXX',
    ativo: 'S',
    acoes:<button/>
  }
]
*/



function EnhancedTableHead(props) {
  const { order, orderBy, rowCount, onRequestSort, headCells } =
    props;
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (

    <MDBox component="thead" bgColor={darkMode ? '' : 'grey-200'}
    >
      {/* <TableHead> */}

      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={headCell.fixed === true ? { position: 'sticky', left: 0, backgroundColor: '#f0f2f5' } : { color: darkMode ? "white" : "secondary" }}
          >

            {

              headCell.disableOrder === true ?

                <Typography
                  variant="h6"
                  component="div"
                >
                  {headCell.label}
                </Typography>
                :

                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  <Typography
                    variant="h6"
                    component="div"
                  >
                    {headCell.label}
                  </Typography>

                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
            }
          </TableCell>
        ))}
      </TableRow>
    </MDBox>


  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,

  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


function TabelaPadrao(props) {

  const { linhas: rows, colunas: headCells, countAll, page, setPage, rowsPerPage, setRowsPerPage, order, setOrder, orderBy, setOrderBy, disablePagination } = props;
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const handleRequestSort = (event, property) => {
    //property é o ID da coluna que sera usada
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // tema personalizado
  const theme = createTheme({
    components: {
      MuiTablePagination: {
        styleOverrides: {
          toolbar: {
            backgroundColor: darkMode ? '#344767' : '#ffffff', 
            color: darkMode ? '#ffffff' : '#191919', 
          },
        },
      },
    },
  });

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size='small'
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}

              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
            />
            <TableBody>
              {rows.map((row, index) => {
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={`tr${index}`}
                  >
                    {
                      headCells.map((item, index) => {

                        return (
                          <TableCell
                            align={item.numeric ? 'right' : 'left'}
                            padding={item.disablePadding ? 'none' : 'normal'}
                            key={`tc${index}`}
                            style={item.fixed === true ? { position: 'sticky', left: 0, backgroundColor: '#f9fafc', borderColor: "#f0f2f5" } : { color: darkMode ? "white" : "secondary" }}
                          >
                            {row[item.id]}
                          </TableCell>
                        )
                      })
                    }
                  </TableRow>
                );
              })}
              {/* {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 33 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
        {!disablePagination &&
          <ThemeProvider theme={theme}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={countAll}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage='Linhas por Página'
            />
          </ThemeProvider>
        }
      </Paper>
    </Box>
  );
}

export default React.memo(TabelaPadrao);