import React, { useContext, useEffect, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { Autocomplete, Checkbox, Grid, IconButton, Typography } from '@mui/material';
import FormField from 'componentes/FormField';
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import SearchIcon from '@mui/icons-material/Search';

import MDTypography from 'components/MDTypography';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import MessagesContext from 'hooks/MessagesContext';
import MDButton from 'components/MDButton';
import AuthContext from 'hooks/AuthContext';
import TabelaServicos from './tabela';
import DialogoConfirma from 'componentes/DialogoConfirma';
import { Link } from 'react-router-dom';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';


const Gen_Forma_Pagamento_Turma = (props) => {

  const { handleSubmit, control, setValue, getValues, turmaAtual } = props;

  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;
  const authContext = useContext(AuthContext);
  const { getSessao } = authContext;
  const sessaolocal = getSessao();
  const [openCollapse, setOpenCollapse] = useState(false);
  const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });
  const opcaoDefault = [{ value: -1, label: '<Selecione...>' }];
  const [recarregaTabela, setRecarregaTabela] = useState(false);
  const [codigoAtual, setCodigoAtual] = useState('');
  

  const ObjModeloGenFormaPagamentoTurma = {
    id: { type: 'text' },
    nome: { type: 'text' },
    valor: { type: 'text' },
  }

  const validaRotaPermissao = async (operacao = '', silencioso = false) => {
    if ((operacao !== 'inserir') &&
      (operacao !== 'editar') &&
      (operacao !== 'excluir') &&
      (operacao !== 'listar')) {
      MensagemErro('Escolha a operação que deseja validar a permissão!');
      return false;
    }

    //validando a permissao
    try {
      await api.get(`${rotafechada()}gen_forma_pagamento_turma/permissao/${operacao}`);
      return true;
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;

      if (silencioso === false) {
        MensagemErro(`Erro: ${msg}`);
      }
      return false;
    }
  }

  const validaCampos = (data) => {

    let camposObrigatorios = ``;

    if (!data.nome){
      camposObrigatorios=`${camposObrigatorios}|Nome`;
    }

    if (camposObrigatorios !== '') {
      throw new Error(`Campos obrigatórios: ${camposObrigatorios}`)
    }
  }

  const gravarRegistro = async (data) => {
    const dados = {};

    Object.keys(ObjModeloGenFormaPagamentoTurma).forEach((nome) => {
      dados[nome] = '';
      if (nome in data['gen_forma_pagamento_turma']) {
        if (typeof data['gen_forma_pagamento_turma'][nome] === 'object') {
          dados[nome] = data['gen_forma_pagamento_turma'][nome]?.value ? data['gen_forma_pagamento_turma'][nome]?.value : '';
        }
        else
          if (typeof data['gen_forma_pagamento_turma'][nome] === 'boolean') {
            dados[nome] = data['gen_forma_pagamento_turma'][nome] === true ? 'S' : 'N'
          }
          else dados[nome] = data['gen_forma_pagamento_turma'][nome];
      }
    })

    dados.empresa_id = sessaolocal.empresa_id || null;
    dados.gen_turma_id = turmaAtual;
    delete dados.id;

    try {
      validaCampos(dados);
    }
    catch (error) {
      MensagemErro(`${error.message}`);
      return;
    }

    try {
      let retorno;
      let codigoTemp = data.gen_forma_pagamento_turma.id;

      if ((data.gen_forma_pagamento_turma.id) && (parseInt(data.gen_forma_pagamento_turma.id, 10) > 0)) {
        retorno = await api.put(`${rotafechada()}gen_forma_pagamento_turma/${data.gen_forma_pagamento_turma.id}`, dados);
      }
      else {
        retorno = await api.post(`${rotafechada()}gen_forma_pagamento_turma`, dados);
        codigoTemp = retorno.data.data.id;
      }

      MensagemAviso(`Gravado com sucesso! Componente: ${codigoTemp}`);
      setCodigoAtual('');
      setRecarregaTabela(!recarregaTabela);
    }
    catch (error) {
      console.log(error);
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const novoComponente = () => {
    //limpo os campos
    Object.keys(ObjModeloGenFormaPagamentoTurma).forEach((nome) => {
      if (ObjModeloGenFormaPagamentoTurma[nome].type === 'autocomplete') {
        setValue(`gen_forma_pagamento_turma.${nome}`, opcaoDefault[0])
      }
      else
        if (ObjModeloGenFormaPagamentoTurma[nome].type === 'checkbox') {
          setValue(`gen_forma_pagamento_turma.${nome}`, false)
        }
        else
          if (ObjModeloGenFormaPagamentoTurma[nome].type === 'text') {
            setValue(`gen_forma_pagamento_turma.${nome}`, '')
          }
    })

    setValue('gen_forma_pagamento_turma.nomeservico', '...')
  }

  const abrirRegistro = async (codigo) => {
    try {
      let url = `${rotafechada()}gen_forma_pagamento_turma?id=${codigo}`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;

      if (!data.length > 0) {
        MensagemErro(`Componente ID ${codigo} não localizado`);
        return;
      }
      setValue('gen_forma_pagamento_turma.id', data[0].id);
      setValue('gen_forma_pagamento_turma.nome', data[0].nome);
      setValue('gen_forma_pagamento_turma.valor', data[0].valor);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const excluirRegistro = async () => {

    setDialogoOpen({ ...dialogoOpen, visible: false });

    try {
      await api.delete(`${rotafechada()}gen_forma_pagamento_turma/${dialogoOpen.id || 0}`);
      MensagemAviso('Excluído com sucesso!');
      novoComponente();
      setRecarregaTabela(!recarregaTabela);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleClickExcluir = async (id) => {
    if (!(await validaRotaPermissao('excluir') === true)) {
      return;
    }
    setDialogoOpen({ ...dialogoOpen, visible: true, id });
  }
  
  useEffect(() => {
    if (parseInt(codigoAtual, 10) > 0) {
      abrirRegistro(codigoAtual);
    }
    else novoComponente();
  }, [turmaAtual, codigoAtual, recarregaTabela])

  return (
    <MDBox p={2} bgColor='#EAFAF1' mt={2}>


      <DialogoConfirma
        open={dialogoOpen.visible}
        title={'Confirma Exclusão'}
        message={'Certeza que deseja excluir?'}
        handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
        handleConfirm={excluirRegistro}
      />

      <IconButton
        aria-label="expand row"
        size="small"
        onClick={() => setOpenCollapse(!openCollapse)}
      >
        {openCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        <Typography variant='h6' mb={2}>Formas de Pagamento</Typography>
      </IconButton>

      <Collapse in={openCollapse} timeout="auto" unmountOnExit>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={2}>{/*id*/}
            <Controller
              name={`gen_forma_pagamento_turma.id`}
              control={props.control}
              defaultValue=''
              render={({ field: { ref, ...props } }) =>
                <FormField

                  autoComplete={false}
                  type='text'
                  label='Id'
                  rows={1}
                  multiline={false}
                  required={false}
                  disabled={true}
                  inputProps={{
                    disabled: true
                  }}
                  inputRef={ref}
                  {...props}
                />}
            />
          </Grid>
          


          <Grid item xs={12} sm={12}>{/*nome*/}
            <Controller
              name={`gen_forma_pagamento_turma.nome`}
              control={props.control}
              defaultValue=''
              render={({ field: { ref, ...props } }) =>
                <FormField

                  autoComplete={false}
                  type='text'
                  label='Nome'
                  rows={1}
                  multiline={false}
                  required={true}
                  disabled={false}
                  inputRef={ref}
                  {...props}

                />}
            />
          </Grid>

          <Grid item xs={12} sm={12}>{/*valor*/}
            <Controller
              name={`gen_forma_pagamento_turma.valor`}
              control={props.control}
              defaultValue=''
              render={({ field: { ref, ...props } }) =>
                <FormField

                  autoComplete={false}
                  type='text'
                  label='Valor'
                  rows={1}
                  multiline={false}
                  disabled={false}
                  inputRef={ref}
                  required={true}
                  {...props}

                />}
            />
          </Grid>
       
        </Grid>

        <MDBox width="100%" display="flex" justifyContent="flex-end" mt={2}>
          <MDButton variant="gradient" color="info" onClick={() => handleSubmit(gravarRegistro)()}>Gravar</MDButton>
          <MDButton variant="gradient" color="info" onClick={novoComponente} sx={{ ml: 2 }}>Novo</MDButton>
        </MDBox>

        <MDBox width="100%" display="flex" mt={2}>
          <TabelaServicos
            turmaAtual={turmaAtual}
            abrirRegistro={abrirRegistro}
            excluirRegistro={handleClickExcluir}
            recarregaTabela={recarregaTabela}
          />
        </MDBox>

      </Collapse>
    </MDBox>
  )
}

export default Gen_Forma_Pagamento_Turma;