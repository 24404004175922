import React, { Component, useContext, useEffect, useState } from 'react';
import { render } from 'react-dom';
import { Stage, Layer, Rect, Text, Shape, Circle, Line, Image, Transformer, Arrow } from 'react-konva';
import Konva from 'konva';
import { Box } from '@mui/system';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import useImage from 'use-image';
import { Autocomplete, Button, Dialog, DialogContent, DialogTitle, Icon, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { copiaProfundaObjetos } from 'commons/utils';
import CamadaDesenho from './camadadesenho/camadadesesenho';
import { PropriedadesRetangulo } from './camadadesenho/objetos/retangulo';
import MDButton from 'components/MDButton';
import { CompactPicker } from 'react-color';
import { PropriedadesCirculo } from './camadadesenho/objetos/circulo';
import { PropriedadesLinhaHorizontal } from './camadadesenho/objetos/linhahorizontal';
import { PropriedadesCotaHorizontal } from './camadadesenho/objetos/cotahorizontal';
import { PropriedadesLinhaVertical } from './camadadesenho/objetos/linhavertical';
import MessagesContext from 'hooks/MessagesContext';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import { PropriedadesTexto } from './camadadesenho/objetos/texto';
import DialogoConfirma from 'componentes/DialogoConfirma';

import SaveIcon from '@mui/icons-material/Save';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FlipToFrontIcon from '@mui/icons-material/FlipToFront';
import FlipToBackIcon from '@mui/icons-material/FlipToBack';
import ArticleIcon from '@mui/icons-material/Article';
import SettingsIcon from '@mui/icons-material/Settings';
import WallpaperIcon from '@mui/icons-material/Wallpaper';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import FiltroImportarProjeto from 'pages/projetos/projetos/Cadastro/cabecalho/filtroimportarprojeto';
import { calculaPosicaoTexto } from './camadadesenho/objetos/funcoescomuns';


const LARGURAPONTASETA = 15;
const QUANTDESLOCAMENTO = 1;
const ALTURA = 768;
const LARGURA = 1024;


function PropriedadesFigura(props) {

    const { itensTela, setItensTela, selectedId } = props;



    if (!(parseInt(selectedId, 10) >= 0)) {
        return false;
    }

    if (!(parseInt(selectedId, 10) <= (itensTela.length - 1))) {
        return false;
    }

    switch (itensTela[selectedId].tipo) {
        case 'rect':
            return (
                <PropriedadesRetangulo
                    {...props}
                />
            )

        case 'circle':
            return (
                <PropriedadesCirculo
                    {...props}
                />
            )

        case 'linhahorizontal':
            return (
                <PropriedadesLinhaHorizontal
                    {...props}
                />
            )

        case 'linhavertical':
            return (
                <PropriedadesLinhaVertical
                    {...props}
                />
            )

        case 'cotahorizontal':
            return (
                <PropriedadesCotaHorizontal
                    {...props}
                />
            )

        case 'cotavertical':
            return (
                <PropriedadesCotaHorizontal
                    {...props}
                />
            )

        case 'text':
            return (
                <PropriedadesTexto
                    {...props}
                />
            )

        default:
            break;
    }

    return false;


}

const opcoesTipos = [
    {
        label: 'Retângulo',
        icon: <Icon fontSize='large'>rectangle</Icon>,
        tipo: 'rect'
    },
    {
        label: 'Círculo',
        icon: <Icon fontSize='large'>circle</Icon>,
        tipo: 'circle'
    },
    {
        label: 'Cota Horizontal',
        icon: <Icon fontSize='large'>text_rotation_none</Icon>,
        tipo: 'cotahorizontal'
    },
    {
        label: 'Cota Vertical',
        icon: <Icon fontSize='large'>text_rotate_vertical</Icon>,
        tipo: 'cotavertical'
    },
    {
        label: 'Texto',
        icon: <Icon fontSize='large'>text_format</Icon>,
        tipo: 'text'
    },
    {
        label: 'Linha Horizontal',
        icon: <Icon fontSize='large'>remove</Icon>,
        tipo: 'linhahorizontal'
    },
    {
        label: '|  Linha Vertical',
        icon: null,
        tipo: 'linhavertical'
    },

]

export function BarraBotoes(props) {

    const { handleClickNovoDesenho, handleClickGravarProjeto,
        mostraDesenhoTemperaModelo, nomeprojeto, handleClickRecarregarDesenho, pedido_tempera_venda_projeto_id,
        handleClickImportarDesenho } = props;

    return (
        <Box display={'flex'}>

            <Tooltip title='Novo Desenho' >
                <IconButton
                    sx={{ color: 'blue' }}
                    onClick={handleClickNovoDesenho}
                >
                    <ArticleIcon fontSize='large' />
                </IconButton>
            </Tooltip>


            <Tooltip title='Gravar Alterações (Ctrl+S'>
                <IconButton
                    sx={{ color: 'blue' }}
                    onClick={handleClickGravarProjeto}
                >
                    <SaveIcon fontSize='large' />
                </IconButton>
            </Tooltip>



            <Tooltip title='Recarregar Desenho (Ctrl+O)'>
                <IconButton
                    sx={{ color: 'blue' }}
                    onClick={handleClickRecarregarDesenho}
                >
                    <FolderOpenIcon fontSize='large' />
                </IconButton>
            </Tooltip>

            {
                parseInt(pedido_tempera_venda_projeto_id, 10) > 0 &&
                <Tooltip title='Mostra Projeto Original'>
                    <IconButton
                        sx={{ color: 'blue' }}
                        onClick={mostraDesenhoTemperaModelo}
                    >
                        <WallpaperIcon fontSize='large' />
                    </IconButton>
                </Tooltip>
            }

            {
                parseInt(pedido_tempera_venda_projeto_id, 10) < 0 &&
                <Tooltip title='Importa desenho de outro projeto'>
                    <IconButton
                        sx={{ color: 'blue' }}
                        onClick={handleClickImportarDesenho}
                    >
                        <ImportExportIcon fontSize='large' />
                    </IconButton>
                </Tooltip>
            }




            <Box>
                <Box display='flex' mt={2}>
                    <Typography variant='h6'>{nomeprojeto}</Typography>
                </Box>
            </Box>

        </Box>
    )

}

export function BarraObjetosePropriedades(props) {

    const { setTipoSelecionado, selectedId, tipoSelecionado, selectShape,
        handleClickDeletaItem, setOpenPropriedades, handleClickNovoObjeto, handleClickDuplicaItem,
        handleClickEnviarParaTras, handleClickEnviarParaFrente,
        handleClickMovimentaEsquerda, handleClickMovimentaDireita,
        handleClickMovimentaCima, handleClickMovimentaBaixo } = props;

    return (
        <Box sx={{ mb: 2, mt: 2 }} >

            <Box display={'flex'} justifyContent='flex-start'>
                <Autocomplete
                    id="ACtipos"
                    sx={{ width: 300 }}
                    options={opcoesTipos}
                    autoHighlight
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {option.icon}
                            {option.label}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Escolha um Tipo"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'tipo', // disable autocomplete and autofill
                            }}
                        />
                    )}
                    value={tipoSelecionado}
                    onChange={(event, newValue) => {
                        setTipoSelecionado(newValue);
                    }}
                />
                <MDButton color='info' sx={{ ml: 2 }} onClick={handleClickNovoObjeto}>
                    <Icon fontSize='large' >add</Icon>
                </MDButton>
            </Box>
            {
                parseInt(selectedId, 10) >= 0 &&
                <Box display={'flex'} justifyContent='flex-start'>

                    <Tooltip title='Cancelar seleção'>
                        <MDButton onClick={() => selectShape(null)}>
                            <Icon fontSize='large'>deselect</Icon>
                        </MDButton>
                    </Tooltip>
                    <Tooltip title='Apaga Item'>
                        <IconButton
                            sx={{ color: 'red' }}
                            onClick={handleClickDeletaItem}
                        >
                            <Icon >delete</Icon>
                        </IconButton>
                    </Tooltip>

                    <Tooltip title='Propriedades do objeto'>
                        <IconButton
                            sx={{ color: 'blue' }}
                            onClick={() => setOpenPropriedades(true)}
                        >
                            <SettingsIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title='Duplicar (Ctrl+D)'>
                        <IconButton
                            sx={{ color: 'blue' }}
                            onClick={handleClickDuplicaItem}
                        >
                            <ContentCopyIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title='Enviar para Frente'>
                        <IconButton
                            sx={{ color: 'blue' }}
                            onClick={handleClickEnviarParaFrente}
                        >
                            <FlipToFrontIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title='Movimenta para esquerda'>
                        <IconButton
                            sx={{ color: 'blue' }}
                            onClick={handleClickMovimentaEsquerda}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title='Movimenta para direita'>

                        <IconButton
                            sx={{ color: 'blue' }}
                            onClick={handleClickMovimentaDireita}
                        >
                            <ArrowForwardIcon />
                        </IconButton>
                    </Tooltip>


                    <Tooltip title='Movimenta para cima'>

                        <IconButton
                            sx={{ color: 'blue' }}
                            onClick={handleClickMovimentaCima}
                        >
                            <ArrowUpwardIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title='Movimenta para baixo'>

                        <IconButton
                            sx={{ color: 'blue' }}
                            onClick={handleClickMovimentaBaixo}
                        >
                            <ArrowDownwardIcon />
                        </IconButton>
                    </Tooltip>



                </Box>
            }
        </Box>
    )
}

export default function ProjetoTempera(props) {

    const { projeto_id, pedido_tempera_venda_projeto_id, nomeprojeto, itemsRef, desabilitaBarras } = props;

    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;

    const [itensTela, setItensTela] = useState([]);
    const [itensTelaModelo, setItensTelaModelo] = useState([]);
    const [itensTelaBackup, setItensTelaBackup] = useState([]);
    const [selectedId, selectShape] = useState(null);

    const refDesenhoModelo = React.useRef(null);

    const [imageBase64, setImageBase64] = useState();
    const [openDialogo, setOpenDialogo] = useState(false);

    const [tipoSelecionado, setTipoSelecionado] = useState(opcoesTipos[0]);
    const [openPropriedades, setOpenPropriedades] = useState(false);

    const [opcoesTexto, setOpcoesTexto] = useState([]);
    const [openModelo, setOpenModelo] = useState(false);
    const [openConfirmacao, setOpenConfirmacao] = useState({ open: false })
    const [openFiltroImportarProjeto, setOpenFiltroImportarProjeto] = useState(false);


    const handleClickNovoRetangulo = () => {

        //const itensTelaTemp = copiaProfundaObjetos(itensTela);

        const itensTelaTemp = itensTela.slice();

        const recttemp = {
            tipo: 'rect',

            x: 10,
            y: 10,
            width: 100,
            height: 100,
            fill: null, //preenchimento
            stroke: 'black', //borda
            strokeWidth: 1

        }

        itensTelaTemp.push(recttemp);
        setItensTela(itensTelaTemp);
    }

    const handleClickNovoCirculo = () => {

        //const itensTelaTemp = copiaProfundaObjetos(itensTela);

        const itensTelaTemp = itensTela.slice();

        const recttemp = {
            tipo: 'circle',

            x: 50,
            y: 50,
            fill: null, //preenchimento
            stroke: 'black', //borda
            radius: 50

        }

        itensTelaTemp.push(recttemp);
        setItensTela(itensTelaTemp);
    }

    const handleClickNovoTexto = () => {

        //const itensTelaTemp = copiaProfundaObjetos(itensTela);

        const itensTelaTemp = itensTela.slice();

        const recttemp = {
            tipo: 'text',

            texto: {
                tipo: { value: 'textosimples', label: 'Texto Simples' },
                text: 'Texto de Exemplo',
                fontSize: 24,
            },

            x: 50,
            y: 50,
            fill: 'black',
        }

        itensTelaTemp.push(recttemp);
        setItensTela(itensTelaTemp);
    }

    const handleClickNovaSeta = () => {

        //const itensTelaTemp = copiaProfundaObjetos(itensTela);

        const itensTelaTemp = itensTela.slice();

        const recttemp = {

            tipo: 'arrow',

            x: 50,
            y: 50,
            points: [0, 0, 0, 100],
            pointerLength: 10,
            pointerWidth: LARGURAPONTASETA,
            fill: 'black',
            stroke: 'black',
            strokeWidth: 1,
            pointerAtBeginning: true,

        }

        itensTelaTemp.push(recttemp);
        setItensTela(itensTelaTemp);
    }

    const handleClickNovaCotaHorizontal = () => {

        //const itensTelaTemp = copiaProfundaObjetos(itensTela);

        const itensTelaTemp = itensTela.slice();

        const recttemp = {

            tipo: 'cotahorizontal',
            texto: {
                text: 'Escolha o texto',
                fontSize: 12,
                x: 150,
                y: 25,
                tipo: { value: 'textosimples', label: 'Texto Simples' }
            },

            x: 50,
            y: 50,
            points: [0, 0, 200, 0],
            pointerLength: 10,
            pointerWidth: LARGURAPONTASETA,
            fill: 'black',
            stroke: 'black',
            strokeWidth: 1,
            pointerAtBeginning: true,

        }

        itensTelaTemp.push(recttemp);
        setItensTela(itensTelaTemp);
    }

    const handleClickNovaCotaVertical = () => {

        //const itensTelaTemp = copiaProfundaObjetos(itensTela);

        const itensTelaTemp = itensTela.slice();

        const recttemp = {

            tipo: 'cotavertical',
            texto: {
                text: 'Seu texto aqui',
                fontSize: 12,
                x: 25,
                y: 100,
                rotation: 270,
                tipo: { value: 'textosimples', label: 'Texto Simples' }
            },

            x: 50,
            y: 50,
            points: [0, 0, 0, 100],
            pointerLength: 10,
            pointerWidth: LARGURAPONTASETA,
            fill: 'black',
            stroke: 'black',
            strokeWidth: 1,
            pointerAtBeginning: true,

        }

        itensTelaTemp.push(recttemp);
        setItensTela(itensTelaTemp);
    }

    const handleClickNovaLinhaHorizontal = () => {

        //const itensTelaTemp = copiaProfundaObjetos(itensTela);

        const itensTelaTemp = itensTela.slice();

        const recttemp = {

            tipo: 'linhahorizontal',
            x: 50,
            y: 50,
            points: [0, 0, 100, 0],
            fill: 'black',
            stroke: 'black',
            strokeWidth: 5,
        }

        itensTelaTemp.push(recttemp);
        setItensTela(itensTelaTemp);
    }

    const handleClickNovaLinhaVertical = () => {

        //const itensTelaTemp = copiaProfundaObjetos(itensTela);

        const itensTelaTemp = itensTela.slice();

        const recttemp = {

            tipo: 'linhavertical',
            x: 50,
            y: 50,
            points: [0, 0, 0, 100],
            fill: 'black',
            stroke: 'black',
            strokeWidth: 5,
            //dash:[10,5],
            dash: null,
        }

        itensTelaTemp.push(recttemp);
        setItensTela(itensTelaTemp);
    }

    const handleClickNovoDesenho = () => {

        setOpenConfirmacao({
            open: true,
            message: 'Você perderá todos os dados. Certeza que deseja iniciar um novo desenho?',
            handleCancel: () => setOpenConfirmacao({ open: false }),
            handleConfirm: () => {
                setOpenConfirmacao({ open: false });
                novoDesenho();
            }
        });

    }

    const handleClickRecarregarDesenho = () => {
        setOpenConfirmacao({
            open: true,
            message: 'Você perderá todas as alterações não salvas. Certeza que deseja recarregar o último desenho salvo?',
            handleCancel: () => setOpenConfirmacao({ open: false }),
            handleConfirm: () => {
                setOpenConfirmacao({ open: false });
                buscaDesenho();
            }
        });
    }

    const novoDesenho = () => {
        const temp = itensTela.slice();
        setItensTelaBackup(temp);
        setItensTela([]);
    }

    const handleRestauraBackup = () => {
        const temp = itensTelaBackup.slice();
        setItensTela(temp);
    }

    const handleClickDeletaItem = () => {

        if (!(parseInt(selectedId, 10) >= 0)) return;

        const temp = itensTela.slice();

        let indice = parseInt(selectedId, 10);

        temp.splice(indice, 1);
        selectShape(null);
        setItensTela(temp);


    }

    const handleClickDuplicaItem = () => {

        if (!(parseInt(selectedId, 10) >= 0)) return;

        const objCopia = copiaProfundaObjetos(itensTela);
        const itemCopia = copiaProfundaObjetos(itensTela[selectedId]);
        itemCopia.x = parseInt(itemCopia.x, 10) + 20;
        itemCopia.y = parseInt(itemCopia.y, 10) + 20;
        objCopia.push(itemCopia);
        selectShape(objCopia.length - 1);
        setItensTela(objCopia);
    }

    const handleClickEnviarParaTras = () => {

        if (!(parseInt(selectedId, 10) >= 0)) return;

        const objCopia = copiaProfundaObjetos(itensTela);
        const itemCopia = copiaProfundaObjetos(itensTela[selectedId]);

        let indice = parseInt(selectedId, 10);
        objCopia.splice(indice, 1);

        objCopia.unshift(itemCopia)

        selectShape(0);
        setItensTela(objCopia);

    }

    const handleClickEnviarParaFrente = () => {

        if (!(parseInt(selectedId, 10) >= 0)) return;

        const objCopia = copiaProfundaObjetos(itensTela);
        const itemCopia = copiaProfundaObjetos(itensTela[selectedId]);

        let indice = parseInt(selectedId, 10);
        objCopia.splice(indice, 1);

        objCopia.push(itemCopia);
        selectShape(objCopia.length - 1);
        setItensTela(objCopia);

    }

    const handleClickMovimentaEsquerda = () => {

        if (!(parseInt(selectedId, 10) >= 0)) return;

        const objCopia = copiaProfundaObjetos(itensTela);
        objCopia[selectedId].x = objCopia[selectedId].x > 0 ? objCopia[selectedId].x - 1 : objCopia[selectedId].x;
        setItensTela(objCopia);
    }

    const handleClickMovimentaDireita = () => {
        if (!(parseInt(selectedId, 10) >= 0)) return;

        const objCopia = copiaProfundaObjetos(itensTela);
        objCopia[selectedId].x = objCopia[selectedId].x < LARGURA ? objCopia[selectedId].x + 1 : objCopia[selectedId].x;
        setItensTela(objCopia);
    }

    const handleClickMovimentaCima = () => {
        if (!(parseInt(selectedId, 10) >= 0)) return;

        const objCopia = copiaProfundaObjetos(itensTela);
        objCopia[selectedId].y = objCopia[selectedId].y > 0 ? objCopia[selectedId].y - 1 : objCopia[selectedId].y;
        setItensTela(objCopia);
    }

    const handleClickMovimentaBaixo = () => {
        if (!(parseInt(selectedId, 10) >= 0)) return;

        const objCopia = copiaProfundaObjetos(itensTela);
        objCopia[selectedId].y = objCopia[selectedId].y < ALTURA ? objCopia[selectedId].y + 1 : objCopia[selectedId].y;
        setItensTela(objCopia);
    }

    const handleKeyDown = (e) => {

        e.preventDefault();

        if (!(parseInt(selectedId, 10) >= 0)) return;

        const teclasMapeadas = ['ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown', 'Delete', 'Tab', 'Escape', 'd', 's', 'o'];
        if (teclasMapeadas.filter((item) => item === e.key).length === 0) {
            return;
        }

        const temp = itensTela.slice();

        let indice = parseInt(selectedId, 10);

        const multiplo = e.ctrlKey ? 10 : 1;

        switch (e.key) {
            case 'ArrowRight':



                if ((temp[indice].x + (QUANTDESLOCAMENTO * multiplo)) > LARGURA)
                    return;
                temp[indice].x = temp[indice].x + (QUANTDESLOCAMENTO * multiplo);


                break;

            case 'ArrowLeft':


                if ((temp[indice].x + (QUANTDESLOCAMENTO * multiplo)) < 0)
                    return;
                temp[indice].x = temp[indice].x - (QUANTDESLOCAMENTO * multiplo);
                break;

            case 'ArrowUp':

                if (e.shiftKey) {
                    //fazer o "aumentar" ou diminuir de tamanho de acordo com o tipo

                }

                if ((temp[indice].y - (QUANTDESLOCAMENTO * multiplo)) < 0)
                    return;

                temp[indice].y = temp[indice].y - (QUANTDESLOCAMENTO * multiplo);
                break;

            case 'ArrowDown':
                if ((temp[indice].y + (QUANTDESLOCAMENTO * multiplo)) > ALTURA)
                    return;
                temp[indice].y = temp[indice].y + (QUANTDESLOCAMENTO * multiplo);
                break;

            case 'Delete':
                temp.splice(indice, 1);
                selectShape(null);
                indice = -1;
                break;

            case 'Escape':
                selectShape(null);
                break;

            case 'Tab':

                if (e.shiftKey) {
                    if (indice > 0) {
                        indice--;
                    }
                    else indice = itensTela.length - 1;
                }
                else {
                    if (indice < (itensTela.length - 1)) {
                        indice++;
                    }
                    else indice = 0;
                }

                selectShape(indice);

                break;

            case 'd':

                if (e.ctrlKey) {
                    handleClickDuplicaItem();
                    return;
                }

                break;

            case 's':

                if (e.ctrlKey) {
                    handleClickGravarProjeto();
                    return;
                }

                break;

            case 'o':

                if (e.ctrlKey) {
                    handleClickRecarregarDesenho();
                    return;
                }

                break;




            default:
                break;
        }

        //se for uma cota horizontal então tenho que reposicionar o texto
        if ((indice >= 0) && (temp[indice].tipo === 'cotahorizontal')) {
            const larguraseta = parseInt(temp[indice].points[2], 10)
            const {x:xTexto,y:yTexto}=calculaPosicaoTexto({angulo:parseInt(temp[indice].rotation,10)||0,largura:larguraseta,x:temp[indice].x,y:temp[indice].y});
            temp[indice].texto.x = xTexto;
            temp[indice].texto.y = yTexto;
        }
        else
            if ((indice >= 0) && (temp[indice].tipo === 'cotavertical')) {
                const alturaseta = parseInt(temp[indice].points[3], 10)
                temp[indice].texto.x = temp[indice].x - 25;
                temp[indice].texto.y = parseInt(temp[indice].y + alturaseta / 2, 10);
            }

        setItensTela(temp);

    }

    // const handleSalvaBase64 = () => {
    //     const uri = stageRef.current.toDataURL({
    //         //  x:50,
    //         //  y:100,
    //         //  width:300,
    //         //  height:300
    //     });
    //     console.log(uri);
    //     setImageBase64(uri);
    //     setOpenDialogo(true);
    // }

    const handleClickNovoObjeto = () => {
        switch (tipoSelecionado?.tipo) {
            case 'rect':
                handleClickNovoRetangulo();
                break;
            case 'circle':
                handleClickNovoCirculo();
                break;

            case 'text':
                handleClickNovoTexto();
                break;

            case 'cotahorizontal':
                handleClickNovaCotaHorizontal();
                break;

            case 'cotavertical':
                handleClickNovaCotaVertical();
                break;

            case 'linhahorizontal':
                handleClickNovaLinhaHorizontal();
                break;

            case 'linhavertical':
                handleClickNovaLinhaVertical();
                break;


            default:
                break;
        }
    }

    const buscaComponenteseMedidas = async () => {

        if (!(parseInt(projeto_id, 10) >= 0)) {
            return;
        }

        try {
            const url = `${rotafechada()}projetos/full/${projeto_id}`;
            const retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;

            const opcoesTemp = [
                {
                    value: 'textosimples',
                    label: 'Texto Simples',
                    caption: 'Texto Simples'
                }];

            data.medidas?.map((item) => {
                opcoesTemp.push({
                    value: `medida|${item.Medidas_Projeto?.referencia}`,
                    label: `Medida: ${item.Medidas_Projeto?.nome}`,
                    caption: `Medida: ${item.Medidas_Projeto?.referencia}`,
                })
            });

            data.componentes?.map((item) => {
                opcoesTemp.push({
                    value: `componente|${item.Componentes_Projeto?.referencia}|ALTURA`,
                    label: `Componente : ${item.Componentes_Projeto?.nome}| ALTURA`,
                    caption: `${item.Componentes_Projeto?.referencia}:A`,
                });
                opcoesTemp.push({
                    value: `componente|${item.Componentes_Projeto?.referencia}|LARGURA`,
                    label: `Componente : ${item.Componentes_Projeto?.nome}| LARGURA`,
                    caption: `${item.Componentes_Projeto?.referencia}:L`,
                });
                opcoesTemp.push({
                    value: `componente|${item.Componentes_Projeto?.referencia}|ALTURA_FINAL`,
                    label: `Componente : ${item.Componentes_Projeto?.nome}| ALTURA_FINAL(altura-folga)`,
                    caption: `${item.Componentes_Projeto?.referencia}:AF`,
                });
                opcoesTemp.push({
                    value: `componente|${item.Componentes_Projeto?.referencia}|LARGURA_FINAL`,
                    label: `Componente : ${item.Componentes_Projeto?.nome}| LARGURA_FINAL(largura-folga)`,
                    caption: `${item.Componentes_Projeto?.referencia}:LF`,
                });
            });

            setOpcoesTexto(opcoesTemp);
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const buscaDesenhoTemperaVendaProjetos = async () => {

        if (!(parseInt(pedido_tempera_venda_projeto_id, 10) >= 0)) {
            return;
        }

        try {
            const url = `${rotafechada()}pedido_tempera_venda_projetos?id=${pedido_tempera_venda_projeto_id}`;
            const retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;

            let itensTelaTemp = [];
            if ((data.length > 0) && (data[0].desenho_tempera)) {
                itensTelaTemp = copiaProfundaObjetos(data[0].desenho_tempera);
            }
            setItensTela(itensTelaTemp);

        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const buscaDesenhoTemperaProjeto = async (somenteModelo = false) => {

        if (!(parseInt(projeto_id, 10) >= 0)) {
            return;
        }

        try {
            const url = `${rotafechada()}projetos?id=${projeto_id}&retornadesenhotempera=S`;
            const retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;

            let itensTelaTemp = [];
            if ((data.length > 0) && (data[0].desenho_tempera)) {
                itensTelaTemp = copiaProfundaObjetos(data[0].desenho_tempera);
            }

            if (!somenteModelo) {
                setItensTela(itensTelaTemp);

            }
            setItensTelaModelo(itensTelaTemp)
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const handleClickGravarProjeto = async () => {


        if (!(parseInt(projeto_id, 10) >= 0)) {
            MensagemErro('Não é possível gravar sem um projeto vinculado')
            return;
        }

        try {
            MostraTelaEspera('Aguarde a gravação');
            let url;
            if (parseInt(pedido_tempera_venda_projeto_id, 10) > 0) {
                url = `${rotafechada()}Pedido_Tempera_Venda_Projetos/${pedido_tempera_venda_projeto_id}`;
                const retorno = await api.put(url, {
                    desenho_tempera: itensTela
                });
            }
            else {

                url = `${rotafechada()}projetos/desenhotempera/${projeto_id}`;
                const retorno = await api.post(url, {
                    dados: itensTela
                });
            }

            FechaTelaEspera();

            MensagemAviso('Gravado com Sucesso!');
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }


    const buscaDesenho = async () => {
        if (parseInt(pedido_tempera_venda_projeto_id, 10) > 0) {
            buscaDesenhoTemperaVendaProjetos();
        }
        else buscaDesenhoTemperaProjeto();
    }

    useEffect(() => {

        buscaComponenteseMedidas();
        buscaDesenho();

    }, [projeto_id, pedido_tempera_venda_projeto_id])

    const mostraDesenhoTemperaModelo = async () => {
        await buscaDesenhoTemperaProjeto(true);
        setOpenModelo(true);
    }

    function getMapRef() {

        //retorna um map (lista) a partir de um
        //useRef

        if (!itemsRef.current) {
            // Initialize the Map on first usage.
            itemsRef.current = new Map();
        }
        return itemsRef.current;
    }

    const handleClickRestauraModelo = async () => {
        setOpenConfirmacao({
            open: true,
            message: 'Todas as alterações serão perdidas! Certeza que deseja restaurar o projeto Original?',
            handleCancel: () => {
                setOpenConfirmacao({ open: false });
                setOpenModelo(false);
            },
            handleConfirm: restauraModelo
        })
    }

    const handleClickImportarDesenho = async () => {
        setOpenConfirmacao({
            open: true,
            message: 'Todas as alterações serão perdidas! Certeza que deseja importar o desenho de outro projeto?',
            handleCancel: () => {
                setOpenConfirmacao({ open: false });
            },
            handleConfirm: () => {
                setOpenConfirmacao({ open: false });
                setOpenFiltroImportarProjeto({open:true});
            }
        })
    }

    const importarProjeto = async (projeto_id_copiar) => {

        setOpenFiltroImportarProjeto({open:false});
        
        if (!(parseInt(projeto_id_copiar, 10) >= 0)) {
            return;
        }

        try {
            const url = `${rotafechada()}projetos?id=${projeto_id_copiar}&retornadesenhotempera=S`;
            const retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;

            let itensTelaTemp = [];
            if ((data.length > 0) && (data[0].desenho_tempera)) {
                itensTelaTemp = copiaProfundaObjetos(data[0].desenho_tempera);
            }

            setItensTela(itensTelaTemp);
            MensagemAviso('Desenho Importado, grave as alterações!')
            
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const restauraModelo = async () => {
        const temp = copiaProfundaObjetos(itensTelaModelo);
        setItensTela(temp);
        setOpenModelo(false);
        setOpenConfirmacao({ open: false });
        MensagemAviso('Grave as alterações')
    }

    return (
        <Box>


            <FiltroImportarProjeto
                open={openFiltroImportarProjeto.open || false}
                setOpen={setOpenFiltroImportarProjeto}
                handleOk={importarProjeto}
            />

            <Dialog onClose={() => setOpenDialogo(false)} open={openDialogo}>
                <Box m={2}>
                    <img src={imageBase64} alt="" />
                </Box>
            </Dialog>

            <DialogoConfirma
                open={openConfirmacao?.open || false}
                message={openConfirmacao?.message || ''}
                title={openConfirmacao?.title || 'Confirmação'}
                handleCancel={openConfirmacao.handleCancel}
                handleConfirm={openConfirmacao.handleConfirm}
            />

            <Dialog
                open={openModelo}
                onClose={() => setOpenModelo(false)}
            >
                <Box m={2}>
                    <MDButton onClick={handleClickRestauraModelo} color='info'>Restaurar projeto original</MDButton>
                </Box>
                <CamadaDesenho
                    selectedId={-1}
                    itensTela={itensTelaModelo}
                    setItensTela={() => { }}
                    selectShape={() => { }}
                    LARGURA={LARGURA}
                    ALTURA={ALTURA}
                    stageRef={refDesenhoModelo}
                />


            </Dialog>

            {
                (!desabilitaBarras) &&
                <>
                    <BarraBotoes

                        handleClickNovoDesenho={handleClickNovoDesenho}
                        handleClickGravarProjeto={handleClickGravarProjeto}
                        mostraDesenhoTemperaModelo={mostraDesenhoTemperaModelo}
                        handleClickRecarregarDesenho={handleClickRecarregarDesenho}
                        nomeprojeto={nomeprojeto}
                        pedido_tempera_venda_projeto_id={pedido_tempera_venda_projeto_id}
                        handleClickImportarDesenho={handleClickImportarDesenho}

                    />

                    <BarraObjetosePropriedades
                        setTipoSelecionado={setTipoSelecionado}
                        selectedId={selectedId}
                        tipoSelecionado={tipoSelecionado}
                        selectShape={selectShape}
                        handleClickDeletaItem={handleClickDeletaItem}
                        setOpenPropriedades={setOpenPropriedades}
                        handleClickNovoObjeto={handleClickNovoObjeto}
                        handleClickDuplicaItem={handleClickDuplicaItem}
                        handleClickEnviarParaTras={handleClickEnviarParaTras}
                        handleClickEnviarParaFrente={handleClickEnviarParaFrente}

                        handleClickMovimentaEsquerda={handleClickMovimentaEsquerda}
                        handleClickMovimentaDireita={handleClickMovimentaDireita}
                        handleClickMovimentaCima={handleClickMovimentaCima}
                        handleClickMovimentaBaixo={handleClickMovimentaBaixo}
                    />
                </>
            }



            <Box
                backgroundColor={'#ffff'}
                border={1}
                borderColor={'#999'}
                maxWidth={LARGURA}
                maxHeight={ALTURA}
            >
                <div
                    tabIndex={1}
                    onKeyDown={handleKeyDown}
                    style={{ border: 'none' }}
                >
                    <CamadaDesenho
                        selectedId={selectedId}
                        itensTela={itensTela}
                        setItensTela={setItensTela}
                        selectShape={selectShape}
                        LARGURA={LARGURA}
                        ALTURA={ALTURA}
                        //stageRef={stageRef}

                        //o Ref é adicionado em uma lista
                        //uso o pedido_tempera_venda_projeto_id como indice
                        //caso seja um componente isolado no projeto
                        //esse pedido_tempera_venda_projeto_id=-1 entao tmbm funcionara
                        stageRef={(node) => {
                            const map = getMapRef();
                            if (node) {
                                map.set(pedido_tempera_venda_projeto_id, node);
                            } else {
                                map.delete(pedido_tempera_venda_projeto_id);
                            }
                        }}

                    />
                </div>

                <Box >
                    {
                        parseInt(selectedId, 10) >= 0 &&

                        <PropriedadesFigura
                            itensTela={itensTela}
                            setItensTela={setItensTela}
                            selectedId={selectedId}
                            open={openPropriedades}
                            setOpen={setOpenPropriedades}
                            opcoesTexto={opcoesTexto}

                        />
                    }

                </Box >
            </Box>
        </Box>
    )

}