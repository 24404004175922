import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Palette from '@mui/icons-material/Settings';
import { Icon } from '@mui/material';
import DialogoConfirma from 'componentes/DialogoConfirma';
import MessagesContext from 'hooks/MessagesContext';
import api from 'commons/api';
import { rotafechada } from 'commons/urlconf';

export default function MenuOpcoesProduto(props) {


    const { produto_preco_id, open, setOpen, currentTarget } = props;

    const messageContext = React.useContext(MessagesContext);
    const { MensagemErro, MensagemAviso, MostraTelaEspera,FechaTelaEspera } = messageContext;

    const [openDialogo, setOpenDialogo] = React.useState(false);
    
    const handleClose = () => {
        setOpen(false);
    };

    const handleClickAlteraCores = async () => {

        setOpenDialogo(false);

        try {
            await api.put(`${rotafechada()}produto_precos/replicaprecooutrascores/${produto_preco_id}`,{});
            MensagemAviso('Alterado');
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
        
    }

    return (
        <React.Fragment>

            <DialogoConfirma
                open={openDialogo}
                message={`Certeza que deseja alterar o valor das demais cores desse produto utilizando os preços atuais?`}
                handleCancel={() => setOpenDialogo(false)}
                handleConfirm={handleClickAlteraCores}
                title='Confirma alteração'
            />

            <Menu
                anchorEl={currentTarget}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem
                    onClick={()=>setOpenDialogo(true)}
                >
                    <ListItemIcon>
                        <Icon>palette</Icon>
                        {/* <Palette fontSize="small" /> */}
                    </ListItemIcon>
                    Altera demais cores
                </MenuItem>

            </Menu>
        </React.Fragment>
    );
}
