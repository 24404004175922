import { Dialog, Icon, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { Box } from "@mui/system";
import PageLayout from "examples/LayoutContainers/PageLayout";
import AuthContext from "hooks/AuthContext";
import DataPrinterContext from "hooks/DataPrinter";
import { useContext } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function PrintColunas(props) {

    const { colunas, estiloColunas } = props;

    return (

        <Box component="thead" sx={{ backgroundColor: '#2222', WebkitPrintColorAdjust: 'exact' }} >
            {/* <TableHead> */}

            <TableRow >
                {colunas?.map((item) => (
                    <TableCell
                        key={`tc${item.id}`}
                        align={item.align ? item.align : 'left'}

                        width={item?.sx?.width || 0}
                        sx={{ ...estiloColunas, ...item.sx }}
                    >

                        {item.label}

                    </TableCell>
                ))}
            </TableRow>
        </Box>
    );
}


export default function RelatorioPadrao(props) {

    const { open, setOpen } = props;
    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();
    const dataPrinterContext = useContext(DataPrinterContext);
    const { titulo, subtitulo, filtros, colunas, linhas, totalizador, estiloColunas, estiloLinhas } = dataPrinterContext;
    
    const handleCancel = () => {
        setOpen(false);
        //window.location.reload(false);
      };
    
    return (

        <Dialog
            open={open}
            fullScreen
            onClose={(e) => setOpen(false)}
        >


            {/* <PageLayout> Comentado pois ele fazia o menu lateral sumir*/} 

                <Paper align='center' fullWidth>
                    <Box display='flex' justifyContent={'space-between'} m={2}>
                        <ArrowBackIcon color='info' fontSize='large' cursor='pointer'
                            onClick={() => 
                                handleCancel()
                            }
                            
                        />
                        <Icon color='info' fontSize='large' cursor='pointer' onClick={() => window.print()}>print</Icon>
                    </Box>
                    <Typography align='center' mb={2}>
                        {
                            titulo || 'Título do Relatório'
                        }
                    </Typography>

                    {subtitulo?.map((item) =>
                        <Typography align='left' mb={2} variant='body2'>
                            {
                                item || ''
                            }
                        </Typography>
                    )}

                    <TableContainer component={Paper}>
                        <Table>
                            <PrintColunas
                                colunas={colunas || []}
                                estiloColunas={estiloColunas}
                            />

                            <TableBody>
                                {linhas?.map((row, indexlinha) => {
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={`tr${indexlinha}`}
                                            sx={{ ...estiloLinhas, ...row.sx }}
                                        >
                                            {
                                                colunas?.map((item, indexcoluna) => {

                                                    return (
                                                        <TableCell
                                                            align={item.align ? item.align : 'left'}
                                                            //padding={item.disablePadding ? 'none' : 'normal'}
                                                            key={`tr${indexlinha}tc${indexcoluna}`}
                                                            sx={{ ...estiloLinhas, ...row.sx }}
                                                        >
                                                            {row[item.id]}
                                                        </TableCell>
                                                    )
                                                })
                                            }
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            {/* </PageLayout> */}
        </Dialog>
    )
}