import { Autocomplete, Grid, IconButton, Typography } from '@mui/material';
import api from 'commons/api';
import { rotafechada } from 'commons/urlconf';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';
import FormField from 'componentes/FormField';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDTypography from 'components/MDTypography';
import MessagesContext from 'hooks/MessagesContext';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { Controller } from 'react-hook-form';

const CoresProjetos = (props) => {

    const { control, setValue, getValues, vendaAtual, handleSubmit,
        etapaAtual, setEtapaAtual, objFull, proximaEtapa, handleSolicitaAbortarProcesso, desenhaCabecalhoPadrao } = props;

    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;
    const opcaoDefault = [{ id: -1, value: -1, label: '<Selecione...>' }];
    const [opcoesCores, setOpcoesCores] = useState(opcaoDefault)

    function gravarTeste(data) {
        console.log('objfull-original:', objFull);
        console.log('obj-RHF:', data);
    }

    const buscaOpcoesCores = async () => {

        try {
            let url = `${rotafechada()}cores?limit=-1&ativo=S`;
            const retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;
            let opcaoTemp = [];
            opcaoTemp.push({ ...opcaoDefault[0] });
            data.map((item) => {
                opcaoTemp.push({ id: item.id, value: item.id, label: item.nome });
            })
            setOpcoesCores(opcaoTemp);
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const validaProximaEtapa = () => {

        let erro = false;
        let itenserro = '';
        objFull?.cores?.map((itemcor, index) => {
            const coratual = getValues(`projetos.cores[${index}].cor_id`)?.value || -1;
            if (coratual <= 0) {
                itenserro = itenserro === '' ? itemcor.Tipos_Produto.nome : `${itenserro} | ${itemcor.Tipos_Produto.nome}`;
                erro = true;
            }
        })

        if (erro) {
            MensagemErro(`Erro na cor do(s) tipo(s): ${itenserro}`)
            return;
        }


        //antes de setar os produtos na cor escolhido precisa limpar
        objFull?.produtos?.map((item, index) => {
            setValue(`projetos.produtos[${index}].produto_grade_id`, { value: -1, label: '' });
        });

        //Percorro os tipos adicionados no projeto pelo "cores"
        //para cada tipo eu percorro os produtos
        //se o produto é do mesmo tipo que o tipo atual
        //percorro o ProdutoGrades para encontrar se tem
        //tem algum Produto na Cor Atual

        let msgerroproduto = ``;
        objFull?.cores?.map((itemcor, indexcor) => {

            const coratual = getValues(`projetos.cores[${indexcor}].cor_id`).value || 0;

            objFull?.produtos?.map((itemproduto, index) => {

                if (itemproduto.Produto.tipo_produto_id === itemcor.tipo_produto_id) {

                    itemproduto.produtogradeautocomplete?.map((itemprodutograde) => {
                        if (coratual === itemprodutograde.cor_id) {
                            console.log(itemprodutograde);
                            setValue(`projetos.produtos[${index}].produto_grade_id`, itemprodutograde);

                            // if (itemprodutograde.valor_venda===null){
                            //     msgerroproduto=msgerroproduto===''?`${itemproduto.Produto.referencia}-${itemproduto.Produto.nome}`:`${msgerroproduto}|${itemproduto.Produto.referencia}-${itemproduto.Produto.nome}`;
                            // }
                        }
                    });
                }
            });
        })

        if (msgerroproduto !== '') {
            MensagemErro(`Produtos que não tem preço na tabela de cor escolhida: ${msgerroproduto} `);
            return;
        }

        proximaEtapa();
    }

    const etapaAnterior = () => {
        setEtapaAtual(etapaAtual - 1);
    }

    useEffect(() => {
        buscaOpcoesCores();
    }, [])



    return (
        <>
            {desenhaCabecalhoPadrao('Cores Padrão - ')}
            <Typography variant='subtitle2'mb={2}>Escolha as cores do seu projeto</Typography>
            <Grid container spacing={1}>

                {
                    objFull?.cores.map((item, index) => {
                        return (
                            <Grid item xs={12} sm={12} key={`cor_${item.id}`}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} >
                                        <Typography  variant='body2'>Tipo: {item.Tipos_Produto.nome}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4} >
                                        <Controller
                                            name={`projetos.cores[${index}].cor_id`}
                                            control={control}
                                            defaultValue={{ value: -1 }}
                                            render={({ field: { onChange, ...props } }) => (
                                                <AutoCompleteStyled
                                                    options={opcoesCores}
                                                    getOptionLabel={(option) => option.label || ''}
                                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                                    renderInput={(params) => <FormField {...params}  label='Cor' />}
                                                    onChange={(_, data) => {
                                                        return onChange(data);
                                                    }}
                                                    disabled={false}
                                                    {...props}

                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )

                    })
                }

            </Grid>

            <MDBox width="100%" display="flex" justifyContent="space-between" mt={2}>
                <MDButton variant="gradient" color="info" onClick={etapaAnterior} sx={{ ml: 2 }}>Retornar</MDButton>
                {
                    (props.parametrosVenda.mostra_botao_obj || false) &&
                    <MDButton variant="gradient" color="warning" onClick={() => handleSubmit(gravarTeste)()} sx={{ ml: 2 }}>Mostra OBJ</MDButton>
                }
                <MDButton variant="gradient" color="info" onClick={validaProximaEtapa} sx={{ ml: 2 }}>Avançar</MDButton>

            </MDBox>
        </>
    )
}

export default CoresProjetos;

