import React, { useContext, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Grid from "@mui/material/Grid";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
// import { TransitionProps } from '@mui/material/transitions';
import MDTypography from "components/MDTypography";
import MDBox from 'components/MDBox';
import { Autocomplete, Box, Collapse, DialogActions, DialogContent, DialogTitle, Icon, InputAdornment, TextField, Tooltip, Typography } from '@mui/material';
import MDInput from 'components/MDInput';
import FormField from "componentes/FormField";
import MDButton from 'components/MDButton';
import TabelaAlteracaoLoteProdutos from './tabela';
import api from 'commons/api';
import { rotafechada } from 'commons/urlconf';
import MessagesContext from 'hooks/MessagesContext';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TabelaPadrao from 'componentes/TabelaPadrao';
import { removeZeros } from 'commons/utils';
import { formataValor } from 'commons/utils';
import CurrencyInput from 'componentes/MascaraMonetaria';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Link } from 'react-router-dom';
import { copiaProfundaObjetos } from 'commons/utils';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import DescriptionIcon from '@mui/icons-material/Description';
import DialogoOpcoes from 'componentes/DialogoOpcoes';
import AuthContext from "hooks/AuthContext";
import PercentIcon from '@mui/icons-material/Percent';
import { retornaSomenteNumeros } from 'commons/utils';


// const Transition = React.forwardRef(function Transition(
//   props: TransitionProps & {
//     children: React.ReactElement;
//   },
//   ref: React.Ref<unknown>,
// ) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

export const confAlteracaoLoteProdutosInitialState = {
  open: false,//estado visivel da tela
  titulo: '',//titulo da tela de pesquisa
  urlBase: '',//url do get que sera feito para retornar os dados
  campoRetorno: '',//campo do Dataset que deseja o valor ao selecionar a linha
  colunas: [],//[{id,numeric,disablePadding,label}]
  camposFiltro: undefined,//[{id,label},{id,label}]
  handleClick: undefined,//função que sera chamada ao selecionar a linha
  campoAuxiliar: '',//campo usado para guardar alguma informação que deseje usar após o retorno da tela
}


export default function AlteracaoLoteProdutos(props) {

  const { open, setOpen, titulo, campoRetorno, urlBase, colunas, limit, camposFiltro, campoAuxiliar,
    filtroTipo, setFiltroTipo, campoPesquisa, setCampoPesquisa, valorPesquisa, setValorPesquisa,
    setCamposFiltro, opcaoDefaultGrupoProduto, opcoesFiltroTipo, setOpcoesFiltroTipo, opcoesFiltroGrupo,
    setOpcoesFiltroGrupo, filtroGrupo, setFiltroGrupo, opcoesFiltroCor, setOpcoesFiltroCor, filtroCor,
    setFiltroCor, opcoesFiltroTabelaPreco, setOpcoesFiltroTabelaPreco, filtroTabelaPreco, setFiltroTabelaPreco,
    openFiltrosAvancados, setOpenFiltrosAvancados, opcoesAtivo, ativo, setAtivo } = props;

  const messageContext = useContext(MessagesContext);
  const authContext = useContext(AuthContext);
  const { getSessao } = authContext;
  const sessaolocal = getSessao();
  const { MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;

  const opcoesAtivoProduto = [
    { value: 'S', label: 'Sim' },
    { value: 'N', label: 'Não' },
  ];

  const campoInicial = camposFiltro ? camposFiltro[0] : '';
  // const [campoPesquisa, setCampoPesquisa] = useState(campoInicial);
  // const [valorPesquisa, setValorPesquisa] = useState('');
  const [countAll, setCountAll] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(limit || 10);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('produto_id');
  // const [orderBy, setOrderBy] = useState(camposFiltro ? camposFiltro[0].id : 'produto_id');

  const [colunasTable, setColunasTable] = useState([]);
  const [linhas, setLinhas] = useState([]);
  const [recarregaDados, setRecarregaDados] = useState(false);
  const [values, setValues] = useState([]); // Inicializa com um array de strings vazias
  const [referencias, setReferencias] = useState();
  const [forceRender, setForceRender] = useState(false);

  /* Opções Lote */
  const [openOpc0, setOpenOpc0] = useState({ open: false, opcao: '' });
  const [novaCor, setNovaCor] = useState();
  const [openOpc1, setOpenOpc1] = useState({ open: false, opcao: '' });
  const [custoLoteValor, setCustoLoteValor] = useState();
  const [openOpc2, setOpenOpc2] = useState({ open: false, opcao: '' });
  const [valorVendaLoteValor, setValorVendaLoteValor] = useState();
  const [openOpc3, setOpenOpc3] = useState({ open: false, opcao: '' });
  const [custoLotePercentual, setCustoLotePercentual] = useState();
  const [openOpc4, setOpenOpc4] = useState({ open: false, opcao: '' });
  const [valorVendaLotePercentual, setValorVendaLotePercentual] = useState();
  const [openOpc5, setOpenOpc5] = useState({ open: false, opcao: '' });
  const [openOpc6, setOpenOpc6] = useState({ open: false, opcao: '' });
  const [openOpc7, setOpenOpc7] = useState({ open: false, opcao: '' });
  const [ativoOpc7, setAtivoOpc7] = useState();
  const [openOpc8, setOpenOpc8] = useState({ open: false, opcao: '' });
  const [custoQuiloLoteValor, setCustoQuiloLoteValor] = useState();


  const [opcoes, setOpcoes] = useState({
    open: false,
    items: [{}]
  });

  const handleClose = () => {
    if (props.handleClose) {
      props.handleClose();
    }
    else setOpen(false);
  };

  const handleClickPesquisa = () => {
    if (!campoPesquisa.id) {
      MensagemErro('Selecione o campo que deseja pesquisar');
      return;
    }

    setRecarregaDados(!recarregaDados);
  }

  const handleChange = (index, event) => {
    const newReferencias = [...referencias];
    newReferencias[index] = event.target.value;
    setReferencias(newReferencias);

  };

  const handleBlurAlteraCampo = async (campo, valor, id, peso) => {
    let url;
    let retorno;

    switch (campo) {
      case 'valor_custo':
        url = `${rotafechada()}produto_grades/${id}`;
        if (peso > 0 && valor > 0) {
          retorno = await api.put(url, {
            valor_custo: valor || 0,
            valor_custo_quilo: removeZeros((valor / peso).toFixed(2))
          });
          setRecarregaDados(!recarregaDados);
        } else {
          retorno = await api.put(url, {
            valor_custo: valor || 0,
          });
        }
        break;
      case 'valor_custo_quilo':
        url = `${rotafechada()}produto_grades/${id}`;
        if (peso > 0 && valor > 0) {
          retorno = await api.put(url, {
            valor_custo_quilo: valor || 0,
            valor_custo: removeZeros((valor * peso).toFixed(2))
          });
        }
        else {
          retorno = await api.put(url, {
            valor_custo_quilo: valor,
          });
        }
        setRecarregaDados(!recarregaDados);
        break;
      case 'peso':
        url = `${rotafechada()}produtos/${id}`;
        retorno = await api.put(url, {
          peso: valor || 0,
        });
        setRecarregaDados(!recarregaDados);
        break;
      case 'tamanho_barra':
        url = `${rotafechada()}produtos/${id}`;
        retorno = await api.put(url, {
          tamanho_barra: valor || 0,
        });
        setRecarregaDados(!recarregaDados);
        break;
      case 'ativo':
        // url = `${rotafechada()}produtos/${id}`;
        // retorno = await api.put(url, {
        //   ativo: valor || 0,
        // });
        // await buscaDados();
        break;
      case 'referencia':
        url = `${rotafechada()}produtos/${id}`;
        retorno = await api.put(url, {
          referencia: valor || '',
        });
        setRecarregaDados(!recarregaDados);
        break;
      case 'produto':
        url = `${rotafechada()}produtos/${id}`;
        retorno = await api.put(url, {
          nome: valor || '',
        });
        setRecarregaDados(!recarregaDados);
        break;
      case 'valor_venda':
        url = `${rotafechada()}produto_precos/${id}`;
        retorno = await api.put(url, {
          valor_venda: valor || 0,
        });
        setRecarregaDados(!recarregaDados);
        break;
      case 'margem':
        url = `${rotafechada()}produto_precos/${id}`;
        retorno = await api.put(url, {
          percentual_margem: valor || 0,
        });
        setRecarregaDados(!recarregaDados);
        break;
      default:
        break;
    }

  }

  const buscaDados = async () => {

    try {

      const operadorUrl = urlBase.indexOf('?') >= 0 ? '&' : '?';
      let complementoUrl = '';

      if ((campoPesquisa.id) && (valorPesquisa)) {
        complementoUrl = `&${campoPesquisa.id}=${valorPesquisa}`;

        if ((valorPesquisa) && (valorPesquisa.length > 0) && (valorPesquisa[0] !== '%') && (valorPesquisa[valorPesquisa.length - 1] !== '%')
          && (camposFiltro) && (camposFiltro.length > 0)) {

          //se o texto a ser buscado nao terminar ou começar com %
          //e tiver uma configuracao no camposFiltro
          //entao localizo que o campo usado nesse momento é um campo que esteja configurado
          //o buscaParcial, se sim acrescento automaticamente o % no inicio e fim


          let encontrouCampo = false;
          for (let i = 0; i < camposFiltro.length; i++) {

            if ((camposFiltro[i].buscaParcial === true) && (camposFiltro[i].id === campoPesquisa.id)) {
              encontrouCampo = true;
            }
          }

          if (encontrouCampo === true) {
            complementoUrl = `&${campoPesquisa.id}=%${valorPesquisa}%`;
          }
        }
      }

      if (page > 0) {
        complementoUrl = `${complementoUrl}&offset=${page * rowsPerPage}`;
      }

      if (orderBy) {
        complementoUrl = `${complementoUrl}&order=${orderBy}`;
        if (order) {
          complementoUrl = `${complementoUrl}&direction=${order}`;
        }
      }

      if (parseInt(filtroGrupo?.id, 10) > 0) {
        complementoUrl = `${complementoUrl}&grupo_produto_id=${filtroGrupo.id}`;
      }

      if (parseInt(filtroTipo?.id, 10) > 0) {
        complementoUrl = `${complementoUrl}&tipo_produto_id=${filtroTipo.id}`;
      }

      if (parseInt(filtroCor?.id, 10) > 0) {
        complementoUrl = `${complementoUrl}&cor_id=${filtroCor.id}`;
      }

      if (ativo) {
        complementoUrl = `${complementoUrl}&ativo=${ativo.value}`;
      }
      MostraTelaEspera(`Buscando produtos`);
      // const url = `${urlBase}${operadorUrl}limit=-1`;
      const url = `${urlBase}${operadorUrl}limit=${rowsPerPage}${complementoUrl}`;
      // console.log('url:', url);
      const retorno = await api.get(encodeURI(url));
      // console.log('retorno:', retorno);
      const { data, tabelas, count, countAll } = retorno.data;
      //setReferencias(data.map(item => item.Produto.referencia))

      if (!count > 0) {
        FechaTelaEspera();
        MensagemErro('Nenhuma informação foi selecionada');
        setLinhas([]);
        setCountAll(0)
        return;
      }

      let colunasTabletemp = [
        // {
        //   id: 'id',
        //   numeric: false,
        //   disablePadding: true,
        //   label: 'Id'
        // },
        {
          id: 'tipo',
          numeric: false,
          disablePadding: true,
          disableOrder: true,
          label: 'Tipo'
        },
        {
          id: 'grupo',
          numeric: false,
          disablePadding: true,
          disableOrder: true,
          label: 'Grupo'
        },
        {
          id: 'referencia',
          numeric: false,
          disablePadding: true,
          disableOrder: true,
          label: 'Referência'
        },
        {
          id: 'produto_icon',
          numeric: false,
          disablePadding: true,
          disableOrder: true,
          label: ''
        },
        {
          id: 'produto',
          numeric: false,
          disablePadding: true,
          disableOrder: true,
          label: 'Produto'
        },
        {
          id: 'cor',
          numeric: false,
          disablePadding: true,
          disableOrder: true,
          label: 'Cor'
        },
        {
          id: 'peso',
          numeric: false,
          disablePadding: true,
          disableOrder: true,
          label: 'Peso'
        },
        {
          id: 'unidade_medida',
          numeric: false,
          disablePadding: true,
          disableOrder: true,
          label: 'Un.'
        },
        {
          id: 'barra',
          numeric: false,
          disablePadding: true,
          disableOrder: true,
          label: 'Tam. barra'
        },
        {
          id: 'ativo',
          numeric: false,
          disablePadding: true,
          disableOrder: true,
          label: 'Ativo'
        },
        {
          id: 'custo',
          numeric: false,
          disablePadding: true,
          disableOrder: true,
          label: 'Custo R$'
        },
        {
          id: 'custo_quilo',
          numeric: false,
          disablePadding: true,
          disableOrder: true,
          label: 'Custo Quilo R$'
        },
      ]

      tabelas.map((item, index) => {
        colunasTabletemp.push(
          {
            id: `tabela${item.id}`,
            numeric: false,
            disablePadding: true,
            label: //item.nome,
              <>
                <MDTypography
                  style={{ borderLeft: "3px solid #1A73E8", paddingLeft: "5px" }}
                  variant="subtitle"
                  fontWeight={"bold"}

                >
                  {item.nome.replace(' ', ' ')}
                </MDTypography>
              </>,
            disableOrder: true
          },
          {
            id: `margem${item.id}`,
            numeric: false,
            disablePadding: true,
            label: 'Margem (%)',
            disableOrder: true
          },
          {
            id: `sugerido${item.id}`,
            numeric: false,
            disablePadding: true,
            label: 'Sugerido',
            disableOrder: true
          },
          {
            id: `venda${item.id}`,
            numeric: false,
            disablePadding: true,
            label: 'Venda R$',
            disableOrder: true
          },
        )
      })

      let dadostemp = [];
      data.map((item, index) => {
        dadostemp.push({
          id: item.id,
          tipo: item.Produto.Tipos_Produto.nome,
          grupo: item.Produto.Grupos_Produto.nome,
          referencia: //item.Produto.referencia
            <TextField
              key={index}
              //label={`Campo ${index + 1}`}
              sx={{ width: 100 }}
              //value={item.Produto.referencia}
              defaultValue={item.Produto.referencia}
              // value={referencias[index]}
              // onChange={(event) => {
              //   handleChange(index, event)
              // }}
              inputProps={{
                onBlur: (e) => {
                  e.preventDefault();
                  handleBlurAlteraCampo('referencia', e.target.value, item.produto_id);
                },
              }}
            />
          ,
          produto_icon: item.Produto.nome ?
            <Tooltip title='Abrir cadastro do produto' placement="top" disableInteractive>
              <Typography variant='subtitle' style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <Link to={`/produtos/produto/${item.produto_id}`} style={{ textDecoration: "none", color: 'black', width: 500 }} target="_blank">
                  <DescriptionIcon />
                </Link>
              </Typography>
            </Tooltip>
            : 'vazio',
          produto: //item.Produto.nome 
            <TextField
              key={index}
              //label={`Campo ${index + 1}`}
              sx={{ width: 300 }}
              //value={item.Produto.referencia}
              defaultValue={item.Produto.nome}
              // value={referencias[index]}
              // onChange={(event) => {
              //   handleChange(index, event)
              // }}
              inputProps={{
                onBlur: (e) => {
                  e.preventDefault();
                  handleBlurAlteraCampo('produto', e.target.value, item.produto_id);
                },
              }}
            />,
          cor: item.Core.id + ' - ' + item.Core.nome,
          custo: //formataValor(item.valor_custo)
            <CurrencyInput
              // value={item.valor_custo}
              value={parseFloat(item.valor_custo)}
              sx={{ width: 80 }}
              // onChange={(e) => {
              //   let tempValor = [...dadosParcelas]
              //   tempValor[index].valor = parseFloat(e.target.value.replace(/\./g, '').replace(',', '.'))
              //   setDadosParcelas(tempValor);
              // }}
              InputProps={{
                onBlur: (e) => {
                  e.preventDefault();
                  handleBlurAlteraCampo('valor_custo', parseFloat(e.target.value.replace(/\./g, '').replace(',', '.')), item.id, item.Produto.peso);
                },
              }}
              InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
            />,
          custo_quilo: //formataValor(item.valor_custo_quilo)
            <CurrencyInput
              value={parseFloat(item.valor_custo_quilo)}
              sx={{ width: 80 }}
              // onChange={(e) => {
              //   let tempValor = [...dadosParcelas]
              //   tempValor[index].valor = parseFloat(e.target.value.replace(/\./g, '').replace(',', '.'))
              //   setDadosParcelas(tempValor);
              // }}
              InputProps={{
                onBlur: (e) => {
                  e.preventDefault();
                  handleBlurAlteraCampo('valor_custo_quilo', parseFloat(e.target.value.replace(/\./g, '').replace(',', '.')), item.id, item.Produto.peso);
                },
              }}
              InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
            />,
          unidade_medida: item.Produto.Unidades_Medida.unidade,
          peso: //removeZeros(item.Produto.peso),
            <>
              <CurrencyInput
                value={parseFloat(removeZeros(item.Produto.peso))}
                sx={{ width: 80 }}
                // onChange={(e) => {
                //   let tempValor = [...dadosParcelas]
                //   tempValor[index].valor = parseFloat(e.target.value.replace(/\./g, '').replace(',', '.'))
                //   setDadosParcelas(tempValor);
                // }}
                InputProps={{
                  onBlur: (e) => {
                    e.preventDefault();
                    handleBlurAlteraCampo('peso', parseFloat(e.target.value.replace(/\./g, '').replace(',', '.')), item.produto_id);
                  },
                }}
                InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
              />
            </>,
          barra: //removeZeros(item.Produto.peso),
            <>
              <CurrencyInput
                value={parseFloat(removeZeros(item.Produto.tamanho_barra))}
                sx={{ width: 80 }}
                // onChange={(e) => {
                //   let tempValor = [...dadosParcelas]
                //   tempValor[index].valor = parseFloat(e.target.value.replace(/\./g, '').replace(',', '.'))
                //   setDadosParcelas(tempValor);
                // }}
                InputProps={{
                  onBlur: (e) => {
                    e.preventDefault();
                    handleBlurAlteraCampo('tamanho_barra', parseFloat(e.target.value.replace(/\./g, '').replace(',', '.')), item.produto_id);
                  },
                }}
                InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
              />
            </>,
          ativo: item.ativo == 'S' ? 'Sim' : 'Não',
          // <>
          //   <AutoCompleteStyled
          //     options={opcoesAtivoProduto}
          //     disableClearable //Retira o X (clear)
          //     //getOptionLabel={(option) => option.label}
          //     //isOptionEqualToValue={(option, value) => option.value === value.value}
          //     renderInput={(params) => <FormField {...params} label="Ativo" />}
          //     //value={item.ativo == 'S' ? 'Sim' : 'Não'}
          //     defaultValue={item.ativo == 'S' ? 'Sim' : 'Não'}
          //     inputProps={{
          //       onBlur: (e) => {
          //         e.preventDefault();
          //         alert('ativo')
          //         handleBlurAlteraCampo('ativo', e, item.produto_id);
          //       },
          //     }}
          //   //onChange={(event, newValue) => {setAtivo(newValue);}}
          //   />
          // </>,
        })
      })

      let count_i = 0
      let count_j = 0
      for (let i = 0; i < tabelas.length; i++) { //1º for tabela de preço
        for (let j = 0; j < tabelas[i].Produto_Precos.length; j++) { //2º for produto_preço
          for (let k = 0; k < dadostemp.length; k++) { //3º for 
            if (tabelas[i].Produto_Precos[j].produto_grade_id == dadostemp[k].id) { // if
              let tabela = `tabela${tabelas[i].id}`
              let margem = `margem${tabelas[i].id}`
              let sugerido = `sugerido${tabelas[i].id}`
              let venda = `venda${tabelas[i].id}`

              dadostemp[k][tabela] = tabelas[i]?.nome
              dadostemp[k][margem] = //formataValor(tabelas[i].Produto_Precos[j]?.percentual_margem).replace('R$', '')
                <>
                  <CurrencyInput
                    value={parseFloat(tabelas[i].Produto_Precos[j]?.percentual_margem)}
                    sx={{ width: 80 }}
                    InputProps={{
                      onBlur: (e) => {
                        e.preventDefault();
                        handleBlurAlteraCampo('margem', parseFloat(e.target.value.replace(/\./g, '').replace(',', '.')), tabelas[i].Produto_Precos[j].id);
                      },
                    }}
                    InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                  />
                </>
              // dadostemp[k][sugerido] = (tabelas[i].Produto_Precos[j]?.Produto_Grade.valor_custo * tabelas[i].Produto_Precos[j]?.percentual_margem)/100
              dadostemp[k][sugerido] = formataValor(((parseFloat(tabelas[i].Produto_Precos[j]?.Produto_Grade.valor_custo) * parseFloat(tabelas[i].Produto_Precos[j]?.percentual_margem)) / 100) + parseFloat(tabelas[i].Produto_Precos[j]?.Produto_Grade.valor_custo))
              // dadostemp[k][sugerido] =  tabelas[i].Produto_Precos[j]?.percentual_margem //margem
              dadostemp[k][venda] = //formataValor(tabelas[i].Produto_Precos[j]?.valor_venda)
                <>
                  <CurrencyInput
                    value={parseFloat(tabelas[i].Produto_Precos[j]?.valor_venda)}
                    sx={{ width: 80 }}
                    InputProps={{
                      onBlur: (e) => {
                        e.preventDefault();
                        handleBlurAlteraCampo('valor_venda', parseFloat(e.target.value.replace(/\./g, '').replace(',', '.')), tabelas[i].Produto_Precos[j].id);
                      },
                    }}
                    InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                  />
                </>
              break
            } //if
          } //3º for
        }//2º for
      }//1º for

      // console.log('count_i:', count_i);
      // console.log('count_j:', count_j);
      // console.log('colunasTabletemp:', colunasTabletemp);
      // console.log('Dados da tabela:', dadostemp);


      setLinhas(dadostemp);
      setColunasTable([...colunasTabletemp]);
      setCountAll(retorno.data.countAll || 0)
      setForceRender(!forceRender); // Força a re-renderização
      FechaTelaEspera();
    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }

  }

  const opcoesProjetos = async () => {
    const items = [
      { value: 0, label: 'Vincular cor para o(s) produto(s) filtrado(s)' },
      { value: 1, label: 'Alterar custo($) do(s) produto(s) filtrado(s)' },
      { value: 2, label: 'Alterar valor venda($) do(s) produto(s) filtrado(s)' },
      { value: 3, label: 'Reajustar custo(%) do(s) produto(s) filtrado(s)' },
      { value: 4, label: 'Reajustar valor venda(%) do(s) produto(s) filtrado(s)' },
      { value: 5, label: 'Copiar sugerido para venda do(s) produto(s) filtrado(s)' },
      { value: 6, label: 'Excluir produto/cor para o(s) produto(s) filtrado(s)' },
      { value: 7, label: 'Ativar/Desativar produto/cor para o(s) produto(s) filtrado(s)' },
      { value: 8, label: 'Alterar custo quilo($) do(s) produto(s) filtrado(s)' },
    ];
    setOpcoes({ ...opcoes, open: true, tipo: 'opcoes', items });

  }

  const onCloseDialogoOpcoes = async (value) => {

    //fechando o dialogo
    setOpcoes({ ...opcoes, open: false, tipo: '' });

    switch (parseInt(value, 10)) {
      case 0:
        setOpenOpc0({ ...openOpc0, open: true, opcao: value })
        break;
      case 1:
        setOpenOpc1({ ...openOpc1, open: true, opcao: value })
        break;
      case 2:
        setOpenOpc2({ ...openOpc2, open: true, opcao: value })
        break;
      case 3:
        setOpenOpc3({ ...openOpc3, open: true, opcao: value })
        break;
      case 4:
        setOpenOpc4({ ...openOpc4, open: true, opcao: value })
        break;
      case 5:
        setOpenOpc5({ ...openOpc5, open: true, opcao: value })
        break;
      case 6:
        setOpenOpc6({ ...openOpc6, open: true, opcao: value })
        break;
      case 7:
        setOpenOpc7({ ...openOpc7, open: true, opcao: value })
        break;
      case 8:
        setOpenOpc8({ ...openOpc8, open: true, opcao: value })
        break;
      default:
        break;
    }
  }

  const handleCancelOpc = () => {
    setOpenOpc0({ ...openOpc0, open: false })
    setOpenOpc1({ ...openOpc1, open: false })
    setOpenOpc2({ ...openOpc2, open: false })
    setOpenOpc3({ ...openOpc3, open: false })
    setOpenOpc4({ ...openOpc4, open: false })
    setOpenOpc5({ ...openOpc5, open: false })
    setOpenOpc6({ ...openOpc6, open: false })
    setOpenOpc7({ ...openOpc7, open: false })
    setOpenOpc8({ ...openOpc8, open: false })
  };

  const handleOkOpc0 = async () => {
    setOpenOpc0({ ...openOpc0, open: false })
    alteracaoLote(openOpc0.opcao)
  };

  const handleOkOpc1 = async () => {
    setOpenOpc1({ ...openOpc1, open: false })
    alteracaoLote(openOpc1.opcao)
  };

  const handleOkOpc2 = async () => {
    setOpenOpc2({ ...openOpc2, open: false })
    alteracaoLote(openOpc2.opcao)
  };

  const handleOkOpc3 = async () => {
    setOpenOpc3({ ...openOpc3, open: false })
    alteracaoLote(openOpc3.opcao)
  };

  const handleOkOpc4 = async () => {
    setOpenOpc4({ ...openOpc4, open: false })
    alteracaoLote(openOpc4.opcao)
  };

  const handleOkOpc5 = async () => {
    setOpenOpc5({ ...openOpc5, open: false })
    alteracaoLote(openOpc5.opcao)
  };

  const handleOkOpc6 = async () => {
    setOpenOpc6({ ...openOpc6, open: false })
    alteracaoLote(openOpc6.opcao)
  };

  const handleOkOpc7 = async () => {
    setOpenOpc7({ ...openOpc7, open: false })
    alteracaoLote(openOpc7.opcao)
  };

  const handleOkOpc8 = async () => {
    setOpenOpc8({ ...openOpc8, open: false })
    alteracaoLote(openOpc8.opcao)
  };

  const alteracaoLote = async (opc) => {
    try {
      MostraTelaEspera('');

      let complementoUrl = '';

      const dados = {
        novo_custo_valor: custoLoteValor,
        novo_custo_quilo_valor: custoQuiloLoteValor,
        novo_valor_venda: valorVendaLoteValor,
        novo_custo_percentual: custoLotePercentual,
        novo_valor_venda_percentual: valorVendaLotePercentual,
        empresa_id: sessaolocal.empresa_id || null,
        nova_cor: novaCor?.value || null,
        tipo_produto_id: parseInt(filtroTipo?.id, 10) > 0 ? filtroTipo?.id : null,
        grupo_produto_id: parseInt(filtroGrupo?.id, 10) > 0 ? filtroGrupo?.id : null,
        tabela_preco_id: parseInt(filtroTabelaPreco?.id, 10) > 0 ? [filtroTabelaPreco] : opcoesFiltroTabelaPreco,
        cor_id: parseInt(filtroCor?.id, 10) > 0 ? filtroCor?.id : null,
        ativo: ativo?.value,
        ativoOpc: ativoOpc7?.value
      }


      if ((campoPesquisa.id) && (valorPesquisa)) {
        complementoUrl = `&${campoPesquisa.id}=${valorPesquisa}`;

        if (campoPesquisa.id === 'id') {
          const temp = retornaSomenteNumeros(valorPesquisa);
          if ((`${temp}`.length !== `${valorPesquisa}`.length) ||
            (!(parseInt(`${valorPesquisa}`, 10) > 0))) {
            MensagemErro('A pesquisa por ID aceita só números, escolha outro campo para busca');
            return;
          }
        }

        if ((valorPesquisa) && (valorPesquisa.length > 0) && (valorPesquisa[0] !== '%') && (valorPesquisa[valorPesquisa.length - 1] !== '%')
          && (camposFiltro) && (camposFiltro.length > 0)) {

          //se o texto a ser buscado nao terminar ou começar com %
          //e tiver uma configuracao no camposFiltro
          //entao localizo que o campo usado nesse momento é um campo que esteja configurado
          //o buscaParcial, se sim acrescento automaticamente o % no inicio e fim


          let encontrouCampo = false;
          for (let i = 0; i < camposFiltro.length; i++) {

            if ((camposFiltro[i].buscaParcial === true) && (camposFiltro[i].id === campoPesquisa.id)) {
              encontrouCampo = true;
            }
          }

          if (encontrouCampo === true) {
            complementoUrl = `&${campoPesquisa.id}=%${valorPesquisa}%`;
            dados[campoPesquisa.id] = '%' + valorPesquisa + '%'
          }
        }
      }

      let retorno = await api.put(encodeURI(`${rotafechada()}produto_grades/editar_alteracao_lote/${opc}?limit=-1`), dados);
      const { data } = retorno.data;

      setNovaCor()
      setValorVendaLoteValor()
      setCustoLoteValor()
      setFiltroTabelaPreco()
      setCustoLotePercentual()
      setValorVendaLotePercentual()
      setCustoQuiloLoteValor()
      setAtivoOpc7()


      FechaTelaEspera();

      setRecarregaDados(!recarregaDados);

    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  React.useEffect(() => {

    if (open) {
      //só busca no Back se a tela estiver visivel
      buscaDados();
    }

  }, [recarregaDados, rowsPerPage, page, order, orderBy, open, filtroTipo, filtroGrupo, filtroCor, ativo])


  const desenhaFiltro = () => {
    return (
      // <Collapse in={openFiltrosAvancados} timeout="auto" unmountOnExit>
      <Box sx={{ margin: 1 }}>
        < Grid container spacing={2} justifyContent="flex-start" >

          <Grid item xs={12} sm={3} > {/* linha do Tipo */}
            <AutoCompleteStyled
              autoHighlight
              disableClearable //Retira o X (clear)
              options={opcoesFiltroTipo}
              getOptionLabel={(option) => option.label || ''}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderInput={(params) => <FormField {...params} label="Tipo" />}
              value={filtroTipo}
              onChange={(event, newValue) => {
                setFiltroTipo(newValue);
              }}
              inputProps={{
                onBlur: (e) => {
                  e.preventDefault();
                  handleClickPesquisar();
                },
              }}
              defaultValue={{ id: -1, value: -1, label: '<Escolha um Tipo>' }}
            />
          </Grid>
          <Grid item xs={12} sm={3}  > {/* linha do Grupo */}
            <AutoCompleteStyled
              options={opcoesFiltroGrupo}
              getOptionLabel={(option) => option.label || ''}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderInput={(params) => <FormField {...params} label="Grupo" />}
              value={filtroGrupo}
              onChange={(event, newValue) => {
                setFiltroGrupo(newValue);
              }}
              defaultValue={{ ...opcaoDefaultGrupoProduto }}
              renderOption={(props, option) => {
                //para evitar o erro de duplicate keys...
                return (
                  <li {...props} key={option.id}>
                    {option.label}
                  </li>
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}  > {/* linha do Cor */}
            <AutoCompleteStyled
              options={opcoesFiltroCor}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderInput={(params) => <FormField {...params} label="Cor" />}
              value={filtroCor}
              onChange={(event, newValue) => {
                setFiltroCor(newValue);
              }}
              defaultValue={null}
            />
          </Grid>
          <Grid item xs={12} sm={3} > {/* linha do Ativo */}
            <AutoCompleteStyled
              options={opcoesAtivo}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderInput={(params) => <FormField {...params} label="Ativo" />}
              value={ativo}
              onChange={(event, newValue) => {
                setAtivo(newValue);
              }}
              defaultValue={null}
            />
          </Grid>
        </Grid>

        < Grid container spacing={2} mt={1} justifyContent="flex-start" >
          <Grid item xs={12} sm={3} > {/* Combo com os campos que podem ser pesquisados */}
            <AutoCompleteStyled
              options={camposFiltro}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderInput={(params) => <FormField {...params} label='Campo' />}
              value={campoPesquisa}
              onChange={(event, newValue) => {
                setCampoPesquisa(newValue);
              }}
              defaultValue={null}
            />
          </Grid>
          <Grid item xs={12} sm={6.5} > {/*Valor a ser Pesquisado*/}
            <FormField type="text" label="Valor a ser Pesquisado" value={valorPesquisa} onChange={(e) => setValorPesquisa(e.target.value)}

              inputProps={
                {
                  onKeyPress: (e) => {

                    if (e.key === 'Enter') {
                      handleClickPesquisar();
                    }
                  },
                }
              }
            />
          </Grid>
          <Grid item xs={12} sm={2.5}>
            <MDBox display="flex" justifyContent="space-evenly">
              {filtroTipo == null ?
                <Tooltip
                  title="Escolha um tipo antes de pesquisar"
                >
                  <spam>
                    <MDButton
                      onClick={handleClickPesquisar}
                      disabled={true}
                      color='info'> Pesquisar
                    </MDButton>
                  </spam>
                </Tooltip>
                :
                <MDButton
                  onClick={handleClickPesquisar}
                  color='info'> Pesquisar
                </MDButton>

              }
              <MDButton
                variant="gradient" color="dark"
                onClick={opcoesProjetos}
              > Opções
              </MDButton>
            </MDBox>
          </Grid>

        </Grid >
      </Box>
      // </Collapse>
    )
  }

  const handleClickPesquisar = async () => {
    //setPage(0);
    await buscaDados();
  }

  return (
    <>


      <DialogoOpcoes
        value={''}
        open={opcoes.open || false}
        onClose={onCloseDialogoOpcoes}
        options={opcoes.items || []}
      />

      <Dialog //Opc 0 - Vincular cor para os produtos filtrados
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        open={openOpc0.open}
        onClose={handleCancelOpc}
      >
        <DialogTitle>{'Nova cor para inserção em lote'}</DialogTitle>
        <DialogContent dividers>
          <AutoCompleteStyled
            options={opcoesFiltroCor}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            renderInput={(params) => <FormField {...params} label="Cor" />}
            value={novaCor}
            onChange={(event, newValue) => {
              setNovaCor(newValue);
            }}
            defaultValue={null}
          />
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleCancelOpc} variant="outlined" color="secondary">
            Cancelar
          </MDButton>
          <MDButton onClick={handleOkOpc0} variant="gradient" color='info' sx={{ height: "100%" }} >
            Confirmar
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog //Opc 1 - Alterar custo($) dos produtos filtrados
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        open={openOpc1.open}
        onClose={handleCancelOpc}
      >
        <DialogTitle>{'Novo custo em valor para a alteração em lote'}</DialogTitle>
        <DialogContent dividers>
          <CurrencyInput
            required={true}
            label="Custo"
            value={parseFloat(custoLoteValor)}
            onChange={(e) => {
              const tempValor = parseFloat(e.target.value.replace(/\./g, '').replace(',', '.'))
              setCustoLoteValor(tempValor)
            }}
            InputProps={{
              endAdornment: <InputAdornment sx={{ width: '5%', color: 'blue' }} position="end"><AttachMoneyIcon /></InputAdornment>,
            }}
            InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
          />
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleCancelOpc} variant="outlined" color="secondary">
            Cancelar
          </MDButton>
          <MDButton onClick={handleOkOpc1} variant="gradient" color='info' sx={{ height: "100%" }} >
            Confirmar
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog //Opc 2 - Alterar valor venda($) dos produtos filtrados
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        open={openOpc2.open}
        onClose={handleCancelOpc}
      >
        <DialogTitle>{'Novo valor de venda para a alteração em lote'}</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <CurrencyInput
                required={true}
                label="Valor de Venda"
                value={parseFloat(valorVendaLoteValor)}
                onChange={(e) => {
                  const tempValor = parseFloat(e.target.value.replace(/\./g, '').replace(',', '.'))
                  setValorVendaLoteValor(tempValor)
                }}
                InputProps={{
                  endAdornment: <InputAdornment sx={{ width: '5%', color: 'blue' }} position="end"><AttachMoneyIcon /></InputAdornment>,
                }}
                InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
              />

            </Grid>
            <Grid item xs={12} sm={12}> {/* Tabela de preço */}
              <AutoCompleteStyled
                options={opcoesFiltroTabelaPreco}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderInput={(params) => <FormField
                  {...params}
                  label="Tabela de preço"
                  helperText={filtroTabelaPreco ? false :
                    <>
                      <Typography pl={1} fontWeight="small" variant="caption" color={'#0239cf'}>Será alterado em todas as tabelas de preço</Typography>
                    </>}
                />}
                value={filtroTabelaPreco}
                onChange={(event, newValue) => {
                  setFiltroTabelaPreco(newValue);
                }}
                defaultValue={null}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleCancelOpc} variant="outlined" color="secondary">
            Cancelar
          </MDButton>
          <MDButton onClick={handleOkOpc2} variant="gradient" color='info' sx={{ height: "100%" }} >
            Confirmar
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog //Opc 3 - Reajustar custo(%) dos produtos filtrados
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        open={openOpc3.open}
        onClose={handleCancelOpc}
      >
        <DialogTitle>{'Reajustar o valor de custo em percentual em lote'}</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <CurrencyInput
                required={true}
                label="Reajuste de custo em percentual"
                value={parseFloat(custoLotePercentual)}
                onChange={(e) => {
                  const tempValor = parseFloat(e.target.value.replace(/\./g, '').replace(',', '.'))
                  setCustoLotePercentual(tempValor)
                }}
                InputProps={{
                  endAdornment: <InputAdornment sx={{ width: '5%', color: custoLotePercentual > 0 ? 'green' : 'red' }} position="end"><PercentIcon /></InputAdornment>,
                }}
                InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
              />

            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleCancelOpc} variant="outlined" color="secondary">
            Cancelar
          </MDButton>
          <MDButton onClick={handleOkOpc3} variant="gradient" color='info' sx={{ height: "100%" }} >
            Confirmar
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog //Opc 4 - Reajustar valor venda(%) dos produtos filtrados
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        open={openOpc4.open}
        onClose={handleCancelOpc}
      >
        <DialogTitle>{'Reajustar o valor de venda em percentual em lote'}</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}> {/* Valor de venda em porcentagem*/}
              <CurrencyInput
                required={true}
                label="Reajuste de valor de venda em percentual"
                value={parseFloat(valorVendaLotePercentual)}
                onChange={(e) => {
                  const tempValor = parseFloat(e.target.value.replace(/\./g, '').replace(',', '.'))
                  setValorVendaLotePercentual(tempValor)
                }}
                InputProps={{
                  endAdornment: <InputAdornment sx={{ width: '5%', color: valorVendaLotePercentual > 0 ? 'green' : 'red' }} position="end"><PercentIcon /></InputAdornment>,
                }}
                InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
              />

            </Grid>
            <Grid item xs={12} sm={12}> {/* Tabela de preço */}
              <AutoCompleteStyled
                options={opcoesFiltroTabelaPreco}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderInput={(params) => <FormField
                  {...params}
                  label="Tabela de preço"
                  helperText={filtroTabelaPreco ? false :
                    <>
                      <Typography pl={1} fontWeight="small" variant="caption" color={'#0239cf'}>Será alterado em todas as tabelas de preço</Typography>
                    </>}
                />}
                value={filtroTabelaPreco}
                onChange={(event, newValue) => {
                  setFiltroTabelaPreco(newValue);
                }}
                defaultValue={null}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleCancelOpc} variant="outlined" color="secondary">
            Cancelar
          </MDButton>
          <MDButton onClick={handleOkOpc4} variant="gradient" color='info' sx={{ height: "100%" }} >
            Confirmar
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog //Opc 5 - Copiar sugerido para venda dos produtos filtrados
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        open={openOpc5.open}
        onClose={handleCancelOpc}
      >
        <DialogTitle>{'Esta ação copiará o(s) preço(s) sugerido(s) para o(s) preço(s) de venda do(s) produto(s) filtrado(s)'}</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <AutoCompleteStyled
                options={opcoesFiltroTabelaPreco}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderInput={(params) => <FormField
                  {...params}
                  label="Tabela de preço"
                  helperText={filtroTabelaPreco ? false :
                    <>
                      <Typography pl={1} fontWeight="small" variant="caption" color={'#0239cf'}>Será alterado em todas as tabelas de preço</Typography>
                    </>}
                />}
                value={filtroTabelaPreco}
                onChange={(event, newValue) => {
                  setFiltroTabelaPreco(newValue);
                }}
                defaultValue={null}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleCancelOpc} variant="outlined" color="secondary">
            Cancelar
          </MDButton>
          <MDButton onClick={handleOkOpc5} variant="gradient" color='info' sx={{ height: "100%" }} >
            Confirmar
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog //Opc 6 - Excluir produto/cor para os produtos filtrados
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        open={openOpc6.open}
        onClose={handleCancelOpc}
      >
        <DialogTitle>{'Esta ação excluirá a(s) cor(es) do(s) produto(s) filtrado(s)'}</DialogTitle>
        <DialogContent dividers>

        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleCancelOpc} variant="outlined" color="secondary">
            Cancelar
          </MDButton>
          <MDButton onClick={handleOkOpc6} variant="gradient" color='info' sx={{ height: "100%" }} >
            Confirmar
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog //Opc 7 - Ativar/Desativar produto/cor para o(s) produto(s) filtrado(s)
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        open={openOpc7.open}
        onClose={handleCancelOpc}
      >
        <DialogTitle>{'Ativar/Desativar produto/cor  em lote'}</DialogTitle>
        <DialogContent dividers>
          <AutoCompleteStyled
            options={opcoesAtivo}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            renderInput={(params) => <FormField {...params} label="Ativo" />}
            value={ativoOpc7}
            onChange={(event, newValue) => {
              setAtivoOpc7(newValue);
            }}
            defaultValue={null}
          />
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleCancelOpc} variant="outlined" color="secondary">
            Cancelar
          </MDButton>
          <MDButton onClick={handleOkOpc7} variant="gradient" color='info' sx={{ height: "100%" }} >
            Confirmar
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog //Opc 8 - 
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        open={openOpc8.open}
        onClose={handleCancelOpc}
      >
        <DialogTitle>{'Novo custo do quilo em valor para a alteração em lote'}</DialogTitle>
        <DialogContent dividers>
          <CurrencyInput
            required={true}
            label="Custo Quilo"
            value={parseFloat(custoQuiloLoteValor)}
            onChange={(e) => {
              const tempValor = parseFloat(e.target.value.replace(/\./g, '').replace(',', '.'))
              setCustoQuiloLoteValor(tempValor)
            }}
            InputProps={{
              endAdornment: <InputAdornment sx={{ width: '5%', color: 'blue' }} position="end"><AttachMoneyIcon /></InputAdornment>,
            }}
            helperText={filtroTabelaPreco ? false :
              <>
                <Typography pl={1} fontWeight="small" variant="caption" color={'#0239cf'}>Alterando o custo quilo também é alterado o custo em relação ao peso</Typography>
              </>}
            InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
          />
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleCancelOpc} variant="outlined" color="secondary">
            Cancelar
          </MDButton>
          <MDButton onClick={handleOkOpc8} variant="gradient" color='info' sx={{ height: "100%" }} >
            Confirmar
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
      //TransitionComponent={Transition}
      >
        <MDBox width="100%">
          <MDBox display="flex" justifyContent="space-between">
            <MDTypography fontWeight='bold' ml={5} mt={1} variant='h3'>{titulo}</MDTypography>

            <Tooltip title="Fechar (Esc)">
              <MDButton size="large" sx={{ padding: 0 }} onClick={() => {
                handleClose()
              }}>
                <Icon>close</Icon>
              </MDButton>

            </Tooltip>
          </MDBox>
        </MDBox>

        <MDBox>

          <MDBox width="100%" display="flex" justifyContent="space-between">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpenFiltrosAvancados(!openFiltrosAvancados)}
            >
              {/* {openFiltrosAvancados ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} */}
              <Typography>Filtros</Typography>
            </IconButton>

          </MDBox>

          {
            desenhaFiltro()
          }

          {/* Grid da Tabela */}
          <Grid container>
            <Grid item xs={12}>
              <div key={forceRender}>
                <TabelaPadrao
                  colunas={colunasTable}
                  linhas={linhas}
                  countAll={countAll}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  order={order}
                  setOrder={setOrder}
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                />
              </div>
            </Grid>
          </Grid>
        </MDBox>

      </Dialog>

    </>
  );
}
