import { Autocomplete, TextField, Box, Checkbox, Dialog, Tooltip, Typography } from '@mui/material';
import defaultImage from "assets/images/products/choose-product.png";
import MDInput from 'components/MDInput';
import MessagesContext from 'hooks/MessagesContext';
import React, { useContext, useEffect, useState } from 'react';
import { Image as KonvaImage, Transformer } from 'react-konva';
import useImage from 'use-image';
import MDBox from 'components/MDBox';

export const PropriedadesDbImagem = (props) => {

    const { itensTela, setItensTela, selectedId, open, setOpen, fieldsDbLabel } = props;

    const messageContext = useContext(MessagesContext);
    const { MensagemErro } = messageContext;
    const [datasetSelecionado, setDatasetSelecionado] = useState(null);
    const [campoSelecionado, setCampoSelecionado] = useState(null);
    const datasetsUnicos = [...new Set(fieldsDbLabel.map((opcao) => opcao.dataset))];

    const handleChangeLargura = (e) => {
        const temp = itensTela.slice();
        temp[selectedId].width =  parseInt(e.target.value, 10);
        setItensTela(temp);
    }

    const handleChangeAltura = (e) => {
        const temp = itensTela.slice();
        temp[selectedId].height = parseInt(e.target.value, 10);
        setItensTela(temp);
    }

    const handleChangeAspectRadio = (e) => {
        const temp = itensTela.slice();
        temp[selectedId].aspectratio = e.currentTarget.checked;
        setItensTela(temp);
    }

    const handleChangeImagemExterna = (e) => {
        const temp = itensTela.slice();
        temp[selectedId].imagemexterna = e.currentTarget.checked;
        setItensTela(temp);
    }
    
    const opcoesFiltradas = datasetSelecionado
        ? fieldsDbLabel.filter((opcao) => opcao.dataset === datasetSelecionado)
        : [];

    const handleChangeField = (newValue) => {
        // console.log(newValue);
        const temp = itensTela.slice();
        temp[selectedId].dataset = newValue?.dataset;
        temp[selectedId].field = newValue?.value;
        setCampoSelecionado(newValue);
        setItensTela(temp);
    }

    useEffect(() => {

        //ao carregar preparo o states com o que ja estava configurado na DbLabel

        if (open !== true)
            return;

        const temp = itensTela.slice();

        if (temp[selectedId].dataset) {
            if (datasetsUnicos?.find((item) => item === temp[selectedId].dataset)) {
                setDatasetSelecionado(temp[selectedId].dataset);
                setCampoSelecionado({
                    label: temp[selectedId].field,
                    dataset: temp[selectedId].dataset,
                    value: temp[selectedId].field
                })
            }
            else setDatasetSelecionado(null);
        }
        else {
            setDatasetSelecionado(null);
            setCampoSelecionado(null);
        }

    }, [open])

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            maxWidth={'md'}
            fullWidth
        >
            <Box m={2} >

                <MDBox
                    mt={2}
                    fullWidth
                >
                    <Autocomplete
                        options={datasetsUnicos}
                        renderInput={(params) => <TextField {...params} label="Escolha o Dataset" />}
                        value={datasetSelecionado}
                        onChange={(event, newValue) => setDatasetSelecionado(newValue || null)}
                    />

                </MDBox>
                <MDBox
                    mt={2}
                    fullWidth
                >
                    <Autocomplete
                        options={opcoesFiltradas}
                        getOptionLabel={(opcao) => opcao.label}
                        renderInput={(params) => <TextField {...params} label="Escolha o Campo" />}
                        value={campoSelecionado} // Para redefinir a seleção ao escolher um novo dataset
                        onChange={(event, newValue) => {
                            handleChangeField(newValue);

                        }}
                    />

                </MDBox>
                <Box>
                    <Typography variant='caption'>Manter aspecto original</Typography>
                    <Checkbox
                        checked={itensTela[selectedId].aspectratio}
                        onChange={handleChangeAspectRadio}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </Box>
                <Box>
                    <Typography variant='caption'>Carregar imagem externa pelo nome do campo do BD</Typography>
                    <Checkbox
                        checked={itensTela[selectedId].imagemexterna}
                        onChange={handleChangeImagemExterna}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </Box>
                <Box mt={2} mb={2}>
                    <MDInput
                        label='Largura'
                        type='number'
                        value={itensTela[selectedId].width}
                        onChange={handleChangeLargura}
                        sx={{ mr: 2 }}
                    />
                    <MDInput
                        label='Altura'
                        type='number'
                        value={itensTela[selectedId].height}
                        onChange={handleChangeAltura}
                    />
                </Box>

            </Box>

        </Dialog>
    )
}

export const DbImagem = ({ shapeProps, isSelected, onSelect, onChange }) => {
    const shapeRef = React.useRef();
    const trRef = React.useRef();

    React.useEffect(() => {
        if (isSelected) {
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    const [imageconvertida] = useImage(defaultImage);

    return (
        <React.Fragment>

            <KonvaImage
                onClick={onSelect}
                onTap={onSelect}
                ref={shapeRef}
                {...shapeProps}
                draggable
                image={imageconvertida}
                onDragEnd={(e) => {
                    onChange({
                        ...shapeProps,
                        x: parseInt(e.target.x(), 10),
                        y: parseInt(e.target.y(), 10),
                    });
                }}
                onTransformEnd={(e) => {

                    const node = shapeRef.current;

                    let scaleX = node.scaleX();
                    let scaleY = node.scaleY();

                    //quando altera uma das dimensoes a outra sofre uma pequena
                    //alteracao e fica com escala 0.99999
                    if ((scaleX < 1) && (scaleX > 0.9)) {
                        scaleX = 1;
                    }
                    if ((scaleX < 1) && (scaleX > 0.9)) {
                        scaleX = 1;
                    }

                    node.scaleX(1);
                    node.scaleY(1);

                    let novalargura = parseInt(Math.max(5, node.width() * scaleX), 10);
                    let novaaltura = parseInt(Math.max(node.height() * scaleY), 10);
          
                    onChange({
                        ...shapeProps,
                        x: parseInt(node.x(), 10),
                        y: parseInt(node.y(), 10),
                        width: novalargura,
                        height: novaaltura
                    });
                }}
            />

            {isSelected && (
                <Transformer
                    ref={trRef}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                    rotateEnabled={false} // Desativar a rotação
                />
            )}
        </React.Fragment>
    );
}