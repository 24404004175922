import React, { useContext, useEffect, useState } from 'react';
import MDBox from "components/MDBox";
import MessagesContext from 'hooks/MessagesContext';
import AuthContext from "hooks/AuthContext";
import { useForm, useWatch } from 'react-hook-form';
import MDButton from 'components/MDButton';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import Cabecalho from './cabecalho';
import { Autocomplete, Grid, Icon, Collapse, Typography, Box, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { formatDate } from "commons/utils";
import MDInput from 'components/MDInput';
import { Controller } from 'react-hook-form';
import FormField from 'componentes/FormField';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';
import { ptBR } from "date-fns/locale";
import { format } from 'date-fns';

//INDEX QUE COMANDA O CABECALHO

function Individual(props) {

  const { empresaAtual } = props;
  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;
  const authContext = useContext(AuthContext);
  const { getSessao } = authContext;
  const sessaolocal = getSessao();
  const { handleSubmit, control, setValue, getValues, setFocus } = useForm({});


  let dataHoje = new Date();
  const data7dias = new Date(dataHoje.setDate(dataHoje.getDate() - 7));
  dataHoje = new Date();
  const data15dias = new Date(dataHoje.setDate(dataHoje.getDate() - 15));
  dataHoje = new Date();
  const data30dias = new Date(dataHoje.setDate(dataHoje.getDate() - 30));

  const periodo = [
    { value: data7dias, label: 'Últimos 7 dias' },
    { value: data15dias, label: 'Últimos 15 dias' },
    { value: data30dias, label: 'Últimos 30 dias' },
    { value: null, label: 'Personalizado' },
  ];


  const [dataInicial, setDataInicial] = useState(data7dias);
  const [dataFinal, setDataFinal] = useState(new Date());


  const [recarregaComponenteOpcoes, setRecarregaComponenteOpcoes] = useState(false);
  const [desabilitaData, setDesabilitaData] = useState(false);

  const [dadosEstatisticasCs, setDadosEstatisticasCs] = useState({});

  const onSubmit = (data) => {
  }

  const validaCampos = (data) => {

    let camposObrigatorios = ``;

    if (!data.valor_base) {
      camposObrigatorios = `${camposObrigatorios}/valor_base`
    }

    if (!data.ativo) {
      camposObrigatorios = `${camposObrigatorios}/Ativo`
    }

    if (!data.periodicidade) {
      camposObrigatorios = `${camposObrigatorios}/Periodicidade`
    }

    if (camposObrigatorios !== '') {
      throw new Error(`Campos obrigatórios: ${camposObrigatorios}`)
    }
  }

  const abrirEstatisticaCs = async () => {

    try {

      const datainicialpesquisa = format(dataInicial, 'yyyy-MM-dd 00:00:00');

      const datafinalpesquisa = format(dataFinal, 'yyyy-MM-dd 23:59:59');

      let url = `${rotafechada()}admin/empresas/estatisticas?empresa_id=${empresaAtual}&data_ini=${new Date(datainicialpesquisa)}&data_fin=${new Date(datafinalpesquisa)}`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;

      setDadosEstatisticasCs(data);


      setValue('dados', JSON.stringify(data).replaceAll(',', '\n\n').replaceAll('"', '').replaceAll('{', '').replaceAll('}', '').replaceAll(':', ': ').replaceAll('_', ' '));

      setRecarregaComponenteOpcoes(!recarregaComponenteOpcoes);

    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
      MensagemErro(`Empresa ${empresaAtual} não possui Estatísticas para o CS`);
    }
  }

  const buscaFiltroEstatisticaCs = async (pDataInicial, pDataFinal) => {

    try {

      let datainicialpesquisa = format(pDataInicial, 'yyyy-MM-dd 00:00:00');
      let datafinalpesquisa = format(pDataFinal, 'yyyy-MM-dd 23:59:59');


      let url = `${rotafechada()}admin/empresas/estatisticas?empresa_id=${empresaAtual}&data_ini=${new Date(datainicialpesquisa)}&data_fin=${new Date(datafinalpesquisa)}`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;

      setDadosEstatisticasCs(data);


      setValue('dados', JSON.stringify(data).replaceAll(',', '\n\n').replaceAll('"', '').replaceAll('{', '').replaceAll('}', '').replaceAll(':', ': ').replaceAll('_', ' '));

      setRecarregaComponenteOpcoes(!recarregaComponenteOpcoes);

    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
      MensagemErro(`Empresa ${empresaAtual} não possui Estatísticas para o CS nesse período`);
    }
  }

  useEffect(async () => {
    await abrirEstatisticaCs();
  }, [])

  const handleClickPesquisar = () => {
    buscaFiltroEstatisticaCs(dataInicial, dataFinal);
  }

  //UseWatch fica escutando o campo perído para quando o periodo mudar ele carregar o state dos datePicker e pesquisa
  const aguardaPeriodo = useWatch({
    control,
    name: "Periodo",
  });

  useEffect(async () => {
    if (aguardaPeriodo && aguardaPeriodo.value != null) {
      setDataInicial(aguardaPeriodo.value);
      setDataFinal(new Date());
      setDesabilitaData(true);
      await buscaFiltroEstatisticaCs(aguardaPeriodo.value, new Date());
    }
    if (aguardaPeriodo && aguardaPeriodo.value == null) {
      setDataInicial(null);
      setDataFinal(null);
      setDesabilitaData(false);
    }
  }, [aguardaPeriodo])

  return (


    <MDBox width='100%' p={1}>

      <Box sx={{ margin: 0 }}>
        <Grid container justifyContent="flex start" >

          <Grid item xs={12} sm={3} paddingRight={1}>{/*Período*/}
            <Controller
              name='Periodo'
              control={control}
              //defaultValue={{value: }}
              render={({ field: { onChange, ...props } }) => (
                <AutoCompleteStyled
                  options={periodo}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  renderInput={(params) => <FormField {...params} label='Período' />}
                  onChange={(_, data) => {
                    return onChange(data);
                  }}
                  disabled={false}
                  {...props}
                />
              )}

            />
          </Grid>

          <Grid item xs={12} sm={3} paddingRight={1}> {/*Periodo ini*/}
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <DesktopDatePicker
                required={true}
                disabled={desabilitaData}
                label='Data Inicial'
                inputFormat="dd/MM/yyyy"
                value={dataInicial}
                onChange={(e) => setDataInicial(e)}
                renderInput={(params) => <FormField {...params} />}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} sm={3} paddingRight={1}>{/*Periodo fin*/}
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <DesktopDatePicker
                required={true}
                disabled={desabilitaData}
                label='Data Final'
                inputFormat="dd/MM/yyyy"
                value={dataFinal}
                onChange={(e) => setDataFinal(e)}
                renderInput={(params) => <FormField {...params} />}
              />
            </LocalizationProvider>
          </Grid>

        </Grid>

        <Grid container justifyContent="flex-end" >
          <Grid item xs={12} sm={2}>{/*Pesquisar*/}
            <MDButton onClick={handleClickPesquisar} color='info' > Pesquisar</MDButton>
          </Grid>

        </Grid>
      </Box>
      {/* </Collapse> */}


      <form onSubmit={handleSubmit(onSubmit)}>
        <Cabecalho
          control={control}
          getValues={getValues}
        />
      </form>
    </MDBox>
  )
}

export default Individual;
