import MessagesContext from 'hooks/MessagesContext';
import React, { useContext, useEffect, useState } from 'react';
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import MDTypography from 'components/MDTypography';
import { Box } from '@mui/system';
import MDButton from 'components/MDButton';


export default function ModulosSistema(props) {


    const { moduloCarregado, setModuloCarregado, setNomeModuloCarregado } = props;
    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;
    const [dataset, setDataset] = useState([]);

    const baseUrl = `${rotafechada()}categorias_modulo_parametro/modulos`;
    const baseCaption = 'Módulos do Sistema';

    const buscaModulos = async () => {

        try {
            MostraTelaEspera(`Buscando ${baseCaption}`);
            const retorno = await api.get(baseUrl);
            FechaTelaEspera();
            const { data } = retorno.data;
            setDataset(data);
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`${msg}`);
        }
    }


    useEffect(async () => {

        buscaModulos();

    }, [])

    const handleClickModulo = (item) => {
        setModuloCarregado(item.id);
        setNomeModuloCarregado(item.nome);
    }

    return (
        <>
            {
                dataset?.map((item, index) => {
                    return (
                        <Box key={index}>
                            <MDButton  >
                                <MDTypography
                                    variant="button"
                                    fontWeight={item.id === moduloCarregado ? "bold" : "normal"}
                                    color={item.id === moduloCarregado ? "info" : "black"}
                                    onClick={() => handleClickModulo(item)}
                                >
                                    {
                                        item.caption
                                    }
                                </MDTypography>
                            </MDButton>
                        </Box>
                    )
                }
                )
            }
        </>
    )
}