import { Dialog, DialogContent, DialogContentText, DialogTitle, Icon, Typography } from "@mui/material";
import { Box } from "@mui/system";
import button from "assets/theme/components/button";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import { converteDataSemUTC } from "commons/utils";
import { formatDate } from "commons/utils";
import DialogoConfirma from "componentes/DialogoConfirma";
import MDButton from "components/MDButton";
import AuthContext from "hooks/AuthContext";
import MessagesContext from "hooks/MessagesContext";
import { useState } from "react";
import { useContext, useEffect } from "react";
import CheckIcon from '@mui/icons-material/Check';

import TabelaEtapa2 from "./tabela";

/*
Nesta etapa

Mostra os Pedidos de Tempera disponiveis para os projetos dessa venda
para selecionar o pedido de têmpera que deseja imprimir

*/


export default function Etapa2_EscolhePedidoTempera(props) {

    const { handleClose, vendaAtual, etapaAtual, setEtapaAtual, abortaProcesso, handleClickPedidoTemperaSelecionado, setEtapaAnterior } = props;

    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;

    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();


    const [openConfirmacao, setOpenConfirmacao] = useState({ open: false, pedido_tempera_venda_projeto_id: null });
    const [datasetPedidosTemperasUsados, setDatasetPedidosTemperasUsados] = useState([]);


    const buscaDadosVendaPedidoTempera = async () => {
        try {
            MostraTelaEspera('Aguarde a busca dos dados...');
            const retorno = await api.get(encodeURI(`${rotafechada()}pedido_tempera_venda_projetos/listarvendaprojetos?venda_id=${vendaAtual}`));
            const { data } = retorno.data;

            if (!data.length > 0) {
                MensagemErro(`A venda não possui projetos!`);
                abortaProcesso();
                return;
            }

            const comPedidoTemperaTemp = {
                colunas: [
                    {
                        id: 'selecao',
                        disableOrder: true,
                        numeric: false,
                        disablePadding: true,
                        label: '(X)'
                    },

                    {
                        id: 'pedido_tempera_id',
                        numeric: false,
                        disablePadding: true,
                        disableOrder: true,
                        label: 'Pedido Têmpera'
                    },
                    {
                        id: 'fornecedor',
                        numeric: false,
                        disablePadding: true,
                        disableOrder: true,
                        label: 'Fornecedor'
                    },
                    {
                        id: 'data',
                        numeric: false,
                        disablePadding: true,
                        disableOrder: true,
                        label: 'Data'
                    },

                ],
                linhas: []
            };


            data.map((item) => {

                const data = converteDataSemUTC(item.Pedido_Tempera_Venda_Projetos[0]?.Pedidos_Tempera.data);

                if (item.Pedido_Tempera_Venda_Projetos?.length > 0) {

                    if (comPedidoTemperaTemp.linhas.filter((itemtemp) => itemtemp.pedido_tempera_id === item.Pedido_Tempera_Venda_Projetos[0]?.pedido_tempera_id).length === 0) {
                        comPedidoTemperaTemp.linhas.push({
                            selecao: <>
                                <MDButton onClick={() => handleClickPedidoTemperaSelecionado(item.Pedido_Tempera_Venda_Projetos[0]?.pedido_tempera_id)}>
                                    <CheckIcon fontSize='large' color='info' />
                                </MDButton>
                            </>,
                            pedido_tempera_id: item.Pedido_Tempera_Venda_Projetos[0]?.pedido_tempera_id,
                            //ATENÇÃO O CAMPO FANTASIA ESTA COMO "FANTAS" DEVIDO AO BUG NO SEQUELIZE, REPORTEI NA ISSUE  https://github.com/sequelize/sequelize/issues/15460
                            fornecedor: item.Pedido_Tempera_Venda_Projetos[0]?.Pedidos_Tempera.Fornecedor.fantas,
                            data: formatDate(new Date(data), 'DD/MM/YY')
                        })
                    }

                }
            })

            setDatasetPedidosTemperasUsados(comPedidoTemperaTemp);

            if (comPedidoTemperaTemp.linhas.length === 1) {

                setEtapaAnterior(etapaAtual - 1);//porque se o cliente clicar em voltar na proxima etapa ele nao precisa vir para essa etapa

                //para nao precisar fazer o usuario clicar no caso de ter só um pedido ja chamo a função
                handleClickPedidoTemperaSelecionado(comPedidoTemperaTemp.linhas[0].pedido_tempera_id);

            }
            else setEtapaAnterior(etapaAtual);

            FechaTelaEspera();

        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            const erro = error.response?.data?.erro || '';
            console.log(erro);
            MensagemErro(`Erro ao tentar resgatar os dados dos projetos da Venda!${msg}`)
        }
    }

    useEffect(() => {
        buscaDadosVendaPedidoTempera();
    }, [])

    const handleClickRetornar = async () => {
        setEtapaAtual(etapaAtual - 1);
    }


    return (
        <>
            <DialogoConfirma
                open={openConfirmacao?.open || false}
                message={openConfirmacao?.message || ''}
                title={openConfirmacao?.title || 'Confirmação'}
                handleCancel={openConfirmacao.handleCancel}
                handleConfirm={openConfirmacao.handleConfirm}
            />

            <Typography variant="h6" mb={2}>Nesta Etapa você precisa escolher com qual Pedido de Têmpera deseja continuar </Typography>
            <TabelaEtapa2
                colunas={datasetPedidosTemperasUsados?.colunas}
                linhas={datasetPedidosTemperasUsados?.linhas}
            />

            <Box display='flex' justifyContent={'space-between'}>
                <MDButton color='info' onClick={handleClickRetornar}>Retornar</MDButton>
            </Box>

        </>
    )
}