import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';


export default function DialogoConfirma(props) {

  const { open, message, handleCancel, handleConfirm, title, color } = props;

  return (
    <div>

      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleCancel} variant="outlined" color="secondary">
            Cancelar
          </MDButton>
          <MDButton onClick={handleConfirm} variant="gradient" color={color ? color : 'info'} sx={{ height: "100%" }}>
            Confirmar
          </MDButton>
          {/* <Button onClick={handleCancel} autoFocus>Cancelar</Button>
          <Button onClick={handleConfirm} >Confirmar</Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}
