import React, { useContext, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Checkbox, Grid, IconButton, Typography, Autocomplete, Tooltip } from '@mui/material';
import FormField from 'componentes/FormField';
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import { useForm, useWatch } from 'react-hook-form';
import MDTypography from 'components/MDTypography';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import MessagesContext from 'hooks/MessagesContext';
import MDButton from 'components/MDButton';
import AuthContext from 'hooks/AuthContext';
import TabelaOpcoes from './tabela';
import DialogoConfirma from 'componentes/DialogoConfirma';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';
import InfoIcon from '@mui/icons-material/Info';
import axios from 'axios';
import InputMask from 'react-input-mask';

const PessoaEnderecos = (props) => {

  const { handleSubmit, control, setValue, getValues, pessoaAtual, recarregaComponenteOpcoes, parametrosPessoa } = props;

  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;
  const authContext = useContext(AuthContext);
  const { getSessao } = authContext;
  const sessaolocal = getSessao();
  const [openCollapse, setOpenCollapse] = useState(false);
  const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });
  const opcaoDefault = [{ value: -1, label: '<Selecione...>' }];
  const [enderecosPessoa, setEnderecosPessoa] = useState(opcaoDefault);
  const [recarregaTabela, setRecarregaTabela] = useState(false);
  const [codigoAtual, setCodigoAtual] = useState('');
  const opcaoDefaultCidadePessoa = [{ value: -1, label: '<Selecione uma cidade>', uf: '' }];
  const [opcoesCidade, setOpcoesCidade] = useState([]);
  const [opcoesEstado, setOpcoesEstado] = useState([
    { value: -1, label: '<Selecione uma opção>' },
    { value: 'AC', label: 'Acre' },
    { value: 'AL', label: 'Alagoas' },
    { value: 'AP', label: 'Amapá' },
    { value: 'AM', label: 'Amazonas' },
    { value: 'BA', label: 'Bahia' },
    { value: 'CE', label: 'Ceara' },
    { value: 'DF', label: 'Distrito Federal' },
    { value: 'ES', label: 'Espírito Santo' },
    { value: 'GO', label: 'Goiás' },
    { value: 'MA', label: 'Maranhão' },
    { value: 'MT', label: 'Mato Grosso' },
    { value: 'MS', label: 'Mato Grosso do Sul' },
    { value: 'MG', label: 'Minas Gerais' },
    { value: 'PA', label: 'Pará' },
    { value: 'PB', label: 'Paraíba' },
    { value: 'PR', label: 'Paraná' },
    { value: 'PE', label: 'Pernambuco' },
    { value: 'PI', label: 'Piauí' },
    { value: 'RJ', label: 'Rio de Janeiro' },
    { value: 'RN', label: 'Rio Grande do Norte' },
    { value: 'RS', label: 'Rio Grande do Sul' },
    { value: 'RO', label: 'Rondônia' },
    { value: 'RR', label: 'Roraima' },
    { value: 'SC', label: 'Santa Catarina' },
    { value: 'SP', label: 'São Paulo' },
    { value: 'SE', label: 'Sergipe' },
    { value: 'TO', label: 'Tocantins' },
  ]);

  const objModeloPessoaEnderecos = {
    id: { type: 'text' },
    pessoa_id: { type: 'autocomplete' },
    pais_id: { type: 'text' },
    estado: { type: 'autocomplete' },
    cidade_id: { type: 'autocomplete' },
    principal: { type: 'checkbox' },
    rua: { type: 'text' },
    numero: { type: 'text' },
    bairro: { type: 'text' },
    complemento: { type: 'text' },
    cep: { type: 'text' },
    ativo: { type: 'text' },
  }

  const validaRotaPermissao = async (operacao = '', silencioso = false) => {
    if ((operacao !== 'inserir') &&
      (operacao !== 'editar') &&
      (operacao !== 'excluir') &&
      (operacao !== 'listar')) {
      MensagemErro('Escolha a operação que deseja validar a permissão!');
      return false;
    }

    //validando a permissao
    try {
      await api.get(`${rotafechada()}pessoa_enderecos/permissao/${operacao}`);
      return true;
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;

      if (silencioso === false) {
        MensagemErro(`Erro: ${msg}`);
      }
      return false;
    }
  }

  const validaCampos = (data) => {

    let camposObrigatorios = ``;
    if (camposObrigatorios !== '') {
      throw new Error(`Campos obrigatórios: ${camposObrigatorios}`)
    }
  }

  const gravarRegistro = async (data) => {

    const dados = {};
    Object.keys(objModeloPessoaEnderecos).forEach((nome) => {
      dados[nome] = '';
      if (nome in data['enderecos']) {
        if (typeof data['enderecos'][nome] === 'object') {
          dados[nome] = data['enderecos'][nome]?.value ? data['enderecos'][nome]?.value : '';
        }
        else
          if (typeof data['enderecos'][nome] === 'boolean') {
            dados[nome] = data['enderecos'][nome] === true ? 'S' : 'N'
          }
          else dados[nome] = data['enderecos'][nome];
      }
    })

    dados.empresa_id = sessaolocal.empresa_id || null;
    dados.pessoa_id = pessoaAtual
    dados.ativo = 'S'
    delete dados.id;

    try {
      validaCampos(dados);
    }
    catch (error) {
      MensagemErro(`${error.message}`);
      return;
    }
    try {

      if ((data.enderecos.id) && (parseInt(data.enderecos.id, 10) > 0)) {
        await api.put(`${rotafechada()}pessoa_enderecos/${data.enderecos.id}`, dados);
      }
      else {
        dados.pais_id = parametrosPessoa?.pais_id || null;
        await api.post(`${rotafechada()}pessoa_enderecos`, dados);
      }

      MensagemAviso(`Gravado com sucesso!`);
      setCodigoAtual('');
      setRecarregaTabela(!recarregaTabela);
    }
    catch (error) {
      console.log(error);
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const buscaEnderecoPessoa = async () => {

    try {
      MostraTelaEspera('')
      let url = `${rotafechada()}pessoas?limit=-1`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;
      let enderecosPessoaTemp = [];
      enderecosPessoaTemp.push({ ...opcaoDefault[0] });
      data.map((item) => {
        enderecosPessoaTemp.push({ id: item.id, value: item.id, label: item.nome })
      })
      setEnderecosPessoa(enderecosPessoaTemp);
      FechaTelaEspera()
    }
    catch (error) {
      FechaTelaEspera()
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const buscaOpcoes = async () => {
    await buscaEnderecoPessoa();
  };

  const novoRegistro = () => {
    //limpo os campos
    Object.keys(objModeloPessoaEnderecos).forEach((nome) => {
      if (objModeloPessoaEnderecos[nome].type === 'autocomplete') {
        setValue(`enderecos.${nome}`, opcaoDefault[0])
      }
      else
        if (objModeloPessoaEnderecos[nome].type === 'checkbox') {
          setValue(`enderecos.${nome}`, false)
        }
        else
          if (objModeloPessoaEnderecos[nome].type === 'text') {
            setValue(`enderecos.${nome}`, '')
          }
    })
  }

  const abrirRegistro = async (codigo) => {
    try {
      MostraTelaEspera('')
      let url = `${rotafechada()}pessoa_enderecos?id=${codigo}`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;

      if (!data.length > 0) {
        MensagemErro(`Endereço ID ${codigo} não localizado`);
        return;
      }
      setValue('enderecos.id', data[0].id);
      setValue(`enderecos.rua`, data[0].rua,);
      setValue(`enderecos.numero`, data[0].numero,);
      setValue(`enderecos.bairro`, data[0].bairro,);
      setValue(`enderecos.complemento`, data[0].complemento,);
      setValue(`enderecos.cep`, data[0].cep,);
      setValue(`enderecos.pais_id`, data[0].pais_id,);
      const vetorestados = opcoesEstado.filter((item) => item.value === data[0].Cidade.uf)
      let nomeestado = '';
      if (vetorestados.length > 0) {
        nomeestado = vetorestados[0]?.label || ''
      }
      setValue(`enderecos.estado`, { value: data[0].Cidade.uf, label: nomeestado || 'Não encontrado' });

      setValue(`enderecos.cidade_id`, { value: data[0].cidade_id, label: data[0].Cidade.nome, uf: data[0].Cidade.uf });

      //nomecidade: item.Cidade?`${item.Cidade?.nome}/${item.Cidade?.uf}` : '',
      setValue('enderecos.principal', data[0].principal === 'S' ? true : false);
      FechaTelaEspera()
    }
    catch (error) {
      FechaTelaEspera()
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const excluirRegistro = async () => {

    setDialogoOpen({ ...dialogoOpen, visible: false });

    try {
      await api.delete(`${rotafechada()}pessoa_enderecos/${dialogoOpen.id || 0}`);
      MensagemAviso('Excluído com sucesso!');
      novoRegistro();
      setRecarregaTabela(!recarregaTabela);

    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleClickExcluir = async (id) => {
    if (!(await validaRotaPermissao('excluir') === true)) {
      return;
    }
    setDialogoOpen({ ...dialogoOpen, visible: true, id });
  }

  const handleBlurBuscaCep = async (cep) => {
    try {
      MostraTelaEspera('Buscando CEP')
      let url = `https://viacep.com.br/ws/${cep}/json/`;
      const retorno = await axios.get(url);
      const data = retorno.data;
      // console.log(data);
      setValue(`enderecos.rua`, data.logradouro);
      setValue(`enderecos.bairro`, data.bairro);
      setValue(`enderecos.cep`, data.cep);

      const vetorestados = opcoesEstado.filter((item) => item.value === data.uf)
      let nomeestado = '';
      if (vetorestados.length > 0) {
        nomeestado = vetorestados[0]?.label || ''
      }
      setValue(`enderecos.estado`, { value: data.uf, label: nomeestado || 'Não encontrado' });

      setValue(`enderecos.cidade_id`, { value: data.ibge, label: data.localidade, uf: data.uf });

      FechaTelaEspera()
    }
    catch (error) {
      FechaTelaEspera()
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }


  useEffect(() => {
    buscaOpcoes();
    if (parseInt(codigoAtual, 10) > 0) {
      abrirRegistro(codigoAtual);
    }
    else novoRegistro();
  }, [pessoaAtual, codigoAtual, recarregaTabela, recarregaComponenteOpcoes])

  const BuscaCidades = async (paramUf = '') => {

    try {
      let url = `${rotafechada()}cidades?limit=-1&uf=${paramUf}`;
      //URL montada para procurar a cidade de acordo com o estado escolhido
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;
      let opcoesCidadeTemp = [];
      opcoesCidadeTemp.push({ ...opcaoDefaultCidadePessoa[0] });

      data.map((item) => {
        opcoesCidadeTemp.push({ /*id: item.id,*/ value: item.id, label: item.nome, uf: item.uf })
      })

      setOpcoesCidade(opcoesCidadeTemp);

      const cidadeAtual = getValues('enderecos.cidade_id');

      if (cidadeAtual?.uf !== paramUf) {
        setValue('enderecos.cidade_id', opcaoDefaultCidadePessoa[0]);
      }
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }

  }

  //UseWatch fica escutando o campo pessoas.estado para quando o estado mudar ele carregar as cidades dessa uf
  const uf = useWatch({
    control,
    name: "enderecos.estado",
  });

  useEffect(async () => {
    if (uf) {
      await BuscaCidades(uf.value ? uf.value : '');
    }
    //else setOpcoesCidade(opcaoDefaultCidadePessoa);
  }, [uf])

  return (
    <MDBox p={2} bgColor={'#fff'} mt={2} borderRadius="lg" coloredShadow='secondary'>

      <DialogoConfirma
        open={dialogoOpen.visible}
        title={'Confirma Exclusão'}
        color={'error'}
        message={'Certeza que deseja excluir?'}
        handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
        handleConfirm={excluirRegistro}
      />

      <IconButton
        aria-label="expand row"
        size="small"
        onClick={() => setOpenCollapse(!openCollapse)}
      >
        {openCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        <Typography variant='h6' mb={2}>Endereços</Typography>
      </IconButton>
      <Tooltip title="Você pode cadastrar mais de um endereço para essa pessoa mas somente o endereço marcado como principal
      aparecerá nos relatórios" placement="top">
        <InfoIcon color="info" sx={{ m: 0.5 }}></InfoIcon>
      </Tooltip>


      <Collapse in={openCollapse} timeout="auto" unmountOnExit>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={2}>{/*id*/}
            <Controller
              name={`enderecos.id`}
              control={props.control}
              defaultValue='Id'
              render={({ field: { ref, ...props } }) =>
                <FormField

                  //autoComplete={false}
                  type='text'
                  label='Id'
                  rows={1}
                  multiline={false}
                  required={false}
                  disabled={true}
                  inputProps={{
                    disabled: true
                  }}
                  inputRef={ref}
                  {...props}
                />}
            />
          </Grid>
          <Grid item xs={12} sm={8}>

          </Grid>
          <Grid item xs={12} sm={2}>{/*principal*/}
            <MDBox width="100%" display="flex" alignItems='center' flexDirection='column'>
              <MDTypography fontWeight="bold" variant="caption">
                {'Endereço Principal'}
              </MDTypography>

              <Controller
                name={`enderecos.principal`}
                control={control}
                render={({ field: { value, ...otherprops } }) => (
                  <Checkbox
                    {...otherprops}
                    checked={value ? (value === true ? true : false) : false}
                    onChange={(e) => setValue(`enderecos.principal`, e.target.checked)}
                  />
                )}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={4}>{/*rua*/}
            <Controller
              name={`enderecos.rua`}
              control={props.control}
              defaultValue='Rua'
              render={({ field: { ref, ...props } }) =>
                <FormField

                  //autoComplete={false}
                  type='text'
                  label='Rua'
                  rows={1}
                  multiline={false}
                  required={false}
                  disabled={false}
                  inputRef={ref}
                  {...props}

                />}
            />
          </Grid>
          <Grid item xs={12} sm={4}>{/*numero*/}
            <Controller
              name={`enderecos.numero`}
              control={props.control}
              defaultValue='Numero'
              render={({ field: { ref, ...props } }) =>
                <FormField

                  //autoComplete={false}
                  type='text'
                  label='Numero'
                  rows={1}
                  multiline={false}
                  required={false}
                  disabled={false}
                  inputRef={ref}
                  {...props}

                />}
            />
          </Grid>
          <Grid item xs={12} sm={4}>{/*bairro*/}
            <Controller
              name={`enderecos.bairro`}
              control={props.control}
              defaultValue='Bairro'
              render={({ field: { ref, ...props } }) =>
                <FormField

                  //autoComplete={false}
                  type='text'
                  label='Bairro'
                  rows={1}
                  multiline={false}
                  required={false}
                  disabled={false}
                  inputRef={ref}
                  {...props}

                />}
            />
          </Grid>
          <Grid item xs={12} sm={4}>{/*complemento*/}
            <Controller
              name={`enderecos.complemento`}
              control={props.control}
              defaultValue='Complemento'
              render={({ field: { ref, ...props } }) =>
                <FormField

                  //autoComplete={false}
                  type='text'
                  label='Complemento'
                  rows={1}
                  multiline={false}
                  required={false}
                  disabled={false}
                  inputRef={ref}
                  {...props}

                />}
            />
          </Grid>

          <Grid item xs={12} sm={4}>{/*cep*/}
            <Controller
              name={`enderecos.cep`}
              control={props.control}
              defaultValue=''
              render={({ field }) =>
                <InputMask mask="99999-999" value={field.value} onChange={field.onChange}>
                  {(inputProps) =>
                    <FormField
                      //autoComplete={false}
                      type='text'
                      label='CEP'
                      rows={1}
                      multiline={false}
                      required={false}
                      // disabled={false}
                      inputRef={field.ref}
                      {...inputProps}
                      inputProps={{
                        ...inputProps.inputProps,
                        onBlur: (e) => {
                          e.preventDefault();
                          const cep = e.target.value.replace(/[^0-9]/g, '');
                          if (cep.length === 8) {
                            handleBlurBuscaCep(cep);
                          }
                        },
                      }}
                    />
                  }
                </InputMask>
              }
            />
          </Grid>

          <Grid item xs={12} sm={4}>{/*estado*/}
            <Controller
              name='enderecos.estado'
              control={control}
              defaultValue={{ value: -1 }}
              render={({ field: { onChange, ...props } }) => (
                <AutoCompleteStyled
                  options={opcoesEstado}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  renderInput={(params) => <FormField {...params} label='Estados' />}
                  onChange={(_, data) => {
                    return onChange(data);
                  }}
                  disabled={false}
                  {...props}

                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>{/*cidade*/}
            <Controller
              name='enderecos.cidade_id'
              control={control}
              defaultValue={{ value: -1 }}
              render={({ field: { onChange, ...props } }) => (
                <AutoCompleteStyled
                  options={opcoesCidade}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  renderInput={(params) => <FormField {...params} label='Cidades' />}
                  onChange={(_, data) => {
                    return onChange(data);
                  }}
                  disabled={false}
                  {...props}

                />
              )}
            />
          </Grid>
        </Grid>
        <MDBox width="100%" display="flex" justifyContent="flex-end" mt={2}>
          <MDButton variant="gradient" color="info" onClick={() => handleSubmit(gravarRegistro)()}>Gravar</MDButton>
          <MDButton variant="gradient" color="info" onClick={novoRegistro} sx={{ ml: 2 }}>Novo</MDButton>
        </MDBox>

        <MDBox width="100%" display="flex" mt={2}>
          <TabelaOpcoes
            pessoaAtual={pessoaAtual}
            abrirRegistro={abrirRegistro}
            excluirRegistro={handleClickExcluir}
            recarregaTabela={recarregaTabela}
            recarregaComponenteOpcoes={recarregaComponenteOpcoes}
            getValues={getValues}
          />
        </MDBox>

      </Collapse>
    </MDBox>
  )
}

export default PessoaEnderecos;