import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import MDButton from 'components/MDButton';

// const options = [
//   'None',
//   'Atria',
//   'Callisto',
//   'Dione',
//   'Ganymede',
//   'Hangouts Call',
//   'Luna',
//   'Oberon',
//   'Phobos',
//   'Pyxis',
//   'Sedna',
//   'Titania',
//   'Triton',
//   'Umbriel',
// ];


export default function DialogoOpcoes(props) {
  const { onClose, value: valueProp, open, title, options, color, ...other } = props;

  const [value, setValue] = React.useState(valueProp);
  const radioGroupRef = React.useRef(null);

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
  };

  const handleChange = (event) => {
    setValue((event.target).value);
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: 500, maxHeight: 435 } }}
      //maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      onClose={handleCancel}
      {...other}
    >
      <DialogTitle>{title || 'Opções'}</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="ringtone"
          name="ringtone"
          value={value}
          onChange={handleChange}
        >
          {options.map((option, index) => (
            <FormControlLabel
              value={option?.value}
              key={`${option.label}`}
              control={<Radio />}
              label={option?.label}
              disabled={option?.disabled ? option?.disabled : false}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        {/* <Button autoFocus onClick={handleCancel}>
          Cancelar
        </Button> */}
        <MDButton onClick={handleCancel} variant="outlined" color="secondary">
          Cancelar
        </MDButton>
        <MDButton onClick={handleOk} variant="gradient" color={color ? color : 'info'} sx={{ height: "100%" }} >
          Confirmar
        </MDButton>
        {/* <Button onClick={handleOk}>Ok</Button> */}
      </DialogActions>
    </Dialog>
  );
}

