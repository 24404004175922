import { Autocomplete, Collapse, Grid, Icon, IconButton, Typography } from '@mui/material';
import api from 'commons/api';
import { rotafechada } from 'commons/urlconf';
import { formataValor } from 'commons/utils';
import TabelaPadrao from 'componentes/TabelaPadrao';
import MDButton from 'components/MDButton';
import MessagesContext from 'hooks/MessagesContext';
import React, { useContext, useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MDInput from 'components/MDInput';
import { formataFloat } from 'commons/utils';
import FormField from 'componentes/FormField';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';
import { truncarTexto } from 'commons/utils';

const TabelaVendaProdutos = (props) => {

    const { vendaAtual, recarregaTabela, abrirRegistro, excluirRegistro, opcoesVendaProjetos } = props;


    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;

    const [countAll, setCountAll] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');
    const [colunasTable, setColunasTable] = React.useState([]);
    const [linhas, setLinhas] = React.useState([]);
    const [openCollapseFiltro, setOpenCollapseFiltro] = useState(false);
    const opcoesVendaProjetosFiltro = [{ value: 0, label: 'Todos' }, ...opcoesVendaProjetos];
    const [filtroVendaProjetoId, setFiltroVendaProjetoId] = useState({...opcoesVendaProjetosFiltro[0]});
    const [nomeProdutoFiltro, setNomeProdutoFiltro] = useState('');
    const[filtroTipoProdutoId,setFiltroTipoProdutoId]=useState({ value: 0, label: 'Todos' })
    const[opcoesTipoProduto,setOpcoesTipoProduto]=useState([{ value: 0, label: 'Todos' }])

    const carregaTabela = async () => {

        if (!(parseInt(vendaAtual, 10) > 0)) {
            return;
        }
        MostraTelaEspera();
        try {

            let complementoUrl = '';

            if (page > 0) {
                complementoUrl = `${complementoUrl}&offset=${page * rowsPerPage}`;
            }

            if (orderBy) {
                complementoUrl = `${complementoUrl}&order=${orderBy}`;
                if (order) {
                    complementoUrl = `${complementoUrl}&direction=${order}`;
                }
            }

            if (filtroVendaProjetoId?.value !== 0) {
                if (filtroVendaProjetoId?.value > 0) {
                    complementoUrl = `${complementoUrl}&venda_projeto_id=${filtroVendaProjetoId?.value}`;
                }
                else {
                    complementoUrl = `${complementoUrl}&venda_projeto_id=null`;
                }
            }

            if (filtroTipoProdutoId?.value !== 0) {
                if (filtroTipoProdutoId?.value > 0) {
                    complementoUrl = `${complementoUrl}&tipo_produto_id=${filtroTipoProdutoId?.value}`;
                }
            }

            if ((nomeProdutoFiltro)&&(nomeProdutoFiltro!=='')){
                complementoUrl = `${complementoUrl}&nomeproduto=${nomeProdutoFiltro}`;
            }

            const url = `${rotafechada()}venda_produtos?limit=${rowsPerPage}&venda_id=${vendaAtual}${complementoUrl}&servico_id=null`;
            const retorno = await api.get(encodeURI(url));
            const { data, countAll: countAllretorno } = retorno.data;
            const dadostemp = [];
            const colunasTabletemp = [
              
                {
                    id: 'venda_projeto_id',
                    numeric: false,
                    disablePadding: true,
                    label: 'Projeto'
                },
                {
                    id: 'tipo_produto_id',
                    numeric: false,
                    disablePadding: true,
                    label: 'Tipo Produto',
                    disableOrder:true
                },
                {
                    id: 'produto_id',
                    numeric: false,
                    disablePadding: true,
                    label: 'Produto'
                },
                {
                    id: 'referencia',
                    numeric: false,
                    disablePadding: true,
                    label: 'Referência',
                    disableOrder: true
                },
                {
                    id: 'nomeproduto',
                    numeric: false,
                    disablePadding: true,
                    label: 'Nome e Cor',
                    disableOrder:true
                },
                {
                    id: 'quantidade',
                    numeric: false,
                    disablePadding: true,
                    label: 'Qtd.'
                },
                {
                    id: 'valor_total',
                    numeric: false,
                    disablePadding: true,
                    label: 'Valor Total'
                },
                {
                    id: 'acoes',
                    numeric: false,
                    disablePadding: true,
                    label: 'Ações',
                    disableOrder: true
                },
                // {
                //     id: 'id',
                //     numeric: false,
                //     disablePadding: true,
                //     label: 'Id'
                // },
            ]

            data.forEach((item) => {
                //console.log('****', item)
                dadostemp.push({
                    id: item.id,
                    tipo_produto_id: item.Produto_Grade ?.Produto?.Tipos_Produto?.nome,
                    venda_projeto_id: item.venda_projeto_id ? `${item.Venda_Projeto?.id}-${item.Venda_Projeto?.Projeto?.nome}${item.Venda_Projeto?.ambiente_instalacao?`-${item.Venda_Projeto?.ambiente_instalacao}`:''}` : '<Avulso>',
                    produto_id: item.Produto_Grade?.produto_id||'',
                    referencia: item.Produto_Grade?.Produto?.referencia||'',
                    //nomeproduto: item.Produto_Grade ? `${truncarTexto(item.Produto_Grade.Produto?.nome, 10)} - ${item.Produto_Grade.Core?.nome}` : '', 
                    nomeproduto: item.Produto_Grade ? `${(item.Produto_Grade.Produto?.nome)} - ${item.Produto_Grade.Core?.nome}` : '', 
                    quantidade: formataFloat(`${item.quantidade}`,4,false),
                    valor_total: formataValor(item.valor_total),
                    acoes: <>
                        <MDButton size="medium" onClick={() => abrirRegistro(item.id)} iconOnly >
                            <Icon fontSize="medium" color="success">edit_icon</Icon>
                        </MDButton>
                        <MDButton size="medium" onClick={() => excluirRegistro(item.id)} iconOnly >
                            <Icon fontSize="medium" color="error">delete_forever</Icon>
                        </MDButton>
                    </>
                })
            })

            setColunasTable([...colunasTabletemp]);
            setLinhas([...dadostemp]);
            setCountAll(countAllretorno);
            FechaTelaEspera();
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const buscaTiposProdutos = async () => {
    
        let url = '';
        let retorno;
    
        try {
          url = `${rotafechada()}tipos_produtos?limit=-1`;
          retorno = await api.get(encodeURI(url));
          const { data } = retorno.data;
          
          let opcoesTipoProdutoTemp = [{ value: 0, label: 'Todos' }];
          data.map((item) => {
            opcoesTipoProdutoTemp.push({ value: item.id, label: item.nome })
          })
          setOpcoesTipoProduto(opcoesTipoProdutoTemp);
        }
        catch (error) {
          const msg = error.response?.data?.msg || error;
          MensagemErro(`Erro: ${msg}`);
        }
    }
    
    useEffect(() => {
        buscaTiposProdutos();
        carregaTabela();
    }, [recarregaTabela, page, rowsPerPage, order, orderBy, filtroVendaProjetoId,filtroTipoProdutoId])

    useEffect(()=>{
        setPage(0);
    },[filtroVendaProjetoId,filtroTipoProdutoId])


    return (
        <>


            <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpenCollapseFiltro(!openCollapseFiltro)}>
                        {openCollapseFiltro ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        <Typography variant='h6' mb={2}>Filtros</Typography>
                    </IconButton>

                    <Collapse in={openCollapseFiltro} timeout="auto" unmountOnExit>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <AutoCompleteStyled
                                    options={opcoesVendaProjetosFiltro}
                                    getOptionLabel={(option) => option.label || ''}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    renderInput={(params) => <FormField {...params} label='Projeto da Venda' />}
                                    disabled={false}

                                    value={filtroVendaProjetoId}
                                    onChange={(event, newValue) => {
                                        setFiltroVendaProjetoId(newValue);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormField fullWidth
                                    variant="standard"
                                    label='Nome do Produto'
                                    value={nomeProdutoFiltro}
                                    onChange={(e) => setNomeProdutoFiltro(e.target.value)}
                                    inputProps={{
                                        onKeyPress: (e) => {
                                            if (e.key === 'Enter') {
                                                setPage(0);
                                                e.preventDefault();
                                                carregaTabela();
                                            }
                                          },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <AutoCompleteStyled
                                    options={opcoesTipoProduto}
                                    getOptionLabel={(option) => option.label || ''}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    renderInput={(params) => <FormField {...params} label='Tipo de Produto' />}
                                    disabled={false}

                                    value={filtroTipoProdutoId}
                                    onChange={(event, newValue) => {
                                        setFiltroTipoProdutoId(newValue);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Collapse>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TabelaPadrao
                        colunas={colunasTable}
                        linhas={linhas}
                        countAll={countAll}
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        order={order}
                        setOrder={setOrder}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                    />
                </Grid>
            </Grid>
        </>
    )
}


export default TabelaVendaProdutos;