import * as React from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box, Typography } from '@mui/material';



export default function ItemFiltro(props) {
    const { value, label, handleClickDelete } = props;

    //console.log("Value vindo do filtro:",value)

    return (
        <Box>
            <Typography variant='button' >
                <span>Projeto: {value}</span><DeleteForeverIcon onClick={(e) => handleClickDelete(value)} />
            </Typography>
        </Box>
    );
}
