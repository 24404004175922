import MDBox from "components/MDBox";
import TermosDeUsoLayout from "./termosdeusolayout";
import bgImage from "assets/images/personalizados/headerlogin.png";
import { Grid, Typography } from "@mui/material";

function TermosDeUso() {

  return (
    <TermosDeUsoLayout image={bgImage}>
      <MDBox
        mx={2}
        mt={-3}
        p={3}
        mb={1}
      >
        <Grid container spacing={3}>

          <Grid item xs={10} sm={10}>

            <Typography variant='h3' mt={2} mb={2} >
              Aceite dos Termos de Uso
            </Typography>

            <Typography variant='body2'>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>Este Contrato de Licença de Usuário Final (“EULA”) é um acordo legal entre o licenciado (pessoa física ou jurídica) (o “LICENCIADO”) e a INVICTOS TECNOLOGIA LTDA, pessoa jurídica de direito privado, inscrita no CNPJ sob n. 11.758.339/0001-78, com sede na Rua Guanabara, nº 945, Vila São Francisco, CEP 79833-210, Dourados, Mato Grosso do Sul, Brasil, (a “LICENCIANTE”) para uso do programa de computador denominado “Projetos Online”, disponibilizado neste ato pela LICENCIANTE (o “SOFTWARE”) por meio do site “https://www.invictostecnologia.com.br” ou outro domínio personalizado  (“Site”), determinado pelo LICENCIADO no ato do licenciamento do SOFTWARE, compreendendo o programa de computador e podendo incluir os meios físicos associados, bem como quaisquer materiais impressos e qualquer documentação online ou eletrônica. Ao utilizar o SOFTWARE, mesmo que parcialmente ou a título de teste, o LICENCIADO estará vinculado aos termos deste EULA, concordando com suas disposições, principalmente com relação ao CONSENTIMENTO PARA o ACESSO, COLETA, USO, ARMAZENAMENTO, TRATAMENTO E TÉCNICAS DE PROTEÇÃO ÀS INFORMAÇÕES do LICENCIADO pela LICENCIANTE, necessárias para a integral execução das funcionalidades ofertadas pelo SOFTWARE. Em caso de discordância com os termos aqui apresentados, a utilização do SOFTWARE deve ser imediatamente interrompida pelo LICENCIADO e comunicada ao LICENCIANTE.</p>
            </Typography>

            <Typography variant='h6' mt={2} mb={2}>
              1. Da Propriedade Intelectual
            </Typography>


            <Typography variant='body2'>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>1.1.	O LICENCIADO não adquire, pelo presente instrumento ou pela utilização do SOFTWARE, nenhum direito de propriedade intelectual ou outros direitos exclusivos, incluindo patentes, desenhos, marcas, direitos autorais ou quaisquer direitos sobre informações confidenciais ou segredos de negócio, bem como todo o conteúdo disponibilizado no Site, incluindo, mas não se limitando a textos, gráficos, imagens, logotipos, ícones, fotografias, conteúdo editorial, notificações, softwares, banco de dados, projetos e materiais cadastrados e qualquer outro material, sobre ou relacionados ao SOFTWARE ou nenhuma parte dele. O LICENCIADO também não adquire nenhum direito sobre ou relacionado ao SOFTWARE ou qualquer componente dele, além dos direitos expressamente licenciados ao LICENCIADO sob o presente EULA ou em qualquer outro contrato mutuamente acordado por escrito entre o LICENCIADO e a LICENCIANTE. Quaisquer direitos não expressamente concedidos sob o presente instrumento são reservados.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>1.2.	Também será de propriedade exclusiva da LICENCIANTE, ou está devidamente licenciado, todo o conteúdo disponibilizado no site, incluindo, mas não se limitando a textos, gráficos, imagens, logos, ícones, fotografias, conteúdo editorial, notificações, softwares e qualquer outro material.</p>
            </Typography>

            <Typography variant='h6' mt={2} mb={2}>
              2. Declarações do LICENCIADO
            </Typography>

            <Typography variant='body2' >
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>2.1.	O LICENCIADO declara ter pleno conhecimento dos direitos e obrigações decorrentes do presente EULA, constituindo este instrumento o acordo completo entre as partes. Declara, ainda, ter lido, compreendido e aceito todos os seus termos e condições.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>2.2.	O LICENCIADO declara que leu, compreendeu e aceitou a política de privacidade, consentindo livre e expressamente às ações de coleta, uso, armazenamento e tratamento das referidas informações e dados.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>2.3.	O LICENCIADO declara estar ciente de que as operações que correspondam à aceitação do presente EULA, de determinadas condições e opções, bem como eventual rescisão do presente instrumento e demais alterações, serão registradas nos bancos de dados da LICENCIANTE, juntamente com a data e hora em que foram realizadas pelo LICENCIADO, podendo tais informações serem utilizadas como prova pelas partes, independentemente do cumprimento de qualquer outra formalidade.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>2.4.	O LICENCIADO declara que está ciente de que, em qualquer hipótese, deve atender rigorosamente a legislação, especialmente no que se refere às suas obrigações tributárias, fiscais, trabalhistas e previdenciárias, seja de natureza principal ou acessória, bem como cumprir a regulação de proteção de dados aplicável e quaisquer outras, entendendo que o Software objeto deste EULA trata-se de uma condição de meio e não de resultado, não dispensando, portanto, a correta alimentação das informações e parametrizações necessárias pelo LICENCIADO com base na legislação em vigor.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>2.5.	O LICENCIADO poderá acessar aplicativo de terceiros através de API’s de conexão com a finalidade de compartilhar suas Informações ou por meio de download sobre determinados Aplicativos Terceiros. O LICENCIADO compreende e concorda que tais Informações poderão ser atribuídas à sua conta no Aplicativo de Terceiros e poderão ser publicadas em tal serviço. Embora essas informações possam ser automaticamente compartilhadas por definição, o LICENCIADO poderá, a qualquer tempo, suspender o compartilhamento ou quando for o caso desinstalar tais Aplicativos Terceiros. O Aplicativo de Terceiros também poderá fornecer controles semelhantes, porém o acesso deverá ser feito diretamente através do Aplicativo de Terceiros, objetivando suspender tal acesso.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>2.6.	O LICENCIADO compreende e concorda que o uso de suas Informações por um Aplicativo de Terceiros coletadas junto ao mesmo (ou conforme autorizado pelo LICENCIADO) é regido pelas políticas de privacidade do Aplicativo de Terceiros e por suas configurações no respectivo serviço, e o uso de tais informações por parte do LICENCIADO é regido por este Termo de Uso e pelas configurações da sua conta no SOFTWARE da LICENCIANTE. A LICENCIANTE não será responsável, sob qualquer hipótese, pelo tratamento dado pelas informações por um Aplicativo Terceiro.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>2.7.	O LICENCIADO declara ainda que está ciente de que para usufruir de algumas das funcionalidades do SOFTWARE, em especial, dos serviços de integração com a rede bancária, assinatura do XML para envio de notas fiscais e captura automática de XML no SEFAZ, deverá disponibilizar à LICENCIANTE as INFORMAÇÕES DE CONTA para que o SOFTWARE, de maneira automatizada, colete informações diretamente nos sites e/ou outros meios virtuais disponibilizados pelas instituições financeiras, com as quais mantenha relacionamento, agindo a LICENCIANTE, neste caso, como representante e mandatária do LICENCIADO nestes atos.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>2.8.	Compartilhamos informações relacionadas aos nossos usuários com terceiros selecionados que nos fornecem uma variedade de serviços que suportam a entrega dos nossos serviços (chamaremos eles de “Processadores Terceirizados”). Esses Processadores Terceirizados variam de fornecedores de infraestrutura técnica a serviço de atendimento ao cliente e ferramentas de autenticação. Asseguramos que o gerenciamento de informações feito em nosso nome por Processadores Terceirizados será feito de acordo com termos contratuais, que requerem que essas informações sejam mantidas seguras, sejam processadas de acordo com as leis de proteção de dados e usadas somente conforme instruímos e não para os propósitos dos Processadores Terceirizados (a menos que tenham a sua autorização).</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>2.8.1.	Processadores Terceirizados podem estar alocados ou processar suas informações fora do país onde você está baseado. Nos casos em que o nosso uso de Processadores Terceirizados envolver a transferência de dados pessoais nós tomaremos as medidas necessárias para garantir que os dados pessoais sejam devidamente protegidos.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>2.8.2.	Os tipos de Processadores Terceirizados com os quais podemos compartilhar elementos dos seus dados pessoais incluem:</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>
                <l>
                  <li>a)	processadores de pagamento acionados por nós para armazenar ou gerenciar informações de pagamento com segurança, como detalhes de cartão de crédito ou débito.</li>
                  <li>b)	fornecedores de gerenciamento de e-mail e ferramentas de distribuição, por exemplo, se você assinar o recebimento de newsletters do da LICENCIANTE ou outras mensagens comerciais, gerenciaremos seu envio usando uma ferramenta terceirizada de distribuição de e-mail;</li>
                  <li>c)	fornecedores de serviços de segurança e prevenção de fraudes. Por exemplo, usamos esses fornecedores para identificar agentes de software automatizado que podem prejudicar nossos serviços ou para prevenir o uso indevido de nossas APIs;</li>
                  <li>d)	fornecedores de plataformas de software que nos ajudam na comunicação ou no fornecimento de serviços de atendimento ao cliente, por exemplo, gerenciamos e respondemos todas as mensagens enviadas por meio da nossa central de ajuda usando uma ferramenta terceirizada de gerenciamento de comunicações;</li>
                  <li>e)	fornecedores relacionados ao sistema bancário;</li>
                  <li>f)	fornecedores de serviços de armazenamento na nuvem online e outros serviços de TI essenciais;</li>
                </l>
              </p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>2.9.	Quando processamos seus dados pessoais podemos contar com sua autorização ou não, mas sempre que você optar por retirá-la somente com a sua autorização, você pode retirar essa autorização a qualquer momento usando a funcionalidade disponível no recurso respectivo do produto ou entrando em contato através do seguinte endereço eletrônico: contato@invictos.com.br. A partir do momento que você retira alguma autorização poderá ficar impedido de gerar automaticamente alguma atividade que era realizada de forma automatizada. Ao revogar seu consentimento, você não vai mais poder usar os serviços e recursos que exigem a coleta ou o uso das informações que coletávamos ou usávamos com base no consentimento.</p>
            </Typography>

            <Typography variant='h6' mt={2} mb={2}>
              3. Licença de Uso do Software
            </Typography>

            <Typography variant='body2'>
              3.1.	Sujeito aos termos e condições aqui estabelecidos, este EULA concede ao LICENCIADO uma licença revogável, não exclusiva e intransferível para uso do SOFTWARE. O LICENCIADO não poderá utilizar e nem permitir que o SOFTWARE seja utilizado para outra finalidade que não seja o processamento de suas informações ou de pessoas jurídicas indicadas pelo LICENCIADO no ato do cadastramento, exceto caso o LICENCIADO seja empresa de contabilidade que utiliza o SOFTWARE para processar informações de seus clientes, observados os limites estabelecidos neste EULA. Esta licença não implica a capacidade de acessar outros softwares além daqueles originalmente localizados no SOFTWARE. Em nenhuma hipótese o LICENCIADO terá acesso ao código fonte do SOFTWARE ora licenciado, por este se tratar de propriedade intelectual da LICENCIANTE.
            </Typography>

            <Typography variant='h6' mt={2} mb={2}>
              4. Das Restrições
            </Typography>

            <Typography variant='body2'>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>4.1.	Em hipótese alguma é permitido ao LICENCIADO ou a terceiros, de forma geral:</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>
                <l>
                  <li>Copiar, ceder, sublicenciar, vender, dar em locação ou em garantia, reproduzir, doar, alienar de qualquer forma, transferir total ou parcialmente, sob quaisquer modalidades, gratuita ou onerosamente, provisória ou permanentemente, o SOFTWARE objeto deste EULA, assim como seus módulos, partes, manuais ou quaisquer informações a ele relativas;</li>
                  <li>Retirar ou alterar, total ou parcialmente, os avisos de reserva de direito existente no SOFTWARE e na documentação;</li>
                  <li>Praticar engenharia reversa, descompilação ou desmontagem do SOFTWARE.</li>
                </l>
              </p>
            </Typography>

            <Typography variant='h6' mt={2} mb={2}>
              5. Do Prazo
            </Typography>

            <Typography variant='body2'>
              5.1.	O presente EULA entra em vigor na data de seu aceite pelo LICENCIADO e vigorará pelo prazo do Plano contratado pelo LICENCIADO. Este EULA será automaticamente renovado por iguais períodos caso o LICENCIADO não se manifeste expressamente em contrário, nos mesmos termos e condições, inclusive no que se refere à eventuais penalidades. Este EULA poderá ser rescindido conforme estabelecido abaixo neste instrumento.
            </Typography>

            <Typography variant='h6' mt={2} mb={2}>
              6. Da Remuneração e Forma de Pagamento
            </Typography>

            <Typography variant='body2'>

              <p style={{ marginBottom: 6, textAlign: 'justify' }}>6.1.	O LICENCIADO deverá pagar à LICENCIANTE o valor do plano de licenciamento escolhido e de acordo com a periodicidade definida entre as opções de pagamento disponibilizadas ao LICENCIADO no ato da contratação.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>6.2.	Caso o LICENCIADO, no decorrer da vigência do presente instrumento, opte por outro plano de licenciamento superior (upgrade), os valores serão alterados de acordo com o respectivo plano escolhido. Caso esta operação gere créditos eles serão utilizados nas próximas renovações, sendo vedada a devolução de valores já pagos que se transformaram em crédito por uma operação de alteração de plano ou de recorrência.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>6.3.	Se o LICENCIADO, no decorrer da vigência do presente instrumento, optar por um plano de licenciamento inferior (downgrade), a alteração de valores será efetuada na próxima renovação, sendo vedada a devolução de valores já pagos.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>6.4.	A falta de pagamento de quaisquer valores nas respectivas datas de vencimento não acarretará na rescisão automática do EULA, mas causará a suspensão do acesso do LICENCIADO ao SOFTWARE até que as pendências financeiras tenham sido regularizadas. O acesso ao SOFTWARE somente será restabelecido após a identificação pela LICENCIANTE do pagamento integral de todos os valores devidos enquanto o mesmo esteve suspenso. A identificação poderá ocorrer em até dois dias úteis após a data de pagamento pelo LICENCIADO. Caso o LICENCIADO não resolva a pendência financeira no prazo de 10 (dez) dias contados do vencimento do valor não pago, a LICENCIANTE se reserva o direito de rescindir o presente EULA.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>6.5.	Caso o LICENCIADO não resolva a pendência financeira no prazo de 90 (noventa) dias corridos, contados do vencimento do valor não pago, a LICENCIANTE se reserva ao direito de apagar de forma definitiva e irrecuperável todas as informações do LICENCIADO que por ventura estejam armazenadas no SOFTWARE, nos termos da Cláusula 7.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>6.6.	Os valores dos planos de licenciamento estabelecidos no ato do licenciamento do SOFTWARE poderão ser atualizados anualmente de acordo com a tabela de planos e preços vigente no momento do reajuste, caso isto ocorra o LICENCIADO será informado com no mínimo 7 dias de antecedência da renovação em que ocorrerá o reajuste de preços.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>6.7.	Eventualmente, poderão ocorrer alterações nos preços dos planos e adicionais contratados fora do período indicado anteriormente; caso isto ocorra, o LICENCIADO será informado com no mínimo 07 (sete) dias corridos de antecedência da sua próxima renovação. No momento do reajuste, o plano pertencente à assinatura terá seu preço reajustado, independente do momento de sua compra.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>6.8.	A LICENCIANTE poderá, a seu critério, por mera liberalidade, oferecer descontos nos valores dos planos de licenciamento. Neste caso, os descontos serão válidos por até 12 (doze) meses ou até o prazo indicado na oferta. A LICENCIANTE poderá renovar ou não os percentuais de desconto, podendo ainda cancelar os descontos após o período de validade, sem aviso prévio.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>6.9.	O LICENCIADO declara estar ciente de que em casos do plano escolhido ter sido cobrança recorrente no cartão de crédito, os dados do cartão serão armazenados pela plataforma de pagamento para ser possível essa cobrança e a LICENCIANTE não tem acesso algum a esses dados.</p>

            </Typography>

            <Typography variant='h6' mt={2} mb={2}>
              7. Restituição das Informações
            </Typography>

            <Typography variant='body2'>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>7.1.	Suspenso o acesso do LICENCIADO ao SOFTWARE, a LICENCIANTE manterá as informações do LICENCIADO armazenadas no SOFTWARE pelo período de 90 (noventa) dias, contados da suspensão de acesso. Durante este período, a LICENCIANTE tornará as informações do LICENCIADO disponíveis para serem extraídas do SOFTWARE em formato “.csv”.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>7.2.	Conforme descrito na cláusula 6 acima, passados 90 (noventa) dias da suspensão do acesso do LICENCIADO ao SOFTWARE, todas as INFORMAÇÕES do LICENCIADO, incluindo as INFORMAÇÕES PESSOAIS, INFORMAÇÕES DE CONTA, INFORMAÇÕES FINANCEIRAS, INFORMAÇÕES DE CADASTROS, VENDAS ou qualquer outra informação, em poder da LICENCIANTE serão excluídos permanentemente do banco de dados da LICENCIANTE, independentemente de terem sido extraídas ou não pelo LICENCIADO.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>7.3.	Não obstante o disposto acima, as informações referentes à data e hora de acesso e ao endereço de protocolo de internet utilizado pelo LICENCIADO para acessar o Site e o SOFTWARE permanecerão armazenadas pela LICENCIANTE por 6 (meses) a contar da data de cada acesso realizado, independentemente do término da relação jurídica e comercial entre a LICENCIANTE e o LICENCIADO, em cumprimento ao disposto no Artigo 15 da Lei nº 12.965/2014, podendo ser armazenados por um período maior de tempo mediante ordem judicial.</p>
            </Typography>

            <Typography variant='h6' mt={2} mb={2}>
              8. Das Obrigações do Licenciado
            </Typography>

            <Typography variant='body2'>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>8.1 Obriga-se o LICENCIADO a:</p>

              <p style={{ marginBottom: 6, textAlign: 'justify' }}>
                <l>

                  <li>a)  Manter pessoal treinado para a operação do SOFTWARE e para a comunicação com a LICENCIANTE e prover, sempre que ocorrerem quaisquer problemas com o SOFTWARE, toda a documentação, relatórios e demais informações que relatem as circunstâncias em que os problemas ocorreram, objetivando facilitar e agilizar os trabalhos;</li>
                  <li>b)	Manter, às suas expensas, linha de telecomunicação, modem, software de comunicação, endereço de correio eletrônico e outros recursos necessários à comunicação com a LICENCIANTE;</li>
                  <li>c)	Responder pelas INFORMAÇÕES e dados inseridas no SOFTWARE, pelo cadastramento, permissões, senhas e modo de utilização de seus usuários, incluindo, mas não se limitando, às informações fornecidas no que diz respeito aos métodos de pagamento (boletos), números de contas bancárias e a informações financeiras disponibilizadas às autoridades fiscais por meio do SOFTWARE no que diz respeito à emissão de notas fiscais ou quanto ao cumprimento das obrigações acessórias disponibilizadas através do SOFTWARE. A LICENCIANTE em hipótese alguma será responsável pelo conteúdo (INFORMAÇÕES, senhas, cópias de informações, etc.) incluído no SOFTWARE, não sendo, portanto, estas INFORMAÇÕES revisadas em momento algum. A responsabilidade pelas INFORMAÇÕES inseridas ou excluídas no SOFTWARE é sempre do LICENCIADO, sendo este o único responsável pela realização de backup das informações, especialmente antes da exclusão. A LICENCIANTE não será responsável pelo armazenamento de informações excluídas pelo LICENCIADO;</li>
                  <li>d)	Certificar-se de que não está proibido por determinação legal e/ou contratual de passar INFORMAÇÕES, INFORMAÇÕES FINANCEIRAS, INFORMAÇÕES DE CONTA e INFORMAÇÕES PESSOAIS, bem como quaisquer outros dados à LICENCIANTE, necessários para a execução do serviço oferecido por este EULA;</li>
                  <li>e)	Garantir a regularidade dos dados pessoais inseridos no SOFTWARE, em especial, no que tange ao cumprimento de bases legais e direitos dos titulares de dados;</li>
                  <li>f)	A qualquer tempo a LICENCIANTE poderá bloquear acesso ao SOFTWARE caso constate qualquer prática pelo LICENCIADO ou terceiros de violação ao presente Termo de uso e/ou qualquer tentativa de fraude ou dê a entender tratar-se de uma tentativa, não reduzindo essa ação a responsabilidade do LICENCIADO sobre seus atos.</li>
                  <li>g)	Não utilizar o SOFTWARE de qualquer forma que possa implicar em ato ilícito, infração, violação de direitos ou danos à LICENCIANTE ou terceiros, incluindo, mas não se limitando ao uso para invasão de dispositivo informático com o objetivo de obter, adulterar ou destruir dados ou informações sem a autorização expressa do titular de tais dados ou do dispositivo ou servidor nos quais estes estejam armazenados;</li>
                  <li>h)	Não publicar, enviar ou transmitir qualquer arquivo que contenha vírus, worms, cavalos de tróia ou qualquer outro programa que possa contaminar, destruir ou interferir no bom funcionamento do SOFTWARE;</li>
                  <li>i)	Informar a LICENCIANTE sempre que houver qualquer alteração das INFORMAÇÕES fornecidas à LICENCIANTE e que possam impedir, limitar ou prejudicar o acesso da LICENCIANTE às INFORMAÇÕES necessárias para a execução das funcionalidades ofertadas pelo SOFTWARE;</li>
                  <li>j)	Atender rigorosamente a legislação brasileira e toda obrigação legal imposta e/ou decorrente de sua atividade e em razão da utilização deste SOFTWARE.</li>
                </l>
              </p>

              <p style={{ marginBottom: 6, textAlign: 'justify' }}>8.2.	Caso o LICENCIADO acredite que seu login e senha de acesso ao SOFTWARE tenham sido roubados ou sejam de conhecimento de outras pessoas, por qualquer razão, o LICENCIADO deverá imediatamente comunicar tal fato à LICENCIANTE, sem prejuízo da alteração da sua senha imediatamente, por meio do SOFTWARE.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>8.3.	Quando o LICENCIADO se tratar de empresa de contabilidade, deverá indicar no ato do cadastramento os usuários autorizados a acessar a base cadastrada, assim como indicar o responsável financeiro pelo pagamento.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>8.4.	Para a utilização dos recursos oferecidos pelo SOFTWARE, no que diz respeito a emissão, inutilização, correção, contingência, cancelamento de documentos fiscais, detalhamento das regiões atendidas, versões do SOFTWARE, uso offline, backup das informações implementadas, entre outros, o LICENCIADO deve verificar e se manter informado, através do site invictostecnologia.com.br ou de nossa central de atendimento. Uma vez aceito os termos deste EULA, isso garante também a aceitação das especificações do produto conforme definido em nosso site.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>8.5.	Obriga-se o LICENCIADO ainda, em conformidade com a cláusula 11.4 deste Termo, a responder pelas INFORMAÇÕES inseridas no SOFTWARE para utilização das funcionalidades de configuração tributária e Automatização de Impostos, cabendo somente ao LICENCIADO a inserção correta de suas próprias informações e a revisão destas informações com um profissional de contabilidade. É de obrigação do LICENCIADO, ainda, a conferência das informações para garantia de tributação de alíquotas aplicáveis e adequação à legislação tributária vigente.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>8.6.	Em caso de utilização do SOFTWARE pelo CONTADOR do LICENCIADO, mediante autorização do LICENCIADO, o CONTADOR deverá assumir a responsabilidade pela conferência das informações inseridas no SOFTWARE, inclusive as informações detalhadas na cláusula 8.1, sendo responsável pela conferência das alíquotas aplicáveis e adequação à legislação tributária vigente.</p>
            </Typography>

            <Typography variant='h6' mt={2} mb={2}>
              9. Das Obrigações do Licenciante
            </Typography>

            <Typography variant='body2'>

              <p style={{ marginBottom: 6, textAlign: 'justify' }}>9.1.	Obriga-se a LICENCIANTE a:</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>a)	Garantir ao LICENCIADO que o SOFTWARE deverá funcionar regularmente, se respeitadas as condições de uso definidas na documentação. Na ocorrência de falhas de programação (“bugs”), a LICENCIANTE obrigar-se-á a corrigir tais falhas, podendo a seu critério substituir a cópia dos programas com falhas por cópias corrigidas;</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>b)	Manter atualizada as funções existentes no SOFTWARE com relação às variáveis normalmente alteradas pela legislação federal. A interpretação legal das normas editadas pelo governo será efetuada com base no entendimento majoritário dos “usuários” do SOFTWARE, doutrinadores e jurisprudência pátria. Ficam excluídas da manutenção as alterações no SOFTWARE originárias de obrigações assumidas pelo LICENCIADO junto a sindicatos ou associações, tais como convenções coletivas de trabalho e outras, bem como necessidades específicas de certos segmentos, além do atendimento de legislações municipais e/ou estaduais.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>c)	Fornecer, ato contínuo ao aceite deste EULA, acesso ao SOFTWARE durante a vigência deste EULA;</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>d)	Suspender o acesso ao SOFTWARE do LICENCIADO que esteja desrespeitando as regras de conteúdo aqui estabelecidas ou as normas legais em vigor;</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>e)	Alterar as especificações e/ou características do SOFTWARE licenciados para a melhoria e/ou correções de erros, de acordo com o plano de produtos definido pela LICENCIANTE;</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>f)	Disponibilizar de forma gratuita aos nossos clientes os seguintes canais de suporte:</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>g) Chat: com funcionamento de segunda a sexta-feira, das 08h00 às 19h (horário de Brasília) – sem intervalo para o almoço. Para acesso, basta logar na sua Conta e acessar o ícone do chat no canto inferior do SOFTWARE;</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>h) Central de ajuda: disponível 24h por dia e todos os dias da semana. Você terá acesso a todos os materiais para orientar o uso do ERP, disponível um link no site https://www.invictostecnologia.com.br/</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>i)	Manter as INFORMAÇÕES FINANCEIRAS, INFORMAÇÕES DE CONTA e INFORMAÇÕES PESSOAIS do LICENCIADO, bem como seus registros de acesso, em sigilo, sendo que as referidas INFORMAÇÕES serão armazenadas em ambiente seguro, sendo respeitadas a intimidade, a vida privada, a honra e a imagem do LICENCIADO, em conformidade com as disposições da Lei nº 12.965/2014. A LICENCIANTE não será responsável pelo armazenamento de informações excluídas pelo LICENCIADO.</p>

              <p style={{ marginBottom: 6, textAlign: 'justify' }}>9.2. A LICENCIANTE também oferece canais pagos de suporte:</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>a) Telefone: Você poderá ter acesso ao suporte via telefone se contratar o nosso suporte telefônico pago. Este canal funciona de segunda a sexta-feira, das 08h00 às 19h (horário de Brasília). Caso o licenciado não contrate o adicional de suporte telefônico pago, será garantido total acesso ao canal de chat e central de ajuda de forma gratuita.</p>

            </Typography>

            <Typography variant='h6' mt={2} mb={2}>
              10. Nível de Serviço
            </Typography>

            <Typography variant='body2'>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>10.1.	A LICENCIANTE empreenderá esforços comercialmente razoáveis para tornar o SOFTWARE disponível o maior tempo possível.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>10.2.	O Compromisso de Nível de Serviço não se aplica caso as circunstâncias de indisponibilidade resultem (i) de uma interrupção do fornecimento de energia elétrica ou paradas emergenciais não superiores a 2 (duas) horas ou que ocorram no período das 24h às 6:00h (horário de Brasília); (ii) forem causadas por fatores que fujam ao razoável controle da LICENCIANTE, inclusive casos de força maior ou de acesso à Internet e problemas correlatos; (iii) resultem de quaisquer atos ou omissões do LICENCIADO, de terceiros ou de aplicativos terceiros; (iv) resultem do equipamento, software ou outras tecnologias que o LICENCIADO usar que impeçam o acesso regular do SOFTWARE; (v) resultem de falhas de instâncias individuais não atribuíveis à indisponibilidade do LICENCIADO; (vi) resultem de alterações realizadas na forma de acesso a INFORMAÇÕES FINANCEIRAS e/ou INFORMAÇÕES DE CONTA do LICENCIADO pelas instituições financeiras; (vii) resultem de práticas de gerenciamento da rede que possam afetar sua qualidade; (viii) em momentos que o SOFTWARE estiver em período denominado JANELA DE MANUTENÇÃO para instalação de novas versões ou manutenções necessárias, que sempre que possível será avisado com antecedência através do sistema de mensagens do próprio SOFTWARE ou através de email.</p>
            </Typography>

            <Typography variant='h6' mt={2} mb={2}>
              11. Isenção de Responsabilidade da Licenciante
            </Typography>

            <Typography variant='body2'>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>11.1.	A LICENCIANTE não se responsabiliza pelas seguintes ocorrências:</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>a)	Por falha de operação, operação por pessoas não autorizadas ou qualquer outra causa em que não exista culpa da LICENCIANTE;</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>b)	Pelo cumprimento dos prazos legais do LICENCIADO para a entrega de documentos fiscais ou pagamentos de impostos;</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>c)	Pelos danos ou prejuízos decorrentes de decisões administrativas, gerenciais ou comerciais tomadas com base nas informações fornecidas pelo SOFTWARE;</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>d)	Por problemas definidos como “caso fortuito” ou “força maior”, contemplados pelo Art. 393 do Código Civil Brasileiro;</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>e)	Por eventuais problemas oriundos de ações de terceiros que possam interferir na qualidade do serviço;</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>f)	Por danos causados a terceiros em razão de conteúdo gerado pelo LICENCIANTE através de SOFTWARE;</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>g)	Pela indisponibilidade ou lentidão de aplicativos terceiros que se conectam com o SOFTWARE por meio de API ou ainda, de fornecedores de telecomunicação do LICENCIADO;</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>h)	Por revisar as INFORMAÇÕES DE CONTA fornecidas pelo LICENCIADO, bem como as INFORMAÇÕES FINANCEIRAS obtidas junto aos sites e/ou meios virtuais das instituições financeiras, seja no que tange à precisão dos dados, seja quanto à legalidade ou ameaça de violação em função do fornecimento destas informações;</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>i)	Por quaisquer produtos e/ou serviços oferecidos por meio dos sites e/ou meios virtuais das instituições financeiras, ainda que de maneira solidária ou subsidiária;</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>j)	Por eventuais infrações legais cometidas pelo LICENCIADO, de ordem fiscal, tributária, trabalhista, previdenciária, criminal, ou qualquer outra.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>k)	Pela geração e envio de obrigações fiscais acessórias, cabendo este procedimento somente ao LICENCIADO.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>11.2.	O acesso às INFORMAÇÕES DE CONTA e às INFORMAÇÕES FINANCEIRAS do LICENCIADO dependem de serviços prestados pelas instituições financeiras. Sendo assim, a LICENCIANTE não assume qualquer responsabilidade quanto à qualidade, precisão, pontualidade, entrega ou falha na obtenção das referidas INFORMAÇÕES junto aos sites e/ou meios virtuais das instituições financeiras.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>11.3.	A LICENCIANTE adota as medidas de segurança adequadas de acordo com os padrões de mercado para a proteção das INFORMAÇÕES do LICENCIADO armazenadas no SOFTWARE, assim como para o acesso às INFORMAÇÕES FINANCEIRAS do LICENCIADO. Contudo, o LICENCIADO reconhece que nenhum sistema, servidor ou software está absolutamente imune a ataques e/ou invasões de hackers e outros agentes maliciosos, não sendo a LICENCIANTE responsável por qualquer exclusão, obtenção, utilização ou divulgação não autorizada de INFORMAÇÕES resultantes de ataques que a LICENCIANTE não poderia razoavelmente evitar por meio dos referidos padrões de segurança.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>11.4.	Conforme o uso do SOFTWARE pelo LICENCIADO, este pode sugerir naturezas de operações ou os impostos de acordo com o histórico de uso, especialmente na utilização das funcionalidades de configuração tributária e Automatização de Impostos. Em conformidade com a cláusula 8.5., a LICENCIANTE não se responsabiliza pelo aceite e pelo preenchimento incorreto dessas informações, cabendo somente ao LICENCIADO a checagem correta de suas próprias informações inseridas, sendo necessário ao LICENCIADO sempre revisar as regras de automatização de impostos e configuração tributária com um profissional de contabilidade, considerando que o SOFTWARE oferece sugestões com base em histórico de regras de tributação conforme legislação, sendo responsabilidade exclusiva do LICENCIADO a conferência das informações de suas notas fiscais e se houve alteração de alíquotas conforme legislação tributária vigente. A LICENCIANTE ressalta que o LICENCIADO poderá revisar e atualizar as regras de configuração tributária e Automatização de Impostos a qualquer tempo para se adequar à legislação vigente. Caso as informações sejam inseridas pelo CONTADOR do LICENCIADO, mediante autorização do LICENCIADO, o CONTADOR será responsável pela veracidade das informações bem como pela conferência das alíquotas aplicáveis e adequação à legislação vigente, nos termos da cláusula 8.5</p>
            </Typography>


            <Typography variant='h6' mt={2} mb={2}>
              12. Da Retomada dos Softwares
            </Typography>

            <Typography variant='body2'>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>12.1.	A LICENCIANTE se reserva o direito de cancelar imediatamente o acesso do LICENCIADO ao SOFTWARE nos casos em que o LICENCIADO usar o SOFTWARE de forma diversa daquela estipulada no presente instrumento</p>
            </Typography>

            <Typography variant='h6' mt={2} mb={2}>
              13. Das Garantias Limitadas
            </Typography>

            <Typography variant='body2'>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>13.1.	Na extensão máxima permitida pela lei em vigor, o SOFTWARE é fornecido “no estado em que se encontra” e “conforme a disponibilidade”, com todas as falhas e sem garantia de qualquer espécie.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>13.2.	Não obstante o disposto no item 9.a acima, a LICENCIANTE não garante que as funções contidas no SOFTWARE atendam às suas necessidades, que a operação do SOFTWARE será ininterrupta ou livre de erros, que qualquer serviço continuará disponível, que os defeitos no SOFTWARE serão corrigidos ou que o SOFTWARE será compatível ou funcione com qualquer SOFTWARE, aplicações ou serviços de terceiros.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>13.3.	Além disso, o LICENCIADO reconhece que o SOFTWARE não deve ser utilizado ou não é adequado para ser utilizado em situações ou ambientes nos quais a falha ou atrasos, os erros ou inexatidões de conteúdo, dados ou informações fornecidas pelo SOFTWARE possam levar à morte, danos pessoais, ou danos físicos ou ambientais graves, incluindo, mas não se limitando, à operação de instalações nucleares, sistemas de navegação ou de comunicação aérea, controle de tráfego aéreo, sistemas de suporte vital ou de armas.</p>
            </Typography>

            <Typography variant='h6' mt={2} mb={2}>
              14. Limitação de Responsabilidade
            </Typography>

            <Typography variant='body2'>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>14.1.	Em nenhum caso a LICENCIANTE será responsável por danos pessoais ou qualquer prejuízo incidental, especial, indireto ou consequente, incluindo, sem limitação, prejuízos por perda de lucro, corrupção ou perda de dados, falha de transmissão ou recepção de dados, não continuidade do negócio ou qualquer outro prejuízo ou perda comercial, decorrentes ou relacionados ao seu uso ou sua inabilidade em usar o SOFTWARE, por qualquer outro motivo. Sob nenhuma circunstância a responsabilidade integral da LICENCIANTE com relação ao LICENCIADO por todos os danos excederá a quantia correspondente ao último plano de licenciamento pago pelo LICENCIADO à LICENCIANTE pela obtenção da presente licença de SOFTWARE.</p>
            </Typography>

            <Typography variant='h6' mt={2} mb={2}>
              15. Consentimento livre, expresso e informado para acesso a informações confidenciais e dados pessoais
            </Typography>

            <Typography variant='body2'>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>15.1.	O LICENCIADO, ao aceitar utilizar o SOFTWARE, além de aceitar integralmente este “EULA”, também consente, livre e expressamente, que a LICENCIANTE colete, use, armazene e faça o tratamento de suas INFORMAÇÕES, incluindo seus dados pessoais, financeiros, bancários, de conta, os quais serão necessários para que o serviço ofertado seja prestado em sua integralidade.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>15.1.1.	Para tanto, o LICENCIADO consente, livre e expressamente, em fornecer os dados que permitam o acesso às INFORMAÇÕES necessárias para que o SOFTWARE execute todas as funções para as quais foi projetado.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>15.2.	O LICENCIADO declara e reconhece que para a prestação dos serviços e funcionalidades de integração a rede bancária ofertadas pelo SOFTWARE, a LICENCIANTE acessa INFORMAÇÕES FINANCEIRAS diretamente no site e/ou outros meios virtuais das instituições financeiras, sem fazer qualquer emulação de medidas de segurança, utilizando-se apenas das INFORMAÇÕES PESSOAIS, INFORMAÇÕES DE CONTA, bem como outras eventualmente necessárias, fornecidas pelo LICENCIADO, conforme autorizado.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>15.3.	O LICENCIADO, por meio deste EULA e fornecendo as INFORMAÇÕES DE CONTA, autoriza e consente expressamente que a LICENCIANTE acesse suas INFORMAÇÕES DE CONTA e INFORMAÇÕES FINANCEIRAS na qualidade de mandatária.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>15.4.	A LICENCIANTE declara que todas as INFORMAÇÕES DE CONTA serão utilizadas única e exclusivamente para a visualização das informações financeiras nos sites e/ou meios virtuais das instituições financeiras, sendo absolutamente vedada a LICENCIANTE a realização de quaisquer transações.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>15.5.	O LICENCIADO consente que, ao acessar o site da LICENCIANTE, esta poderá coletar informações técnicas de navegação, tais como tipo de navegador do computador utilizado para acesso ao site, endereço de protocolo de Internet, páginas visitadas e tempo médio gasto no site. Tais informações poderão ser usadas para orientar o próprio LICENCIADO e melhorar os serviços ofertados.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>15.6.	O LICENCIADO consente livre e expressamente que suas INFORMAÇÕES poderão ser transferidas a terceiros em decorrência da venda, aquisição, fusão, reorganização societária ou qualquer outra mudança no controle da LICENCIANTE. A LICENCIANTE, contudo, compromete-se, nestes casos, a informar o LICENCIADO.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>15.7.	O LICENCIADO consente livre e expressamente que a LICENCIANTE utilize cookies apenas para controlar a audiência e a navegação em seu site e possibilitar a identificação de serviços segmentados e personalizados ao perfil do LICENCIADO. A LICENCIANTE garante que estas informações coletadas por meio de cookies são estatísticas e não pessoais, bem como que não serão utilizadas para propósitos diversos dos expressamente previstos neste EULA, comprometendo-se a adotar todas as medidas necessárias a fim de evitar o acesso e o uso de tais informações por quaisquer terceiros, sem a devida autorização.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>15.8.	Válido somente para LICENCIADOS conectados a um contador:</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>15.8.1.	Com o intuito de facilitar a comunicação e o tráfego de informações entre o LICENCIADO e seu contador, diminuir entraves burocráticos e margens de erro na entrega das informações contábeis, a plataforma da LICENCIANTE permite a liberação pelo LICENCIADO de acesso direto ao seu sistema financeiro para seu contador, permite que sejam exportadas informações dos lançamentos financeiros para integrar com o sistema contábil que utiliza. A conexão acontece com o consentimento do LICENCIADO, quando este informa o e-mail do contador e envia a ele um convite para o acesso ao seu sistema.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>15.9.	Válido somente para LICENCIADOS indicados por INSTITUIÇÃO BANCÁRIA</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>15.9.1.	O LICENCIADO que realizar a contratação do SOFTWARE através da indicação de uma INSTITUIÇÃO BANCÁRIA consente livre e expressamente que suas INFORMAÇÕES sejam compartilhadas com a INSTITUIÇÃO BANCÁRIA que efetuou a indicação do sistema ao LICENCIADO. Este compartilhamento poderá ser cessado a qualquer momento, para isso o LICENCIADO deverá entrar em contato com o nosso suporte pelo chat que fica no canto inferior direito. O LICENCIADO que optar por não ceder acesso às suas informações originárias do uso do SOFTWARE precisará informar à LICENCIANTE e à INSTITUIÇÃO BANCÁRIA em requisições específicas para cada uma.</p>
            </Typography>

            <Typography variant='h6' mt={2} mb={2}>
              16.	Da proteção de dados e Conformidade com a Lei Geral de Proteção de Dados
            </Typography>
            <Typography variant='body2'>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>16.1.	Compromissos da LICENCIANTE:</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>a)	A LICENCIANTE se compromete a tratar os dados pessoais dos usuários do SOFTWARE com a finalidade específica de execução das funcionalidades oferecidas pelo SOFTWARE, respeitando os princípios de boa-fé, finalidade, adequação, necessidade, livre acesso, qualidade dos dados, transparência, segurança, prevenção, não discriminação e responsabilização e prestação de contas.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>b)	A LICENCIANTE implementará medidas técnicas e organizacionais adequadas para proteger os dados pessoais contra perda, alteração, acesso não autorizado ou outras formas de tratamento inadequado ou ilícito.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>c)	A LICENCIANTE garante que todo o processamento de dados pessoais será feito de forma a garantir a confidencialidade, integridade e disponibilidade dos dados.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>d)	Tratar os dados conforme sua política de privacidade , atualizando o LICENCIADO sempre que houver alteração desta.</p>

              <p style={{ marginBottom: 6, textAlign: 'justify' }}>16.2.	Compromissos do LICENCIADO:</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>a)	Acessar a política de privacidade do LICENCIANTE para ter conhecimento prévio de como todos os dados tidos como pessoais (dados referentes à pessoas naturais) serão tratados.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>b)	O LICENCIADO se compromete a utilizar o SOFTWARE de maneira a respeitar a legislação de proteção de dados aplicável, especialmente quanto à coleta, armazenamento e uso de dados pessoais.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>c)	O LICENCIADO garantirá que os dados pessoais fornecidos à LICENCIANTE tenham sido coletados de maneira lícita e com o devido consentimento dos titulares dos dados, quando aplicável.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>d)	O LICENCIADO deverá informar a LICENCIANTE sobre qualquer solicitação de titulares de dados relacionada a seus direitos previstos na LGPD, bem como colaborar para o atendimento adequado dessas solicitações.</p>

              <p style={{ marginBottom: 6, textAlign: 'justify' }}>16.3.	Qualquer transferência de dados pessoais a terceiros só ocorrerá com a prévia comunicação e consentimento do LICENCIADO, respeitadas as disposições da LGPD.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>16.4.	Em caso de violação de dados pessoais que possa acarretar risco ou dano relevante aos titulares, a LICENCIANTE se compromete a comunicar o LICENCIADO e as autoridades competentes em conformidade com a legislação aplicável, em um prazo razoável.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>16.5.	As partes se comprometem a cooperar com as autoridades de proteção de dados e permitir auditorias, incluindo inspeções, realizadas pela autoridade de proteção de dados ou outro auditor autorizado pelo LICENCIADO.</p>
            </Typography>

            <Typography variant='h6' mt={2} mb={2}>
              17. Da Rescisão
            </Typography>

            <Typography variant='body2'>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>17.1.	O LICENCIADO poderá solicitar devolução total dos valores pagos em até 7 (sete) dias após a data de contratação ou conforme a adesão de campanhas promocionais. Após este prazo de 7 dias, não haverá devolução dos valores pagos e o LICENCIADO poderá solicitar somente o cancelamento da renovação automática, desde que comunique à LICENCIANTE, por escrito e mediante função específica da plataforma, com antecedência mínima de 15 (quinze) dias da próxima renovação, devendo pagar o saldo devedor do plano de licenciamento contratado, se existente. Para os planos de licenciamento com pagamento antecipado, caso o LICENCIADO decida rescindir este EULA antes do término do prazo contratado, o LICENCIANTE não restituirá ao LICENCIADO o saldo restante do plano de licenciamento contratado (exceto em campanhas promocionais que prevejam a devolução dos valores pagos). Este valor será retido pela LICENCIANTE para cobrir os custos operacionais. Aplicam-se as mesmas regras para a contratação do Suporte Premium e demais adicionais.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>17.2.	A LICENCIANTE poderá rescindir este EULA a qualquer tempo, desde que comunique ao LICENCIADO, com antecedência mínima de 15 (quinze) dias, devendo neste caso restituir ao LICENCIADO o saldo devedor do plano de licenciamento contratado, se existente. Este prazo de antecedência mínima e a obrigação de restituição acima não se aplicarão nos casos previstos na cláusula 12 acima e nos casos de violação do presente instrumento.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>17.3.	A LICENCIANTE poderá rescindir o EULA a qualquer momento em caso de violação pelo LICENCIADO dos termos e condições ora acordados, ou em caso de atraso de pagamento não sanado no prazo de 90 (noventa) dias, conforme cláusula 6 acima.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>17.4.	No caso de rescisão do presente contrato, os dados pessoais, bancários, financeiros e demais informações do LICENCIADO ficarão disponíveis conforme cláusula 7 deste termo, sendo excluídos permanentemente após 90 (noventa) dias da data da rescisão.</p>
            </Typography>

            <Typography variant='h6' mt={2} mb={2}>
              18. Das disposições Legais
            </Typography>

            <Typography variant='body2'>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>18.1.	O LICENCIADO não poderá prestar serviços a terceiros utilizando o SOFTWARE da LICENCIANTE sem autorização prévia e expressa da LICENCIANTE. A autorização de uso do SOFTWARE é fornecida por CNPJ. Desta forma, o SOFTWARE não pode operar sob o regime de multiempresa, necessitando para cada CNPJ uma licença específica;</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>18.2.	Caso o LICENCIADO venha a desenvolver um novo módulo ou produto que caracterize cópia, de todo ou em parte, quer seja do dicionário de dados quer seja do programa, será considerado como parte do SOFTWARE fornecido pela LICENCIANTE, ficando, portanto, sua propriedade incorporada pela LICENCIANTE e seu uso condicionado a estas cláusulas contratuais;</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>18.3.	Este EULA obriga as partes e seus sucessores e somente o LICENCIADO possui licença não exclusiva para a utilização do SOFTWARE, sendo-lhe, entretanto, vedado transferir os direitos e obrigações acordados por este instrumento. Tal limitação, no entanto, não atinge a LICENCIANTE, que poderá, a qualquer tempo, ceder, no todo ou em parte, os direitos e obrigações inerentes ao presente EULA;</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>18.4.	A tolerância de uma parte para com a outra quanto ao descumprimento de qualquer uma das obrigações assumidas neste instrumento não implicará em novação ou renúncia de direito. A parte tolerante poderá, a qualquer tempo, exigir da outra parte o fiel e cabal cumprimento deste instrumento;</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>18.5.	Não constitui causa de rescisão contratual o não cumprimento das obrigações aqui assumidas em decorrência de fatos que independam da vontade das partes, tais como os que configuram o caso fortuito ou força maior, conforme previsto no artigo 393 do Código Civil Brasileiro;</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>18.6.	Se qualquer disposição deste EULA for considerada nula, anulável, inválida ou inoperante, nenhuma outra disposição deste EULA será afetada como consequência disso e, portanto, as disposições restantes deste EULA permanecerão em pleno vigor e efeito como se tal disposição nula, anulável, inválida ou inoperante não estivesse contida neste EULA;</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>18.7.	O LICENCIADO concorda que a LICENCIANTE possa divulgar a celebração deste instrumento para fins comerciais, fazendo menção ao nome e à marca do LICENCIADO em campanhas comerciais, podendo, inclusive, divulgar mensagens enviadas de forma escrita ou oral, por telefone, para uso em sites, jornais, revistas e outras campanhas, enquanto vigorar o presente EULA. O LICENCIADO aceita, ainda, receber comunicações via correio eletrônico sobre treinamentos, parcerias e campanhas relacionadas ao SOFTWARE;</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>18.8.	Neste ato, o LICENCIANTE expressamente autoriza o LICENCIADO a colher e utilizar seus dados técnicos e operacionais presentes no SOFTWARE, para fins de estudos e melhorias no SOFTWARE;</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>18.9.	A LICENCIANTE poderá, a seu exclusivo critério, a qualquer tempo e sem a necessidade de comunicação prévia ao LICENCIADO:</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>a)	Encerrar, modificar ou suspender, total ou parcialmente, o acesso do LICENCIADO ao SOFTWARE, quando referido acesso ou cadastro estiver em violação das condições estabelecidas neste EULA;</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>b)	Excluir, total ou parcialmente, as informações cadastradas pelo LICENCIADO que não estejam em consonância com as disposições deste EULA;</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>c)	Acrescentar, excluir ou modificar o Conteúdo oferecido no site;</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>d)	Alterar quaisquer termos e condições deste EULA mediante simples comunicação ao LICENCIADO.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>18.10.	A LICENCIANTE ainda poderá, a seu exclusivo critério, suspender, modificar ou encerrar as atividades do SOFTWARE, mediante comunicação prévia ao LICENCIADO, com antecedência mínima de 15 (quinze) dias, disponibilizando formas e alternativas de extrair do Site as informações, salvo nas hipóteses de caso fortuito ou força maior.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>18.11.	A LICENCIANTE poderá, por meio de comunicação ao endereço eletrônico indicado pelo LICENCIADO em seu cadastro ou por meio de aviso no Site, definir preços para a oferta de determinados conteúdos e/ou serviços, ainda que inicialmente tais serviços tenham sido ofertados de forma gratuita, sendo a utilização destes, após o referido aviso, considerada como concordância do LICENCIADO com a cobrança de tais preços.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>18.12.	Fica certo e entendido pelo LICENCIADO que somente a pessoa cadastrada pelo próprio LICENCIADO como administradora de conta poderá solicitar que as informações do LICENCIADO inseridas no Software sejam apagadas. Ainda, o LICENCIADO declara sua ciência de que uma vez apagadas, estas não poderão mais ser recuperadas, ficando a LICENCIANTE isenta de qualquer responsabilidade por quaisquer perdas ou danos decorrentes deste procedimento solicitado pelo LICENCIADO.</p>
            </Typography>

            <Typography variant='h6' mt={2} mb={2}>
            19.	Da Lei Aplicável e Foro
            </Typography>

            <Typography variant='body2'>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>19.1.	Este EULA será regido, interpretado e se sujeitará às leis brasileiras e, o LICENCIADO e a LICENCIANTE desde logo elegem, de forma irrevogável e irretratável, o foro da Comarca da Cidade de Dourados, Estado do Mato Grosso do Sul, para dirimir quaisquer dúvidas ou controvérsias oriundas deste EULA, com a exclusão de qualquer outro, por mais privilegiado que seja.</p>
            </Typography>

            <Typography variant='h6' mt={2} mb={2}>
              20. Das definições
            </Typography>

            <Typography variant='body2'>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>20.1.	Os termos utilizados neste instrumento deverão ser interpretados e usados conforme definições abaixo, seja no singular ou plural:</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>20.2.	INFORMAÇÕES DE CONTA: informações e dados relativos a contas correntes, contas poupanças, cartões de crédito, certificados, incluindo logins, senhas e demais informações necessárias para acessar, coletar, armazenar, usar e tratar as informações obtidas nos sites das instituições financeiras.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>20.3.	INFORMAÇÕES FINANCEIRAS: informações fornecidas pelo LICENCIADO ou coletadas diretamente dos sites e dispositivos da instituição financeira por meio do SOFTWARE com os dados do LICENCIADO, tais como recebimentos, pagamentos, investimentos, etc.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>20.4.	INFORMAÇÕES PESSOAIS: qualquer informação disponibilizada pelo LICENCIADO que o identifique ou torne identificável pessoas naturais, tais como nome, endereço, data de nascimento, número de telefone, fax, endereço eletrônico, número de documentos, etc.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>20.5.	INFORMAÇÕES: entende-se todas as informações do LICENCIADO relacionadas a INFORMAÇÕES PESSOAIS, INFORMAÇÕES FINANCEIRAS e INFORMAÇÕES DE CONTA.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>20.6.	LICENCIADO: pessoa física ou jurídica, com plena capacidade de contratar, que acessa o SOFTWARE da LICENCIANTE por meio do site, realizando seu cadastro, aceitando os termos do presente EULA e usufruindo das funcionalidades oferecidas de acordo com o plano de licenciamento contratado.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>20.7.	SOFTWARE: software de propriedade exclusiva da LICENCIANTE, cujas funcionalidades e serviços estão disponibilizados pelo site, por meio do qual as INFORMAÇÕES FINANCEIRAS do LICENCIADO serão fornecidas diretamente por ele ou coletadas diretamente nos sites das instituições financeiras de maneira automatizada.</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>20.8.	API: Application Programming Interface que em português significa Interface de Programação de Aplicativos. É um conjunto de rotinas e padrões de programação para acesso a um aplicativo de software ou plataforma baseado na Web.</p>
            </Typography>

            <Typography variant='body2' mt={6}>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>  Dourados/MS, Abril de 2024</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>INVICTOS TECNOLOGIA LTDA</p>
            </Typography>

          </Grid>
        </Grid>
      </MDBox>
    </TermosDeUsoLayout >
  );
}

export default TermosDeUso;
