import React, { useState, useEffect, useContext } from 'react';

// @mui material components
import Grid from "@mui/material/Grid";
import { Icon } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

//My Componentes
import FormField from "componentes/FormField";
//My componentes e functions
import MessagesContext from "hooks/MessagesContext";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import AuthContext from 'hooks/AuthContext';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';



function Cadastro(props) {

    const messageContext = useContext(MessagesContext);
    const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;

    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();

    const { setCodigoAbrir, codigoAbrir, setAba } = props;

    const [id_externo, setId_externo] = useState('');
    const [nome, setNome] = useState('');
    const [referencia, setReferencia] = useState('');
    const [classificacao, setClassificacao] = useState('');
    const [tipo_produto_id, setTipo_produto_id] = useState({ value: -1, label: '<Escolha um Tipo>' });
    const [optionTiposProdutos, setOptionTiposProdutos] = useState([{ value: -1, label: '<Escolha um Tipo>' }]);

    const [ativo, setAtivo] = useState({
        label: 'Sim',
        value: 'S'
    });
    const [optionAtivo, setOptionAtivo] = useState([
        {
            label: 'Não',
            value: 'N'
        },
        {
            label: 'Sim',
            value: 'S'
        },
    ])

    const handleClickGravar = async (e) => {
        e.preventDefault();

        try {
            MostraTelaEspera('Gravando o grupo de produtos...');
            let retorno;

            const tipoprodutotemp = (parseInt(tipo_produto_id?.value, 10) > 0) ? tipo_produto_id.value : 0;

            const dados = {
                empresa_id: sessaolocal.empresa_id || null,
                nome,
                referencia,
                classificacao,
                tipo_produto_id: tipoprodutotemp,
                ativo: ativo.value,
                id_externo
            }
            // console.log(dados);

            if (codigoAbrir && parseInt(codigoAbrir, 10) > 0)
                retorno = await api.put(`${rotafechada()}grupos_produtos/${codigoAbrir}`, dados);
            else {
                retorno = await api.post(`${rotafechada()}grupos_produtos`, dados);
                props.setCodigoAbrir(retorno.data.data.id || 0)
            }

            MensagemAviso('Gravado com sucesso!');
            FechaTelaEspera();
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const buscaDados = async (tiposProdutos = []) => {

        if (!parseInt(props.codigoAbrir, 10) > 0)
            return;

        try {
            const retorno = await api.get(`${rotafechada()}grupos_produtos?id=${props.codigoAbrir}`);
            const { data, count } = retorno.data;

            if (!count > 0) {
                MensagemErro('Nenhuma informação foi selecionada');
                return;
            }

            if (data[0].empresa_id !== sessaolocal.empresa_id) {
                MensagemErro('Registro de outra empresa!');
                return;
            }

            setId_externo(data[0].id_externo);
            setNome(data[0].nome);
            setReferencia(data[0].referencia);
            setClassificacao(data[0].classificacao);

            if (data[0].ativo === 'N') {
                setAtivo(optionAtivo[0])
            }
            else setAtivo(optionAtivo[1])

            tiposProdutos.forEach((item) => {
                if (item.value == data[0].tipo_produto_id) {
                    setTipo_produto_id({ ...item })
                }
            })

        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }

    }

    const buscaTiposProdutos = async () => {
        try {
            const url = `${rotafechada()}tipos_produtos?limit=-1`;
            let retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;
            let opcoesTipoProdutoTemp = [{ id: -1, value: -1, label: '<Selecione um Tipo>' }];

            data.map((item) => {
                opcoesTipoProdutoTemp.push({ id: item.id, value: item.id, label: item.nome })
            })

            setOptionTiposProdutos(opcoesTipoProdutoTemp);

            return opcoesTipoProdutoTemp;
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }
    const limpaImputs = async () => {

        setCodigoAbrir('');
        setNome('');
        setReferencia('');
        setClassificacao('');
        setTipo_produto_id({ value: -1, label: '<Escolha um Tipo>' });

    }

    useEffect(() => {
        const preenche = async () => {
            const TiposProdutosTemp = await buscaTiposProdutos();
            await buscaDados(TiposProdutosTemp);
        }
        preenche();

    }, [codigoAbrir])

    return (
        <MDBox p={2}>
            <MDBox width="100%" display="flex" justifyContent="space-between">
                <MDButton
                    variant="gradient"
                    color="secondary"
                    type="button"
                    onClick={() => setAba(0)}
                >Voltar</MDButton>
                <MDButton
                    variant="gradient"
                    circular
                    color="info"
                    type="button"
                    onClick={limpaImputs}
                >
                    <Icon>add</Icon>
                </MDButton>
            </MDBox>
            <form onSubmit={handleClickGravar}>
                <MDBox>
                    <MDBox mt={3}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={3}>
                                <FormField
                                    type="text"
                                    label="Grupo de Produto"
                                    value={props.codigoAbrir || ''}
                                    disabled={true}
                                    onChange={(e) => setNome(e.target.value)} />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <FormField
                                    type="text"
                                    label="ID Externo"
                                    value={id_externo || ''}
                                    disabled={true}
                                    onChange={(e) => setNome(e.target.value)} />
                            </Grid>
                        </Grid>
                    </MDBox>

                    <MDBox mt={2}>
                        <Grid container spacing={2}>{/*nome*/}
                            <Grid item xs={12} sm={12}>
                                <FormField required type="text" label="Nome" value={nome} onChange={(e) => setNome(e.target.value)} />
                            </Grid>
                        </Grid>
                    </MDBox>

                    <MDBox mt={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>{/*classificacao*/}

                                <FormField type="text" label="Classificação" value={classificacao} onChange={(e) => setClassificacao(e.target.value)} />

                            </Grid>

                            <Grid item xs={12} sm={6}>{/*referencia*/}
                                <FormField type="text" label="Referência" value={referencia} onChange={(e) => setReferencia(e.target.value)} />
                            </Grid>

                            <Grid item xs={12} sm={6}>{/*tipo_produto_id*/}
                                <AutoCompleteStyled
                                    defaultValue={{ value: -1 }}
                                    options={optionTiposProdutos}
                                    getOptionLabel={(option) => option.label || ''}
                                    renderInput={(params) => <FormField required {...params} label="Tipos de Produtos" />}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    value={tipo_produto_id}
                                    onChange={(event, newValue) => {
                                        setTipo_produto_id(newValue);
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={2}>{/*ativo*/}
                                <AutoCompleteStyled
                                disableClearable //Retira o X (clear)
                                    defaultValue={optionAtivo ? optionAtivo[0] : { value: 'N' }}
                                    options={optionAtivo}
                                    getOptionLabel={(option) => option.label || ''}
                                    renderInput={(params) => <FormField {...params} label="Ativo" />}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    value={ativo}
                                    onChange={(event, newValue) => {
                                        setAtivo(newValue);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </MDBox>
                    <MDBox p={2}>
                        <MDBox mt={3} width="100%" display="flex" justifyContent="flex-end">
                            <MDButton
                                variant="gradient"
                                color="info"
                                type="submit"
                                //onClick={handleClickGravar}
                            >
                                Gravar
                            </MDButton>
                        </MDBox>
                    </MDBox>
                </MDBox>
            </form>

        </MDBox>
    )

}

export default Cadastro;