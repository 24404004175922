import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CadastroEmpresa from "./Cadastro";
import Sidenav from "./componentes/Sidenav.jsx";
import ConfiguracaoImpressao from "./ConfiguracaoImpressao";
import EmpresaContatos from "./EmpresaContatos";
import PerfilLogado from "./PerfilLogado";


export default function Empresa(props) {

    return (
        <>
            <DashboardLayout>
                <DashboardNavbar />

                <MDBox mt={4}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={2}>
                            <Sidenav />
                        </Grid>
                        <Grid item xs={12} lg={10}>
                            <MDBox mb={3}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <PerfilLogado/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CadastroEmpresa />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <EmpresaContatos />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ConfiguracaoImpressao />
                                    </Grid>

                                </Grid>
                            </MDBox>
                        </Grid>
                    </Grid>
                </MDBox>
            </DashboardLayout>

        </>
    )
}