import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useContext, useEffect, useState } from "react";
import SidenavAdmin from "./Sidenav";
import AdminEmpresas from "./Empresas";
import { rotafechada } from "commons/urlconf";
import api from "commons/api";
import MessagesContext from "hooks/MessagesContext";
import TiposPessoasAdmin from "./tipospessoas";
import CategoriaCentralRelatoriosAdmin from "./categoriacentralrelatorios";
import TiposContratosAdmin from "./tiposcontratos";
import PlanoContasAdmin from "./planocontas";
import FormaPagamentoAdmin from "./formapagamento";
import AtualizacaoAdmin from "./atualizacao";
import PerfisAcessoAdmin from "./perfisacesso";
import TabelaPrecoAdmin from "./tabelapreco";
import AjustaBaseFinanceiroAdmin from "./ajustabasefinanceiro";
import PagarContasLoteAdmin from "./pagarcontaslote"

export default function Admin(props) {

    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;
    const [permitido, setPermitido] = useState(false);
    const [openCollapseEmpresa, setopenCollapseEmpresa] = useState(false);
    const [openCollapseTipoPessoa, setopenCollapseTipoPessoa] = useState(false);
    const [openCollapseCategoriaCentralRelatorios, setopenCollapseCategoriaCentralRelatorios] = useState(false);
    const [openCollapseTipoContrato, setopenCollapseTipoContrato] = useState(false);
    const [openCollapsePlanoContas, setopenCollapsePlanoContas] = useState(false);
    const [openCollapseFormaPagamento, setopenCollapseFormaPagamento] = useState(false);
    const [openCollapseAcessoPerfil, setopenCollapseAcessoPerfil] = useState(false);
    const [openCollapseAtualizacao, setopenCollapseAtualizacao] = useState(false);
    const [openCollapseTabelaPreco, setopenCollapseTabelaPreco] = useState(false);
    const [openCollapseAjustaBaseFinanceiro, setopenCollapseAjustaBaseFinanceiro] = useState(false);
    const [openCollapsePagarContasLote, setopenCollapsePagarContasLote] = useState(false);


    const validaPermissao = async () => {

        const baseUrl = `${rotafechada()}admin`;
        try {
            await api.get(`${baseUrl}/permissao/acessar`);
            setPermitido(true);
        }
        catch (error) {
            setPermitido(false);
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Não autorizado o Acesso a esse módulo`);
        }
    }

    useEffect(() => {
        validaPermissao();
    }, [])

    const handleClickCollapseEmpresa = (estado) => {
        setopenCollapseEmpresa(estado);
        if (estado === true) {
            setopenCollapseTipoPessoa(false);
            setopenCollapseCategoriaCentralRelatorios(false);
            setopenCollapseAtualizacao(false);
            setopenCollapseAcessoPerfil(false);
            setopenCollapseTabelaPreco(false);
            setopenCollapseTipoContrato(false);
            setopenCollapsePlanoContas(false);
            setopenCollapseFormaPagamento(false);
            setopenCollapseAjustaBaseFinanceiro(false);
            setopenCollapsePagarContasLote(false);
        }
    }

    const handleClickCollapseTipoPessoa = (estado) => {
        setopenCollapseTipoPessoa(estado);
        if (estado === true) {
            setopenCollapseEmpresa(false);
            setopenCollapseAtualizacao(false);
            setopenCollapseAcessoPerfil(false);
            setopenCollapseTabelaPreco(false);
            setopenCollapseTipoContrato(false);
            setopenCollapsePlanoContas(false);
            setopenCollapseFormaPagamento(false);
            setopenCollapseAjustaBaseFinanceiro(false);
            setopenCollapsePagarContasLote(false);
        }
    }

    const handleClickCollapseCategoriaCentralRelatorios = (estado) => {
        setopenCollapseCategoriaCentralRelatorios(estado);
        if (estado === true) {
            setopenCollapseTipoPessoa(false);
            setopenCollapseEmpresa(false);
            setopenCollapseAtualizacao(false);
            setopenCollapseAcessoPerfil(false);
            setopenCollapseTabelaPreco(false);
            setopenCollapseTipoContrato(false);
            setopenCollapsePlanoContas(false);
            setopenCollapseFormaPagamento(false);
            setopenCollapseAjustaBaseFinanceiro(false);
            setopenCollapsePagarContasLote(false);
        }
    }

    const handleClickCollapseTipoContrato = (estado) => {
        setopenCollapseTipoContrato(estado);
        if (estado === true) {
            setopenCollapseTipoPessoa(false);
            setopenCollapseCategoriaCentralRelatorios(false);
            setopenCollapseEmpresa(false);
            setopenCollapseAtualizacao(false);
            setopenCollapseAcessoPerfil(false);
            setopenCollapseTabelaPreco(false);
            setopenCollapsePlanoContas(false);
            setopenCollapseFormaPagamento(false);
            setopenCollapseAjustaBaseFinanceiro(false);
            setopenCollapsePagarContasLote(false);
        }
    }

    const handleClickCollapseAcessoPerfil = (estado) => {
        setopenCollapseAcessoPerfil(estado);
        if (estado === true) {
            setopenCollapseEmpresa(false);
            setopenCollapseAtualizacao(false);
            setopenCollapseTipoPessoa(false);
            setopenCollapseCategoriaCentralRelatorios(false);
            setopenCollapseTabelaPreco(false);
            setopenCollapseTipoContrato(false);
            setopenCollapsePlanoContas(false);
            setopenCollapseFormaPagamento(false);
            setopenCollapseAjustaBaseFinanceiro(false);
            setopenCollapsePagarContasLote(false);
        }
    }

    const handleClickCollapseAtualizacao = (estado) => {
        setopenCollapseAtualizacao(estado);
        if (estado === true) {
            setopenCollapseEmpresa(false);
            setopenCollapseTipoPessoa(false);
            setopenCollapseCategoriaCentralRelatorios(false);
            setopenCollapseAcessoPerfil(false);
            setopenCollapseTabelaPreco(false);
            setopenCollapseTipoContrato(false);
            setopenCollapsePlanoContas(false);
            setopenCollapseFormaPagamento(false);
            setopenCollapseAjustaBaseFinanceiro(false);
            setopenCollapsePagarContasLote(false);
        }
    }

    const handleClickCollapseTabelaPreco = (estado) => {
        setopenCollapseTabelaPreco(estado);
        if (estado === true) {
            setopenCollapseEmpresa(false);
            setopenCollapseTipoPessoa(false);
            setopenCollapseCategoriaCentralRelatorios(false);
            setopenCollapseAcessoPerfil(false);
            setopenCollapseAtualizacao(false);
            setopenCollapseTipoContrato(false);
            setopenCollapsePlanoContas(false);
            setopenCollapseFormaPagamento(false);
            setopenCollapseAjustaBaseFinanceiro(false);
            setopenCollapsePagarContasLote(false);
        }
    }

    const handleClickCollapsePlanoContas = (estado) => {
        setopenCollapsePlanoContas(estado);
        if (estado === true) {
            setopenCollapseEmpresa(false);
            setopenCollapseTipoPessoa(false);
            setopenCollapseCategoriaCentralRelatorios(false);
            setopenCollapseAcessoPerfil(false);
            setopenCollapseAtualizacao(false);
            setopenCollapseTipoContrato(false);
            setopenCollapseTabelaPreco(false);
            setopenCollapseFormaPagamento(false);
            setopenCollapseAjustaBaseFinanceiro(false);
            setopenCollapsePagarContasLote(false);
        }
    }

    const handleClickCollapseFormaPagamento = (estado) => {
        setopenCollapseFormaPagamento(estado);
        if (estado === true) {
            setopenCollapseEmpresa(false);
            setopenCollapseTipoPessoa(false);
            setopenCollapseCategoriaCentralRelatorios(false);
            setopenCollapseAcessoPerfil(false);
            setopenCollapseAtualizacao(false);
            setopenCollapseTipoContrato(false);
            setopenCollapseTabelaPreco(false);
            setopenCollapsePlanoContas(false);
            setopenCollapseAjustaBaseFinanceiro(false);
            setopenCollapsePagarContasLote(false);
        }
    }

    const handleClickCollapseAjustaBaseFinanceiro = (estado) => {
        setopenCollapseAjustaBaseFinanceiro(estado);
        if (estado === true) {
            setopenCollapseEmpresa(false);
            setopenCollapseTipoPessoa(false);
            setopenCollapseCategoriaCentralRelatorios(false);
            setopenCollapseAcessoPerfil(false);
            setopenCollapseAtualizacao(false);
            setopenCollapseTipoContrato(false);
            setopenCollapseTabelaPreco(false);
            setopenCollapsePlanoContas(false);
            setopenCollapseFormaPagamento(false);
            setopenCollapsePagarContasLote(false);
        }
    }

    const handleClickCollapsePagarContasLote = (estado) => {
        setopenCollapsePagarContasLote(estado);
        if (estado === true) {
            setopenCollapseEmpresa(false);
            setopenCollapseTipoPessoa(false);
            setopenCollapseCategoriaCentralRelatorios(false);
            setopenCollapseAcessoPerfil(false);
            setopenCollapseAtualizacao(false);
            setopenCollapseTipoContrato(false);
            setopenCollapseTabelaPreco(false);
            setopenCollapsePlanoContas(false);
            setopenCollapseFormaPagamento(false);
            setopenCollapseAjustaBaseFinanceiro(false);
        }
    }

    return (
        <>
            {
                permitido ?
                    <DashboardLayout>
                        <DashboardNavbar />

                        <MDBox mt={4}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} lg={2}>
                                    <SidenavAdmin />
                                </Grid>
                                <Grid item xs={12} lg={10}>
                                    <MDBox mb={3}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <AdminEmpresas
                                                    openCollapseEmpresa={openCollapseEmpresa}
                                                    handleClickCollapseEmpresa={handleClickCollapseEmpresa}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TiposPessoasAdmin
                                                    openCollapseTipoPessoa={openCollapseTipoPessoa}
                                                    handleClickCollapseTipoPessoa={handleClickCollapseTipoPessoa}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <CategoriaCentralRelatoriosAdmin
                                                    openCollapseCategoriaCentralRelatorios={openCollapseCategoriaCentralRelatorios}
                                                    handleClickCollapseCategoriaCentralRelatorios={handleClickCollapseCategoriaCentralRelatorios}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TiposContratosAdmin
                                                    openCollapseTipoContrato={openCollapseTipoContrato}
                                                    handleClickCollapseTipoContrato={handleClickCollapseTipoContrato}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <PerfisAcessoAdmin
                                                    openCollapseAcessoPerfil={openCollapseAcessoPerfil}
                                                    handleClickCollapseAcessoPerfil={handleClickCollapseAcessoPerfil}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <AtualizacaoAdmin
                                                    openCollapseAtualizacao={openCollapseAtualizacao}
                                                    handleClickCollapseAtualizacao={handleClickCollapseAtualizacao}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TabelaPrecoAdmin
                                                    openCollapseTabelaPreco={openCollapseTabelaPreco}
                                                    handleClickCollapseTabelaPreco={handleClickCollapseTabelaPreco}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <PlanoContasAdmin
                                                    openCollapsePlanoContas={openCollapsePlanoContas}
                                                    handleClickCollapsePlanoContas={handleClickCollapsePlanoContas}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormaPagamentoAdmin
                                                    openCollapseFormaPagamento={openCollapseFormaPagamento}
                                                    handleClickCollapseFormaPagamento={handleClickCollapseFormaPagamento}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <AjustaBaseFinanceiroAdmin
                                                    openCollapseAjustaBaseFinanceiro={openCollapseAjustaBaseFinanceiro}
                                                    handleClickCollapseAjustaBaseFinanceiro={handleClickCollapseAjustaBaseFinanceiro}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <PagarContasLoteAdmin
                                                    openCollapsePagarContasLote={openCollapsePagarContasLote}
                                                    handleClickCollapsePagarContasLote={handleClickCollapsePagarContasLote}
                                                />
                                            </Grid>
                                        </Grid>
                                    </MDBox>
                                </Grid>
                            </Grid>
                        </MDBox>
                    </DashboardLayout>
                    :
                    <>

                    </>
            }
        </>
    )
}